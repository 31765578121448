import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { constants } from 'src/helpers/constants';

@Injectable({
    providedIn: 'root'
})

export class NotificationService {

    constructor(private http: HttpClient) { }

    async Generate2FACode(request: any): Promise<any> {
        return await this.http.post(constants.url_constants.api + '/api/Notification/Generate2FACode', request).toPromise();
    }
    async Verify2FACode(request: any): Promise<any> {
        return await this.http.post(constants.url_constants.api + '/api/Notification/Verify2FACode', request).toPromise();
    }
}