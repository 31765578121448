import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { SharedService } from '../../../../ej-shared/services/shared.service';
import { ToggleOptions } from '../../directives/toggle.directive';
import { LayoutConfigService } from '../../services/layout-config.service'
import { StorageService } from '../../../../ej-shared/services/storage.service'
import { AuthService } from 'src/idsvr/auth.service';

@Component({
	selector: 'ej-header-mobile',
	templateUrl: './header-mobile.component.html',
	styleUrls: ['./header-mobile.component.scss']
})
export class HeaderMobileComponent implements OnInit {
	headerLogo: string;
	asideDisplay: boolean;
	isMember: boolean = false;
	displaySimpleHeader: boolean = false;
	username: any;
	toggleOptions: ToggleOptions = {
		target: 'body',
		targetState: 'kt-header__topbar--mobile-on',
		togglerState: 'kt-header-mobile__toolbar-topbar-toggler--active'
	};

	constructor(private layoutConfigService: LayoutConfigService,
		private readonly sharedService: SharedService,
		private readonly storageService: StorageService,
		private cdRef: ChangeDetectorRef,
		private readonly authService: AuthService) {
		this.sharedService.getLogoChangeEvent().subscribe(() => {
			let logo = this.storageService.getTenantLogo();
			if (logo == undefined || logo == null || logo == "" || logo == "undefined" || logo == "null") {
				this.headerLogo = this.layoutConfigService.getLogo();
				this.cdRef.detectChanges();
			} else {
				logo = logo.replace('OriginalImage', '150x40')
				this.headerLogo = logo;
				this.cdRef.detectChanges();
			}
		});

		this.setDisplayHeaderCustom();
	}

	ngOnInit() {
		let logo = this.storageService.getTenantLogo();
		if (logo == undefined || logo == null || logo == "" || logo == "undefined" || logo == "null") {
			this.headerLogo = this.layoutConfigService.getLogo();
			this.cdRef.detectChanges();
		} else {
			logo = logo.replace('OriginalImage', '150x40')
			this.headerLogo = logo;
			this.cdRef.detectChanges();
		}
		this.isMember = this.sharedService.isMember();
		this.cdRef.detectChanges();
		this.asideDisplay = this.layoutConfigService.getConfig('aside.self.display');
		this.username = this.storageService.getLoggedinUserName();
	}

	setDisplayHeaderCustom() {
		if (window.location.pathname.includes('createcourse') || window.location.pathname.includes('courseprofile')) {
			this.displaySimpleHeader = true;
		}
	}

	signout() {
		this.authService.signout();
	  }
}