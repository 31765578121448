import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, PRIMARY_OUTLET, NavigationStart } from '@angular/router';
import { filter } from 'rxjs/operators';
import { map } from 'rxjs/internal/operators';
import { Title } from '@angular/platform-browser';
import { SharedService } from '../../../ej-shared/services/shared.service'

@Component({
	selector: 'ej-subheader',
	templateUrl: './subheader.component.html',
})
export class SubheaderComponent implements OnInit {

	layout: string;
	breadcrumbs;
	title = "Home";

	constructor(
		private cdRef: ChangeDetectorRef,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private titleService: Title,
		private readonly sharedService: SharedService
	) {
		this.sharedService.getBredcumEvent().subscribe(() => {
			this.breadcrumbs = this.sharedService.subHeader.bredcums;
			this.title = this.sharedService.subHeader.title;
			this.titleService.setTitle(this.title);
			this.cdRef.detectChanges();
		})
	}

	ngOnInit(): void {
		this.router.events
			.subscribe(route => {
				this.breadcrumbs = [];
				this.title = '';
			});
	}
}