<!DOCTYPE html>
<html lang="en"
    class="wf-poppins-n3-active wf-poppins-n4-active wf-poppins-n5-active wf-poppins-n6-active wf-poppins-n7-active wf-roboto-n3-active wf-roboto-n4-active wf-roboto-n5-active wf-roboto-n6-active wf-roboto-n7-active wf-active">

<head>
    <meta charset="utf-8">
    <title>
        eduJournal
    </title>
    <meta name="description" content="Latest updates and statistic charts">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    <!--begin::Web font -->

    <script src="https://ajax.googleapis.com/ajax/libs/webfont/1.6.16/webfont.js"></script>
    <link rel="stylesheet"
        href="http://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700%7CRoboto:300,400,500,600,700"
        media="all">
    <script>
        WebFont.load({
            google: {
                "families": ["Poppins:300,400,500,600,700", "Roboto:300,400,500,600,700"]
            },
            active: function () {
                sessionStorage.fonts = true;
            }
        });
    </script>

    <!--end::Web font -->
    <!--begin::Base Styles -->
    <!-- <link href="../../../assets/vendors/base/vendors.bundle.css" rel="stylesheet" type="text/css"> -->
    <!-- <link href="../../../assets/demo/default/base/style.bundle.css" rel="stylesheet" type="text/css"> -->
    <!--end::Base Styles -->
    <link rel="shortcut icon" href="../../../assets/demo/default/media/img/logo/fav.ico">
    <script src="http://cdnjs.cloudflare.com/ajax/libs/jquery-mousewheel/3.1.13/jquery.mousewheel.min.js"></script>
    <!-- END HEAD -->
</head>

<body
    class="kt--skin- kt-header--fixed kt-header--fixed-mobile kt-aside-left--enabled kt-aside-left--skin-dark kt-aside-left--fixed kt-aside-left--offcanvas kt-footer--push kt-aside--offcanvas-default">
    <!-- begin:: Page -->
    <div class="kt-grid kt-grid--hor kt-grid--root kt-page">
        <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-login kt-login--signin kt-login--2 kt-login-2--skin-2"
            id="m_login" style="background-image: url(../../../assets/app/media/img//bg/bg-3.jpg);">
            <div class="kt-grid__item kt-grid__item--fluid	m-login__wrapper">
                <div class="kt-login__container">
                    <div class="kt-login__logo">
                        <a href="#">
                            <img src="assets/media/logos/ej_logo.png">
                        </a>
                    </div>
                    <div class="kt-login__signin">
                        <div class="kt-login__head">
                            <h3 class="kt-login__title">Create and Sell your courses.</h3>
                            <div class="kt-login__desc">Simple to use system that can manage your entire Training Institute.
                                Claim your free account and start growing your business.</div>
                        </div>
                        <form class="kt-form kt--padding-top-20" name="form"
                            (ngSubmit)="f.form.valid && registerTenant()" #f="ngForm" novalidate>
                            <div class="form-group validated">
                                <div class="input-group">
                                    <input type="email" class="form-control kt-input" id="email" name="email"
                                        autocomplete="off" #email="ngModel"
                                        [(ngModel)]="tenantCreateViewModel.email" (blur)="tenantEmailOccured()"
                                        [ngClass]="{ 'is-invalid': f.submitted && email.invalid }" required
                                        maxlength="100" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$">
                                    <div class="input-group-append"><span class="input-group-text">
                                            <i class="la la-envelope"></i></span>
                                    </div>
                                </div>
                                <div *ngIf="f.submitted && email.invalid" class="invalid-feedback">
                                    <div *ngIf="email.errors.required">Email is required</div>
                                    <div *ngIf="email.errors.pattern">
                                        Invalid email format
                                    </div>
                                    <div *ngIf="email.errors.maxlength">
                                        Email cannot exceed 100 characters
                                    </div>
                                </div>
                            </div>

                            <div class="form-group validated">
                                <div class="input-group">
                                    <input type="text" class="form-control" id="tenancyname" name="tenancyname"
                                        autocomplete="off" #tenancyname="ngModel"
                                        [(ngModel)]="tenantCreateViewModel.tenancyname" (blur)="tenancyNameOccured()"
                                        [ngClass]="{ 'is-invalid': f.submitted && tenancyname.invalid }" required
                                        minlength="3" maxlength="100" />
                                    <div class="input-group-append"><span class="input-group-text"
                                            id="basic-addon2">.edujournal.com</span></div>
                                </div>
                                <div *ngIf="f.submitted && tenancyname.invalid" class="invalid-feedback">
                                    <div *ngIf="tenancyname.errors.required">Tenancy Name is required</div>
                                    <div *ngIf="tenancyname.errors.minlength">
                                        Tenancy Name must have at least 3 characters
                                    </div>
                                    <div *ngIf="tenancyname.errors.maxlength">
                                        Tenancy Name cannot exceed 100 characters
                                    </div>
                                </div>
                            </div>
                            <div class="kt-login__form-action kt-align-center">
                                <button type="submit"
                                    class="btn btn-focus kt-btn kt-btn--pill kt-btn--custom kt-btn--air kt-login__btn kt-login__btn--primary"
                                    [disabled]="isSave || (tenantCreateViewModel?.tenancyname == '' || tenantCreateViewModel?.email == '')">
                                    SIGN UP FOR FREE
                                </button>
                            </div>

                        </form>
                    </div>
                    <div class="kt-login__account">
                        <span class="kt-login__account-msg">
                            <!--By Signing Up, you agree to our <a href="javascript:;" id="m_login_signup" class="kt-link kt-link--light kt-login__account-link">T&C</a> and <a href="javascript:;" id="m_login_signup" class="kt-link kt-link--light kt-login__account-link">Privacy Policy</a>-->
                            Try eduJournal free for 14 days. No risk, and no payment information required.
                        </span>&nbsp;&nbsp;
                        <!--<a href="javascript:;" id="m_login_signup" class="kt-link kt-link--light kt-login__account-link">Sign Up</a>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- end:: Page -->
    <!--begin::Base Scripts -->
    <script src="../../../assets/vendors/base/vendors.bundle.js" type="text/javascript"></script>
    <!-- <script src="../../../assets/demo/default/base/scripts.bundle.js" type="text/javascript"></script> -->
    <!--end::Base Scripts -->
    <!--begin::Page Snippets -->
    <script src="../../../assets/snippets/custom/pages/user/login.js" type="text/javascript"></script>
    <!--end::Page Snippets -->
    <!-- end::Body -->



</body>

</html>