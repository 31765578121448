import * as tslib_1 from "tslib";
import { OnInit, ChangeDetectorRef } from '@angular/core';
export class TenantPackageComponent {
    constructor(tenantRegistrationService, chRef, route, router) {
        this.tenantRegistrationService = tenantRegistrationService;
        this.chRef = chRef;
        this.route = route;
        this.router = router;
        this.editionViewModel = {};
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.getEdition();
        });
    }
    getEdition() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.tenantRegistrationService.getAvailableEditions().then((data) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                // getting the first edition as per old logic
                // need to change
                yield this.tenantRegistrationService.getEditionByID(data[0].id).then(data => {
                    this.editionViewModel = data;
                    this.chRef.detectChanges();
                });
            }));
        });
    }
    registrationrequest(editionId, packageId, trialPeriod) {
        this.router.navigate(['/register', editionId, packageId, trialPeriod]);
        //this.router.navigate(['/register']);
    }
}
