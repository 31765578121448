import { Injectable } from '@angular/core';
import { monthNumToName, padNumber, NumToMonth } from '../../datepicker/util/util';
import { NgbDateAdapter, NgbDateStruct, } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class NgbCustomDateNativeAdapter extends NgbDateAdapter<string> {

  model: any;

  /**
   * Converts a native `Date` to a `NgbDateStruct`.
   */
  fromModel(date: string): NgbDateStruct {
    if (!date) { return null; }
    let _day = Number(date.substring(0, 2));
    let _month = Number(NumToMonth(date.substring(3, 6)));
    let _year = Number(date.substring(7, 11));
    this.model = {
      "year": _year,
      "month": _month,
      "day": _day
    }
    return this.model;
  }

  toModel(date: NgbDateStruct): string {

    return date ? String('00' + date.day).slice(-2) + '-' + monthNumToName(padNumber(date.month)) + '-' + date.year.toString() : null;
  }

  protected _fromNativeDate(date: Date): NgbDateStruct {
    return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
  }

  protected _toNativeDate(date: NgbDateStruct): Date {
    const jsDate = new Date(date.year, date.month - 1, date.day, 12);
    // avoid 30 -> 1930 conversion
    jsDate.setFullYear(date.year);
    return jsDate;
  }
}