import { Component, OnInit, ChangeDetectorRef, ViewRef } from '@angular/core';
import { GlobalService } from 'src/ej-shared/services/global.service';
import { SharedService } from '../../../ej-shared/services/shared.service'

@Component({
  selector: 'ej-header-menu',
  templateUrl: './header-menu.component.html'
})
export class HeaderMenuComponent implements OnInit {
  subscriptionExpiryInfo: any;
  secondaryInitialSetupCompleted: boolean;
  isTenant: boolean;
  isMember: boolean;

  constructor(private cdRef: ChangeDetectorRef,
    private globalService: GlobalService,
    private readonly sharedService: SharedService
  ) {

    this.isTenant = globalService.isTenant;
    this.isMember = this.sharedService.isMember();
    this.globalService.isTenantChange.subscribe((value) => {
      this.isTenant = value;
      this.detectChanges();
    });

    this.subscriptionExpiryInfo = globalService.subscriptionExpiryInfo;
    this.globalService.subscriptionExpiryInfoChange.subscribe((value) => {
      this.subscriptionExpiryInfo = value;
      this.detectChanges();
    });

    // this.secondaryInitialSetupCompleted = globalService.secondaryInitialSetupCompleted;
    // this.globalService.secondaryInitialSetupCompletedChange.subscribe((value) => {
    //   this.secondaryInitialSetupCompleted = value;
    //   this.detectChanges();
    // });
  }

  ngOnInit() {
  }

  detectChanges() {
    if (this.cdRef && !(this.cdRef as ViewRef).destroyed) {
      this.cdRef.detectChanges();
    }
  }
}
