import * as tslib_1 from "tslib";
import { Subject, BehaviorSubject } from 'rxjs';
import * as objectPath from 'object-path';
export class MenuConfigService {
    constructor() {
        this.menuList$ = new BehaviorSubject([]);
        // register on config changed event and set default config
        this.onConfigUpdated$ = new Subject();
    }
    /**
     * Returns the menuConfig
     */
    getMenus() {
        return this.menuConfig;
    }
    /**
     * Load config
     *
     * @param config: any
     */
    loadConfigs(config) {
        this.menuConfig = config;
        this.onConfigUpdated$.next(this.menuConfig);
    }
    loadMenuByProfileRole(roleName) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (roleName == 'Super Admin') {
                return yield objectPath.get(this.getMenus(), 'menu_items.items');
            }
            return [];
        });
    }
}
