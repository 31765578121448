import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { constants } from '../../../helpers/constants';
import { environment } from 'src/environments/environment';
export class TenantNotFoundComponent {
    constructor(http, router) {
        this.http = http;
        this.router = router;
    }
    ngOnInit() {
        this.tenantUrl = window.location.origin;
        this.registerTenantUrl = environment.websiteUrl;
        this.validateTenant();
    }
    validateTenant() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.http.get(constants.url_constants.api + '/api/TenantResolverApi/GetTenantDetail').toPromise().then((res) => {
                if (res.tenant.id != "00000000-0000-0000-0000-000000000000") {
                    this.router.navigate(['/']);
                }
            });
        });
    }
}
