import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { Router } from '@angular/router';
import * as $ from 'jquery'
import { StorageService } from './storage.service';
declare var angular: any;

@Injectable({
    providedIn: "root"
})

export class NavigatorService {
    constructor(
        private router: Router,
        private readonly storageService: StorageService
    ) { }
    public navigate(dest: string, reload: boolean) {
        if (dest == 'courses' || dest == '/courses') {
            dest = '/myapp/courses';
        }

        if (window.location.pathname.includes('adminapp') || dest.includes('adminapp')
            || window.location.pathname.includes('member') || dest.includes('member')
            || window.location.pathname.includes('myapp') || dest.includes('myapp')) {
            this.router.navigate([dest]);
        } else if (reload) {
            window.location.href = dest;
        }
        else {
            let elem: HTMLElement = document.getElementById('btnNavigate') as HTMLElement;
            elem.setAttribute('href', dest);
            elem.click();
        }
    }
}

angular.module('core')
    .factory('navigatorService', downgradeInjectable(NavigatorService));