import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-tenant-reg-result',
  templateUrl: './tenant-reg-result.component.html',
  styleUrls: ['./tenant-reg-result.component.css',
    '../../../../assets/vendors/general/bootstrap/dist/css/bootstrap.min.css',
    '../../../../assets/css/wp/animate.css',
    '../../../../assets/vendors/general/swiper/css/swiper.min.css',
    '../../../../assets/vendors/general/bootstrap/dist/css/bootstrap.min.css',
    '../../../../assets/css/wp/layout.min.css'
  ]
})
export class TenantRegResultComponent implements OnInit {
  isSuccess: boolean;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.isSuccess = (this.route.snapshot.paramMap.get('isSuccess') == 'true');
  }

}
