/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header-mobile.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../../directives/toggle.directive";
import * as i5 from "./header-mobile.component";
import * as i6 from "../../services/layout-config.service";
import * as i7 from "../../../../ej-shared/services/shared.service";
import * as i8 from "../../../../ej-shared/services/storage.service";
import * as i9 from "../../../../idsvr/auth.service";
var styles_HeaderMobileComponent = [i0.styles];
var RenderType_HeaderMobileComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderMobileComponent, data: {} });
export { RenderType_HeaderMobileComponent as RenderType_HeaderMobileComponent };
function View_HeaderMobileComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "kt-header-mobile__logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "a", [["routerLink", "/"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", "logo"]], [[1, "src", 4]], null, null, null, null))], function (_ck, _v) { var currVal_2 = "/"; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = _co.headerLogo; _ck(_v, 3, 0, currVal_3); }); }
function View_HeaderMobileComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "kt-header-mobile__toolbar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "kt-header-mobile__toggler kt-header-mobile__toggler--left"], ["id", "kt_aside_mobile_toggler"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["class", "kt-header-mobile__topbar-toggler"], ["ejToggle", ""], ["id", "kt_header_mobile_topbar_toggler"]], null, null, null, null, null)), i1.ɵdid(4, 4210688, null, 0, i4.ToggleDirective, [i1.ElementRef], { options: [0, "options"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["class", "flaticon-more"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.toggleOptions; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.asideDisplay || _co.isMember); _ck(_v, 1, 0, currVal_0); }); }
function View_HeaderMobileComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "kt-header-mobile__toolbar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["style", "color: #959cb6;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Hi,\u00A0"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["style", "color: #6c7293; font-weight: 500;"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\u00A0\u00A0 "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "a", [["class", "btn btn-label btn-label-primary btn-sm btn-bold"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.signout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Sign Out "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.username; _ck(_v, 4, 0, currVal_0); }); }
export function View_HeaderMobileComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "kt-header-mobile kt-header-mobile--fixed"], ["id", "kt_header_mobile"]], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "justify-content-end": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderMobileComponent_1)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderMobileComponent_2)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderMobileComponent_3)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "kt-header-mobile kt-header-mobile--fixed"; var currVal_1 = _ck(_v, 3, 0, _co.displaySimpleHeader); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = !_co.displaySimpleHeader; _ck(_v, 5, 0, currVal_2); var currVal_3 = !_co.displaySimpleHeader; _ck(_v, 7, 0, currVal_3); var currVal_4 = _co.displaySimpleHeader; _ck(_v, 9, 0, currVal_4); }, null); }
export function View_HeaderMobileComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ej-header-mobile", [], null, null, null, View_HeaderMobileComponent_0, RenderType_HeaderMobileComponent)), i1.ɵdid(1, 114688, null, 0, i5.HeaderMobileComponent, [i6.LayoutConfigService, i7.SharedService, i8.StorageService, i1.ChangeDetectorRef, i9.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderMobileComponentNgFactory = i1.ɵccf("ej-header-mobile", i5.HeaderMobileComponent, View_HeaderMobileComponent_Host_0, {}, {}, []);
export { HeaderMobileComponentNgFactory as HeaderMobileComponentNgFactory };
