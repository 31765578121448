export class currentUserInfo {
    constructor() {
      this.profile = {};
    }
    access_token: string;
    token_type: string;
    expires_at: number;
    refresh_token: string;
    profile: object;
  }

  export class TokenResponse {
    accessToken: string;
    tokenType: string;
    refreshToken: string;
    expiresIn: number;
  }
  
  export class TokenfromURL {
    constructor(){
          this.tokenResponse = new TokenResponse();
      }
    tokenResponse: TokenResponse;
  }