import * as tslib_1 from "tslib";
import { OnInit, ElementRef, AfterViewInit } from '@angular/core';
import { Trainer } from './trainer.model';
import { FileTransferDTO } from './trainer.model';
import { environment } from '../../../environments/environment';
export class TrainerGettingStartedComponent {
    constructor(router, trainerGettingStartedService, storageService, tenantInitService, globalService) {
        this.router = router;
        this.trainerGettingStartedService = trainerGettingStartedService;
        this.storageService = storageService;
        this.tenantInitService = tenantInitService;
        this.globalService = globalService;
        this.config = [];
        this.fileTransferDTO = new FileTransferDTO();
        this.currentstep = 1;
        this.trainer = new Trainer();
        this.error = {
            firstName: false,
            lastName: false,
            email: false,
            phone: false
        };
        this.loggedInCountry = '';
        this.loggedInCountryCode = '';
        this.addressSaved = false;
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (environment.isProduction)
                this.checkTenantPrimarySetupStatus();
            this.countries = this.storageService.getCountryCodes();
            yield this.trainerGettingStartedService.getTenantLoggedInCountry().then(res => {
                let country = res.country_name;
                this.loggedInCountry = country;
                this.trainer.address.country = country;
                if (country != undefined && country != null && country != "") {
                    let countrycodes = this.storageService.getCountryCodes();
                    let selectedCountry = countrycodes.filter(x => x.name.toLowerCase() == this.trainer.address.country.toLowerCase());
                    if (selectedCountry != undefined && selectedCountry.length > 0) {
                        this.loggedInCountryCode = selectedCountry[0].dial_code + '-';
                        this.trainer.phoneNumber = selectedCountry[0].dial_code + '-';
                    }
                }
            });
        });
    }
    ngAfterViewInit() {
        $(document).ready(function () {
            function formatState(state) {
                if (!state.id) {
                    return state.text;
                }
                if (state.element.text == "") {
                    return;
                }
                var flagcssclass = 'flag-icon-' + state.element.getAttribute("data-country");
                var $state = $('<span><span class="flag-icon flag-icon-squared ' + flagcssclass + '"></span>  &nbsp;' + state.text + '</span>');
                return $state;
            }
            ;
            function formatResult(state) {
                if (!state.id) {
                    return state.text;
                }
                var flagcssclass = 'flag-icon-' + state.element.getAttribute("data-country");
                var name = state.text;
                var $state = $('<span> <span class="flag-icon flag-icon-squared ' + flagcssclass + '"></span>' + '&nbsp;' + name + '</span>');
                return $state;
            }
            ;
            $("#ddlCountryCode").select2({
                templateResult: formatState,
                templateSelection: formatResult,
            });
        });
    }
    checkTenantPrimarySetupStatus() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.tenantInitService.checkTrainerPrimaryInitialSetupStatus().then((response) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (response.status) {
                    this.router.navigate(['/dashboard']);
                }
            }));
        });
    }
    saveandcontinue() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.currentstep == 1) {
                let resultName = this.validateFirstName();
                let resultEmail = this.validateStep2();
                if (resultName && resultEmail) {
                    this.trainer.requiredSampleData = true;
                    this.currentstep = 2;
                }
                let countrycodes = this.storageService.getCountryCodes();
                if (this.trainer.address != undefined && this.trainer.address.country != null && this.trainer.address.country != undefined) {
                    let selectedCountry = countrycodes.filter(x => x.name.toLowerCase() == this.trainer.address.country.toLowerCase());
                    if (selectedCountry != undefined && selectedCountry.length > 0) {
                        this.trainer.phoneNumber = selectedCountry[0].dial_code + '-';
                    }
                }
            }
            else if (this.currentstep == 2) {
                yield this.trainerGettingStartedService.saveTrainerProfileDetails(this.trainer).then(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    this.globalService.showSchoolTour = true;
                    yield this.globalService.setCountries();
                    yield this.globalService.setCurrency(null);
                    yield this.globalService.setInternationalCurrency(null);
                    this.router.navigate(['/dashboard']);
                    return;
                }));
            }
        });
    }
    previousclickevent() {
        this.currentstep = this.currentstep - 1;
    }
    validateFirstName() {
        this.error.firstName = false;
        if (this.trainer.firstName == undefined || this.trainer.firstName == null || this.trainer.firstName == "" || this.trainer.firstName.trim() == '') {
            this.error.firstName = true;
        }
        return !this.error.firstName;
    }
    validateStep2() {
        this.error.email = false;
        if (this.trainer.email != undefined && this.trainer.email != null && this.trainer.email != "" && this.trainer.email.trim() != '') {
            this.error.email = !this.validateEmail(this.trainer.email);
            ;
        }
        else {
            this.error.email = true;
        }
        return !this.error.email;
    }
    validateEmail(email) {
        var regexEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
        if (regexEmail.test(email)) {
            return true;
        }
        else {
            return false;
        }
    }
    receivePhoneNumber(number) {
        this.trainer.phoneNumber = number;
    }
    skip() {
        if (this.currentstep == 1) {
            this.trainer.firstName = "";
            this.trainer.lastName = "";
        }
        else if (this.currentstep == 2) {
            this.trainer.email = "";
            this.trainer.phoneNumber = this.loggedInCountryCode;
        }
        else if (this.currentstep == 5) {
        }
        this.currentstep = +this.currentstep + 1;
        if (this.currentstep == 5) {
            this.trainer.requiredSampleData = true;
        }
        this.ngAfterViewInit();
    }
}
