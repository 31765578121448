import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { AuthService } from '../../../idsvr/auth.service'
import { StorageService } from '../../../ej-shared/services/storage.service'
import { SharedService } from '../../../ej-shared/services/shared.service'
import { constants } from '../../../helpers/constants'
import { Router } from '@angular/router';
import { NavigatorService } from 'src/ej-shared/services/navigator.service';

@Component({
  selector: 'ej-topbar-user',
  templateUrl: './topbar-user.component.html'
})
export class TopbarUserComponent implements OnInit {
  username: string;
  isAdmin: boolean;
  isMember: boolean;
  trainerType: any;
  constructor(
    private cdRef: ChangeDetectorRef,
    private readonly authService: AuthService,
    private storageService: StorageService,
    private readonly router: Router,
    private readonly navigatorService: NavigatorService,
    private readonly sharedService: SharedService) {
    this.sharedService.getClickEvent().subscribe(() => {
      this.ngOnInit();
    })
  }

  ngOnInit() {
    this.username = this.storageService.getLoggedinUserName().toLocaleUpperCase();
    this.isAdmin = false;
    if (window.location.origin.replace('/client', '').toLowerCase() == constants.admin_url.replace('/client', '').toLowerCase()) {
      this.isAdmin = true;
    }
    this.isMember = this.sharedService.isMember();
    this.trainerType = this.storageService.getTrainerType();
    this.cdRef.detectChanges();
  }

  logout() {
    this.authService.signout();
  }

  gotoWebsite() {
    this.authService.gotoWebsite();
  }

  redirect(path) {
    this.navigatorService.navigate(path, false)
  }

  redirectToMyProfile(){
    if(this.trainerType == 1 || this.trainerType == 3){
      this.redirect('/myapp/trainerprofile');
    }
    else{
      this.redirect('/myprofile');
    }
  }
}