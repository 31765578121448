import { AfterViewInit, Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ToggleOptions } from '../../directives/toggle.directive';
import { HtmlClassService } from '../../html-class.service';
import { LayoutConfigService } from '../../services/layout-config.service'
import { StorageService } from '../../../../ej-shared/services/storage.service'
import { SharedService } from '../../../../ej-shared/services/shared.service'
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/idsvr/auth.service';

@Component({
	selector: 'ej-brand',
	templateUrl: './brand.component.html',
	styleUrls: ['brand.component.scss']
})
export class BrandComponent implements OnInit {
	headerLogo: string;
	headerStickyLogo: string;

	toggleOptions: ToggleOptions = {
		target: 'body',
		targetState: 'kt-aside--minimize',
		togglerState: 'kt-aside__brand-aside-toggler--active'
	};

	constructor(
		private cdRef: ChangeDetectorRef,
		private layoutConfigService: LayoutConfigService,
		public htmlClassService: HtmlClassService,
		private readonly storageService: StorageService,
		private readonly sharedService: SharedService,
		private readonly authService: AuthService
	) {
		this.sharedService.getLogoChangeEvent().subscribe(() => {
			let logo = this.storageService.getTenantLogo();
			if (logo == undefined || logo == null || logo == "" || logo == "undefined" || logo == "null") {
				this.headerLogo = this.layoutConfigService.getLogo();
				this.headerStickyLogo = this.layoutConfigService.getStickyLogo();
				this.cdRef.detectChanges();
			} else {
				logo = logo.replace('OriginalImage', '150x40')
				this.headerLogo = logo;
				this.headerStickyLogo = logo;
				this.cdRef.detectChanges();
			}
		});
	}

	ngOnInit(): void {
		let logo = this.storageService.getTenantLogo();
		if (logo == undefined || logo == null || logo == "" || logo == "undefined" || logo == "null") {
			this.headerLogo = this.layoutConfigService.getLogo();
			this.headerStickyLogo = this.layoutConfigService.getStickyLogo();
			this.cdRef.detectChanges();
		} else {
			logo = logo.replace('OriginalImage', '150x40')
			this.headerLogo = logo;
			this.headerStickyLogo = logo;
			this.cdRef.detectChanges();
		}
		this.cdRef.detectChanges();
	}

	headerLogoClick(){
		if(this.storageService.getTrainerType() == 1 || this.storageService.getTrainerType() == 3){
			window.open(environment.websiteUrl);
		}
		else{
			this.authService.gotoWebsite();
		}
	}
}