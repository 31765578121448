import * as tslib_1 from "tslib";
import { BlockUI } from 'ng-block-ui';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as i0 from "@angular/core";
import { BlockuiTemplateComponent } from '../../ej-ts/layout/block-ui-template/block-ui-template.component';
export class LoaderService {
    constructor() {
        this.blockTemplate = BlockuiTemplateComponent;
        this.blockLoader = false;
    }
    showLoader(message = "Please wait...", autostop = false) {
        if (!this.blockLoader) {
            this.blockUI.start(message);
            if (autostop) {
                setTimeout(() => {
                    this.blockUI.stop();
                }, 1000);
            }
            else {
                setTimeout(() => {
                    this.blockUI.stop();
                }, 30000);
            }
        }
    }
    hideLoader() {
        this.blockUI.stop();
    }
    setBlockLoader(message = "Please wait...", autostop = false) {
        this.blockLoader = true;
        setTimeout(() => {
            this.blockLoader = false;
        }, 5000);
    }
}
LoaderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoaderService_Factory() { return new LoaderService(); }, token: LoaderService, providedIn: "root" });
tslib_1.__decorate([
    BlockUI()
], LoaderService.prototype, "blockUI", void 0);
angular.module('core')
    .factory('loaderService', downgradeInjectable(LoaderService));
