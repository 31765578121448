import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { HttpClient } from '@angular/common/http';
import { constants } from 'src/helpers/constants';
import { GlobalService } from './global.service';
declare var angular: any;

@Injectable({
    providedIn: 'root'
})
export class TenantInitService {
    constructor(private http: HttpClient,
        public globalService: GlobalService) { }

    async checkPrimaryInitialSetupStatus() {
        return await this.http.get(constants.url_constants.api + '/api/InitialSetup/checkPrimaryInitialSetupStatus/').toPromise().then((res: any) => {
            this.globalService.setPrimaryInitialSetupCompleted(res.status);
            return res;
        });
    }

    async checkSecondaryInitialSetupStatus() {
        return await this.http.get(constants.url_constants.api + '/api/InitialSetup/checkSecondaryInitialSetupStatus/').toPromise().then((res: any) => {
            this.globalService.setSecondaryInitialSetupCompleted(res.status);
            return res;
        });
    }

    async getTenantSubscriptionExpiryInfo() {
        return await this.http.get(constants.url_constants.api + '/api/LayoutApi/GetTenantSubscriptionExpiryInfo/').toPromise().then((res: any) => {
            this.globalService.setSubscriptionExpiryInfo(res);
            return res;
        });
    }

    // sets the global variables
    async setTenantInit() {
        await this.getTenantSubscriptionExpiryInfo().then(async (response: any) => {
            await this.checkPrimaryInitialSetupStatus().then(async (response: any) => {
                if (response.status) {
                    await this.checkSecondaryInitialSetupStatus().then(response => { });
                }
            });
        });
    }
    
    async checkTrainerPrimaryInitialSetupStatus() {
        return await this.http.get(constants.url_constants.api + '/api/InitialSetup/checkTrainerPrimaryInitialSetupStatus/').toPromise().then((res: any) => {
            this.globalService.setTrainerPrimaryInitialSetupCompleted(res.status);
            return res;
        });
    }
}

angular.module('core')
    .factory('tenantInitService', downgradeInjectable(TenantInitService));