

export class Bredcum {
    link: string;
    label: string;
}

export class SubHeader {
    title: string;
    bredcums: Array<Bredcum> = new Array<Bredcum>();
}