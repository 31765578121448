import * as tslib_1 from "tslib";
import { downgradeInjectable } from '@angular/upgrade/static';
import { constants } from 'src/helpers/constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./storage.service";
export class PermissionService {
    constructor(http, storageService) {
        this.http = http;
        this.storageService = storageService;
        this.permissionList = [];
        this.apiUrl = constants.url_constants.api;
    }
    setPermissions(permissions) {
        this.permissionList = permissions;
        //$rootScope.$broadcast('permissionsChanged');
    }
    hasPermission(permission) {
        permission = permission.trim();
        return this.permissionList.some(item => {
            if (typeof item !== 'string') { // item.Name is only used because when I called setPermission, I had a Name property
                return false;
            }
            return item.trim() === permission;
        });
    }
    //Get User Permissions
    GetUserPermissionById() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let userId = this.storageService.getUserId();
            return yield this.http.get(this.apiUrl + '/api/UserApi/GetUserPermissionById/' + userId).toPromise();
        });
    }
}
PermissionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PermissionService_Factory() { return new PermissionService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.StorageService)); }, token: PermissionService, providedIn: "root" });
angular.module('core')
    .factory('permissions', downgradeInjectable(PermissionService));
