import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
declare var angular: any;
import { SharedService } from './shared.service'
import { JsonPipe } from '@angular/common';

@Injectable({
    providedIn: "root"
})

export class StorageService {
    constructor(
        private readonly sharedService: SharedService
    ) { }

    // Used to get the display name in header
    public getLoggedinUserName(): string {
        let objUserDetails = JSON.parse(window.localStorage.getItem('currentUser'));
        if (this.getTrainerType() == 1 || this.getTrainerType() == 3) {
            return this.getTenantName();
        }
        else {
            if (objUserDetails != null && objUserDetails != undefined && objUserDetails.profile != null && objUserDetails.profile != undefined) {
                return objUserDetails.profile.given_name == undefined ? objUserDetails.profile.preferred_username : objUserDetails.profile.given_name;
                //return objUserDetails.profile.preferred_username == undefined ? objUserDetails.profile.name : objUserDetails.profile.preferred_username;
            } else {
                // Something went wrong with current user
                return '';
            }
        }
    }

    public setCurrentUser(user) {
        window.localStorage.setItem('currentUser', JSON.stringify(user));
    }

    public clearCurrentUser() {
        window.localStorage.removeItem('currentUser');
        window.localStorage.removeItem('tenantid');
        window.localStorage.removeItem('tenantlogo');
        window.localStorage.removeItem('issignintokenuser');
        window.localStorage.clear();
    }

    public getCurrentUser(): string {
        return window.localStorage.getItem('currentUser');
    }

    public getAccessToken(): string {
        let currentUser = JSON.parse(this.getCurrentUser());
        if (currentUser && currentUser.access_token) {
            return currentUser.access_token;
        } else {
            return '';
        }
    }

    public getUserId(): string {
        let currentUser = JSON.parse(this.getCurrentUser());
        if (currentUser && currentUser.access_token) {
            return currentUser.profile.sub;
        } else {
            return '';
        }
    }

    public setTenantId(tenantId: string) {
        window.localStorage.setItem('tenantid', tenantId);
    }

    public getTenantId(): string {
        return window.localStorage.getItem('tenantid');
    }

    public setTenantLogo(logo: string) {
        window.localStorage.setItem('tenantlogo', logo);
        //this.sharedService.sendLogoChangeEvent();
    }

    public getTenantLogo(): string {
        return window.localStorage.getItem('tenantlogo');
    }

    public setOTAC(tenantId: string) {
        window.localStorage.setItem('otac', tenantId);
    }

    public getOTAC(): string {
        return window.localStorage.getItem('otac');
    }

    public setCountryCodes(data) {
        window.localStorage.setItem('countrycodes', JSON.stringify(data));
    }
    public getCountryCodes() {
        return JSON.parse(window.localStorage.getItem('countrycodes'));
    }

    public setPhoneCountryCodes(data) {
        window.localStorage.setItem('phonecountrycodes', JSON.stringify(data));
    }
    public getPhoneCountryCodes() {
        return JSON.parse(window.localStorage.getItem('phonecountrycodes'));
    }

    public setTrainerType(data) {
        window.localStorage.setItem('trainertype', JSON.stringify(data));
    }
    public getTrainerType() {
        return JSON.parse(window.localStorage.getItem('trainertype'));
    }

    public setTenantName(data) {
        window.localStorage.setItem('tenantname', JSON.stringify(data));
    }
    public getTenantName() {
        return JSON.parse(window.localStorage.getItem('tenantname'));
    }

    public setCurrency(data) {
        window.localStorage.setItem('currency', JSON.stringify(data));
    }
    public getCurrency() {
        return JSON.parse(window.localStorage.getItem('currency'));
    }

    public setInternationalCurrency(data) {
        window.localStorage.setItem('internationalcurrency', JSON.stringify(data));
    }
    public getInternationalCurrency() {
        return JSON.parse(window.localStorage.getItem('internationalcurrency'));
    }

    public setIsSignInTokenUser(data) {
        window.localStorage.setItem('issignintokenuser', JSON.stringify(data));
    }
    public getIsSignInTokenUser() {
        return JSON.parse(window.localStorage.getItem('issignintokenuser'));
    }
}

angular.module('core')
    .factory('storageService', downgradeInjectable(StorageService));