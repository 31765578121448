/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./signin.component";
import * as i2 from "@angular/router";
import * as i3 from "../auth.service";
import * as i4 from "../../ej-shared/services/shared.service";
var styles_SigninComponent = [];
var RenderType_SigninComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SigninComponent, data: {} });
export { RenderType_SigninComponent as RenderType_SigninComponent };
export function View_SigninComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_SigninComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ej-signin", [], null, null, null, View_SigninComponent_0, RenderType_SigninComponent)), i0.ɵdid(1, 114688, null, 0, i1.SigninComponent, [i2.ActivatedRoute, i2.Router, i3.AuthService, i4.SharedService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SigninComponentNgFactory = i0.ɵccf("ej-signin", i1.SigninComponent, View_SigninComponent_Host_0, {}, {}, []);
export { SigninComponentNgFactory as SigninComponentNgFactory };
