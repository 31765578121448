import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { downgradeInjectable } from '@angular/upgrade/static';
declare var angular: any;

@Injectable({
    providedIn: "root"
})

export class ToasterService {
    constructor(private toastr: ToastrService) { }

    error(message: string): void {
        this.toastr.error(message, null, {
            timeOut: 3000,
            enableHtml: true,
        });
    }

    success(message: string): void {
        this.toastr.success(message, null, {
            timeOut: 3000,
            enableHtml: true
        });
    }

    info(message: string): void {
        this.toastr.info(message, null, {
            timeOut: 3000,
            enableHtml: true,
            closeButton: true,
        });
    }

    warning(message: string): void {
        this.toastr.warning(message, null, {
            timeOut: 3000,
            enableHtml: true
        });
    }
    clear() {
        this.toastr.clear();
    }
}

angular.module('core')
    .factory('toasterService', downgradeInjectable(ToasterService));