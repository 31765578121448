<div class="kt-subheader  kt-grid__item" id="kt_subheader">
    <div class="kt-container  kt-container--fluid ">
        <div class="kt-subheader__main">
            <!-- <button class="kt-subheader__mobile-toggle kt-subheader__mobile-toggle--left" id="kt_subheader_mobile_toggle"><span></span></button> -->
            <h3 class="kt-subheader__title"> {{title}}</h3>
            <span class="kt-subheader__separator kt-subheader__separator--v" *ngIf="breadcrumbs?.length > 0"></span>
            <div class="kt-subheader__breadcrumbs" *ngFor='let breadcrumb of breadcrumbs;index as i;last as isLast'>
                <span class="kt-subheader__breadcrumbs-separator" *ngIf="i!=0"></span>
                <a [routerLink]='[breadcrumb.link]' *ngIf='breadcrumb.link' class="kt-subheader__breadcrumbs-link">{{ breadcrumb.label }} </a>
                <a *ngIf='breadcrumb.link=="" && !isLast' class="kt-subheader__breadcrumbs-link">{{ breadcrumb.label }}</a>
                <a *ngIf='breadcrumb.link=="" && isLast' class="kt-subheader__breadcrumbs-link kt-font-brand">{{ breadcrumb.label }}</a>
            </div>
        </div>
    </div>
</div>