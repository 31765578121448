/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./footer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./footer.component";
import * as i4 from "../services/layout-config.service";
import * as i5 from "../../../ej-shared/services/global.service";
var styles_FooterComponent = [i0.styles];
var RenderType_FooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
function View_FooterComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["class", "btn btn-warning"], ["href", "subscription"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-angle-up"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Upgrade "]))], null, null); }
function View_FooterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "mt-cookie-consent-bar"], ["id", "subscriptionInfoDiv"], ["ng-class", "{'kt-bg-danger':subscriptionExpiryInfo.IsAccountExpired}"], ["style", "display: block;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "div", [["class", "mt-cookie-consent-bar-holder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "mt-cookie-consent-bar-content"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "mt-cookie-consent-bar-action"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\u00A0 "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "a", [["class", "btn btn-light btn-icon"], ["href", "javascript:;"], ["id", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeSubscriptionInfoDiv() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "i", [["class", "fa fa-times"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.subscriptionExpiryInfo.ShowUpgradeButton; _ck(_v, 7, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.subscriptionExpiryInfo.IsAccountExpired ? _co.subscriptionExpiryInfo.ExpiredMessage : _co.subscriptionExpiryInfo.ExpirationMessage); _ck(_v, 4, 0, currVal_0); }); }
export function View_FooterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "kt-footer kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop"], ["id", "kt_footer"], ["style", " position: fixed; bottom: 0; width: 100%;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "kt-container  kt-container--fluid "]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "kt-footer__copyright"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" eduJournal |\u00A0 "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "a", [["class", "kt-link"], ["href", "https://edujournal.com"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" www.edujournal.com "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.subscriptionExpiryInfo.ShowExpiryInfoDiv && !_co.subscriptionExpiryInfo.IsAccountExpired); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ej-footer", [], null, null, null, View_FooterComponent_0, RenderType_FooterComponent)), i1.ɵdid(1, 114688, null, 0, i3.FooterComponent, [i4.LayoutConfigService, i1.ChangeDetectorRef, i5.GlobalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FooterComponentNgFactory = i1.ɵccf("ej-footer", i3.FooterComponent, View_FooterComponent_Host_0, {}, {}, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };
