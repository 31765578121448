import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef, AfterViewInit, ViewEncapsulation } from '@angular/core';
import {StudentRegistrationformService} from '../student-registration-forms.service'
import { Router } from '@angular/router';
import { StorageService } from '../../../../ej-shared/services/storage.service'
import { GlobalService } from '../../../../ej-shared/services/global.service'
import { StudentRegistrationForm } from '../student-registration-form.model'
import {SharedService} from '../../../../ej-shared/services/shared.service'

@Component({
  selector: 'ej-add-student-registration-form',
  templateUrl: './add-student-registration-form.component.html',
  styleUrls: ['./add-student-registration-form.component.css']
})
export class AddStudentRegistrationFormComponent implements OnInit {

  public studentRegistrationForm: StudentRegistrationForm = new StudentRegistrationForm();

  public iseditmode: boolean = false;

  public error = {
    formname: false,
    description: false
  }
  constructor(private readonly router: Router,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly studentRegistrationformService: StudentRegistrationformService,
    private readonly sharedService: SharedService,
    private readonly globalService: GlobalService) 
    {}

  ngOnInit() {
    let bredcums = [];
    let bredcum = { 'label': 'Add Registration Form', 'link': '' };
    bredcums.push(bredcum);
    bredcum = { 'label': 'Registration Forms', 'link': '' };
    bredcums.push(bredcum);
    let subHeader = { 'title': 'Settings', 'bredcums': bredcums };
    this.sharedService.sendBredcumEvent(subHeader);
  }

  async saveandcontinue(){
    await this.studentRegistrationformService.saveRegistrationForm(this.studentRegistrationForm).then((response:any)=>{
      //this.registrationforms = response; 
    });
    this.changeDetectorRef.detectChanges();
  }
}
