<style>
    textarea {
        min-height: 70px !important;
    }
</style>

<div class="">
    <div class="row justify-content-md-center">
        <div class="col-lg-12">
            <!--begin::Portlet-->
            <div class="kt-portlet">
                <!--begin::Form-->
                <form class="kt-form  kt-form--label-align-right ng-pristine ng-valid" novalidate>
                    <div class="kt-portlet__body">
                        <input type="hidden"  />
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group ">
                                    <label>Name *</label>
                                    <input class="form-control" [(ngModel)]="studentRegistrationForm.formName" (ngModelChange)="error.formname = false" [ngClass]="{ 'is-invalid': error.formname }">
                                    <div *ngIf="error.formname" class="invalid-feedback">
                                        Please Enter Form Name --description
                                    </div>
                                </div>
                            </div>                            
                        </div>                        
                        <div class="form-group  row">
                            <div class="col-lg-12">
                                <label>Description *</label>
                                <div class="-icon -icon--right">
                                    <textarea class="form-control" [(ngModel)]="studentRegistrationForm.description" (ngModelChange)="error.description = false" [ngClass]="{ 'is-invalid': error.description }"> </textarea>
                                    <div class="invalid-feedback">Description is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="kt-portlet__foot kt-portlet__foot--solid">
                        <div class="kt-form__actions kt-form__actions--right"> 
                            <button  (click)="saveandcontinue()" *ngIf="!iseditmode" class="btn btn-primary"  ><i
                                    class="fa fa-save"></i> Save</button>
                            <button  (click)="saveandcontinue()" *ngIf="iseditmode" class="btn btn-primary"  ><i
                                    class="fa fa-save"></i> Update</button>
                            <a class="btn btn-secondary" routerLink="/studentregistrationforms" href=""><i class="fa fa-times"></i>
                                Close</a>
                        </div>
                    </div>
                </form>
                <!--end::Form-->
            </div>
            <!--end::Portlet-->
        </div>
    </div>
</div>