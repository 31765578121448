<head>
    <link href="../../../assets//css/error/error-5.css" rel="stylesheet" type="text/css" />
</head>
<div class="kt-grid kt-grid--hor kt-grid--root kt-page">


    <div class="kt-grid__item kt-grid__item--fluid kt-grid  kt-error-v5">
        <div class="kt-error_container">
            <span class="kt-error_title">
                <h1>Oops!</h1>
            </span>
            <p class="kt-error_subtitle">
                Something went wrong here.
            </p>
            <p class="kt-error_description">
                We're working on it and we'll get it fixed as soon possible. Click <a href="javascript:void(0);" (click)="goBack()">here</a> to go back or use our Help Center.
            </p>
        </div>
    </div>
</div>