import { Component, OnInit } from '@angular/core';
import { StorageService } from 'src/ej-shared/services/storage.service';
import { GlobalService } from 'src/ej-shared/services/global.service';
import { IntercomConfigKeys } from 'src/environments/environment';

declare var require: any;
var CryptoJS = require("crypto-js");
declare global {
  interface Window {
    Intercom: any;
  }
}

@Component({
  selector: 'ej-intercom',
  templateUrl: './intercom.component.html',
  styleUrls: ['./intercom.component.css']
})
export class IntercomComponent implements OnInit {
  username: any;
  userEmail: any;
  isTenant: boolean = false;
  constructor(private readonly storageService: StorageService,
    private readonly globalService: GlobalService) { }

  ngOnInit() {
    var self = this;
    setTimeout(() => {
      self.isTenant = self.globalService.getIsTenant();
      if (self.isTenant) {
        self.username = self.storageService.getLoggedinUserName();
        let user = self.storageService.getCurrentUser();
        if (JSON.parse(user)) {
          let currentUser = JSON.parse(user);
          self.userEmail = currentUser.profile.preferred_username;

          window.Intercom("boot", {
            app_id: IntercomConfigKeys.app_id,
            name: self.username, // Full name
            email: self.userEmail, // Email address
            user_hash: self.signKey(IntercomConfigKeys.identity_verification_secret, self.userEmail)
          });
        }
      }
    }, 5000);
  }

  signKey(clientKey: string, msg: string) {
    return CryptoJS.HmacSHA256(msg, clientKey).toString(CryptoJS.enc.Hex);
  }
}
