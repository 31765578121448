/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./student-registration-forms.component.css.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "@angular/common";
import * as i4 from "@angular/router";
import * as i5 from "./student-registration-forms.component";
import * as i6 from "./student-registration-forms.service";
import * as i7 from "../../../ej-shared/services/shared.service";
import * as i8 from "../../../ej-shared/services/global.service";
var styles_StudentRegistrationFormsComponent = [i0.styles];
var RenderType_StudentRegistrationFormsComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_StudentRegistrationFormsComponent, data: {} });
export { RenderType_StudentRegistrationFormsComponent as RenderType_StudentRegistrationFormsComponent };
function View_StudentRegistrationFormsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "kt-widget11__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "kt-widget11__sub"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "td", [["class", "kt-align-right kt-font-brand"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 16777216, null, null, 2, "a", [["class", "btn btn-secondary-only"], ["data-placement", "left"], ["ngbTooltip", "Configure"]], [[8, "href", 4]], null, null, null, null)), i1.ɵdid(10, 737280, null, 0, i2.NgbTooltip, [i1.ElementRef, i1.Renderer2, i1.Injector, i1.ComponentFactoryResolver, i1.ViewContainerRef, i2.NgbTooltipConfig, i1.NgZone, i3.DOCUMENT, i1.ChangeDetectorRef, i1.ApplicationRef], { placement: [0, "placement"], ngbTooltip: [1, "ngbTooltip"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 0, "i", [["class", "la la-cogs"]], null, null, null, null, null))], function (_ck, _v) { var currVal_3 = "left"; var currVal_4 = "Configure"; _ck(_v, 10, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.formName); _ck(_v, 4, 0, currVal_0); var currVal_1 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.description); _ck(_v, 6, 0, currVal_1); var currVal_2 = i1.ɵinlineInterpolate(1, "customForms/", _v.context.$implicit.id, ""); _ck(_v, 9, 0, currVal_2); }); }
export function View_StudentRegistrationFormsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "col-lg-12 kt-padding-b-20 kt-align-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "a", [["class", "btn btn-primary"], ["href", ""], ["routerLink", "/addstudentregistrationform"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "fa fa-plus"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Add New Form "])), (_l()(), i1.ɵeld(6, 0, null, null, 9, "div", [["class", "row justify-content-md-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 8, "div", [["class", "col-lg-10"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 7, "div", [["class", "kt-portlet"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 6, "div", [["class", "kt-portlet__body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 5, "div", [["class", "kt-widget11"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 4, "div", [["class", "table-responsive"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 3, "table", [["class", "table"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StudentRegistrationFormsComponent_1)), i1.ɵdid(15, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "/addstudentregistrationform"; _ck(_v, 3, 0, currVal_2); var currVal_3 = _co.registrationforms; _ck(_v, 15, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).target; var currVal_1 = i1.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); }); }
export function View_StudentRegistrationFormsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ej-student-registration-forms", [], null, null, null, View_StudentRegistrationFormsComponent_0, RenderType_StudentRegistrationFormsComponent)), i1.ɵdid(1, 114688, null, 0, i5.StudentRegistrationFormsComponent, [i4.Router, i1.ChangeDetectorRef, i6.StudentRegistrationformService, i7.SharedService, i8.GlobalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StudentRegistrationFormsComponentNgFactory = i1.ɵccf("ej-student-registration-forms", i5.StudentRegistrationFormsComponent, View_StudentRegistrationFormsComponent_Host_0, {}, {}, []);
export { StudentRegistrationFormsComponentNgFactory as StudentRegistrationFormsComponentNgFactory };
