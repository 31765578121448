import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenfromURL } from '../model/login';
import { AuthService } from '../auth.service';
import { SharedService } from 'src/ej-shared/services/shared.service';

@Component({
  selector: 'ej-signin',
  templateUrl: './signin.component.html'
})
export class SigninComponent implements OnInit {
  tokenfromURL: TokenfromURL = new TokenfromURL();
  usertoken: any;

  constructor(private route: ActivatedRoute, 
    private router: Router,
    private authService: AuthService,
    private sharedService: SharedService) { }

  ngOnInit() {
    // this.sharedService.sendShowSetupEvent(true);
    
    let accesstoken = this.route.snapshot.params['access_token'];
    let expiresat = this.route.snapshot.params['expires_at'];
    let refreshtoken = this.route.snapshot.params['refresh_token'];

    if (accesstoken == undefined || accesstoken == null || accesstoken == "") {
      accesstoken = "";
      this.router.navigate(['/']);
    }
    if (expiresat == undefined || expiresat == null || expiresat == "") {
      expiresat = 0;
    }
    if (refreshtoken == undefined || refreshtoken == null || refreshtoken == "") {
      refreshtoken = "";
    }

    this.tokenfromURL.tokenResponse.accessToken = accesstoken;
    this.tokenfromURL.tokenResponse.expiresIn = expiresat;
    this.tokenfromURL.tokenResponse.refreshToken = refreshtoken;
    this.tokenfromURL.tokenResponse.tokenType = "Bearer";
    this.usertoken = this.tokenfromURL;

    this.authService.completeAuthenticationSignIn(this.usertoken);
  }
}
