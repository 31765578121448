import { Injectable } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { downgradeInjectable } from '@angular/upgrade/static';
declare var angular: any;
import { BlockuiTemplateComponent } from '../../ej-ts/layout/block-ui-template/block-ui-template.component'

@Injectable({
    providedIn: 'root'
})
export class LoaderService {
    @BlockUI() blockUI: NgBlockUI;
    blockTemplate = BlockuiTemplateComponent;
    constructor() { }
    public blockLoader: boolean = false;

    showLoader(message: string | null = "Please wait...", autostop: boolean = false) {
        if (!this.blockLoader) {
            this.blockUI.start(message);
            if (autostop) {
                setTimeout(() => {
                    this.blockUI.stop();
                }, 1000);
            } else {
                setTimeout(() => {
                    this.blockUI.stop();
                }, 30000);
            }
        }
    }

    hideLoader() {
        this.blockUI.stop();
    }

    setBlockLoader(message: string | null = "Please wait...", autostop: boolean = false) {
        this.blockLoader = true;
        setTimeout(() => {
            this.blockLoader = false;
        }, 5000);
    }
}
angular.module('core')
    .factory('loaderService', downgradeInjectable(LoaderService));