import * as tslib_1 from "tslib";
import { OnInit, ChangeDetectorRef } from '@angular/core';
export class StudentRegistrationFormsComponent {
    constructor(router, changeDetectorRef, studentRegistrationformService, sharedService, globalService) {
        this.router = router;
        this.changeDetectorRef = changeDetectorRef;
        this.studentRegistrationformService = studentRegistrationformService;
        this.sharedService = sharedService;
        this.globalService = globalService;
    }
    ngOnInit() {
        let bredcums = [];
        let bredcum = { 'label': 'Manage Application Settings', 'link': '' };
        bredcums.push(bredcum);
        bredcum = { 'label': 'Registration Forms', 'link': '' };
        bredcums.push(bredcum);
        let subHeader = { 'title': 'Settings', 'bredcums': bredcums };
        this.sharedService.sendBredcumEvent(subHeader);
        this.getRegistrationforms();
    }
    getRegistrationforms() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.studentRegistrationformService.getRegistrationForms().then((response) => {
                this.registrationforms = response;
            });
            this.changeDetectorRef.detectChanges();
        });
    }
}
