import * as tslib_1 from "tslib";
import { downgradeInjectable } from '@angular/upgrade/static';
import { constants } from 'src/helpers/constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./global.service";
export class TenantInitService {
    constructor(http, globalService) {
        this.http = http;
        this.globalService = globalService;
    }
    checkPrimaryInitialSetupStatus() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(constants.url_constants.api + '/api/InitialSetup/checkPrimaryInitialSetupStatus/').toPromise().then((res) => {
                this.globalService.setPrimaryInitialSetupCompleted(res.status);
                return res;
            });
        });
    }
    checkSecondaryInitialSetupStatus() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(constants.url_constants.api + '/api/InitialSetup/checkSecondaryInitialSetupStatus/').toPromise().then((res) => {
                this.globalService.setSecondaryInitialSetupCompleted(res.status);
                return res;
            });
        });
    }
    getTenantSubscriptionExpiryInfo() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(constants.url_constants.api + '/api/LayoutApi/GetTenantSubscriptionExpiryInfo/').toPromise().then((res) => {
                this.globalService.setSubscriptionExpiryInfo(res);
                return res;
            });
        });
    }
    // sets the global variables
    setTenantInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.getTenantSubscriptionExpiryInfo().then((response) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                yield this.checkPrimaryInitialSetupStatus().then((response) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    if (response.status) {
                        yield this.checkSecondaryInitialSetupStatus().then(response => { });
                    }
                }));
            }));
        });
    }
    checkTrainerPrimaryInitialSetupStatus() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(constants.url_constants.api + '/api/InitialSetup/checkTrainerPrimaryInitialSetupStatus/').toPromise().then((res) => {
                this.globalService.setTrainerPrimaryInitialSetupCompleted(res.status);
                return res;
            });
        });
    }
}
TenantInitService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TenantInitService_Factory() { return new TenantInitService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.GlobalService)); }, token: TenantInitService, providedIn: "root" });
angular.module('core')
    .factory('tenantInitService', downgradeInjectable(TenantInitService));
