import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { constants } from '../../../helpers/constants'
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tenant-not-found',
  templateUrl: './tenant-not-found.component.html',
  styleUrls: ['./tenant-not-found.component.css']
})
export class TenantNotFoundComponent implements OnInit {

  public tenantUrl: string;
  public registerTenantUrl : string;
  constructor(
    private readonly http: HttpClient,
    private router: Router
  ) { }

  ngOnInit() {
    this.tenantUrl = window.location.origin;
    this.registerTenantUrl = environment.websiteUrl;
    this.validateTenant();
  }


  async validateTenant(){
    await this.http.get(constants.url_constants.api + '/api/TenantResolverApi/GetTenantDetail').toPromise().then((res: any) => {
      if (res.tenant.id != "00000000-0000-0000-0000-000000000000") {
          this.router.navigate(['/']);
      } 
  });
  }
}
