import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
export class TenantRegistrationComponent {
    constructor(tenantRegistrationService, route, router, toastrMessageService) {
        this.tenantRegistrationService = tenantRegistrationService;
        this.route = route;
        this.router = router;
        this.toastrMessageService = toastrMessageService;
        this.tenantCreateViewModel = {};
        this.isSave = false;
    }
    ngOnInit() {
        this.editionId = this.route.snapshot.paramMap.get('editionId');
        this.packageId = this.route.snapshot.paramMap.get('packageId');
        this.trialPeriod = this.route.snapshot.paramMap.get('trialPeriod');
        this.tenantCreateViewModel.email = '';
        this.tenantCreateViewModel.tenancyname = '';
    }
    registerTenant() {
        this.isSave = true;
        this.tenantCreateViewModel.editionId = this.editionId;
        this.tenantCreateViewModel.packageId = this.packageId;
        this.tenantRegistrationService.registerTenant(this.tenantCreateViewModel).then(res => {
            this.router.navigate(['/registration', res.status]);
        });
    }
    tenantEmailOccured() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.tenantCreateViewModel.email = this.tenantCreateViewModel.email.trim();
            if (this.tenantCreateViewModel.email) {
                yield this.tenantRegistrationService.tenantEmailOccured(this.tenantCreateViewModel.email).then((data) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    if (data) {
                        this.toastrMessageService.info('"' + this.tenantCreateViewModel.email + '"' + " already used");
                        this.tenantCreateViewModel.email = "";
                    }
                }));
            }
        });
    }
    tenancyNameOccured() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.tenantCreateViewModel.tenancyname = this.tenantCreateViewModel.tenancyname.trim();
            if (this.tenantCreateViewModel.tenancyname) {
                yield this.tenantRegistrationService.tenancyNameOccured(this.tenantCreateViewModel.tenancyname).then((data) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    if (data) {
                        this.toastrMessageService.info('"' + this.tenantCreateViewModel.tenancyname + '"' + " already used");
                        this.tenantCreateViewModel.tenancyname = "";
                    }
                }));
            }
        });
    }
}
