import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef, AfterViewInit, ViewEncapsulation } from '@angular/core';
import{StudentRegistrationformService} from './student-registration-forms.service'
import { Router } from '@angular/router';
import { StorageService } from '../../../ej-shared/services/storage.service'
import { GlobalService } from '../../../ej-shared/services/global.service'
import { StudentRegistrationForm } from './student-registration-form.model'
import {SharedService} from '../../../ej-shared/services/shared.service'

@Component({
  selector: 'ej-student-registration-forms',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './student-registration-forms.component.html',
  styleUrls: ['./student-registration-forms.component.css']
})
export class StudentRegistrationFormsComponent implements OnInit {
 
  registrationforms: Array<StudentRegistrationForm>;

  constructor(private readonly router: Router,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly studentRegistrationformService: StudentRegistrationformService,
    private readonly sharedService: SharedService,
    private readonly globalService: GlobalService) 
    {}

  ngOnInit() {
    let bredcums = [];
            let bredcum = { 'label': 'Manage Application Settings', 'link': '' };
            bredcums.push(bredcum);
            bredcum = { 'label': 'Registration Forms', 'link': '' };
            bredcums.push(bredcum);
            let subHeader = { 'title': 'Settings', 'bredcums': bredcums };
            
            this.sharedService.sendBredcumEvent(subHeader);

   this.getRegistrationforms();
  }

 async getRegistrationforms(){
    await this.studentRegistrationformService.getRegistrationForms().then((response:any)=>{
      this.registrationforms = response; 
    });
    this.changeDetectorRef.detectChanges();
  }
}
