import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { constants } from 'src/helpers/constants';
@Injectable({
    providedIn: 'root'
})

export class TenantVerificationService {

    constructor(private http: HttpClient) { }

    async getTenantVerification(): Promise<any> {
        return await this.http.get(constants.url_constants.api + '/api/TenantsApi/GetTenantVerification').toPromise();
    }
    async getTenantVerificationDetails(): Promise<any> {
        return await this.http.get(constants.url_constants.api + '/api/TenantsApi/GetTenantVerificationDetails').toPromise();
    }
    async getTenantDetails(tenantId): Promise<any> {
        return await this.http.get(constants.url_constants.api + '/api/TenantsApi/GetByID?id='+tenantId).toPromise();
    }

}