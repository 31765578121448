<!DOCTYPE html>
<html lang="en"
    class="wf-poppins-n3-active wf-poppins-n4-active wf-poppins-n5-active wf-poppins-n6-active wf-poppins-n7-active wf-roboto-n3-active wf-roboto-n4-active wf-roboto-n5-active wf-roboto-n6-active wf-roboto-n7-active wf-active">

<head>
    <meta charset="utf-8">
    <title>
        eduJournal | Registration
    </title>
    <meta name="description" content="Latest updates and statistic charts">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    <!--begin::Web font -->

    <!-- <script src="https://ajax.googleapis.com/ajax/libs/webfont/1.6.16/webfont.js"></script> -->
    <link rel="stylesheet"
        href="http://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700%7CRoboto:300,400,500,600,700"
        media="all">
    <script>
        WebFont.load({
            google: { "families": ["Poppins:300,400,500,600,700", "Roboto:300,400,500,600,700"] },
            active: function () {
                sessionStorage.fonts = true;
            }
        });
    </script>

    <!--end::Web font -->
    <!--begin::Base Styles -->
    <!-- <link href="../../.../../../assets/vendors/base/vendors.bundle.css" rel="stylesheet" type="text/css"> -->
    <!-- <link href="../../.../../../assets/demo/default/base/style.bundle.css" rel="stylesheet" type="text/css"> -->
    <!--end::Base Styles -->
    <!-- <link rel="shortcut icon" href="../../.../../../assets/demo/default/media/img/logo/fav.ico"> -->
    <!-- <script src="http://cdnjs.cloudflare.com/ajax/libs/jquery-mousewheel/3.1.13/jquery.mousewheel.min.js"></script> -->

</head>
<!-- END HEAD -->

<body
    class="kt-page--fluid kt--skin- kt-content--skin-light2 kt-header--fixed kt-header--fixed-mobile kt-aside-left--enabled kt-aside-left--skin-dark kt-aside-left--fixed kt-aside-left--offcanvas kt-footer--push kt-aside--offcanvas-default">
    <!-- begin:: Page -->
    <div class="kt-grid kt-grid--hor kt-grid--root kt-page">
        <!-- BEGIN: Header -->
        <header id="m_header" class="kt-grid__item    kt-header " kt-minimize-offset="200"
            kt-minimize-mobile-offset="200">
            <div class="kt-container kt-container--fluid kt-container--full-height"
                style="box-shadow:0px 0px 15px 0px rgba(69,65,78,0.1);">
                <div class="kt-stack kt-stack--ver kt-stack--desktop">
                    <!-- BEGIN: Brand -->
                    <div class="kt-stack__item kt-brand  kt-brand--skin-light ">
                        <div class="kt-stack kt-stack--ver kt-stack--general">
                            <div class="kt-stack__item kt-stack__item--middle kt-brand__logo">
                                <a href="?page=index&amp;demo=default" class="kt-brand__logo-wrapper">
                                    <img alt="" src="assets/media/logos/ej_logo.png">
                                </a>
                            </div>
                        </div>
                    </div>
                    <!-- END: Brand -->
                    <div class="kt-stack__item kt-stack__item--fluid kt-header-head" id="m_header_nav"
                        style="box-shadow:none;">
                        <div id="m_header_topbar"
                            class="kt-topbar  kt-stack kt-stack--ver kt-stack--general kt-stack--fluid">
                            <div class="kt-stack__item kt-topbar__nav-wrapper">
                                <ul class="kt-topbar__nav kt-nav kt-nav--inline">
                                    <li id="m_quick_sidebar_toggle" class="kt-nav__item">
                                        <a href="javascript:void(0)" class="kt-nav__link kt-dropdown__toggle">
                                            <span class="kt-nav__link-icon"><i class="la la-sign-in"></i></span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <!-- END: Header -->
        <!-- begin::Body -->
        <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver-desktop kt-grid--desktop kt-body kt--nopadding"
            style="padding: 0px;">
            <div class="kt-portlet col-lg-12" style="background-color:transparent;">
                <div class="kt-portlet__body kt-portlet__body--no-padding">
                    <div class="kt-pricing-table-2">
                        <div class="kt-pricing-table-2__head" style="padding-bottom:5rem;background-color:transparent;">
                            <div class="kt-pricing-table-2__title kt--font-dark">
                                <h1>{{editionViewModel?.edition?.name}}</h1>
                            </div>
                            <div class="btn-group nav kt-btn-group kt-btn-group--pill kt-btn-group--air" role="group">
                                <button type="button"
                                    class="btn kt-btn--pill  active kt-btn--wide kt-btn--uppercase kt-btn--bolder"
                                    data-toggle="tab" href="#kt-pricing-table_content1" role="tab" aria-expanded="true">
                                    Annual Plans
                                </button>
                                <button type="button"
                                    class="btn kt-btn--pill  kt-btn--wide kt-btn--uppercase kt-btn--bolder"
                                    data-toggle="tab" href="#kt-pricing-table_content2" role="tab" aria-expanded="false">
                                    Monthly Plans
                                </button>
                            </div>
                        </div>
                        <div class="tab-content">
                            <div class="tab-pane active" id="kt-pricing-table_content1" aria-expanded="true">
                                <div class="kt-pricing-table-3 kt-pricing-table-3--fixed">
                                    <div class="kt-pricing-table-3__items">
                                        <div class="row row-no-padding">
                                            <div class="kt-pricing-table-3__item col-lg-3"
                                            [ngClass]="{'kt-pricing-table-3__item--focus kt--bg-brand' : package.isRecomended , 'kt--bg-light' : !package.isRecomended}"
                                                *ngFor="let package of editionViewModel?.editionData">
                                                <div class="kt-pricing-table-3__wrapper">
                                                    <h3 class="kt-pricing-table-3__title">{{package?.name}}</h3>
                                                    <span class="kt-pricing-table-3__price">
                                                        <span class="kt-pricing-table-3__label">$</span>
                                                        <span
                                                            class="kt-pricing-table-3__number spyearlyPrice">{{package?.packagePrices[1]?.price}}</span>
                                                        <span class="kt-pricing-table-3__text">/&nbsp;&nbsp;Per
                                                            Installation</span>
                                                    </span>
                                                    <span class="kt-pricing-table-3__description">
                                                        <span *ngFor="let feature of package.features">
                                                            {{feature?.previewDisplayName}}<br>
                                                        </span>
                                                    </span>
                                                    <div class="kt-pricing-table-3__btn">
                                                        <a href="javascript:void(0)"
                                                            (click)="registrationrequest(editionViewModel.edition.id, package.id, package.trialPeriod)"
                                                            class="btn kt-btn--pill  btn-primary kt-btn--wide kt-btn--uppercase kt-btn--bolder kt-btn--lg">
                                                            START FREE TRAIL
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane" id="kt-pricing-table_content2" aria-expanded="false">
                                <div class="kt-pricing-table-3 kt-pricing-table-3--fixed">
                                    <div class="kt-pricing-table-3__items">
                                        <div class="row row-no-padding">
                                            <div class="kt-pricing-table-3__item col-lg-3"
                                            [ngClass]="{'kt-pricing-table-3__item--focus kt--bg-brand' : package.isRecomended , 'kt--bg-light' : !package.isRecomended}"
                                                *ngFor="let package of editionViewModel.editionData">
                                                <div class="kt-pricing-table-3__wrapper">
                                                    <h3 class="kt-pricing-table-3__title">{{package?.name}}</h3>
                                                    <span class="kt-pricing-table-3__price">
                                                        <span class="kt-pricing-table-3__label">$</span>
                                                        <span
                                                            class="kt-pricing-table-3__number spmonthlyPrice">{{package?.packagePrices[0]?.price}}</span>
                                                        <span class="kt-pricing-table-3__text">/&nbsp;&nbsp;Per
                                                            Installation</span>
                                                    </span>
                                                    <span class="kt-pricing-table-3__description">
                                                        <span *ngFor="let feature of package.features">
                                                            {{feature?.previewDisplayName}}<br>
                                                        </span>
                                                    </span>
                                                    <div class="kt-pricing-table-3__btn">
                                                        <a href="javascript:void(0)"
                                                            (click)="registrationrequest(editionViewModel.edition.id, package.iD, package.trialPeriod)"
                                                            class="btn kt-btn--pill  btn-primary kt-btn--wide kt-btn--uppercase kt-btn--bolder kt-btn--lg">
                                                            START FREE TRAIL
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
        <!-- end:: Body -->
        <!-- begin::Footer -->
        <!-- end::Footer -->


    </div>
    <!-- end:: Page -->

    <!--begin::Global Theme Bundle -->
    <!-- <script src="../../../assets/vendors/base/vendors.bundle.js" type="text/javascript"></script> -->
    <!-- <script src="../../../assets/demo/default/base/scripts.bundle.js" type="text/javascript"></script> -->
    <!--end::Global Theme Bundle -->
    <!-- end::Body -->



</body>

</html>