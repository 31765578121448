import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { TrimTextPipeEllipsis } from './pipes/trim-text-ellipsis.pipe';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PhoneNumberComponent } from '../utility/components/phone-number/phone-number.component';
import { DigitOnlyDirective } from './directives/digits-only.directive'
import { NgbDate, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CurrencyFormatPipe } from './pipes/currency-format.pipe';
import { ArrayFilterCallbackPipe } from './pipes/array-filter-callback.pipe';
import { CourseCommonViewComponent } from '../tenant-portal/course-common-view/course-common-view.component';
import { CoursesNewComponent } from '../tenant-portal/courses-new/courses-new.component';
import { CourseViewComponent } from '../tenant-portal/course-view/course-view.component';
import { IntercomComponent } from './components/intercom/intercom.component';
import { NgSelect2Module } from 'ng-select2';
import { EnquiryViewComponent } from '../shared/enquiry-view/enquiry-view.component';
import { BlockUIModule } from 'ng-block-ui';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgxPermissionsModule } from 'ngx-permissions';
import { SocialShareComponent } from '../shared/social-share/social-share.component';
import { CourseEnrolmentsViewComponent } from '../shared/course-enrolments-view/course-enrolments-view.component';
import { InternationalCurrencyFormatPipe } from './pipes/international-currency-format.pipe';
import { CourseCardCommonComponent } from '../shared/course-card-common/course-card-common.component';
import { AddAdminCoursePriceCommonComponent } from '../admin-portal/admin-course-price/add-admin-course-price-common/add-admin-course-price-common.component';
import { EnrollComponent } from '../shared/enroll/enroll.component';
import { EntryFormComponent } from './components/entry-form/entry-form.component';
import { EntryFieldComponent } from './components/entry-form/entry-field/entry-field.component';
import { StudentCourseScheduleComponent } from '../shared/student-course-schedule/student-course-schedule.component';
import { PaymentLinkModalComponent } from '../shared/payment-link-modal/payment-link-modal.component';
import { TrimTextPipe } from './pipes/trim-text.pipe';
import { LeadPaymentLinkModalComponent } from '../shared/lead-payment-link-modal/lead-payment-link-modal.component';
import { AddPaymentModalComponent } from '../shared/add-payment-modal/add-payment-modal.component';
import { StudentActivationEmailModalComponent } from '../shared/student-activation-email-modal/student-activation-email-modal.component';
import { SafePipe } from './pipes/safe.pipe';

@NgModule({
    declarations: [
        TrimTextPipe,
        TrimTextPipeEllipsis,
        PhoneNumberComponent,
        DigitOnlyDirective,
        CurrencyFormatPipe,
        InternationalCurrencyFormatPipe,
        ArrayFilterCallbackPipe,
        CourseCommonViewComponent,
        CoursesNewComponent,
        CourseViewComponent,
        IntercomComponent,
        EnquiryViewComponent,
        SocialShareComponent,
        CourseEnrolmentsViewComponent,
        CourseCardCommonComponent,
        AddAdminCoursePriceCommonComponent,
        EnrollComponent,
        EntryFormComponent,
        EntryFieldComponent,
        StudentCourseScheduleComponent,
        PaymentLinkModalComponent,
        LeadPaymentLinkModalComponent,
        AddPaymentModalComponent,
        StudentActivationEmailModalComponent,
        SafePipe
    ],
    providers: [],
    imports: [RouterModule, CommonModule, FormsModule, NgbModule, NgSelect2Module, BlockUIModule, PerfectScrollbarModule, NgxPermissionsModule],
    exports: [
        TrimTextPipe,
        TrimTextPipeEllipsis,
        PhoneNumberComponent,
        DigitOnlyDirective,
        NgbModule,
        CurrencyFormatPipe,
        InternationalCurrencyFormatPipe,
        ArrayFilterCallbackPipe,
        CourseCommonViewComponent,
        CoursesNewComponent,
        CourseViewComponent,
        IntercomComponent,
        EnquiryViewComponent,
        SocialShareComponent,
        CourseEnrolmentsViewComponent,
        CourseCardCommonComponent,
        AddAdminCoursePriceCommonComponent,
        EnrollComponent,
        StudentCourseScheduleComponent,
        PaymentLinkModalComponent,
        LeadPaymentLinkModalComponent,
        AddPaymentModalComponent,
        StudentActivationEmailModalComponent,
        NgxPermissionsModule,
        SafePipe
    ]
})

export class SharedModule {
}
