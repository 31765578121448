import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, AfterViewInit, OnChanges } from '@angular/core';
import { StorageService } from '../../../../ej-shared/services/storage.service'
import { GlobalService } from '../../../../ej-shared/services/global.service'
import { PhoneNumberUtil } from 'google-libphonenumber';

declare var $: any;
declare var jQuery: any;
const phoneUtil = PhoneNumberUtil.getInstance();

@Component({
  selector: 'ej-phone-number',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.scss']
})
export class PhoneNumberComponent implements AfterViewInit, OnChanges {
  @Input() disabled = false;

  @Input('inputPhoneNumber') inputPhoneNumber;
  @Input('displayLabel') displayLabel = true;
  @Input('required') required = false;
  @Input('isSubmitted') isSubmitted = false;
  @Input('inputClass') inputClass = '';
  @Input('dropdownClass') dropdownClass = '';
  @Output() phoneNumberChanged = new EventEmitter<string>();
  @Output() phoneNumberError = new EventEmitter<boolean>();

  public countryCode: string;
  public countryCodes: any;
  public trainerType: any;
  public phoneNumber: string;
  public phoneNumberErrorMessage: string;
  public phoneError: boolean = false;


  constructor(
    private readonly storageService: StorageService,
    private readonly globalService: GlobalService
  ) { }

  ngAfterViewInit() {
    var self = this;
    this.setupPhoneNumber();
    this.countryCodes = this.storageService.getPhoneCountryCodes();
    this.trainerType = this.storageService.getTrainerType();
    $(document).ready(function () {
      function customSearch(params, data) {
        if ($.trim(params.term) === '') {
          return data;
        }
        if (typeof data.text === 'undefined') {
          return null;
        }
        if (data.text.indexOf(params.term) > -1 || data.element.getAttribute('data-country').indexOf(params.term) > -1 || data.element.getAttribute('data-countryname').toLowerCase().includes(params.term.toLowerCase())) {
          var modifiedData = $.extend({}, data, true);
          return modifiedData;
        }
        return null;
      }
      function formatResult(state) {
        if (!state.id) {
          return state.text;
        }
        var flagcssclass = state.element.getAttribute("data-country");
        var $state = $(
          '<span> <span class="' + flagcssclass + '">' + state.element.getAttribute("dial-code") + '</span></span>'
        );
        return $state;
      };
      function formatState(state) {
        if (!state.id) {
          return state.text;
        }
        if (state.element.text == "") {
          return;
        }
        var flagcssclass = 'flag-icon-' + state.element.getAttribute("data-country");
        var $state = $(
          '<span><span class="flag-icon flag-icon-squared ' + flagcssclass + '"></span>  &nbsp;' + state.text + '</span>'
        );
        return $state;
      };

      $(".countrycode").select2({
        templateSelection: formatResult,
        templateResult: formatState,
        matcher: customSearch,
        containerCssClass: self.dropdownClass
      });

      $('#ddlCountryCode').on("select2:select", function (e) {
        self.countryCode = $(this).val().split(':')[1];
        self.phoneNumberChangedEvent();
      });
    });

  }

  ngOnChanges() {
    if(this.isSubmitted){
      this.validatePhoneNumber();
    }
  }

  setupPhoneNumber() {
    if (this.inputPhoneNumber != undefined && this.inputPhoneNumber != null && this.inputPhoneNumber != '') {
      if (this.inputPhoneNumber.includes('-')) {
        this.countryCode = (this.inputPhoneNumber.split('-')[0] == undefined || this.inputPhoneNumber.split('-')[0] == null || this.inputPhoneNumber.split('-')[0] == '') ? this.globalService.getCountry().dial_code : this.inputPhoneNumber.split('-')[0];
        this.phoneNumber = this.inputPhoneNumber.split('-')[1];
      } else {
        this.countryCode = this.globalService.getCountry().dial_code;
        this.phoneNumber = this.inputPhoneNumber;
      }
    } else {
      this.countryCode = this.globalService.getCountry().dial_code;
    }
  }

  phoneNumberChangedEvent() {
    this.phoneNumberEmit();
    this.validatePhoneNumber();
  }

  phoneNumberEmit() {
    let phoneNumber = '';
    if (this.countryCode != undefined && this.countryCode != null && this.countryCode != '' && this.phoneNumber != undefined && this.phoneNumber != null && this.phoneNumber != '') {
      phoneNumber = this.countryCode.replace(' ', '') + '-' + this.phoneNumber;
    } else if (this.phoneNumber != undefined && this.phoneNumber != null && this.phoneNumber != '') {
      phoneNumber = this.phoneNumber;
    }
    this.phoneNumberChanged.emit(phoneNumber);
  }

  validatePhoneNumber() {
    var self = this;
    this.phoneError = false;
    this.phoneNumberErrorMessage = "";

    if (this.phoneNumber && this.phoneNumber.length > 0) {

      var regionCode = '';
      var selectedCountries = this.countryCodes.filter(x => x.dial_code == self.countryCode.trim());
      if (selectedCountries && selectedCountries.length > 0) { regionCode = selectedCountries[0].code; }
      this.phoneError = !phoneUtil.isValidNumberForRegion(phoneUtil.parse(this.phoneNumber, regionCode), regionCode);
      this.phoneNumberErrorMessage = this.phoneError ? "Invalid phone number" : "";

      // if (this.phoneNumber.length < 8) {
      //   this.phoneError = true;
      //   this.phoneNumberErrorMessage = "Min length should be 8";
      // }
      // if (this.phoneNumber.length > 12 && this.trainerType == 3) {
      //   this.phoneError = true;
      //   this.phoneNumberErrorMessage = "Max length should be 12";
      // }
      // if (this.phoneNumber.length > 8 && this.trainerType != 3) {
      //   this.phoneError = true;
      //   this.phoneNumberErrorMessage = "Max length should be 8";
      // }
    }
    else if (self.required) {
      this.phoneError = true;
      this.phoneNumberErrorMessage = "Please enter Phone";
    }
    this.phoneNumberError.emit(this.phoneError);
  }
}
