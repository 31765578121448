<div class="modal fade show" id="session-timeout-dialog" data-backdrop="static" data-keyboard="false" style="padding-right: 17px; display: none;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Session Timeout!</h4>
            </div>
            <div class="modal-body">
                <p>Your session is about to expire.</p>
                <p>Redirecting in <span class="countdown-holder">{{countdown}}</span> seconds.</p>
                <div class="progress">
                    <div class="progress-bar progress-bar-striped countdown-bar active" role="progressbar"
                        [style.width]="timeoutPercentage + '%'" [attr.aria-valuenow]="timeoutNow"
                        [attr.aria-valuemin]="0" [attr.aria-valuemax]="timeoutMax">
                        <span class="countdown-holder">{{countdown}}s</span> 
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button id="session-timeout-dialog-logout" type="button" (click)="logout()"
                    class="btn btn-default">Logout</button>
                <button id="session-timeout-dialog-keepalive" type="button" (click)="reset()" class="btn btn-primary"
                    data-dismiss="modal">Stay Connected</button> </div>
        </div>
    </div>
</div>