import { Component, OnInit } from '@angular/core';
import { TenantRegistrationService } from './tenant-registration.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from 'src/ej-shared/services/toastr.service';

@Component({
  selector: 'app-tenant-registration',
  templateUrl: './tenant-registration.component.html',
  styleUrls: ['./tenant-registration.component.css']
})
export class TenantRegistrationComponent implements OnInit {

  editionId: any;
  packageId: any;
  trialPeriod: any;
  tenantCreateViewModel: any = {};
  
  isSave: boolean = false;

  constructor(private readonly tenantRegistrationService: TenantRegistrationService,
    private route: ActivatedRoute,
    private router: Router,
    private readonly toastrMessageService: ToasterService) { }

  ngOnInit() {
    this.editionId = this.route.snapshot.paramMap.get('editionId');
    this.packageId = this.route.snapshot.paramMap.get('packageId');
    this.trialPeriod = this.route.snapshot.paramMap.get('trialPeriod');
    this.tenantCreateViewModel.email = '';
    this.tenantCreateViewModel.tenancyname = '';
  }

  registerTenant() {
    this.isSave = true;
    this.tenantCreateViewModel.editionId = this.editionId;
    this.tenantCreateViewModel.packageId = this.packageId;

    this.tenantRegistrationService.registerTenant(this.tenantCreateViewModel).then(res => {
      this.router.navigate(['/registration', res.status]);
    });
  }

  async tenantEmailOccured() {
    this.tenantCreateViewModel.email = this.tenantCreateViewModel.email.trim();
    if (this.tenantCreateViewModel.email) {
      await this.tenantRegistrationService.tenantEmailOccured(this.tenantCreateViewModel.email).then(async data => {
        if (data) {
          this.toastrMessageService.info('"' + this.tenantCreateViewModel.email + '"' + " already used");
          this.tenantCreateViewModel.email = "";
        }
      });
    }
  }

  async tenancyNameOccured() {
    this.tenantCreateViewModel.tenancyname = this.tenantCreateViewModel.tenancyname.trim();
    if (this.tenantCreateViewModel.tenancyname) {
      await this.tenantRegistrationService.tenancyNameOccured(this.tenantCreateViewModel.tenancyname).then(async data => {
        if (data) {
          this.toastrMessageService.info('"' + this.tenantCreateViewModel.tenancyname + '"' + " already used");
          this.tenantCreateViewModel.tenancyname = "";
        }
      });
    }
  }
}
