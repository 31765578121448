<div class="kt-grid kt-grid--hor kt-grid--root">
    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
        <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper kt-padding-t-0" id="kt_wrapper">
            <div class="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
                <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid ">
                    <div class="row row-no-padding row-col-separator-md justify-content-center">
                        <div class="col-lg-3 order-sm-1 order-2 kt-hidden-mobile">
                            <div class="kt-portlet kt-portlet--skin-solid kt-bg-primary kt-portlet--height-fluid kt-form">
                                <div class="kt-portlet__head kt-portlet__head--noborder kt-padding-t-25">
                                    <div class="kt-portlet__head-label">
                                        <span class="kt-portlet__head-icon">
                                            <img src="../../../assets//media/logos/ej_logo-light.png">
                                        </span>
                                    </div>
                                </div>
                                <div class="kt-portlet__body">
                                    <div class="kt-heading kt-heading--lg kt-font-light">You are few clicks away to create and sell your courses.</div>
                                    <div class="kt-heading kt-heading--sm kt-font-light">Start your Training Institute in minutes.
                                        <br>Save time and money.</div>
                                </div>
                                <div class="kt-portlet__foot kt-portlet__foot--solid kt-bg-primary">
                                    <div class="kt-form__actions kt-form__actions--right">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon kt-svg-icon--light kt-svg-icon--lg">
                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <rect id="bound" x="0" y="0" width="24" height="24" />
                                                <path
                                                    d="M13.6855025,18.7082217 C15.9113859,17.8189707 18.682885,17.2495635 22,17 C22,16.9325178 22,13.1012863 22,5.50630526 L21.9999762,5.50630526 C21.9999762,5.23017604 21.7761292,5.00632908 21.5,5.00632908 C21.4957817,5.00632908 21.4915635,5.00638247 21.4873465,5.00648922 C18.658231,5.07811173 15.8291155,5.74261533 13,7 C13,7.04449645 13,10.79246 13,18.2438906 L12.9999854,18.2438906 C12.9999854,18.520041 13.2238496,18.7439052 13.5,18.7439052 C13.5635398,18.7439052 13.6264972,18.7317946 13.6855025,18.7082217 Z"
                                                    id="Combined-Shape" fill="#000000" />
                                                <path
                                                    d="M10.3144829,18.7082217 C8.08859955,17.8189707 5.31710038,17.2495635 1.99998542,17 C1.99998542,16.9325178 1.99998542,13.1012863 1.99998542,5.50630526 L2.00000925,5.50630526 C2.00000925,5.23017604 2.22385621,5.00632908 2.49998542,5.00632908 C2.50420375,5.00632908 2.5084219,5.00638247 2.51263888,5.00648922 C5.34175439,5.07811173 8.17086991,5.74261533 10.9999854,7 C10.9999854,7.04449645 10.9999854,10.79246 10.9999854,18.2438906 L11,18.2438906 C11,18.520041 10.7761358,18.7439052 10.4999854,18.7439052 C10.4364457,18.7439052 10.3734882,18.7317946 10.3144829,18.7082217 Z"
                                                    id="Path-41-Copy" fill="#000000" opacity="0.3" />
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-7 order-1 order-sm-2">
                            <div class="kt-portlet kt-portlet--height-fluid kt-form">
                                <div class="kt-portlet__body kt-padding-t-60" *ngIf="currentstep == 1">
                                    <div class="kt-align-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon kt-svg-icon--primary kt-svg-icon--xl">
                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <rect id="bound" x="0" y="0" width="24" height="24" />
                                                <path
                                                    d="M13.5,21 L13.5,18 C13.5,17.4477153 13.0522847,17 12.5,17 L11.5,17 C10.9477153,17 10.5,17.4477153 10.5,18 L10.5,21 L5,21 L5,4 C5,2.8954305 5.8954305,2 7,2 L17,2 C18.1045695,2 19,2.8954305 19,4 L19,21 L13.5,21 Z M9,4 C8.44771525,4 8,4.44771525 8,5 L8,6 C8,6.55228475 8.44771525,7 9,7 L10,7 C10.5522847,7 11,6.55228475 11,6 L11,5 C11,4.44771525 10.5522847,4 10,4 L9,4 Z M14,4 C13.4477153,4 13,4.44771525 13,5 L13,6 C13,6.55228475 13.4477153,7 14,7 L15,7 C15.5522847,7 16,6.55228475 16,6 L16,5 C16,4.44771525 15.5522847,4 15,4 L14,4 Z M9,8 C8.44771525,8 8,8.44771525 8,9 L8,10 C8,10.5522847 8.44771525,11 9,11 L10,11 C10.5522847,11 11,10.5522847 11,10 L11,9 C11,8.44771525 10.5522847,8 10,8 L9,8 Z M9,12 C8.44771525,12 8,12.4477153 8,13 L8,14 C8,14.5522847 8.44771525,15 9,15 L10,15 C10.5522847,15 11,14.5522847 11,14 L11,13 C11,12.4477153 10.5522847,12 10,12 L9,12 Z M14,12 C13.4477153,12 13,12.4477153 13,13 L13,14 C13,14.5522847 13.4477153,15 14,15 L15,15 C15.5522847,15 16,14.5522847 16,14 L16,13 C16,12.4477153 15.5522847,12 15,12 L14,12 Z"
                                                    id="Combined-Shape" fill="#000000" />
                                                <rect id="Rectangle-Copy-2" fill="#FFFFFF" x="13" y="8" width="3"
                                                    height="3" rx="1" />
                                                <path
                                                    d="M4,21 L20,21 C20.5522847,21 21,21.4477153 21,22 L21,22.4 C21,22.7313708 20.7313708,23 20.4,23 L3.6,23 C3.26862915,23 3,22.7313708 3,22.4 L3,22 C3,21.4477153 3.44771525,21 4,21 Z"
                                                    id="Rectangle-2" fill="#000000" opacity="0.3" />
                                            </g>
                                        </svg>
                                    </div>
                                    <div class="kt-heading kt-heading--lg kt-align-center">Tell Us About Yourself
                                    </div>
                                    <label>Enter Your Name</label>
                                    <div class="form-group row">
                                        <div class="col-lg-6">
                                            <input class="form-control" [(ngModel)]="trainer.firstName" placeholder="First Name" (ngModelChange)="error.firstName = false" [ngClass]="{ 'is-invalid': error.firstName}">
                                            <div *ngIf="error.firstName" class="invalid-feedback">
                                                Please Enter First Name
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <input class="form-control" [(ngModel)]="trainer.lastName" placeholder="Last Name" (ngModelChange)="error.lastName = false" [ngClass]="{ 'is-invalid': error.lastName}">
                                            <!-- <div *ngIf="error.lastName" class="invalid-feedback">
                                                Please Enter Last Name
                                            </div> -->
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-lg-12">
                                            <label>Email</label>
                                            <input type="text" class="form-control" [(ngModel)]="trainer.email" [ngClass]="{ 'is-invalid': error.email }">
                                            <div *ngIf="error.email" class="invalid-feedback">
                                                Please Enter valid email.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-lg-12">
                                            <ej-phone-number [inputPhoneNumber]="trainer.phoneNumber" (phoneNumberChanged)="receivePhoneNumber($event)" [ngClass]="{ 'is-invalid': error.phone }"></ej-phone-number>
                                        </div>
                                    </div>
                                </div>
                                <div class="kt-portlet__body kt-padding-t-60" *ngIf="currentstep == 2">
                                    <div class="kt-align-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon kt-svg-icon--primary kt-svg-icon--xl">
                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <rect id="bound" x="0" y="0" width="24" height="24" />
                                                <path
                                                    d="M13.5,21 L13.5,18 C13.5,17.4477153 13.0522847,17 12.5,17 L11.5,17 C10.9477153,17 10.5,17.4477153 10.5,18 L10.5,21 L5,21 L5,4 C5,2.8954305 5.8954305,2 7,2 L17,2 C18.1045695,2 19,2.8954305 19,4 L19,21 L13.5,21 Z M9,4 C8.44771525,4 8,4.44771525 8,5 L8,6 C8,6.55228475 8.44771525,7 9,7 L10,7 C10.5522847,7 11,6.55228475 11,6 L11,5 C11,4.44771525 10.5522847,4 10,4 L9,4 Z M14,4 C13.4477153,4 13,4.44771525 13,5 L13,6 C13,6.55228475 13.4477153,7 14,7 L15,7 C15.5522847,7 16,6.55228475 16,6 L16,5 C16,4.44771525 15.5522847,4 15,4 L14,4 Z M9,8 C8.44771525,8 8,8.44771525 8,9 L8,10 C8,10.5522847 8.44771525,11 9,11 L10,11 C10.5522847,11 11,10.5522847 11,10 L11,9 C11,8.44771525 10.5522847,8 10,8 L9,8 Z M9,12 C8.44771525,12 8,12.4477153 8,13 L8,14 C8,14.5522847 8.44771525,15 9,15 L10,15 C10.5522847,15 11,14.5522847 11,14 L11,13 C11,12.4477153 10.5522847,12 10,12 L9,12 Z M14,12 C13.4477153,12 13,12.4477153 13,13 L13,14 C13,14.5522847 13.4477153,15 14,15 L15,15 C15.5522847,15 16,14.5522847 16,14 L16,13 C16,12.4477153 15.5522847,12 15,12 L14,12 Z"
                                                    id="Combined-Shape" fill="#000000" />
                                                <rect id="Rectangle-Copy-2" fill="#FFFFFF" x="13" y="8" width="3"
                                                    height="3" rx="1" />
                                                <path
                                                    d="M4,21 L20,21 C20.5522847,21 21,21.4477153 21,22 L21,22.4 C21,22.7313708 20.7313708,23 20.4,23 L3.6,23 C3.26862915,23 3,22.7313708 3,22.4 L3,22 C3,21.4477153 3.44771525,21 4,21 Z"
                                                    id="Rectangle-2" fill="#000000" opacity="0.3" />
                                            </g>
                                        </svg>
                                    </div>
                                    <div class="kt-heading kt-heading--lg kt-align-center">Setup Sample Data
                                    </div>
                                    <div class="ng-tns-c4-12">
                                        <label class="kt-checkbox">
                                            <input class="ng-tns-c4-12 ng-untouched ng-pristine ng-valid"
                                                [(ngModel)]="trainer.requiredSampleData" type="checkbox">
                                            <h4>Import Sample Data </h4> <span class="ng-tns-c4-3"></span>
                                        </label>
                                        <span class="form-text text-warning">Sample data is recommended to help you
                                            evaluate eduJournal</span>
                                    </div>
                                </div>
                                <div class="kt-portlet__foot kt-portlet__foot--no-border" *ngIf="currentstep == 2">
                                    <div class="kt-form__actions row">
                                        <div class="col-lg-6 kt-align-left kt-width-50">
                                            <button href="javascript:void(0)" (click)="previousclickevent()" class="btn btn-secondary"><i class="fa fa-angle-left"></i>
                                                Previous</button>
                                        </div>
                                        <div class="col-lg-6 kt-align-right kt-width-50">
                                            <button href="javascript:void(0)" (click)="saveandcontinue()" class="btn btn-primary">Get Started <i class="fa fa-angle-right"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="kt-portlet__foot kt-portlet__foot--no-border" *ngIf="currentstep == 1">
                                    <div class="kt-form__actions kt-form__actions--right">
                                        <a href="javascript:void(0)" (click)="saveandcontinue()" class="btn btn-primary kt-font-light">Next <i class="fa fa-angle-right"></i>
                                        </a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>