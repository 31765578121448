import * as tslib_1 from "tslib";
import { constants } from 'src/helpers/constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class CourseApprovalService {
    constructor(http) {
        this.http = http;
    }
    getCourseApprovalStatusCount(tenantId = "00000000-0000-0000-0000-000000000000") {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(constants.url_constants.api + '/api/TrainingProgramApi/GetCourseApprovalStatusCount/' + tenantId).toPromise();
        });
    }
    saveCourseApproval(courseApprovalPostModel) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.post(constants.url_constants.api + '/api/TrainingProgramApi/SaveCourseApproval', courseApprovalPostModel).toPromise();
        });
    }
    searchCourseApprovals(courseApprovalFilter, tenantId = "00000000-0000-0000-0000-000000000000") {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.post(constants.url_constants.api + '/api/TrainingProgramApi/SearchCourseApprovals/' + tenantId, courseApprovalFilter).toPromise();
        });
    }
    getTraningProgramCategories() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(constants.url_constants.api + '/api/TrainingProgramApi/GetTraningProgramCategories').toPromise();
        });
    }
    getCourseApprovals(courseId, tenantId, isTenant = false) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(constants.url_constants.api + '/api/TrainingProgramApi/GetCourseApprovals/' + courseId + '/' + tenantId + '/' + isTenant).toPromise();
        });
    }
    SaveCourseContentToTrainingProgram(CourseContentMappingFilter) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.post(constants.url_constants.api + '/api/CourseContentMapping/SaveCourseContentToTrainingProgram/', CourseContentMappingFilter).toPromise();
        });
    }
    GetCourseContentIdList(CourseId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(constants.url_constants.api + '/api/CourseContentMapping/GetCourseContentIdList/' + CourseId).toPromise();
        });
    }
}
CourseApprovalService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CourseApprovalService_Factory() { return new CourseApprovalService(i0.ɵɵinject(i1.HttpClient)); }, token: CourseApprovalService, providedIn: "root" });
