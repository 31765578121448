import { downgradeInjectable } from '@angular/upgrade/static';
import * as i0 from "@angular/core";
const ics = require('ics');
var FileSaver = require('file-saver');
export class CalendarService {
    downloadIcs(title, description, start, end, location) {
        ics.createEvent(this.getCalendarIcsModel(title, description, start, end, location), (error, value) => {
            if (error) { }
            var blob = new Blob([value], { type: "text/plain;charset=utf-8" });
            FileSaver.saveAs(blob, "event.ics");
        });
    }
    getCalendarIcsModel(title, description, start, end, location) {
        var model = {
            title: title,
            description: description,
            start: this.getCalendarIcsDate(start),
            end: this.getCalendarIcsDate(end),
            location: location
        };
        return model;
    }
    getCalendarIcsDate(dateString) {
        var d = new Date(dateString);
        return [d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes()];
    }
}
CalendarService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CalendarService_Factory() { return new CalendarService(); }, token: CalendarService, providedIn: "root" });
angular.module('core')
    .factory('calendarService', downgradeInjectable(CalendarService));
