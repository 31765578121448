import { Component, ElementRef, OnInit, ViewChild, ChangeDetectorRef, ViewRef } from '@angular/core';
import * as objectPath from 'object-path';
import { LayoutConfigService } from '../services/layout-config.service'
import { HtmlClassService } from '../html-class.service';
import { constants } from '../../../helpers/constants';
import { GlobalService } from 'src/ej-shared/services/global.service';
import { SharedService } from '../../../ej-shared/services/shared.service'
import { StorageService } from 'src/ej-shared/services/storage.service';


@Component({
	selector: 'ej-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
	menuHeaderDisplay: boolean;
	@ViewChild('ktHeader', { static: false }) ktHeader: ElementRef;
	isAdmin: boolean;
	subscriptionExpiryInfo: any;
	isTenant: boolean;
	isTenantExpired: boolean = false;
	isMember: boolean = false;
	trainerType: any;
	
	constructor(
		private layoutConfigService: LayoutConfigService,
		public htmlClassService: HtmlClassService,
		private globalService: GlobalService,
		private cdRef: ChangeDetectorRef,
		private readonly sharedService: SharedService,
		private readonly storageService: StorageService
	) {
		this.isTenant = globalService.isTenant;
		this.globalService.isTenantChange.subscribe((value) => {
			this.isTenant = value;
			this.tenantExpiryEvent();
			this.detectChanges();
		});

		this.subscriptionExpiryInfo = globalService.subscriptionExpiryInfo;
		this.globalService.subscriptionExpiryInfoChange.subscribe((value) => {
			this.subscriptionExpiryInfo = value;
			this.tenantExpiryEvent();
			this.detectChanges();
		});
	}

	ngOnInit(): void {
		const config = this.layoutConfigService.getConfig();
		this.menuHeaderDisplay = objectPath.get(config, 'header.menu.self.display');
		this.isAdmin = false;
		if (window.location.origin.replace('/client', '').toLowerCase() == constants.admin_url.replace('/client', '').toLowerCase()) {
			this.isAdmin = true;
		}
		this.isMember = this.sharedService.isMember();
		this.trainerType = this.storageService.getTrainerType();
	}

	detectChanges() {
		if (this.cdRef && !(this.cdRef as ViewRef).destroyed) {
			this.cdRef.detectChanges();
		}
	}

	tenantExpiryEvent() {
		if (this.isTenant && this.subscriptionExpiryInfo.IsAccountExpired) {
			this.isTenantExpired = true;
		}
	}
}