<!-- <div class="footer" id="subscriptionInfoDiv" *ngIf="subscriptionExpiryInfo.ShowExpiryInfoDiv">
    {{subscriptionExpiryInfo.IsAccountExpired?subscriptionExpiryInfo.ExpiredMessage:subscriptionExpiryInfo.ExpirationMessage}}
    &nbsp;
    <a class="btn btn-primary" href="subscription" *ngIf="subscriptionExpiryInfo.ShowUpgradeButton">Upgrade</a>
    <a id='close' href="javascript:void(0);" (click)="closeSubscriptionInfoDiv()"><i class="fas fa-times"></i></a>
</div> -->
<div class="mt-cookie-consent-bar" ng-class="{'kt-bg-danger':subscriptionExpiryInfo.IsAccountExpired}" style="display: block;" id="subscriptionInfoDiv" *ngIf="subscriptionExpiryInfo.ShowExpiryInfoDiv && !subscriptionExpiryInfo.IsAccountExpired">
    <div class="container">
        <div class="mt-cookie-consent-bar-holder">
            <div class="mt-cookie-consent-bar-content">
                {{subscriptionExpiryInfo.IsAccountExpired?subscriptionExpiryInfo.ExpiredMessage:subscriptionExpiryInfo.ExpirationMessage}}
                <!-- <a href="javascript:;" tatget="_blank">Terms &amp; Conditions</a> -->
            </div>
            <div class="mt-cookie-consent-bar-action">
                <a class="btn btn-warning" href="subscription" *ngIf="subscriptionExpiryInfo.ShowUpgradeButton">
                    <i class="fa fa-angle-up"></i>Upgrade
                </a>&nbsp;
                <a id='close' href="javascript:;" (click)="closeSubscriptionInfoDiv()" class="btn btn-light btn-icon"><i
                        class="fa fa-times"></i></a>
            </div>
        </div>
    </div>
</div>
<div class="kt-footer kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop" id="kt_footer" style=" position: fixed; bottom: 0; width: 100%;">
    <div class="kt-container  kt-container--fluid ">
        <div class="kt-footer__copyright">
            eduJournal |&nbsp;
            <a href="https://edujournal.com" class="kt-link">
                www.edujournal.com
            </a>
        </div>
    </div>
</div>