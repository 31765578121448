import * as tslib_1 from "tslib";
import { constants } from 'src/helpers/constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class TenantVerificationService {
    constructor(http) {
        this.http = http;
    }
    getTenantVerification() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(constants.url_constants.api + '/api/TenantsApi/GetTenantVerification').toPromise();
        });
    }
    getTenantVerificationDetails() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(constants.url_constants.api + '/api/TenantsApi/GetTenantVerificationDetails').toPromise();
        });
    }
    getTenantDetails(tenantId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(constants.url_constants.api + '/api/TenantsApi/GetByID?id=' + tenantId).toPromise();
        });
    }
}
TenantVerificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TenantVerificationService_Factory() { return new TenantVerificationService(i0.ɵɵinject(i1.HttpClient)); }, token: TenantVerificationService, providedIn: "root" });
