import { Subject } from 'rxjs';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as i0 from "@angular/core";
import { SubHeader } from '../models/bredcum.model';
import { environment } from '../../environments/environment';
export class SharedService {
    constructor() {
        this.subHeader = new SubHeader();
        this.subject = new Subject();
        this.bredcumEvent = new Subject();
        this.menuEvent = new Subject();
        this.logoChangeEvent = new Subject();
        this.sideMenuChangeEvent = new Subject();
        this.showSetupScreenEvent = new Subject();
        this.closeMobileTopbar = new Subject();
        this.showSplashScreen = false;
    }
    sendClickEvent() {
        this.subject.next();
    }
    getClickEvent() {
        return this.subject.asObservable();
    }
    sendBredcumEvent(subHeader) {
        this.subHeader = subHeader;
        this.bredcumEvent.next();
        this.sendMobileTopbarCloseEvent();
        window.scroll(0, 0);
    }
    getBredcumEvent() {
        return this.bredcumEvent.asObservable();
    }
    sendMenuEvent() {
        this.menuEvent.next();
    }
    getMenuEvent() {
        return this.menuEvent.asObservable();
    }
    sendLogoChangeEvent() {
        this.logoChangeEvent.next();
    }
    getLogoChangeEvent() {
        return this.logoChangeEvent.asObservable();
    }
    sendSideMenuEvent(activeitem) {
        this.activeItem = activeitem;
        this.sideMenuChangeEvent.next();
    }
    getSideMenuEvent() {
        return this.sideMenuChangeEvent.asObservable();
    }
    sendShowSetupEvent(showSplashScreen) {
        this.showSplashScreen = showSplashScreen;
        this.showSetupScreenEvent.next();
    }
    getShowSetupEvent() {
        return this.showSetupScreenEvent.asObservable();
    }
    isEmail(search) {
        let isValidEmail;
        let regexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        isValidEmail = regexp.test(search);
        return isValidEmail;
    }
    sendMobileTopbarCloseEvent() {
        this.closeMobileTopbar.next();
    }
    getMobileTopbarCloseEvent() {
        return this.closeMobileTopbar.asObservable();
    }
    isMember() {
        if (window.location.origin.toLowerCase() == environment.member_url.toLowerCase()) {
            return true;
        }
        else {
            return false;
        }
    }
    isAdmin() {
        if (window.location.origin.toLowerCase() == environment.admin_url.toLowerCase()) {
            return true;
        }
        else {
            return false;
        }
    }
    isRedirectUrl() {
        if (window.location.origin.toLowerCase() == environment.shortRedirect_url.toLowerCase()) {
            return true;
        }
        else {
            return false;
        }
    }
}
SharedService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SharedService_Factory() { return new SharedService(); }, token: SharedService, providedIn: "root" });
angular.module('core')
    .factory('sharedService', downgradeInjectable(SharedService));
