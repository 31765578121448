<!DOCTYPE html>
<html lang="en"
    class="wf-poppins-n3-active wf-poppins-n4-active wf-poppins-n5-active wf-poppins-n6-active wf-poppins-n7-active wf-roboto-n3-active wf-roboto-n4-active wf-roboto-n5-active wf-roboto-n6-active wf-roboto-n7-active wf-active">
<!-- begin::Head -->

<head>
    <meta charset="utf-8">
    <title>eduJournal | Course Enquiry</title>
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta content="width=device-width, initial-scale=1" name="viewport" />
    <meta content="" name="description" />
    <meta content="" name="author" />

    <!-- GLOBAL MANDATORY STYLES -->
    <link href="https://fonts.googleapis.com/css?family=Hind:300,400,500,600,700" rel="stylesheet" type="text/css">
    <!-- Favicon -->
    <link rel="shortcut icon" href="favicon.ico" />

    <script src='https://www.google.com/recaptcha/api.js'></script>
</head>
<!-- end::Head -->
<!-- start::Body -->

<body
    class="kt-page--fluid kt--skin- kt-page--loading-enabled kt-content--skin-light2 kt-header--fixed kt-header--fixed-mobile kt-aside-left--enabled kt-aside-left--skin-light kt-aside-left--fixed kt-aside-left--offcanvas kt-aside-right--enabled kt-footer--fixed kt-footer--push kt-aside--offcanvas-default">
    <!-- begin::Page loader -->
    <div class="kt-page-loader kt-page-loader--base">
        <div class="kt-spinner kt-spinner--brand"></div>
    </div>
    <!-- end::Page Loader -->
    <!-- begin:: Page -->
    <div class="kt-grid kt-grid--hor kt-grid--root kt-page">
        <!--========== HEADER ==========-->
        <header class="header navbar-fixed-top">
            <!-- Navbar -->
            <nav class="navbar" role="navigation">
                <div class="c1">
                    <!-- Brand and toggle get grouped for better mobile display -->
                    <div class="menu-container js_nav-item">
                        <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".nav-collapse">
                            <span class="sr-only">Toggle navigation</span>
                            <span class="toggle-icon"></span>
                        </button>

                        <!-- Logo -->
                        <div class="logo">
                            <a class="logo-wrap" href="javascript:void(0)" (click)="tenantHomeNav()">
                                <img class="logo-img1"
                                    [src]="ejLogoSrc"
                                    alt="eduJournal">
                            </a>
                        </div>
                        <!-- End Logo -->
                    </div>

                    <!-- Collect the nav links, forms, and other content for toggling -->
                    <div class="collapse navbar-collapse nav-collapse">
                        <div class="menu-container">
                            <ul class="nav navbar-nav-right">
                                <li class="js_nav-item nav-item"><a class="nav-item-child nav-item-hover"
                                        href="javascript:void(0)" (click)="tenantHomeNav()">Home</a></li>
                            </ul>
                        </div>
                    </div>
                    <!-- End Navbar Collapse -->
                </div>
            </nav>
            <!-- Navbar -->
        </header>
        <!--========== END HEADER ==========-->
        <!-- begin::Body -->
        <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver-desktop kt-grid--desktop kt-body"
            style="padding-left:0px !important;">
            <div class="kt-grid__item kt-grid__item--fluid kt-wrapper">
                <div class="bg-color-sky-light">
                    <div class="row justify-content-center" style="padding-top: 70px;">
                        <div class="col-lg-10">
                            <!--begin::Portlet-->
                            <div class="kt-portlet kt-portlet--tabs" id="main_portlet">
                                <div class="kt-portlet__body">
                                    <div class="kt-form__heading">
                                        <h3 class="kt-form__heading-title">
                                            Course &amp; Pricing Details
                                        </h3>
                                    </div>
                                    <div class="kt-widget19">
                                        <div class="kt-widget19__content">
                                            <div class="kt-widget19__header">
                                                <div class="kt-widget19__user-img">
                                                    <img class="course-image"
                                                        src="{{(courseEnquiryModel?.trainingProgramViewModel?.courseImageName!=null && courseEnquiryModel?.trainingProgramViewModel?.courseImageName!='') ? (courseEnquiryModel?.trainingProgramViewModel?.courseImageRelativePath + '\\360x240\\' + courseEnquiryModel?.trainingProgramViewModel?.courseImageName) : 'assets/media/logos/Default_Image_Course.png'}}"
                                                        alt="">
                                                </div>
                                                <div class="kt-widget19__info">
                                                    <h3>
                                                        <span
                                                            id="course-name">{{courseEnquiryModel?.trainingProgramViewModel?.name}}
                                                        </span>
                                                    </h3>
                                                    <span class="kt-widget19__time kt--margin-top-5">
                                                        {{courseEnquiryModel?.trainingProgramViewModel?.address.venuename}}
                                                        {{courseEnquiryModel?.trainingProgramViewModel?.address.addressline1}}
                                                        {{courseEnquiryModel?.trainingProgramViewModel?.address.addressline2}}
                                                        {{courseEnquiryModel?.trainingProgramViewModel?.address.city}}
                                                        {{courseEnquiryModel?.trainingProgramViewModel?.address.state}}
                                                        {{courseEnquiryModel?.trainingProgramViewModel?.address.country}}
                                                        {{courseEnquiryModel?.trainingProgramViewModel?.address.postalcode}}
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="kt-widget19__body">
                                                <p class="avoid-text-exceed"
                                                    [innerHTML]="courseEnquiryModel?.trainingProgramViewModel?.description">
                                                </p>
                                                <ul class="nav nav-pills nav-fill" role="tablist" *ngIf="(courseEnquiryModel?.trainingProgramViewModel?.dailyProgramPrice.price != null
                                                || courseEnquiryModel?.trainingProgramViewModel?.weeklyProgramPrice.price != null
                                                || ( hasDailyPrice || hasWeeklyPrice))">
                                                    <li class="nav-item"
                                                        *ngIf="courseEnquiryModel?.trainingProgramViewModel?.dailyProgramPrice.price != null
                                                        || courseEnquiryModel?.trainingProgramViewModel?.weeklyProgramPrice.price != null">
                                                        <a class="nav-link active show" data-toggle="tab"
                                                            data-target="#m_widget4_tab1_content">
                                                            Price by Entire Course
                                                        </a>
                                                    </li>
                                                    <li class="nav-item" *ngIf="(hasDailyPrice || hasWeeklyPrice)">
                                                        <a class="nav-link" data-toggle="tab"
                                                            data-target="#m_widget4_tab2_content">
                                                            {{courseEnquiryModel?.trainingProgramViewModel?.scheduletype==1 ? 'Price by Session' : 'Price by Weekly Schedule'}}
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div class="tab-content kt-portlet__body">
                                                    <!--Tab 1-->
                                                    <div class="tab-pane active" id="m_widget4_tab1_content"
                                                        *ngIf="courseEnquiryModel?.trainingProgramViewModel?.dailyProgramPrice.price != null
                                                    || courseEnquiryModel?.trainingProgramViewModel?.weeklyProgramPrice.price != null">
                                                        <ng-container
                                                            *ngIf="courseEnquiryModel?.trainingProgramViewModel?.scheduletype==1">
                                                            <table class="table">
                                                                <thead>
                                                                    <tr class="row">
                                                                        <th class="col-lg-4">Schedule Date &amp; Time
                                                                        </th>
                                                                        <th class="col-lg-6">Title</th>
                                                                        <th class="col-lg-2">Trainers</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr class="row"
                                                                        *ngFor="let sessions of courseEnquiryModel?.trainingProgramViewModel?.dailySchedules">
                                                                        <td class="col-lg-4">
                                                                            <p>{{sessions.sessiondate | mcDateFormat : true}},
                                                                                {{processTimeInput(sessions.fromtime) | date: "shortTime"}}
                                                                                -
                                                                                {{processTimeInput(sessions.totime) | date: "shortTime"}}
                                                                            </p>
                                                                        </td>
                                                                        <td class="col-lg-6">{{sessions.title}}</td>
                                                                        <td class="col-lg-2">
                                                                            <span
                                                                                *ngFor="let trainers of sessions.trainers; let i = index">
                                                                                {{trainers.firstName}}
                                                                                {{trainers.lastName}}
                                                                                <span
                                                                                    *ngIf="(i+1)<sessions.trainers.length">,
                                                                                </span>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <div class="kt-portlet__body-separator"></div>
                                                            <div class="form-group  row kt--align-right">
                                                                <label
                                                                    class="col-xl-9 col-lg-4 col-form-label">Price</label>
                                                                <div class="col-xl-2 col-lg-8">
                                                                    <span>
                                                                        <h4>
                                                                            {{courseEnquiryModel?.trainingProgramViewModel?.dailyProgramPrice.price | ejCurrencyFormat: true}}
                                                                        </h4>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div class="kt-portlet__body-separator"></div>
                                                        </ng-container>

                                                        <ng-container
                                                            *ngIf="courseEnquiryModel?.trainingProgramViewModel?.scheduletype==2">
                                                            <table class="table">
                                                                <thead>
                                                                    <tr class="row">
                                                                        <th class="col-lg-6">Schedule Day &amp; Time
                                                                        </th>
                                                                        <th class="col-lg-6">Trainers</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr class="row"
                                                                        *ngFor="let sessions of courseEnquiryModel?.trainingProgramViewModel?.weeklySchedules">
                                                                        <td class="col-lg-6">
                                                                            <p><b>{{sessions.weekday}}</b></p>
                                                                            <p>{{processTimeInput(sessions.fromtime) | date: "shortTime"}}
                                                                                -
                                                                                {{processTimeInput(sessions.totime) | date: "shortTime"}}
                                                                            </p>
                                                                        </td>
                                                                        <td class="col-lg-6">
                                                                            <span
                                                                                *ngFor="let trainers of sessions.trainers; let i = index">
                                                                                {{trainers.firstName}}
                                                                                {{trainers.lastName}}
                                                                                <span
                                                                                    *ngIf="(i+1)<sessions.trainers.length">,
                                                                                </span>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <div class="kt-portlet__body-separator"></div>
                                                            <div class="form-group  row kt--align-right">
                                                                <label
                                                                    class="col-xl-9 col-lg-4 col-form-label">Price</label>
                                                                <div class="col-xl-3 col-lg-8">
                                                                    <span>
                                                                        <h4>

                                                                            {{courseEnquiryModel?.trainingProgramViewModel?.weeklyProgramPrice.price | ejCurrencyFormat: true}}
                                                                        </h4>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div class="kt-portlet__body-separator"></div>
                                                        </ng-container>
                                                    </div>


                                                    <!--Tab 2-->
                                                    <div *ngIf="(hasDailyPrice || hasWeeklyPrice)"
                                                        [ngClass]="(courseEnquiryModel?.trainingProgramViewModel?.dailyProgramPrice.price == null 
                                                        && courseEnquiryModel?.trainingProgramViewModel?.weeklyProgramPrice.price == null) ? 'tab-pane active' : 'tab-pane'"
                                                        id="m_widget4_tab2_content">
                                                        <ng-container
                                                            *ngIf="courseEnquiryModel?.trainingProgramViewModel?.scheduletype==1">
                                                            <table class="table">
                                                                <thead>
                                                                    <tr class="row">
                                                                        <!-- <th class="col-lg-1">
                                                                            <div class="kt-checkbox-list">
                                                                                <label class="kt-checkbox">
                                                                                    <input type="checkbox"
                                                                                        id="session-checkbox-head"
                                                                                        (change)="sHeaderCheckboxChange()"><span></span>
                                                                                </label>
                                                                            </div>
                                                                        </th> -->
                                                                        <th class="col-lg-3">Schedule Date &amp; Time
                                                                        </th>
                                                                        <th class="col-lg-5">Title</th>
                                                                        <th class="col-lg-2">Trainers</th>
                                                                        <th class="col-lg-1">Price</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr class="row"
                                                                        *ngFor="let sessions of courseEnquiryModel?.trainingProgramViewModel?.dailySchedules">
                                                                        <!-- <td class="col-lg-1">
                                                                            <div class="kt-checkbox-list">
                                                                                <label class="kt-checkbox">
                                                                                    <input type="checkbox"
                                                                                        class="session-checkbox"
                                                                                        (change)="sCheckboxChange()">
                                                                                    <span></span>
                                                                                </label>
                                                                            </div>
                                                                        </td> -->
                                                                        <td class="col-lg-3">
                                                                            <p>{{sessions.sessiondate | mcDateFormat : true}},
                                                                                {{processTimeInput(sessions.fromtime) | date: "shortTime"}}
                                                                                -
                                                                                {{processTimeInput(sessions.totime) | date: "shortTime"}}
                                                                            </p>
                                                                        </td>
                                                                        <td class="col-lg-5">{{sessions.title}}</td>
                                                                        <td class="col-lg-2">
                                                                            <span
                                                                                *ngFor="let trainers of sessions.trainers; let i = index">
                                                                                {{trainers.firstName}}
                                                                                {{trainers.lastName}}
                                                                                <span
                                                                                    *ngIf="(i+1)<sessions.trainers.length">,
                                                                                </span>
                                                                            </span>
                                                                            <br>
                                                                        </td>

                                                                        <td class="col-lg-1 session-price-td"> <span
                                                                                class="session-price">{{sessions.dailyscheduleprice.price | ejCurrencyFormat: true}}</span>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <div class="kt-portlet__body-separator"></div>
                                                            <!-- <div
                                                                class="form-group  row kt--align-right">
                                                                <label
                                                                    class="col-xl-10 col-lg-4 col-form-label">Price</label>
                                                                <div class="col-xl-2 col-lg-8">
                                                                    <h4> <span id="session-price-total"> 0.00</span>
                                                                    </h4>
                                                                </div>
                                                            </div> -->
                                                            <div class="kt-portlet__body-separator"></div>
                                                        </ng-container>

                                                        <ng-container
                                                            *ngIf="courseEnquiryModel?.trainingProgramViewModel?.scheduletype==2">
                                                            <table class="table">
                                                                <thead>
                                                                    <tr class="row">
                                                                        <!-- <th class="col-lg-1">
                                                                            <div class="kt-checkbox-list">
                                                                                <label class="kt-checkbox">
                                                                                    <input type="checkbox"
                                                                                        id="session-checkbox-head"
                                                                                        (change)="sHeaderCheckboxChange()"><span></span>
                                                                                </label>
                                                                            </div>
                                                                        </th> -->
                                                                        <th class="col-lg-4">Schedule Day &amp; Time
                                                                        </th>
                                                                        <th class="col-lg-4">Trainers</th>
                                                                        <th class="col-lg-3">Price</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr class="row"
                                                                        *ngFor="let sessions of courseEnquiryModel?.trainingProgramViewModel?.weeklySchedules">
                                                                        <!-- <td class="col-lg-1">
                                                                            <div class="kt-checkbox-list">
                                                                                <label class="kt-checkbox">
                                                                                    <input type="checkbox"
                                                                                        class="session-checkbox"
                                                                                        (change)="sCheckboxChange()">
                                                                                    <span></span>
                                                                                </label>
                                                                            </div>
                                                                        </td> -->
                                                                        <td class="col-lg-4">
                                                                            <p><b>{{sessions.weekday}}</b></p>
                                                                            <p>{{processTimeInput(sessions.fromtime) | date: "shortTime"}}
                                                                                -
                                                                                {{processTimeInput(sessions.totime) | date: "shortTime"}}
                                                                            </p>
                                                                        </td>
                                                                        <td class="col-lg-4">
                                                                            <span
                                                                                *ngFor="let trainers of sessions.trainers; let i = index">
                                                                                {{trainers.firstName}}
                                                                                {{trainers.lastName}}
                                                                                <span
                                                                                    *ngIf="(i+1)<sessions.trainers.length">,
                                                                                </span>
                                                                            </span>
                                                                        </td>
                                                                        <td class="col-lg-3 session-price-td"> <span
                                                                                class="session-price">{{sessions.weeklyscheduleprice.price | ejCurrencyFormat: true}}</span>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <div class="kt-portlet__body-separator"></div>
                                                            <!-- <div
                                                                class="form-group  row kt--align-right">
                                                                <label
                                                                    class="col-xl-9 col-lg-4 col-form-label">Price</label>
                                                                <div class="col-xl-3 col-lg-8">
                                                                    <span>
                                                                        <h4> <span id="session-price-total">
                                                                                0.00</span>
                                                                        </h4>

                                                                    </span>
                                                                </div>
                                                            </div> -->
                                                            <div class="kt-portlet__body-separator"></div>
                                                        </ng-container>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!--Enquiry Form-->

                                    <div>
                                        <div class="pb-4">
                                            <h3 class="kt-form__heading-title">Send us a note</h3>
                                            <span>Please fill out the form below with the query and we'll get back to
                                                you.</span><br>
                                        </div>
                                        <div class="col-md-5 col-sm-7">
                                            <form id="enquiryForm" name="enquiryForm" #f="ngForm"
                                                (ngSubmit)="f.form.valid && saveEnquiryEvent(f)" novalidate>
                                                <div class="margin-b-10">
                                                    <input type="text" class="form-control" placeholder="Name"
                                                        id="firstName" name="firstName" maxlength="50"
                                                        autocomplete="off" #firstName="ngModel" required
                                                        [(ngModel)]="enquiryModel.firstName"
                                                        [ngClass]="{ 'is-invalid': f.submitted && firstName.invalid }">
                                                    <div *ngIf="f.submitted && email.invalid" class="invalid-feedback">
                                                        <div *ngIf="firstName.errors?.required">Name is required</div>
                                                    </div>
                                                </div>
                                                <div class="margin-b-10">
                                                    <input type="email" class="form-control" placeholder="Email"
                                                        id="email" name="email"
                                                        [ngClass]="{ 'is-invalid': f.submitted && email.invalid }"
                                                        maxlength="100" #email="ngModel" autocomplete="off"
                                                        [(ngModel)]="enquiryModel.email" required
                                                        pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$">
                                                    <div *ngIf="f.submitted && email.invalid" class="invalid-feedback">
                                                        <div *ngIf="email.errors?.required">Email is required</div>
                                                        <div *ngIf="email.errors?.pattern">Invalid email format</div>
                                                    </div>
                                                </div>
                                                <div class="margin-b-10">
                                                    <ej-phone-number *ngIf="!loading"
                                                        [inputPhoneNumber]="enquiryModel.mobile" [displayLabel]="false"
                                                        (phoneNumberChanged)="receivePhoneNumber($event)"
                                                        (phoneNumberError)="receivePhoneNumberError($event)">
                                                    </ej-phone-number>
                                                    <div *ngIf="f.submitted && (phoneNumberError || phoneCountryCodeError)"
                                                        class="invalid-feedback displayBlock">
                                                        <div *ngIf="phoneNumberError">Phone is required</div>
                                                        <div *ngIf="phoneCountryCodeError && !phoneNumberError">Country
                                                            Code is required</div>
                                                    </div>
                                                </div>
                                                <!-- <div class="margin-b-10">
                                                    <input type="text" class="form-control" placeholder="Mobile"
                                                        id="mobile" name="mobile" autocomplete="off" maxlength="15"
                                                        pattern=".{8,15}" #mobile="ngModel"
                                                        [(ngModel)]="enquiryModel.mobile" required
                                                        [ngClass]="{ 'is-invalid': f.submitted && mobile.invalid }">
                                                    <div *ngIf="f.submitted && mobile.invalid" class="invalid-feedback">
                                                        <div *ngIf="mobile.errors?.required">Mobile is required</div>
                                                        <div *ngIf="mobile.errors?.pattern">Invalid mobile number</div>
                                                    </div>
                                                </div> -->
                                                <div class="margin-b-20">
                                                    <textarea class="form-control" rows="5" placeholder="Message"
                                                        id="message" autocomplete="off" name="message" maxlength="1000"
                                                        #message="ngModel" [(ngModel)]="enquiryModel.message" required
                                                        [ngClass]="{ 'is-invalid': f.submitted && message.invalid }">
                                                    </textarea>
                                                    <div *ngIf="f.submitted && message.invalid"
                                                        class="invalid-feedback">
                                                        <div *ngIf="message.errors?.required">Message is required</div>
                                                    </div>
                                                </div>
                                                <div class="form-group margin-b-20"
                                                    [ngClass]="{ 'is-invalid': f.submitted && errorRecaptchaRender }">
                                                    <re-captcha (resolved)="resolved($event)" #recaptchaRef="reCaptcha"
                                                        siteKey={{captchaSiteKey}}>
                                                    </re-captcha>
                                                    <div *ngIf="f.submitted && errorRecaptchaRender"
                                                        class="invalid-feedback">
                                                        <div *ngIf="errorRecaptchaRender">Please verify that you are not
                                                            a robot.</div>
                                                    </div>
                                                </div>
                                                <button type="submit"
                                                    class="btn-theme btn-theme-sm btn-base-bg text-uppercase"
                                                    [disabled]="saveEnquiryBtnDisabled">
                                                    Submit
                                                </button>
                                            </form>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <!--end::Portlet-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end:: Body -->
        <!-- begin::Footer -->
        <footer class="footer">
            <div class="content container">
                <div class="row">
                    <div class="col-xs-6">
                        <img class="footer-logo" src="assets/media/logos/ej_logo.png" alt="eduJournal">
                    </div>
                    <div class="col-xs-6 text-right sm-text-left">
                        <p class="margin-b-0"><a class="fweight-400" href="http://edujournal.com">www.edujournal.com</a>
                        </p>
                    </div>
                </div>
                <!--// end row -->
            </div>
        </footer>
        <!-- end::Footer -->
    </div>
    <!-- end:: Page -->

    <!-- JAVASCRIPTS(Load javascripts at bottom, this will reduce page load time) -->
    <!-- CORE PLUGINS -->
    <script src="assets/vendors/general/jquery/dist/jquery.js" type="text/javascript"></script>
    <script src="assets/vendors/wp-js/jquery-migrate.min.js" type="text/javascript"></script>
    <script src="assets/vendors/general/bootstrap/dist/js/bootstrap.min.js" type="text/javascript"></script>

    <!-- PAGE LEVEL PLUGINS -->
    <script src="assets/vendors/wp-js/jquery.easing.js" type="text/javascript"></script>
    <script src="assets/vendors/wp-js/jquery.back-to-top.js" type="text/javascript"></script>
    <script src="assets/vendors/wp-js/jquery.smooth-scroll.js" type="text/javascript"></script>
    <script src="assets/vendors/wp-js/jquery.wow.min.js" type="text/javascript"></script>
    <script src="assets/vendors/wp-js/jquery.parallax.min.js" type="text/javascript"></script>
    <script src="assets/vendors/wp-js/jquery.appear.js" type="text/javascript"></script>
    <script src="assets/vendors/general/swiper/js/swiper.jquery.min.js" type="text/javascript"></script>

    <!-- PAGE LEVEL SCRIPTS -->
    <script src="assets/vendors/wp-js/layout.min.js" type="text/javascript"></script>
    <script src="assets/vendors/wp-js/components/progress-bar.min.js" type="text/javascript"></script>
    <script src="assets/vendors/general/swiper/js/swiper.min.js" type="text/javascript"></script>
    <script src="assets/vendors/wp-js/components/wow.min.js" type="text/javascript"></script>
    <!-- end::Page Loader -->
    <!-- end::Body -->

</body>

</html>