import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { constants } from '../../helpers/constants';
export class AuthCallbackComponent {
    constructor(authService, sharedService, permissionService, globalService, http, loaderService, permissionsService) {
        this.authService = authService;
        this.sharedService = sharedService;
        this.permissionService = permissionService;
        this.globalService = globalService;
        this.http = http;
        this.loaderService = loaderService;
        this.permissionsService = permissionsService;
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            //this.sharedService.sendShowSetupEvent(true);
            this.loaderService.blockLoader = true;
            this.loaderService.hideLoader();
            yield this.authService.completeAuthentication();
            this.sharedService.sendClickEvent();
            // menu load event
            this.sharedService.sendMenuEvent();
            //Get User Permissions
            if (!this.sharedService.isMember()) {
                this.permissionService.GetUserPermissionById().then(response => {
                    this.globalService.setUserPermissionData(response.item1);
                    this.globalService.setLoggedUser(response.item2);
                    this.permissionsService.loadPermissions(response.item1);
                });
            }
            else {
                //load student permissions
            }
            if (!this.sharedService.isMember() && !this.sharedService.isAdmin() && !this.sharedService.isRedirectUrl()) {
                yield this.getIP().then(res => {
                    if (res.ip) {
                        let apiReq = { 'Query': res.ip };
                        this.saveTenantLoginDetails(apiReq).then(res2 => { });
                    }
                });
            }
        });
    }
    getIP() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get('https://api.ipify.org?format=json').toPromise();
        });
    }
    saveTenantLoginDetails(apiReq) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.post(constants.url_constants.api + '/api/TenantsApi/SaveTenantLastLogin', apiReq).toPromise();
        });
    }
}
