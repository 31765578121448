<head>
    <link href="../../../assets/css/demo1/pages/login/login-1.css" rel="stylesheet" type="text/css" />
    <link href="../../../assets/css/demo1/style.bundle.css" rel="stylesheet" type="text/css" />
    <link href="../../../assets/css/demo1/skins/header/base/light.css" rel="stylesheet" type="text/css" />
    <link href="../../../assets/css/demo1/skins/header/menu/light.css" rel="stylesheet" type="text/css" />
    <link href="../../../assets/css/demo1/skins/brand/light.css" rel="stylesheet" type="text/css" />
    <link rel="shortcut icon" href="../../../assets/media/logos/favicon.ico" />
</head>

<div class="kt-grid kt-grid--ver kt-grid--root" (window:resize)="onResize($event)">
    <div class="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v1" id="kt_login">
        <div
            class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
            <!--begin::Aside-->
            <div class="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside kt-hidden-mobile"
                style="background-image: url(../assets/media//bg/ej-bg-5.jpg);width: 500px !important;"
                [ngStyle]="{ 'height': viewHeight}">
                <div class="kt-grid__item">
                    <a href="#" class="kt-login__logo">
                        <img src="../assets/media/logos/ej_logo-light.png">
                    </a>
                </div>
                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver">
                    <div class="kt-grid__item kt-grid__item--bottom">
                        <h3 class="kt-login__title text-center">You are few clicks away to create and Sell your courses.
                        </h3>
                        <h4 class="kt-login__subtitle text-center">Start your Training Institute in minutes, save time
                            and
                            money.</h4>
                    </div>
                </div>
                <div class="kt-grid__item">
                    <div class="kt-login__info">
                        <div class="kt-login__copyright ">
                            © {{currentYear}} eduJournal
                        </div>
                        <div class="kt-login__menu">
                            <a href="https://www.edujournal.com/privacy-policy/" target="_blank"
                                class="kt-link">Privacy</a>
                            <a href="https://www.edujournal.com/terms-of-use/" target="_blank" class="kt-link">Terms</a>
                            <a href="#" class="kt-link">Contact</a>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside kt-hidden-desktop kt-bg-light">
                <div class="kt-grid__item">
                    <a href="#" class="kt-login__logo kt-margin-0 justify-content-center">
                        <img
                            src="https://member.edujournal.com/assets/demo/default/media/img/logo/logo_default_light.png">
                    </a>
                </div>
            </div>
            <!--begin::Aside-->

            <!--begin::Content-->
            <div class="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper"
                [ngStyle]="{ 'max-height': viewHeight}" style="overflow: auto;">

                <!--begin::Head-->
                <div class="kt-login__head kt-hidden-mobile mb-2">
                    <span class="kt-login__signup-label" style="color: #959cb6;">Hi, </span>
                    <span class="kt-login__signup-label" style="color: #6c7293;">{{username}}</span>&nbsp;&nbsp;
                    <a href="javascript:void(0)" (click)="signout()"
                        class="btn btn-label btn-label-primary btn-sm btn-bold">Sign Out</a>
                </div>
                <!--end::Head-->

                <div class="kt-login__body kt-margin-t-20 kt-hidden" *ngIf="currentstep == 1">
                    <div class="kt-login__form" style="max-width: 600px !important;">
                        <form class="kt-form mb-0" action="" novalidate="novalidate">
                            <div class="form-group kt-margin-b-20">
                                <h4>What is your institution name?</h4>
                                <input class="form-control" [(ngModel)]="schoolProfile.schoolname"
                                    (ngModelChange)="error.schoolname = false" name="schoolProfile.schoolname"
                                    [ngClass]="{ 'is-invalid': error.schoolname }"
                                    placeholder="Enter your institution name">
                                <div *ngIf="error.schoolname" class="invalid-feedback">
                                    Please enter institution name
                                </div>
                            </div>
                            <div class="kt-login__actions">
                                <a href="#" class="kt-link kt-login__link-forgot"></a>
                                <button id="kt_login_signin_submit" type="button"
                                    class="btn btn-primary btn-elevate kt-login__btn-primary"
                                    (click)="saveandcontinue()">
                                    Next <i class="fa fa-angle-right"></i>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="kt-login__body kt-margin-t-20" *ngIf="currentstep == 1">
                    <!--begin::Signin-->
                    <div class="kt-login__form" style="max-width: 600px !important;">
                        <!--begin::Form-->
                        <form class="kt-form" action="" novalidate="novalidate">
                            <div class="form-group kt-margin-b-40">
                                <h4>What is your Business Registration Number?</h4>
                                <input type="text" class="form-control" [(ngModel)]="schoolProfile.registrationnumber"
                                    name="schoolProfile.registrationnumber" maxlength="15"
                                    placeholder="Enter your Business Registration Number">
                            </div>
                            <div class="form-group kt-margin-b-40" *ngIf="allowIncorporationUpload"
                                [ngClass]="{ 'is-invalid' : incorporationDocumentError || incorporationDocumentTypeError }">
                                <h4>Upload your certificate of incorporation</h4>
                                <label>This will help us confirm you're an authorized representative of this
                                    business.</label>
                                <div class="dropzone dropzone-default dz-clickable"
                                    id="m-dropzone-incorporationdocument" (click)="fileIncorporationInput.click()"
                                    ejDragDrop style="background-color: rgba(247, 247, 249, 0.7);"
                                    (onFileDropped)="uploadIncorporationDocuments($event)">
                                    <input hidden type="file" #fileIncorporationInput onclick="this.value = null"
                                        (change)="uploadIncorporationDocuments($event)"
                                        accept=".png, .jpg, .jpeg, .pdf">
                                    <div class="kt-dropzone__msg dz-message needsclick">
                                        <h3 class="kt-dropzone__msg-title">Drop file here or click to upload.</h3>
                                        <span class="kt-dropzone__msg-desc">Accepted file formats <strong>.doc, .docx,
                                                .jpg, .jpeg, .pdf, .png</strong> file not exceeding 2MB.</span>
                                    </div>
                                </div>
                                <div *ngIf="incorporationDocumentError" class="invalid-feedback">
                                    File size cannot exceed 2 MB.
                                </div>
                                <div *ngIf="incorporationDocumentTypeError" class="invalid-feedback">
                                    Accepted file formats are .png, .jpg, .jpeg, .pdf
                                </div>
                                <span class="form-text text-muted">Your privacy is important to us. We'll use this
                                    document only to verify your business and we will never share or post it
                                    publicly.</span>
                            </div>
                            <div class="form-group kt-margin-b-40" *ngIf="displayIncorporationUploadGrid">
                                <h4>Uploaded certificate of incorporation</h4>
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr role="row">
                                                <th>Name</th>
                                                <th style="width: 17%;">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr role="row"
                                                *ngFor="let doc of schoolProfile?.incorporationCertificateDocuments; let odd = odd;  let even = even; let index = index"
                                                [ngClass]="{'even' :even, 'odd' : odd}" [hidden]="doc.isDeleted">
                                                <td>
                                                    <!-- <span *ngIf="doc.name != '' && doc.name != undefined">{{doc.name}}</span> -->
                                                    <span>Certificate of Incorporation</span>
                                                </td>
                                                <td>
                                                    <a class="btn btn-sm btn-outline-secondary btn-icon btn-icon-md"
                                                        href="{{ doc.relativePath }}" target="_blank"
                                                        style="cursor: pointer;color:black" ngbTooltip="View">
                                                        <i class="fa fa-eye"></i>
                                                    </a>
                                                    &nbsp;
                                                    <a class="btn btn-sm btn-outline-secondary btn-icon btn-icon-md"
                                                        (click)="deleteIncorporationDocument(index)"
                                                        style="cursor: pointer;color: black;">
                                                        <i class="fa fa-trash"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <!--begin::Action-->
                            <div class="kt-login__actions">
                                <a href="#" class="kt-link kt-login__link-forgot"></a>
                                <button id="kt_login_signin_submit" type="button"
                                    class="btn btn-primary btn-elevate kt-login__btn-primary"
                                    (click)="saveandcontinue()">
                                    Next <i class="fa fa-angle-right"></i>
                                </button>
                            </div>
                            <!--end::Action-->
                        </form>
                        <!--end::Form-->
                    </div>
                </div>
                <div class="kt-login__body kt-margin-t-20" *ngIf="currentstep == 2">
                    <div class="kt-login__form" style="max-width: 600px !important;">
                        <form class="kt-form mb-0" action="" novalidate="novalidate">
                            <div class="form-group kt-margin-b-40" *ngIf="!imageUploaded"
                                [ngClass]="{ 'is-invalid' : logoImageError || logoFileTypeError}">
                                <h4>Upload your institution logo</h4>
                                <div *ngIf="!imageUploaded"
                                    style="background-color: rgba(247, 247, 249, 0.7) !important"
                                    class="dropzone dropzone-default dz-clickable" id="m-dropzone-two"
                                    (click)="fileInput.click()" ejDragDrop (onFileDropped)="uploadFile($event)">
                                    <input hidden type="file" #fileInput onclick="this.value = null"
                                        (change)="uploadFile($event)" accept=".png, .jpg, .jpeg">
                                    <div class="kt-dropzone__msg dz-message needsclick">
                                        <h3 class="kt-dropzone__msg-title">Drop an image file or click to
                                            upload.</h3>
                                        <span class="kt-dropzone__msg-desc">Upload your institution logo</span>
                                    </div>
                                </div>
                                <div *ngIf="logoImageError" class="invalid-feedback">
                                    File size cannot exceed 1 MB.
                                </div>
                                <div *ngIf="logoFileTypeError" class="invalid-feedback">
                                    Accepted file formats are .png, .jpg, .jpeg
                                </div>
                            </div>
                            <div class="form-group row" *ngIf="imageUploaded">
                                <div class="col-lg-12">
                                    <h4>Upload your institution logo</h4>
                                    <div id="imageDivMain" class="kt-demo" data-code-preview="true"
                                        data-code-html="true" data-code-js="false">
                                        <div class="kt-demo__preview kt-align-center">
                                            <div>
                                                <img id="uploadedImage"
                                                    style="margin: 5px; padding: 2px; border: 1px solid lightgray; height: 36.5%; width: 36.5%;">

                                            </div>
                                            <a class="btn btn-sm btn-danger btn-icon" (click)="removeImage()"
                                                ngbTooltip="Remove Image"><i class="fa fa-times"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group kt-margin-b-40">
                                <h4>Do you have a tag line?</h4>
                                <input class="form-control" [(ngModel)]="schoolProfile.schoolTitle"
                                    name="schoolProfile.schoolTitle">
                            </div>
                            <div class="kt-login__actions">
                                <div class="col-lg-6 kt-align-left kt-width-50">
                                    <button href="javascript:void(0)" (click)="previousclickevent()"
                                        class="btn btn-secondary btn-elevate kt-login__btn-secondary" type="button">
                                        <i class="fa fa-angle-left"></i> Previous
                                    </button>
                                </div>
                                <div class="col-lg-6 kt-align-right kt-width-50">
                                    <!-- <a class="kt-margin-r-10" href="javascript:void(0)" (click)="skip()">Skip</a> -->
                                    <button href="javascript:void(0)" (click)="saveandcontinue()" type="button"
                                        class="btn btn-primary btn-elevate kt-login__btn-primary">Next <i class="fa fa-angle-right"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="kt-login__body kt-margin-t-20" *ngIf="currentstep == 3" style="height: auto;">
                    <div class="kt-login__form" style="max-width: 600px !important;">
                        <form class="kt-form mb-0" action="" novalidate="novalidate">
                            <div class="form-group kt-margin-b-20">
                                <h4>Where are you located?</h4>
                                <input type="text" class="form-control kt-input" maxlength="100"
                                    placeholder="Enter your postal address or location"
                                    [(ngModel)]="schoolProfile.address.addressline1"
                                    name="schoolProfile.address.addressline1">
                            </div>
                            <div class="form-group kt-margin-b-40">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <label>Country</label>
                                        <select class="form-control country kt-margin-t-5 kt-margin-b-15"
                                            [(ngModel)]="schoolProfile.address.country" id="ddlCountry"
                                            name="schoolProfile.address.country">
                                            <option *ngFor="let option of countries"
                                                [attr.data-country]="option.flag_font_code" [ngValue]="option.name">
                                                {{option.name}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-lg-6">
                                        <label>City</label>
                                        <input type="text" class="form-control kt-input kt-margin-t-5" maxlength="100"
                                            autocomplete="off" [(ngModel)]="schoolProfile.address.city"
                                            name="schoolProfile.address.city" placeholder="Enter city name">
                                    </div>
                                </div>
                            </div>

                            <div class="form-group kt-margin-b-20">
                                <h4>Select your timezone and currency <br><label>Date, time, and currency will default
                                        to
                                        selected timezone and currency</label></h4>
                                <select name="schoolProfile.countryTimeZoneId" class="form-control"
                                    [(ngModel)]="schoolProfile.countryTimeZoneId" id="ddlTimeZone" [disabled]="true">
                                    <option *ngFor="let option of countryTimeZones"
                                        selected="schoolProfile.countryTimeZoneId == option.timeZoneId"
                                        [ngValue]="option.timeZoneId">
                                        {{option.displayName}}
                                    </option>
                                </select>

                                <select name="schoolProfile.currencyCode" class="form-control"
                                    [(ngModel)]="schoolProfile.currencyCode" id="ddlCurrency" [disabled]="true">
                                    <option *ngFor="let option of currencies"
                                        selected="schoolProfile.currencyCode == option.code" [ngValue]="option.code">
                                        ({{option.symbol}}) {{option.code}}
                                    </option>
                                </select>

                                <select name="schoolProfile.internationalCurrencyCode" class="form-control"
                                    [(ngModel)]="schoolProfile.internationalCurrencyCode" id="ddlInternationalCurrency">
                                    <option *ngFor="let option of currencies"
                                        selected="schoolProfile.internationalCurrencyCode == option.code" [ngValue]="option.code">
                                        ({{option.symbol}}) {{option.code}}
                                    </option>
                                </select>
                            </div>

                            <div class="form-group kt-margin-b-20">
                                <h4 class="kt-margin-b-15">Who is the primary contact person?</h4>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <label>Name</label>
                                        <input class="form-control kt-margin-t-5" type="text" autocomplete="off"
                                            placeholder="Enter primary contact Name"
                                            [(ngModel)]="schoolProfile.tenantPrimaryContactName"
                                            name="schoolProfile.tenantPrimaryContactName">
                                    </div>
                                    <div class="col-lg-6">
                                        <ej-phone-number [inputPhoneNumber]="schoolProfile.tenantPrimaryContactPhone"
                                            (phoneNumberChanged)="receiveTenantPhoneNumber($event)"
                                            (phoneNumberError)="receiveTenantPhoneNumberError($event)"
                                            [inputClass]="'kt-margin-t-5'"
                                            [dropdownClass]="'ej-select2-mt-5 ej-select2-selection-arrow'">
                                        </ej-phone-number>
                                    </div>
                                </div>
                            </div>
                            <div class="kt-login__actions">
                                <div class="col-lg-6 kt-align-left kt-width-50">
                                    <button href="javascript:void(0)" (click)="previousclickevent()"
                                        class="btn btn-secondary btn-elevate kt-login__btn-secondary" type="button"><i class="fa fa-angle-left"></i>
                                        Previous</button>
                                </div>
                                <div class="col-lg-6 kt-align-right kt-width-50">
                                    <!-- <a class="kt-margin-r-10" href="javascript:void(0)" (click)="skip()">Skip</a> -->
                                    <button href="javascript:void(0)" (click)="saveandcontinue()"
                                        class="btn btn-primary btn-elevate kt-login__btn-primary" type="button">Next <i class="fa fa-angle-right"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="kt-login__body kt-margin-t-20" *ngIf="currentstep == 4" style="height: auto;">
                    <div class="kt-login__form" style="max-width: 600px !important;">
                        <form class="kt-form mb-0" action="" novalidate="novalidate">
                            <div class="form-group kt-margin-b-40">
                                <h4>What is your institution website address</h4>
                                <input type="text" class="form-control ej-select-no-backgroundimg"
                                    [(ngModel)]="schoolProfile.URL" autocomplete="off" maxlength="150"
                                    placeholder="E.g.: https://www.edujournal.com" name="schoolProfile.URL"
                                    [ngClass]="{ 'is-invalid': error.url }">
                                <div *ngIf="error.url" class="invalid-feedback">
                                    Please enter valid url.
                                </div>
                            </div>
                            <div class="form-group kt-margin-b-20">
                                <h4>What is your institution contact details</h4>
                            </div>
                            <div class="form-group kt-margin-b-40">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <label>Email</label>
                                        <input type="text" class="form-control ej-select-no-backgroundimg kt-margin-t-5"
                                            [(ngModel)]="schoolProfile.email" [ngClass]="{ 'is-invalid': error.email }"
                                            placeholder="Enter email address" autocomplete="off"
                                            name="schoolProfile.email" [disabled]="true">
                                        <div *ngIf="error.email" class="invalid-feedback">
                                            Please enter valid email.
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <ej-phone-number [inputPhoneNumber]="schoolProfile.phone"
                                            (phoneNumberChanged)="receivePhoneNumber($event)"
                                            (phoneNumberError)="receivePhoneNumberError($event)"
                                            [inputClass]="'kt-margin-t-5'"
                                            [dropdownClass]="'ej-select2-mt-5 ej-select2-selection-arrow'">
                                        </ej-phone-number>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group kt-margin-b-40">
                                <h4>Add your social media links</h4>
                                <div class="input-group">
                                    <input type="text" class="form-control ej-select-no-backgroundimg"
                                        placeholder="Facebook" aria-describedby="basic-addon2"
                                        [(ngModel)]="schoolProfile.facebookURL" name="schoolProfile.facebookURL"
                                        (blur)="validateFacebookUrl($event)"
                                        [ngClass]="{ 'is-invalid' : error.facebookLink }">
                                    <div class="input-group-append" style="height: 50px; margin-top: 1.25rem;"><span
                                            class="input-group-text" style="border:none;" id="basic-addon2"><i
                                                class="fab fa-facebook-f"></i></span></div>
                                    <div *ngIf="error.facebookLink" class="invalid-feedback">
                                        Please re-check the Facebook Url
                                    </div>
                                </div>
                                <div class="input-group">
                                    <input type="text" class="form-control ej-select-no-backgroundimg"
                                        placeholder="Twitter" aria-describedby="basic-addon2"
                                        [(ngModel)]="schoolProfile.twitterURL" name="schoolProfile.twitterURL"
                                        (blur)="validateTwitterUrl($event)"
                                        [ngClass]="{ 'is-invalid' : error.twitterLink }">
                                    <div class="input-group-append" style="height: 50px; margin-top: 1.25rem;"><span
                                            class="input-group-text" style="border:none;" id="basic-addon2"><i
                                                class="fab fa-twitter"></i></span></div>
                                    <div *ngIf="error.twitterLink" class="invalid-feedback">
                                        Please re-check the Twitter Url
                                    </div>
                                </div>
                                <div class="input-group">
                                    <input type="text" class="form-control ej-select-no-backgroundimg"
                                        placeholder="LinkedIn" aria-describedby="basic-addon2"
                                        [(ngModel)]="schoolProfile.linkedInURL" name="schoolProfile.linkedInURL"
                                        (blur)="validateLinkedinUrl($event)"
                                        [ngClass]="{ 'is-invalid' : error.linkedInLink }">
                                    <div class="input-group-append" style="height: 50px; margin-top: 1.25rem;"><span
                                            class="input-group-text" style="border:none;" id="basic-addon2"><i
                                                class="fab fa-linkedin-in"></i></span></div>
                                    <div *ngIf="error.linkedInLink" class="invalid-feedback">
                                        Please re-check the LinkedIn Url
                                    </div>
                                </div>
                                <div class="input-group">
                                    <input type="text" class="form-control ej-select-no-backgroundimg" placeholder="Additional Links"
                                        aria-describedby="basic-addon2" [(ngModel)]="schoolProfile.otherSocialMediaURL"
                                        name="schoolProfile.otherSocialMediaURL" [ngClass]="{ 'is-invalid' : error.otherSocialMediaLink }"
                                        (blur)="validateOtherSocialMediaUrl($event)">
                                    <div class="input-group-append" style="height: 50px; margin-top: 1.25rem;"><span
                                            class="input-group-text" style="border:none;" id="basic-addon2"><i
                                                class="fas fa-ellipsis-h"></i></span></div>
                                    <div *ngIf="error.otherSocialMediaLink" class="invalid-feedback">
                                        Please re-check the Url
                                    </div>
                                </div>
                            </div>
                            <div class="kt-login__actions">
                                <div class="col-lg-6 kt-align-left kt-width-50">
                                    <button href="javascript:void(0)" (click)="previousclickevent()"
                                        class="btn btn-secondary btn-elevate kt-login__btn-secondary" type="button"><i class="fa fa-angle-left"></i>
                                        Previous</button>
                                </div>
                                <div class="col-lg-6 kt-align-right kt-width-50">
                                    <!-- <a class="kt-margin-r-10" href="javascript:void(0)" (click)="skip()">Skip</a> -->
                                    <button href="javascript:void(0)" (click)="saveandcontinue()"
                                        class="btn btn-primary btn-elevate kt-login__btn-primary" type="button">Next <i class="fa fa-angle-right"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="kt-login__body kt-margin-t-20" *ngIf="currentstep == 5">
                    <div class="kt-login__form" style="max-width: 600px !important;">
                        <form class="kt-form mb-0" action="" novalidate="novalidate">
                            <div class="form-group kt-margin-b-20">
                                <div class="kt-heading kt-heading--sm">Setup Sample Data</div>
                                <div class="ng-tns-c4-12">
                                    <label class="kt-checkbox">
                                        <input class="ng-tns-c4-12 ng-untouched ng-pristine ng-valid"
                                            [(ngModel)]="schoolProfile.requiredSampleData" type="checkbox"
                                            name="schoolProfile.requiredSampleData">
                                        <h4>Import Sample Data </h4> <span class="ng-tns-c4-3"></span>
                                    </label>
                                    <span class="form-text text-warning">Sample data is recommended to help you
                                        evaluate eduJournal</span>
                                </div>
                            </div>
                            <!--begin::Action-->
                            <div class="kt-login__actions">
                                <div class="col-lg-6 kt-align-left kt-width-50">
                                    <button href="javascript:void(0)" (click)="previousclickevent()"
                                        class="btn btn-secondary btn-elevate kt-login__btn-secondary" type="button"><i class="fa fa-angle-left"></i>
                                        Previous</button>
                                </div>
                                <div class="col-lg-6 kt-align-right kt-width-50">
                                    <button href="javascript:void(0)" (click)="saveandcontinue()"
                                        class="btn btn-primary btn-elevate kt-login__btn-primary" type="button">Get Started <i
                                            class="fa fa-angle-right"></i>
                                    </button>
                                </div>
                            </div>
                            <!--end::Action-->
                        </form>
                    </div>
                </div>
            </div>
            <!--end::Content-->
        </div>
    </div>
</div>

<div style="display: none;">
    <ng-container *ngTemplateOutlet="imagecroppermodal"></ng-container>
</div>
<ng-template #imagecroppermodal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title"> Crop Image</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <!-- <angular-cropper #angularCropper [imageUrl]="imageUrl"></angular-cropper> -->
        <image-cropper #imageCropper *ngIf="imageUrl" [imageBase64]="imageUrl" [imageChangedEvent]="imageChangedEvent"
            [onlyScaleDown]="true" [roundCropper]="false" [maintainAspectRatio]="false"
            [canvasRotation]="canvasRotation" [transform]="transform" [alignImage]="'left'"
            [style.display]="showCropper ? null : 'none'" format="jpeg" (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady($event)" (loadImageFailed)="loadImageFailed()">
        </image-cropper>
    </div>
    <div class="modal-footer">
        <div style="float: right;">
            <button type="button" class="btn btn-secondary" (click)="modal.dismiss('Cross click')">Cancel</button>
            <button type="submit" class="btn btn-primary kt-margin-l-5" (click)="cropImage()">Capture</button>
        </div>
    </div>
</ng-template>

<div class="modal modal-md fade" id="kt_modal_otp" tabindex="-1" role="dialog" data-backdrop="static"
    data-keyboard="false" aria-labelledby="exampleModalLabel" style="display: none;" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="kt-section text-center">
                    <div class="kt-section__title">Please enter the OTP to verify your account</div>
                    <div class="kt-section__desc">
                        A One-Time Password has been sent to <span *ngIf="!OtpTokenModel.editPhone">
                            {{OtpTokenModel.PhoneNumber}} </span>
                        <ej-phone-number *ngIf="OtpTokenModel.editPhone" [inputPhoneNumber]="OtpTokenModel.PhoneNumber"
                            (phoneNumberChanged)="receivePhoneNumber($event)" [displayLabel]="false"
                            style="max-width: 210px;  margin-left: 10px;"
                            (phoneNumberError)="receiveOtpPhoneNumberError($event)">
                        </ej-phone-number>
                        &nbsp;&nbsp;
                        <a *ngIf="!OtpTokenModel.editPhone" (click)="ChangeOTPPhone()" placement="bottom"
                            tooltipClass="kt-font-info" ngbTooltip="Edit Phone number" href="javascript:void(0)">
                            <i class="fas fa-edit"></i>
                        </a>
                        <a *ngIf="OtpTokenModel.editPhone" (click)="SendOtpNewPhone()" placement="bottom"
                            tooltipClass="kt-font-info" ngbTooltip="Re-Send Code" href="javascript:void(0)">
                            <i class="fas fa-arrow-right"></i>
                        </a>
                    </div>
                    <div class="kt-section__content">
                        <div id="otp" class="inputs d-flex flex-row justify-content-center mt-2">
                            <input class="m-2 text-center form-control rounded" type="text"
                                *ngFor="let t of OtpTokenModel.TokenCode; let index = index" id="{{t.id}}" maxlength="1"
                                name="{{t.name}}" (keyup)="onKeyUpOTP($event)" [(ngModel)]="t.value" />
                        </div>
                        <div class="form-group kt-margin-b-10" *ngIf="error.otpVerification">
                            <label class="invalid-feedback" style="display: block;">
                                {{error.otpVerificationMsg}}
                            </label>
                        </div>
                        <div class="mt-4">
                            <button class="btn btn-success px-4 validate" (click)="VerifyOtp()">Validate</button>
                            <a href="javascript:void(0)" class="btn btn-secondary ml-2" (click)="skipOtp()">Skip</a>
                        </div>
                        <ng-container *ngIf="!OtpTokenModel.editPhone">
                            <div class="mt-3">
                                <a href="javascript:void(0)" class="kt-link kt-font-bolder"
                                    *ngIf="OtpResendCountdown <= 0" (click)="RequestOtp()">Resend OTP</a>
                                <a class="kt-link kt-font-bolder disabled" *ngIf="OtpResendCountdown > 0">Resend OTP</a>
                            </div>
                            <div class="mt-1" *ngIf="OtpResendCountdown > 0">
                                <span class="text-muted kt-font-sm">Resend in {{OtpResendCountdown}} seconds</span>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>