/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./topbar-quick-panel.component";
var styles_TopbarQuickPanelComponent = [];
var RenderType_TopbarQuickPanelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TopbarQuickPanelComponent, data: {} });
export { RenderType_TopbarQuickPanelComponent as RenderType_TopbarQuickPanelComponent };
export function View_TopbarQuickPanelComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "kt-header__topbar-item dropdown"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [["class", "kt-header__topbar-wrapper"]], null, null, null, null, null))], null, null); }
export function View_TopbarQuickPanelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ej-topbar-quick-panel", [], null, null, null, View_TopbarQuickPanelComponent_0, RenderType_TopbarQuickPanelComponent)), i0.ɵdid(1, 114688, null, 0, i1.TopbarQuickPanelComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TopbarQuickPanelComponentNgFactory = i0.ɵccf("ej-topbar-quick-panel", i1.TopbarQuickPanelComponent, View_TopbarQuickPanelComponent_Host_0, {}, {}, []);
export { TopbarQuickPanelComponentNgFactory as TopbarQuickPanelComponentNgFactory };
