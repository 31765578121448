import { Component, OnInit, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'ej-topbar-quick-panel',
  templateUrl: './topbar-quick-panel.component.html'
})
export class TopbarQuickPanelComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
}