/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./header-menu.component";
import * as i3 from "../../../ej-shared/services/global.service";
import * as i4 from "../../../ej-shared/services/shared.service";
var styles_HeaderMenuComponent = [];
var RenderType_HeaderMenuComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_HeaderMenuComponent, data: {} });
export { RenderType_HeaderMenuComponent as RenderType_HeaderMenuComponent };
function View_HeaderMenuComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [["href", "javascript:;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "img", [["alt", "Logo"], ["src", "../assets/media/logos/ej_logo.png"]], null, null, null, null, null))], null, null); }
export function View_HeaderMenuComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "kt-header-menu-wrapper"], ["id", "kt_header_menu_wrapper"], ["style", "opacity: 1;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "kt-header-logo"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeaderMenuComponent_1)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.isTenant && ((_co.subscriptionExpiryInfo == null) ? null : _co.subscriptionExpiryInfo.IsAccountExpired)) || _co.isMember); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_HeaderMenuComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ej-header-menu", [], null, null, null, View_HeaderMenuComponent_0, RenderType_HeaderMenuComponent)), i0.ɵdid(1, 114688, null, 0, i2.HeaderMenuComponent, [i0.ChangeDetectorRef, i3.GlobalService, i4.SharedService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderMenuComponentNgFactory = i0.ɵccf("ej-header-menu", i2.HeaderMenuComponent, View_HeaderMenuComponent_Host_0, {}, {}, []);
export { HeaderMenuComponentNgFactory as HeaderMenuComponentNgFactory };
