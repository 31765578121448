<!-- begin:: Header Mobile -->
<div id="kt_header_mobile" class="kt-header-mobile kt-header-mobile--fixed"
    [ngClass]="{'justify-content-end' : displaySimpleHeader}">
    <div class="kt-header-mobile__logo" *ngIf="!displaySimpleHeader">
        <a routerLink="/">
            <img alt="logo" [attr.src]="headerLogo" />
        </a>
    </div>
    <div class="kt-header-mobile__toolbar" *ngIf="!displaySimpleHeader">
        <button [hidden]="!asideDisplay || isMember" class="kt-header-mobile__toggler kt-header-mobile__toggler--left"
            id="kt_aside_mobile_toggler"><span></span></button>
        <button ejToggle [options]="toggleOptions" class="kt-header-mobile__topbar-toggler"
            id="kt_header_mobile_topbar_toggler"><i class="flaticon-more"></i></button>
    </div>
    <div class="kt-header-mobile__toolbar" *ngIf="displaySimpleHeader">
        <span style="color: #959cb6;">Hi,&nbsp;</span>
        <span style="color: #6c7293; font-weight: 500;">{{username}}</span>&nbsp;&nbsp;
        <a href="javascript:void(0)" (click)="signout()" class="btn btn-label btn-label-primary btn-sm btn-bold">
            Sign Out
        </a>
    </div>
</div>
<!-- end:: Header Mobile -->