<head>
    <base href="../">
    <meta charset="utf-8" />
    <title>Metronic | Login Page 1</title>
    <meta name="description" content="Login page example">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    <script src="https://ajax.googleapis.com/ajax/libs/webfont/1.6.16/webfont.js"></script>
    <script>
        WebFont.load({
            google: {
                "families": ["Poppins:300,400,500,600,700", "Roboto:300,400,500,600,700"]
            },
            active: function () {
                sessionStorage.fonts = true;
            }
        });
    </script>
    <link href="../../../assets/css/demo1/pages/login/login-1.css" rel="stylesheet" type="text/css" />
    <link href="../../../assets/css/demo1/style.bundle.css" rel="stylesheet" type="text/css" />
    <link href="../../../assets/css/demo1/skins/header/base/light.css" rel="stylesheet" type="text/css" />
    <link href="../../../assets/css/demo1/skins/header/menu/light.css" rel="stylesheet" type="text/css" />
    <link href="../../../assets/css/demo1/skins/brand/light.css" rel="stylesheet" type="text/css" />
    <link href="../../../assets/css/demo1/skins/aside/light.css" rel="stylesheet" type="text/css" />
    <link rel="shortcut icon" href="../../../assets/media/logos/favicon.ico" />

</head>

<div class="kt-grid kt-grid--ver kt-grid--root">
    <div class="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v1" id="kt_login">
        <div
            class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
            <div class="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside kt-hidden-mobile"
                style="background-image: url(../assets/media//bg/ej-bg-4.png);width: 500px !important;">
                <div class="kt-grid__item">
                    <a href="#" class="kt-login__logo">
                        <img src="../../../assets/media/logos/ej_logo-light.png">
                    </a>
                </div>
                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver">
                    <div class="kt-grid__item kt-grid__item--bottom">
                        <h3 class="kt-login__title text-center">You are few clicks away to create and Sell your
                            courses.</h3>
                        <h4 class="kt-login__subtitle text-center">Start your Training Institute in minutes, save time
                            and money.</h4>
                    </div>
                </div>
                <div class="kt-grid__item">
                    <div class="kt-login__info">
                        <div class="kt-login__copyright ">
                            © 2020 eduJournal
                        </div>
                        <div class="kt-login__menu">
                            <a href="https://www.edujournal.com/privacy-policy/" target="_blank" class="kt-link">Privacy</a>
                            <a href="https://www.edujournal.com/terms-of-use/" target="_blank" class="kt-link">Terms</a>
                            <a href="#" class="kt-link">Contact</a>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside kt-hidden-desktop kt-bg-light kt-padding-t-70">
                <div class="kt-grid__item">
                    <a href="#" class="kt-login__logo kt-margin-0 justify-content-center">
                        <img
                            src="https://member.edujournal.com/assets/demo/default/media/img/logo/logo_default_light.png">
                    </a>
                </div>
            </div>
            <div
                class="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper kt-padding-t-5">
                <div class="kt-login__body kt-margin-t-20" *ngIf="currentstep == 1">
                    <div class="kt-login__form" style="max-width: 600px !important;">
                        <form class="kt-form" action="" novalidate="novalidate">
                            <div class="form-group kt-margin-b-20">
                                <h4>What kind of course do you offer?</h4>
                                <input type="text" class="form-control" placeholder="Search for the course category...">
                            </div>
                            <div class="kt-divider">
                                <span></span>
                                <span>or choose from below category</span>
                                <span></span>
                            </div>
                            <div class=" kt-margin-t-20">
                                <button type="button" class="btn btn-secondary btn-font-lg">Sports</button> &nbsp;
                                <button type="button" class="btn btn-secondary btn-font-lg">Language</button> &nbsp;
                                <button type="button" class="btn btn-secondary btn-font-lg">Music</button> &nbsp;
                                <button type="button" class="btn btn-secondary btn-font-lg">Cooking</button> &nbsp;
                                <button type="button" class="btn btn-secondary btn-font-lg">Swimming</button>
                            </div>

                            <div class="form-group kt-margin-b-20 kt-margin-t-40">
                                <label>Select related tags: Optional</label>
                            </div>
                            <div class="kt-margin-t-20">
                                <button type="button" class="btn btn-secondary btn-pill btn-sm">tag1</button> &nbsp;
                                <button type="button" class="btn btn-secondary btn-pill btn-sm">tag2</button> &nbsp;
                                <button type="button" class="btn btn-secondary btn-pill btn-sm">tag3</button> &nbsp;
                                <button type="button" class="btn btn-secondary btn-pill btn-sm">tag4</button> &nbsp;
                                <button type="button" class="btn btn-secondary btn-pill btn-sm">tag5</button>
                            </div>

                            <div class="kt-login__actions">
                                <a href="#" class="kt-link kt-login__link-forgot">
                                </a>
                                <button id="kt_login_signin_submit" href="javascript:void(0)"
                                    (click)="saveandcontinue()"
                                    class="btn btn-primary btn-elevate kt-login__btn-primary">Next <i
                                        class="fa fa-angle-right"></i></button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="kt-login__body kt-margin-t-20" *ngIf="currentstep == 2">
                    <div class="kt-login__form" style="max-width: 600px !important;">
                        <form class="kt-form" action="" novalidate="novalidate">
                            <div class="form-group kt-margin-b-40">
                                <h4>Which language do you speak?</h4>
                                <div class="kt-margin-t-20">
                                    <button type="button" class="btn btn-secondary btn-pill btn-sm">Telugu</button>
                                    &nbsp;
                                    <button type="button" class="btn btn-secondary btn-pill btn-sm">Kannada</button>
                                    &nbsp;
                                    <button type="button" class="btn btn-secondary btn-pill btn-sm">Hindi</button>
                                    &nbsp;
                                    <button type="button" class="btn btn-secondary btn-pill btn-sm">Tamil</button>
                                    &nbsp;
                                    <button type="button" class="btn btn-secondary btn-pill btn-sm">Malayalam</button>
                                </div>
                            </div>

                            <div class="form-group kt-margin-b-40 kt-margin-t-50">
                                <h4>What type of class do you want to conduct?</h4>
                                <div class="row kt-margin-t-20">
                                    <div class="col-lg-6">
                                        <label class="kt-option">
                                            <span class="kt-option__control">
                                                <span class="kt-checkbox kt-margin-b-20">
                                                    <input type="checkbox" value="1" checked="">
                                                    <span></span>
                                                </span>
                                            </span>
                                            <span class="kt-option__label">
                                                <span class="kt-option__head">
                                                    <span class="kt-option__title">
                                                        Individual Class
                                                    </span>
                                                    <span class="kt-option__focus">

                                                    </span>
                                                </span>
                                                <span class="kt-option__body">
                                                    One class per student
                                                </span>

                                            </span>
                                        </label>
                                    </div>
                                    <div class="col-lg-6">
                                        <label class="kt-option">
                                            <span class="kt-option__control">
                                                <span class="kt-checkbox kt-margin-b-20">
                                                    <input type="checkbox" name="m_option_1" value="1">
                                                    <span></span>
                                                </span>
                                            </span>
                                            <span class="kt-option__label">
                                                <span class="kt-option__head">
                                                    <span class="kt-option__title">
                                                        Group Class
                                                    </span>
                                                    <span class="kt-option__focus">

                                                    </span>
                                                </span>
                                                <span class="kt-option__body">
                                                    One class with multiple students
                                                </span>

                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="kt-login__actions">
                                <button href="javascript:void(0)" (click)="previousclickevent()"
                                    class="btn btn-secondary btn-elevate kt-login__btn-secondary">
                                    <i class="fa fa-angle-left"></i>Back
                                </button>
                                <button id="kt_login_signin_submit" href="javascript:void(0)"
                                    (click)="saveandcontinue()"
                                    class="btn btn-primary btn-elevate kt-login__btn-primary">Next <i
                                        class="fa fa-angle-right"></i></button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="kt-login__body kt-margin-t-20" *ngIf="currentstep == 3">
                    <div class="kt-login__form" style="max-width: 600px !important;">
                        <form class="kt-form" action="" novalidate="novalidate">
                            <div class="form-group kt-margin-b-40">
                                <h4>Give a unique title for your course</h4>
                                <input class="form-control" type="text"
                                    placeholder="Ex: Learn programming with basic understanding" name="email"
                                    autocomplete="off">
                            </div>
                            <div class="form-group kt-margin-b-40">
                                <h4>Upload a banner image</h4>
                                <div class="kt-dropzone dropzone dz-clickable" action="inc/api/dropzone/upload.php"
                                    id="m-dropzone-one">
                                    <div class="kt-dropzone__msg dz-message needsclick">
                                        <h3 class="kt-dropzone__msg-title">Drop files here or click to upload.</h3>
                                        <span class="kt-dropzone__msg-desc">This is just a demo dropzone. Selected
                                            files are <strong>not</strong> actually uploaded.</span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group kt-margin-b-40">
                                <h4>How do you want to conduct your class?</h4>
                                <div class="kt-radio-inline kt-margin-t-20">
                                    <label class="kt-radio">
                                        <input type="radio" name="radio2"> Online
                                        <span></span>
                                    </label>
                                    <label class="kt-radio">
                                        <input type="radio" name="radio2"> Offline
                                        <span></span>
                                    </label>
                                </div>
                            </div>
                            <div class="form-group kt-margin-b-20">
                                <h4>Where will you conduct your course?</h4>
                                <input class="form-control" type="text"
                                    placeholder="Enter your postal address or location" name="email" autocomplete="off">
                            </div>
                            <div class="kt-login__actions">
                                <button href="javascript:void(0)" (click)="previousclickevent()"
                                    class="btn btn-secondary btn-elevate kt-login__btn-secondary">
                                    <i class="fa fa-angle-left"></i>Back
                                </button>
                                <button href="javascript:void(0)" (click)="saveandcontinue()"
                                    id="kt_login_signin_submit"
                                    class="btn btn-primary btn-elevate kt-login__btn-primary">Next <i
                                        class="fa fa-angle-right"></i></button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="kt-login__body kt-margin-t-20" *ngIf="currentstep == 4">
                    <div class="kt-login__form" style="max-width: 600px !important;">
                        <form class="kt-form" action="" novalidate="novalidate">
                            <div class="form-group kt-margin-b-40">
                                <h4>Tell about yourself (40 words minimum)</h4>
                                <textarea class="form-control"
                                    placeholder="Ex: I am a programmer with..i have done my degree in..." name="email"
                                    autocomplete="off"></textarea>
                            </div>
                            <div class="form-group kt-margin-b-40">
                                <h4>Add your CV(without your personal details)</h4>
                                <textarea class="form-control" name="email" autocomplete="off" rows="5"></textarea>
                            </div>
                            <div class="form-group kt-margin-b-40">
                                <h4>Add your social media links</h4>
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Facebook"
                                        aria-describedby="basic-addon2">
                                    <div class="input-group-append" style="height: 50px; margin-top: 1.25rem;"><span
                                            class="input-group-text" style="border:none;" id="basic-addon2"><i
                                                class="fab fa-facebook-f"></i></span></div>
                                </div>
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Twitter"
                                        aria-describedby="basic-addon2">
                                    <div class="input-group-append" style="height: 50px; margin-top: 1.25rem;"><span
                                            class="input-group-text" style="border:none;" id="basic-addon2"><i
                                                class="fab fa-twitter"></i></span></div>
                                </div>
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Linkdin"
                                        aria-describedby="basic-addon2">
                                    <div class="input-group-append" style="height: 50px; margin-top: 1.25rem;"><span
                                            class="input-group-text" style="border:none;" id="basic-addon2"><i
                                                class="fab fa-linkedin-in"></i></span></div>
                                </div>
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Additional Links"
                                        aria-describedby="basic-addon2">
                                    <div class="input-group-append" style="height: 50px; margin-top: 1.25rem;"><span
                                            class="input-group-text" style="border:none;" id="basic-addon2"><i
                                                class="fas fa-ellipsis-h"></i></span></div>
                                </div>
                            </div>
                            <div class="kt-login__actions">
                                <button href="javascript:void(0)" (click)="previousclickevent()"
                                    class="btn btn-secondary btn-elevate kt-login__btn-secondary">
                                    <i class="fa fa-angle-left"></i>Back
                                </button>
                                <button id="kt_login_signin_submit" href="javascript:void(0)"
                                    (click)="saveandcontinue()"
                                    class="btn btn-primary btn-elevate kt-login__btn-primary">Next <i
                                        class="fa fa-angle-right"></i></button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="kt-login__body kt-margin-t-20" *ngIf="currentstep == 5">
                    <div class="kt-login__form" style="max-width: 600px !important;">
                        <form class="kt-form" action="" novalidate="novalidate">
                            <div class="form-group kt-margin-b-40">
                                <h4>What is your fee per session?</h4>
                                <div class="input-group col-lg-5 row">
                                    <input type="text" class="form-control" placeholder="Amount"
                                        aria-describedby="basic-addon2">
                                    <div class="input-group-append" style="height: 50px; margin-top: 1.25rem;"><span
                                            class="input-group-text" style="border:none;" id="basic-addon2">$</span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group kt-margin-b-40">
                                <label class="kt-checkbox">
                                    <input type="checkbox">
                                    <span></span>
                                    <h4>Apply discount when student enrols for more sessions</h4>
                                </label>
                                <div class="input-daterange input-group" id="kt_datepicker_5">
                                    <input type="text" class="form-control" name="start"
                                        placeholder="Discount in percentage">
                                    <div class="input-group-append" style="height: 50px; margin-top: 1.25rem;">
                                        <span class="input-group-text" style="border:none;">On</span>
                                    </div>
                                    <input type="text" class="form-control" name="end" placeholder="No of sessions">
                                </div>
                            </div>
                            <div class="form-group kt-margin-b-40">
                                <label class="kt-checkbox">
                                    <input type="checkbox">
                                    <span></span>
                                    <h4>Offer your first class for free</h4>
                                </label>
                            </div>
                            <div class="kt-login__actions">
                                <button href="javascript:void(0)" (click)="previousclickevent()"
                                    class="btn btn-secondary btn-elevate kt-login__btn-secondary">
                                    <i class="fa fa-angle-left"></i>Back
                                </button>
                                <button id="kt_login_signin_submit" href="javascript:void(0)"
                                    (click)="saveandcontinue()"
                                    class="btn btn-primary btn-elevate kt-login__btn-primary">Next <i
                                        class="fa fa-angle-right"></i></button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="kt-login__body kt-margin-t-20" *ngIf="currentstep == 6">
                    <div class="kt-login__form" style="max-width: 600px !important;">
                        <form class="kt-form" action="" novalidate="novalidate">
                            <div class="form-group kt-margin-b-40">
                                <h4>Your contact information</h4>
                                <input class="form-control" type="text" name="email" autocomplete="off"
                                    placeholder="Phone Number" />
                            </div>
                            <div class="form-group kt-margin-b-40">
                                <h4>Your profile picture</h4>
                                <div class="row text-center">
                                    <div class="col-lg-12">
                                        <div class="kt-avatar kt-avatar--outline kt-avatar--circle"
                                            id="kt_apps_user_add_avatar">
                                            <div class="kt-avatar__holder"
                                                style="background-image: url(./assets/media/users/300_10.jpg)">
                                            </div>
                                            <label class="kt-avatar__upload" data-toggle="kt-tooltip" title=""
                                                data-original-title="Change avatar">
                                                <i class="fa fa-pen"></i>
                                                <input type="file" name="kt_apps_user_add_user_avatar">
                                            </label>
                                            <span class="kt-avatar__cancel" data-toggle="kt-tooltip" title=""
                                                data-original-title="Cancel avatar">
                                                <i class="fa fa-times"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="kt-login__actions">
                                <button href="javascript:void(0)" (click)="previousclickevent()"
                                    class="btn btn-secondary btn-elevate kt-login__btn-secondary">
                                    <i class="fa fa-angle-left"></i>Back
                                </button>
                                <button id="kt_login_signin_submit" href="javascript:void(0)"
                                    (click)="saveandcontinue()"
                                    class="btn btn-primary btn-elevate kt-login__btn-primary">Next <i
                                        class="fa fa-angle-right"></i></button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="kt-login__body kt-margin-t-20" *ngIf="currentstep == 7">
                    <div class="kt-login__form" style="max-width: 600px !important;">
                        <form class="kt-form" action="" novalidate="novalidate">
                            <div class="form-group kt-margin-b-40">
                                <h4>What is the duration of your course?</h4>
                                <div class="input-daterange input-group" id="kt_datepicker_5">
                                    <input class="form-control" name="fromDate" [(ngModel)]="fromDate"
                                        placeholder="From Date" ngbDatepicker #r="ngbDatepicker" (click)="r.toggle()"
                                        onkeydown="return false" [placement]="'bottom'" autocomplete="off"
                                        [ngClass]="{ 'is-invalid': error.fromDate }">
                                    <div class="input-group-append" style="height: 50px; margin-top: 1.25rem;">
                                        <span class="input-group-text" style="border:none;"><i
                                                class="fa fa-ellipsis-h"></i></span>
                                    </div>
                                    <input class="form-control" name="toDate" [(ngModel)]="toDate" placeholder="To Date"
                                        ngbDatepicker #t="ngbDatepicker" (click)="t.toggle()" onkeydown="return false"
                                        [placement]="'bottom'" autocomplete="off"
                                        [ngClass]="{ 'is-invalid': error.toDate }">
                                    <div *ngIf="error.fromDate" class="invalid-feedback">
                                        Please select date range.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group kt-margin-b-40">
                                <h4>What is your course schedule?</h4>
                            </div>
                            <div class="form-group kt-margin-b-40">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <label>Weekday</label>
                                        <select name="weekday" class="form-control" [(ngModel)]="weekday"
                                            id="ddlWeekday" [ngClass]="{ 'is-invalid': error.weekday }">
                                            <option *ngFor="let option of weekdays" selected="weekday == option.name"
                                                [ngValue]="option.name">
                                                {{option.name}}
                                            </option>
                                        </select>
                                        <div *ngIf="error.weekday" class="invalid-feedback">
                                            Please select Weekday.
                                        </div>
                                    </div>

                                    <div class="col-lg-3">
                                        <label>Start time</label>
                                        <input class="form-control" type="text" [(ngModel)]="fromTime" #ft="ngModel"
                                            name="fromTime" id="fromTime" autocomplete="off"
                                            [ngClass]="{ 'is-invalid': error.fromTime }" />
                                        <!-- <input class="form-control timepicker" type="text" [(ngModel)]="fromTime" name="fromTime" autocomplete="off" placeholder="-:--" [ngClass]="{ 'is-invalid': error.fromTime }"> -->
                                    </div>
                                    <div *ngIf="error.fromTime" class="invalid-feedback">
                                        Please select Start time.
                                    </div>
                                    <div class="col-lg-3">
                                        <label>End time</label>
                                        <input class="form-control" type="text" [(ngModel)]="toTime" #tt="ngModel"
                                            name="toTime" id="toTime" autocomplete="off"
                                            [ngClass]="{ 'is-invalid': error.toTime }" />
                                        <!-- <input class="form-control" type="text" [(ngModel)]="toTime" name="toTime" autocomplete="off" placeholder="-:--" [ngClass]="{ 'is-invalid': error.toTime }"> -->
                                    </div>
                                    <div *ngIf="error.toTime" class="invalid-feedback">
                                        Please select End time.
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <input class="form-control" type="text" name="description"
                                            [(ngModel)]="description" autocomplete="off"
                                            placeholder="Specify what you teach in this session">
                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-center">
                                <button class="btn btn-outline-primary" (click)="addSession()">Add Another
                                    session</button>
                            </div>
                            <div class="table-responsive kt-margin-t-20"
                                *ngFor="let weeklySchedule of trainingProgram.weeklySchedules">
                                <table class="table table-bordered">
                                    <tbody>
                                        <tr class=" kt-shape-bg-color-1 ">
                                            <td colspan="2 ">
                                                <div class="row ">
                                                    <div class="col-lg-6 text-left ">
                                                        <strong>{{ weeklySchedule.weekday }}</strong>
                                                    </div>
                                                    <div class="col-lg-6 text-right ">
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngFor="let schedules of weeklySchedule.schedules; let i=index">
                                            <th width="30% " scope="row ">{{ schedules.fromTime - schedules.toTime}
                                            </th>
                                            <td class=" ">{{ schedules.description }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="kt-login__actions ">
                                <button href="javascript:void(0) " (click)="previousclickevent() "
                                    class="btn btn-secondary btn-elevate kt-login__btn-secondary ">
                                    <i class="fa fa-angle-left "></i>Back
                                </button>
                                <button id="kt_login_signin_submit " href="javascript:void(0) "
                                    (click)="saveandcontinue() "
                                    class="btn btn-primary btn-elevate kt-login__btn-primary ">Next <i
                                        class="fa fa-angle-right "></i></button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="kt-login__body kt-margin-t-20 " *ngIf="currentstep==8 ">
                    <div class="kt-login__form " style="max-width: 600px !important; ">
                        <h5 class="kt-font-success ">
                            Congratulations! You successfully completed creating your first course
                        </h5>
                        <small>Promote your course by sharing in your social media accounts</small>
                        <div class="kt-portlet kt-portlet--bordered kt-margin-t-20 ">
                            <div class="kt-portlet__body ">
                                <div class="row justify-content-center kt-margin-b-20 ">
                                    <strong class="kt-font-dark ">Choose the platforms on which you wish to promote
                                        your course</strong>
                                </div>
                                <div class="row justify-content-center ">
                                    <div class="btn-group ">
                                        <button type="button " class="btn btn-lg btn-secondary "><i
                                                class="fab fa-facebook-f "></i></button>
                                        <button type="button " class="btn btn-lg btn-secondary "><i
                                                class="fab fa-twitter "></i></button>
                                        <button type="button " class="btn btn-lg btn-secondary "><i
                                                class="fab fa-linkedin-in "></i></button>
                                        <button type="button " class="btn btn-lg btn-secondary "><i
                                                class="fas fa-copy "></i></button>
                                        <button type="button " class="btn btn-lg btn-secondary "><i
                                                class="fas fa-envelope "></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="kt-login__actions ">
                            <button href="javascript:void(0) " (click)="previousclickevent() "
                                class="btn btn-secondary btn-elevate kt-login__btn-secondary ">
                                <i class="fa fa-angle-left "></i>Back
                            </button>
                            <button id="kt_login_signin_submit " href="javascript:void(0) "
                                class="btn btn-primary btn-elevate kt-login__btn-primary ">Getstarted <i
                                    class="fa fa-angle-right "></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<script>
    var KTAppOptions = {
        "colors ": {
            "state ": {
                "brand ": "#5ba96f ",
                "dark ": "#282a3c ",
                "light ": "#ffffff ",
                "primary ": "#61CE70 ",
                "success ": "#34bfa3 ",
                "info ": "#36a3f7 ",
                "warning ": "#ffb822 ",
                "danger ": "#fd3995 "
            },
            "base ": {
                "label ": ["#c5cbe3 ", "#a1a8c3 ", "#3d4465 ", "#3e4466 "],
                "shape ": ["#f0f3ff ", "#d9dffa ", "#afb4d4 ", "#646c9a "]
            }
        }
    };
</script>
<script src="../../../assets/vendors/global/vendors.bundle.js " type="text/javascript "></script>
<script src="../../../assets/js/demo1/scripts.bundle.js " type="text/javascript "></script>
<script src="../../../assets/js/demo1/pages/login/login-1.js " type="text/javascript "></script>
<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.4.0/jquery.min.js"></script>
<script src="assets/jquery-ui.js"></script>