import * as tslib_1 from "tslib";
import { BehaviorSubject } from 'rxjs';
import { constants } from 'src/helpers/constants';
import { map } from 'rxjs/operators';
export class MenuAsideService {
    constructor(menuConfigService, http) {
        this.menuConfigService = menuConfigService;
        this.http = http;
        this.menuList$ = new BehaviorSubject([]);
        this.apiUrl = constants.url_constants.api;
        //this.loadMenu();
    }
    // async getMenu() {
    // 	//const menuItems: any[];
    // 	await this.getMenuItems().then(data => {
    // 		// this.menuList$.next(data);
    // 		return data;
    // 	});
    // 	// return menuItems;
    // }
    getMenu() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(this.apiUrl + '/api/LayoutApi/GetMenu')
                .pipe(map((res) => { return res; })).toPromise();
        });
    }
    loadMenu() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const menuItems = yield this.menuConfigService.loadMenuByProfileRole("Super Admin").then(data => {
                return data;
            });
            this.menuList$.next(menuItems);
            return menuItems;
        });
    }
    // async getPermissions(username: string, insurerCode: string): Promise<Observable<Permission[]>> {
    // 	const permissions = await this.apiPermissionService.permissionsapi(username, insurerCode).then(data => {
    // 		return data;
    // 	});
    // 	this.permissionsService.loadPermissions(await permissions)
    // 	console.log(permissions)
    // 	return of(permissions);
    // }
    getStudentMenu() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(this.apiUrl + '/api/LayoutApi/GetStudentMenu')
                .pipe(map((res) => { return res; })).toPromise();
        });
    }
}
