<!-- BEGIN: Left Aside -->
<div ejOffcanvas [options]="menuCanvasOptions"
    class="kt-aside  kt-aside--fixed  kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop" id="kt_aside">
    <ej-brand></ej-brand>
    <!-- BEGIN: Aside Menu -->
    <div class="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid" id="kt_aside_menu_wrapper">
        <div #asideMenu ejMenu [options]="menuOptions" [perfectScrollbar]="{wheelPropagation: false}"
            [ngStyle]="{'max-height': '90vh', 'position': 'relative'}" id="kt_aside_menu" class="kt-aside-menu"
            [attr.data-ktmenu-vertical]="1" (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)"
            [ngClass]="htmlClassService.getClasses('aside_menu', true)">
            <ul class="kt-menu__nav" [ngClass]="htmlClassService.getClasses('aside_menu_nav', true)">
                <ng-container [ngTemplateOutlet]="menuListTemplate"></ng-container>
            </ul>
        </div>
    </div>
    <!-- END: Aside Menu -->
</div>
<!-- END: Left Aside -->

<ng-template #menuListTemplate>
    <ng-container *ngFor="let child of menuList">
        <ng-container *ngIf="child.name" [ngTemplateOutlet]="menuItemTemplate"
            [ngTemplateOutletContext]="{ item: child }"></ng-container>
    </ng-container>
</ng-template>

<ng-template #menuItemTemplate let-item="item" let-parentItem="parentItem">
    <ng-container *ngIf="!item.permission" [ngTemplateOutlet]="menuItemInnerTemplate"
        [ngTemplateOutletContext]="{ item: item, parentItem: parentItem  }"></ng-container>
    <ng-template>
        <ng-container [ngTemplateOutlet]="menuItemInnerTemplate"
            [ngTemplateOutletContext]="{ item: item, parentItem: parentItem  }"></ng-container>
    </ng-template>
</ng-template>

<ng-template #menuItemInnerTemplate let-item="item" let-parentItem="parentItem">
    <!-- Menu items not part of school tour -->
    <li *ngIf="item.url != 'courses' && item.url != 'studentlist' && item.url != 'feepayments' && item.url != 'enquirylist' && item.url != 'dashboard' && item.url != 'users' && item.url != 'trainers' && item.url != 'settings'"
        [attr.aria-haspopup]="true" [attr.data-ktmenu-submenu-toggle]="getItemAttrSubmenuToggle(item)"
        [attr.data-ktmenu-submenu-mode]="item.mode"
        [attr.data-ktmenu-dropdown-toggle-class]="item['dropdown-toggle-class']" [ngClass]="getItemCssClasses(item)"
        [ngbTooltip]="item.tooltip" data-placement="right">
        <!-- if menu item hasn't submenu -->
        <a *ngIf="!item.items || item.items?.length == 0" (click)="itemClickEvent(item.url)"
            [ngClass]="{'active': selectedURL == item.url}" class="kt-menu__link kt-menu__toggle">
            <ng-container [ngTemplateOutlet]="menuItemTextTemplate"
                [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"></ng-container>
        </a>

        <!-- if menu item has sumnenu child  -->
        <a *ngIf="item.items && item.items?.length > 0" class="kt-menu__link kt-menu__toggle">
            <ng-container [ngTemplateOutlet]="menuItemTextTemplate"
                [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"></ng-container>
        </a>

        <!-- if menu item has submenu child then recursively call new menu item component -->
        <div *ngIf="item.items && item.items?.length > 0" class="kt-menu__submenu">
            <span class="kt-menu__arrow"></span>
            <div *ngIf="item['custom-class'] === 'kt-menu__item--submenu-fullheight'" class="kt-menu__wrapper">
                <!-- wrap submenu to full height -->
                <ng-container [ngTemplateOutlet]="menuSubmenuTemplate"
                    [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"></ng-container>
            </div>
            <!-- normal submenu -->
            <ng-container *ngIf="item['custom-class'] !== 'kt-menu__item--submenu-fullheight'"
                [ngTemplateOutlet]="menuSubmenuTemplate"
                [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }">
            </ng-container>
        </div>
    </li>

    <!-- Menu items part of school tour -->
    <li *ngIf="item.url == 'courses'" [attr.aria-haspopup]="true"
        [attr.data-ktmenu-submenu-toggle]="getItemAttrSubmenuToggle(item)" [attr.data-ktmenu-submenu-mode]="item.mode"
        [attr.data-ktmenu-dropdown-toggle-class]="item['dropdown-toggle-class']" [ngClass]="getItemCssClasses(item)"
        [ngbTooltip]="item.tooltip" data-placement="right" joyrideStep="Courses" [stepContent]="courseContent"
        stepPosition="right" ngbTooltip="Manage Courses" [prevTemplate]="prevButton" [nextTemplate]="nextButton"
        [doneTemplate]="doneButton" [counterTemplate]="counter">
        <!-- if menu item hasn't submenu -->
        <a *ngIf="!item.items || item.items?.length == 0" (click)="itemClickEvent(item.url)"
            [ngClass]="{'active': selectedURL == item.url}" class="kt-menu__link kt-menu__toggle">
            <ng-container [ngTemplateOutlet]="menuItemTextTemplate"
                [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"></ng-container>
        </a>

        <!-- if menu item has sumnenu child  -->
        <a *ngIf="item.items && item.items?.length > 0" class="kt-menu__link kt-menu__toggle">
            <ng-container [ngTemplateOutlet]="menuItemTextTemplate"
                [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"></ng-container>
        </a>

        <!-- if menu item has submenu child then recursively call new menu item component -->
        <div *ngIf="item.items && item.items?.length > 0" class="kt-menu__submenu">
            <span class="kt-menu__arrow"></span>
            <div *ngIf="item['custom-class'] === 'kt-menu__item--submenu-fullheight'" class="kt-menu__wrapper">
                <!-- wrap submenu to full height -->
                <ng-container [ngTemplateOutlet]="menuSubmenuTemplate"
                    [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"></ng-container>
            </div>
            <!-- normal submenu -->
            <ng-container *ngIf="item['custom-class'] !== 'kt-menu__item--submenu-fullheight'"
                [ngTemplateOutlet]="menuSubmenuTemplate"
                [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }">
            </ng-container>
        </div>
    </li>

    <li *ngIf="item.url == 'studentlist'" [attr.aria-haspopup]="true"
        [attr.data-ktmenu-submenu-toggle]="getItemAttrSubmenuToggle(item)" [attr.data-ktmenu-submenu-mode]="item.mode"
        [attr.data-ktmenu-dropdown-toggle-class]="item['dropdown-toggle-class']" [ngClass]="getItemCssClasses(item)"
        [ngbTooltip]="item.tooltip" data-placement="right" joyrideStep="Students" [stepContent]="studentsContent"
        stepPosition="right" ngbTooltip="Manage Students">
        <!-- if menu item hasn't submenu -->
        <a *ngIf="!item.items || item.items?.length == 0" (click)="itemClickEvent(item.url)"
            [ngClass]="{'active': selectedURL == item.url}" class="kt-menu__link kt-menu__toggle">
            <ng-container [ngTemplateOutlet]="menuItemTextTemplate"
                [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"></ng-container>
        </a>

        <!-- if menu item has sumnenu child  -->
        <a *ngIf="item.items && item.items?.length > 0" class="kt-menu__link kt-menu__toggle">
            <ng-container [ngTemplateOutlet]="menuItemTextTemplate"
                [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"></ng-container>
        </a>

        <!-- if menu item has submenu child then recursively call new menu item component -->
        <div *ngIf="item.items && item.items?.length > 0" class="kt-menu__submenu">
            <span class="kt-menu__arrow"></span>
            <div *ngIf="item['custom-class'] === 'kt-menu__item--submenu-fullheight'" class="kt-menu__wrapper">
                <!-- wrap submenu to full height -->
                <ng-container [ngTemplateOutlet]="menuSubmenuTemplate"
                    [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"></ng-container>
            </div>
            <!-- normal submenu -->
            <ng-container *ngIf="item['custom-class'] !== 'kt-menu__item--submenu-fullheight'"
                [ngTemplateOutlet]="menuSubmenuTemplate"
                [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }">
            </ng-container>
        </div>
    </li>

    <li *ngIf="item.url == 'feepayments'" [attr.aria-haspopup]="true"
        [attr.data-ktmenu-submenu-toggle]="getItemAttrSubmenuToggle(item)" [attr.data-ktmenu-submenu-mode]="item.mode"
        [attr.data-ktmenu-dropdown-toggle-class]="item['dropdown-toggle-class']" [ngClass]="getItemCssClasses(item)"
        [ngbTooltip]="item.tooltip" data-placement="right" joyrideStep="FeesPayments"
        [stepContent]="feesPaymentsContent" stepPosition="right" ngbTooltip="Manage Fee Payments">
        <!-- if menu item hasn't submenu -->
        <a *ngIf="!item.items || item.items?.length == 0" (click)="itemClickEvent(item.url)"
            [ngClass]="{'active': selectedURL == item.url}" class="kt-menu__link kt-menu__toggle">
            <ng-container [ngTemplateOutlet]="menuItemTextTemplate"
                [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"></ng-container>
        </a>

        <!-- if menu item has sumnenu child  -->
        <a *ngIf="item.items && item.items?.length > 0" class="kt-menu__link kt-menu__toggle">
            <ng-container [ngTemplateOutlet]="menuItemTextTemplate"
                [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"></ng-container>
        </a>

        <!-- if menu item has submenu child then recursively call new menu item component -->
        <div *ngIf="item.items && item.items?.length > 0" class="kt-menu__submenu">
            <span class="kt-menu__arrow"></span>
            <div *ngIf="item['custom-class'] === 'kt-menu__item--submenu-fullheight'" class="kt-menu__wrapper">
                <!-- wrap submenu to full height -->
                <ng-container [ngTemplateOutlet]="menuSubmenuTemplate"
                    [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"></ng-container>
            </div>
            <!-- normal submenu -->
            <ng-container *ngIf="item['custom-class'] !== 'kt-menu__item--submenu-fullheight'"
                [ngTemplateOutlet]="menuSubmenuTemplate"
                [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }">
            </ng-container>
        </div>
    </li>

    <li *ngIf="item.url == 'enquirylist'" [attr.aria-haspopup]="true"
        [attr.data-ktmenu-submenu-toggle]="getItemAttrSubmenuToggle(item)" [attr.data-ktmenu-submenu-mode]="item.mode"
        [attr.data-ktmenu-dropdown-toggle-class]="item['dropdown-toggle-class']" [ngClass]="getItemCssClasses(item)"
        [ngbTooltip]="item.tooltip" data-placement="right" joyrideStep="Enquiries" [stepContent]="enquiriesContent"
        stepPosition="right" ngbTooltip="Manage Enquiries">
        <!-- if menu item hasn't submenu -->
        <a *ngIf="!item.items || item.items?.length == 0" (click)="itemClickEvent(item.url)"
            [ngClass]="{'active': selectedURL == item.url}" class="kt-menu__link kt-menu__toggle">
            <ng-container [ngTemplateOutlet]="menuItemTextTemplate"
                [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"></ng-container>
        </a>

        <!-- if menu item has sumnenu child  -->
        <a *ngIf="item.items && item.items?.length > 0" class="kt-menu__link kt-menu__toggle">
            <ng-container [ngTemplateOutlet]="menuItemTextTemplate"
                [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"></ng-container>
        </a>

        <!-- if menu item has submenu child then recursively call new menu item component -->
        <div *ngIf="item.items && item.items?.length > 0" class="kt-menu__submenu">
            <span class="kt-menu__arrow"></span>
            <div *ngIf="item['custom-class'] === 'kt-menu__item--submenu-fullheight'" class="kt-menu__wrapper">
                <!-- wrap submenu to full height -->
                <ng-container [ngTemplateOutlet]="menuSubmenuTemplate"
                    [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"></ng-container>
            </div>
            <!-- normal submenu -->
            <ng-container *ngIf="item['custom-class'] !== 'kt-menu__item--submenu-fullheight'"
                [ngTemplateOutlet]="menuSubmenuTemplate"
                [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }">
            </ng-container>
        </div>
    </li>

    <li *ngIf="item.url == 'dashboard'" [attr.aria-haspopup]="true"
        [attr.data-ktmenu-submenu-toggle]="getItemAttrSubmenuToggle(item)" [attr.data-ktmenu-submenu-mode]="item.mode"
        [attr.data-ktmenu-dropdown-toggle-class]="item['dropdown-toggle-class']" [ngClass]="getItemCssClasses(item)"
        [ngbTooltip]="item.tooltip" data-placement="right" joyrideStep="Dashboard" [stepContent]="dashboardContent"
        stepPosition="right" ngbTooltip="Statistics & Dashboard">
        <!-- if menu item hasn't submenu -->
        <a *ngIf="!item.items || item.items?.length == 0" (click)="itemClickEvent(item.url)"
            [ngClass]="{'active': selectedURL == item.url}" class="kt-menu__link kt-menu__toggle">
            <ng-container [ngTemplateOutlet]="menuItemTextTemplate"
                [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"></ng-container>
        </a>

        <!-- if menu item has sumnenu child  -->
        <a *ngIf="item.items && item.items?.length > 0" class="kt-menu__link kt-menu__toggle">
            <ng-container [ngTemplateOutlet]="menuItemTextTemplate"
                [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"></ng-container>
        </a>

        <!-- if menu item has submenu child then recursively call new menu item component -->
        <div *ngIf="item.items && item.items?.length > 0" class="kt-menu__submenu">
            <span class="kt-menu__arrow"></span>
            <div *ngIf="item['custom-class'] === 'kt-menu__item--submenu-fullheight'" class="kt-menu__wrapper">
                <!-- wrap submenu to full height -->
                <ng-container [ngTemplateOutlet]="menuSubmenuTemplate"
                    [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"></ng-container>
            </div>
            <!-- normal submenu -->
            <ng-container *ngIf="item['custom-class'] !== 'kt-menu__item--submenu-fullheight'"
                [ngTemplateOutlet]="menuSubmenuTemplate"
                [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }">
            </ng-container>
        </div>
    </li>

    <li *ngIf="item.url == 'users'" [attr.aria-haspopup]="true"
        [attr.data-ktmenu-submenu-toggle]="getItemAttrSubmenuToggle(item)" [attr.data-ktmenu-submenu-mode]="item.mode"
        [attr.data-ktmenu-dropdown-toggle-class]="item['dropdown-toggle-class']" [ngClass]="getItemCssClasses(item)"
        [ngbTooltip]="item.tooltip" data-placement="right" joyrideStep="Users" [stepContent]="usersContent"
        stepPosition="right" ngbTooltip="Manage Users">
        <!-- if menu item hasn't submenu -->
        <a *ngIf="!item.items || item.items?.length == 0" (click)="itemClickEvent(item.url)"
            [ngClass]="{'active': selectedURL == item.url}" class="kt-menu__link kt-menu__toggle">
            <ng-container [ngTemplateOutlet]="menuItemTextTemplate"
                [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"></ng-container>
        </a>

        <!-- if menu item has sumnenu child  -->
        <a *ngIf="item.items && item.items?.length > 0" class="kt-menu__link kt-menu__toggle">
            <ng-container [ngTemplateOutlet]="menuItemTextTemplate"
                [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"></ng-container>
        </a>

        <!-- if menu item has submenu child then recursively call new menu item component -->
        <div *ngIf="item.items && item.items?.length > 0" class="kt-menu__submenu">
            <span class="kt-menu__arrow"></span>
            <div *ngIf="item['custom-class'] === 'kt-menu__item--submenu-fullheight'" class="kt-menu__wrapper">
                <!-- wrap submenu to full height -->
                <ng-container [ngTemplateOutlet]="menuSubmenuTemplate"
                    [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"></ng-container>
            </div>
            <!-- normal submenu -->
            <ng-container *ngIf="item['custom-class'] !== 'kt-menu__item--submenu-fullheight'"
                [ngTemplateOutlet]="menuSubmenuTemplate"
                [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }">
            </ng-container>
        </div>
    </li>
    <li *ngIf="item.url == 'trainers'" [attr.aria-haspopup]="true"
        [attr.data-ktmenu-submenu-toggle]="getItemAttrSubmenuToggle(item)" [attr.data-ktmenu-submenu-mode]="item.mode"
        [attr.data-ktmenu-dropdown-toggle-class]="item['dropdown-toggle-class']" [ngClass]="getItemCssClasses(item)"
        [ngbTooltip]="item.tooltip" data-placement="right" joyrideStep="Trainers" [stepContent]="trainersContent"
        stepPosition="right" ngbTooltip="Manage Trainers">
        <!-- if menu item hasn't submenu -->
        <a *ngIf="!item.items || item.items?.length == 0" (click)="itemClickEvent(item.url)"
            [ngClass]="{'active': selectedURL == item.url}" class="kt-menu__link kt-menu__toggle">
            <ng-container [ngTemplateOutlet]="menuItemTextTemplate"
                [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"></ng-container>
        </a>

        <!-- if menu item has sumnenu child  -->
        <a *ngIf="item.items && item.items?.length > 0" class="kt-menu__link kt-menu__toggle">
            <ng-container [ngTemplateOutlet]="menuItemTextTemplate"
                [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"></ng-container>
        </a>

        <!-- if menu item has submenu child then recursively call new menu item component -->
        <div *ngIf="item.items && item.items?.length > 0" class="kt-menu__submenu">
            <span class="kt-menu__arrow"></span>
            <div *ngIf="item['custom-class'] === 'kt-menu__item--submenu-fullheight'" class="kt-menu__wrapper">
                <!-- wrap submenu to full height -->
                <ng-container [ngTemplateOutlet]="menuSubmenuTemplate"
                    [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"></ng-container>
            </div>
            <!-- normal submenu -->
            <ng-container *ngIf="item['custom-class'] !== 'kt-menu__item--submenu-fullheight'"
                [ngTemplateOutlet]="menuSubmenuTemplate"
                [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }">
            </ng-container>
        </div>
    </li>

    <li *ngIf="item.url == 'settings'" [attr.aria-haspopup]="true"
        [attr.data-ktmenu-submenu-toggle]="getItemAttrSubmenuToggle(item)" [attr.data-ktmenu-submenu-mode]="item.mode"
        [attr.data-ktmenu-dropdown-toggle-class]="item['dropdown-toggle-class']" [ngClass]="getItemCssClasses(item)"
        [ngbTooltip]="item.tooltip" data-placement="right" joyrideStep="Settings" [stepContent]="settingsContent"
        stepPosition="right" ngbTooltip="Manage Application">
        <!-- if menu item hasn't submenu -->
        <a *ngIf="!item.items || item.items?.length == 0" (click)="itemClickEvent(item.url)"
            [ngClass]="{'active': selectedURL == item.url}" class="kt-menu__link kt-menu__toggle">
            <ng-container [ngTemplateOutlet]="menuItemTextTemplate"
                [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"></ng-container>
        </a>

        <!-- if menu item has sumnenu child  -->
        <a *ngIf="item.items && item.items?.length > 0" class="kt-menu__link kt-menu__toggle">
            <ng-container [ngTemplateOutlet]="menuItemTextTemplate"
                [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"></ng-container>
        </a>

        <!-- if menu item has submenu child then recursively call new menu item component -->
        <div *ngIf="item.items && item.items?.length > 0" class="kt-menu__submenu">
            <span class="kt-menu__arrow"></span>
            <div *ngIf="item['custom-class'] === 'kt-menu__item--submenu-fullheight'" class="kt-menu__wrapper">
                <!-- wrap submenu to full height -->
                <ng-container [ngTemplateOutlet]="menuSubmenuTemplate"
                    [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"></ng-container>
            </div>
            <!-- normal submenu -->
            <ng-container *ngIf="item['custom-class'] !== 'kt-menu__item--submenu-fullheight'"
                [ngTemplateOutlet]="menuSubmenuTemplate"
                [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }">
            </ng-container>
        </div>
    </li>
</ng-template>

<ng-template #menuSubmenuTemplate let-item="item" let-parentItem="parentItem">
    <ul class="kt-menu__subnav">
        <ng-container *ngFor="let child of item.items">
            <!-- <ng-container *ngIf="child.section" [ngTemplateOutlet]="menuItemSectionTemplate" [ngTemplateOutletContext]="{ item: child, parentItem: item }"></ng-container> -->
            <!-- <ng-container *ngIf="child.separator" [ngTemplateOutlet]="menuItemSeparatorTemplate" [ngTemplateOutletContext]="{ item: child, parentItem: item }"></ng-container> -->
            <ng-container *ngIf="child.name" [ngTemplateOutlet]="menuItemTemplate"
                [ngTemplateOutletContext]="{ item: child, parentItem: item }"></ng-container>
        </ng-container>
    </ul>
</ng-template>

<ng-template #menuItemTextTemplate let-item="item" let-parentItem="parentItem">
    <i *ngIf="item.icon" class="kt-menu__link-icon" [ngClass]="item.icon"></i>
    <span class="kt-menu__link-text" [ngClass]="{'active': selectedURL == item.url}">{{item.name}}</span>
    <!-- menu item with badge -->
    <span *ngIf="item.badge && item.badge.value != 0" class="kt-menu__link-badge">
        <span class="kt-badge" [ngClass]="item.badge.type">{{item.badge.value}}</span>
    </span>
    <!-- if menu item has submenu child then put arrow icon -->
    <i *ngIf="item.items?.length > 0" class="kt-menu__ver-arrow la la-angle-right"></i>
</ng-template>

<ng-template #menuItemSeparatorTemplate let-item="item" let-parentItem="parentItem">
    <li class="kt-menu__separator"><span></span></li>
</ng-template>

<ng-template #menuItemSectionTemplate let-item="item" let-parentItem="parentItem">
    <li class="kt-menu__section">
        <h4 class="kt-menu__section-text">{{item.section}}</h4>
        <i class="kt-menu__section-icon flaticon-more-v2"></i>
    </li>
</ng-template>

<ng-template #courseContent>
    <p class="text-dark-75 font-weight-bolder font-size-h5 m-0">Let's start with creating your courses here.
        <br> Create your workshop or weekly training sessions and manage them intelligently.
    </p>
</ng-template>
<ng-template #studentsContent>
    <p class="text-dark-75 font-weight-bolder font-size-h5 m-0">Create student profile and manage students
        registrations.
    </p>
</ng-template>
<ng-template #feesPaymentsContent>
    <p class="text-dark-75 font-weight-bolder font-size-h5 m-0">Keep track of all the fees collected, fees dues and
        previous carry forward of any student.
    </p>
</ng-template>
<ng-template #enquiriesContent>
    <p class="text-dark-75 font-weight-bolder font-size-h5 m-0">Track your enquires and manage them
        intelligently with help of <b>eduJournal</b>.
    </p>
</ng-template>
<ng-template #usersContent>
    <p class="text-dark-75 font-weight-bolder font-size-h5 m-0">Manage your users, add new Users and view their
        permissions at one place using simple pages.
    </p>
</ng-template>
<ng-template #trainersContent>
    <p class="text-dark-75 font-weight-bolder font-size-h5 m-0">Track your trainers information, add new trainers and
        manage them using this simple feature.
    </p>
</ng-template>
<ng-template #settingsContent>
    <p class="text-dark-75 font-weight-bolder font-size-h5 m-0">Setup your Training Institute profile with logo. <br> Manage your applications settings from one place using
        this simple feature from <b>eduJournal</b>.
    </p>
</ng-template>
<ng-template #dashboardContent>
    <p class="text-dark-75 font-weight-bolder font-size-h5 m-0">View your top selling courses and statistics.
    </p>
</ng-template>
<ng-template #prevButton>
    <button class="btn btn-outline-secondary btn-sm" (click)="next()">Prev</button>
</ng-template>
<ng-template #nextButton>
    <button class="btn btn-outline-primary btn-sm" (click)="next()">Next</button>
</ng-template>
<ng-template #doneButton>
    <button class="btn btn-primary btn-sm">Finish</button>
</ng-template>
<ng-template #counter>
    <button class="btn btn-outline-primary btn-sm" (click)="triggerNext()">Skip</button>
</ng-template>

<ng-template #courseContent_mobile>
    <p class="text-dark-75 font-weight-bolder font-size-h5 m-0">Let's start with creating your courses.
        <br> Create your workshop or weekly training sessions and manage them intelligently.
    </p>
</ng-template>
<ng-template #studentsContent_mobile>
    <p class="text-dark-75 font-weight-bolder font-size-h5 m-0">Create student profile and manage students
        registrations.
    </p>
</ng-template>
<ng-template #feesPaymentsContent_mobile>
    <p class="text-dark-75 font-weight-bolder font-size-h5 m-0">Keep track of all the fees collected, fees dues and
        previous carry forward of any student.
    </p>
</ng-template>
<ng-template #usersContent_mobile>
    <p class="text-dark-75 font-weight-bolder font-size-h5 m-0">Manage your users, add new Users and view their
        permissions at one place using simple pages.
    </p>
</ng-template>
<joyrideStep joyrideStep="CoursesMobile" stepPosition="center" ngbTooltip="Manage Courses"
    [stepContent]="courseContent_mobile">
</joyrideStep>
<joyrideStep joyrideStep="StudentsMobile" stepPosition="center" ngbTooltip="Manage Sudents"
    [stepContent]="studentsContent_mobile">
</joyrideStep>
<joyrideStep joyrideStep="FeesPaymentsMobile" stepPosition="center" ngbTooltip="Manage Fee Payments"
    [stepContent]="feesPaymentsContent_mobile">
</joyrideStep>
<joyrideStep joyrideStep="UsersMobile" stepPosition="center" ngbTooltip="Manage Users" [stepContent]="usersContent_mobile">
</joyrideStep>

<div style="display: none;">
    <ng-container *ngTemplateOutlet="tourStartModal">

    </ng-container>
</div>
<ng-template #tourStartModal let-modal>
    <div class="modal fade show" id="kt_modal_6" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
        style="display: block; padding-right: 17px;" aria-modal="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">

                <div class="modal-body text-center">
                    <img class="kt-hidden-mobile" src="../assets/media/img/EJ_Social-media_Poster_466x300.jpg ">
                    <img class="kt-hidden-desktop" src="../assets/media/img/EJ_Social-media_Poster_315x200.jpg ">
                    <div class="kt-widget14 text-center">
                        <div class="kt-widget14__header">
                            <h3 class="kt-widget14__title">
                                Thank you for registering with us.
                            </h3>
                            <span class="kt-widget14__desc">
                                You can manage your Training Institute in simple steps.
                            </span>
                            <span class="kt-widget14__desc">Let us help you start using <b>eduJournal</b> with simple
                                tour</span>
                        </div>
                        <div class="kt-widget14__content justify-content-center">
                            <button class="btn btn-primary" (click)="startTourFromModal()">Take a tour</button>
                            <button class="btn btn-secondary ml-2" (click)="skipTour()">Skip</button>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>


    <!-- <div class="modal-header ">
        <h5 class="modal-title ">Welcome to eduJournal</h5>
        <button type="button " class="close " aria-label="Close " (click)="modal.dismiss( 'Cross click') ">
            <span aria-hidden="true ">×</span>
        </button>
    </div>
    <div class="modal-body ">
        <p>Thank you for registering with us.</p>
        <p class="text-dark-75 font-weight-bolder font-size-h5 m-0 "> <b>eduJournal</b> is the easiest way for education institutes to manage its operations and students.
        </p><br>
        <p>Let us help you to start using <b>eduJournal</b> with a simple tour.</p>
    </div>
    <div class="modal-footer ">
        <button type="button " class="btn btn-primary " (click)="startTourFromModal() ">
            Start
        </button>
    </div> -->
</ng-template>