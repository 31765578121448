import * as tslib_1 from "tslib";
import { OnInit, ChangeDetectorRef } from '@angular/core';
import { StudentRegistrationForm } from '../student-registration-form.model';
export class AddStudentRegistrationFormComponent {
    constructor(router, changeDetectorRef, studentRegistrationformService, sharedService, globalService) {
        this.router = router;
        this.changeDetectorRef = changeDetectorRef;
        this.studentRegistrationformService = studentRegistrationformService;
        this.sharedService = sharedService;
        this.globalService = globalService;
        this.studentRegistrationForm = new StudentRegistrationForm();
        this.iseditmode = false;
        this.error = {
            formname: false,
            description: false
        };
    }
    ngOnInit() {
        let bredcums = [];
        let bredcum = { 'label': 'Add Registration Form', 'link': '' };
        bredcums.push(bredcum);
        bredcum = { 'label': 'Registration Forms', 'link': '' };
        bredcums.push(bredcum);
        let subHeader = { 'title': 'Settings', 'bredcums': bredcums };
        this.sharedService.sendBredcumEvent(subHeader);
    }
    saveandcontinue() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.studentRegistrationformService.saveRegistrationForm(this.studentRegistrationForm).then((response) => {
                //this.registrationforms = response; 
            });
            this.changeDetectorRef.detectChanges();
        });
    }
}
