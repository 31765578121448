
export class Trainer {
    constructor() {
        this.address = new Address();
    }
    id: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    profilePictureRelativePath: string;
    profilePictureName: string;
    gender: string;
    dateOfBirth: string;
    address: Address;
    requiredSampleData: boolean;
}

export class Address {
    constructor() {
        this.id = "00000000-0000-0000-0000-000000000000";
    }
    id: string;
    addressline1: string;
    addressline2: string;
    city: string;
    state: string;
    country: string;
    postalcode: string;
}

export class FileTransferDTO {
    fileName: string;
    fileDataBase64: any;
    contentType: string;
}

export class TrainerOtherDetails {
    constructor() {
        this.languages = [];
    }
    public aboutTrainer: string;
    public trainerCV: string;
    public facebookURL: string;
    public twitterURL: string;
    public linkedInURL: string;
    public otherSocialMediaURL: string;
    public languages: any;
    public qualification: string;
    public qualificationDescription: string;
    public qualificationDocuments: any;
    public accreditations: any;
    public photoIdDocuments: any;
    public addressIdDocuments: any;
    public profilePhotoDocuments: any;
}