import { Component, OnInit, ChangeDetectorRef, ViewRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { TenantHomeService } from '../tenant-home.service';
import { ActivatedRoute, Router } from '@angular/router';
//import { ToasterService } from 'src/ej-shared/services/toastr.service';
import Swal from 'sweetalert2'
import { NgForm } from '@angular/forms';
import { SharedService } from 'src/ej-shared/services/shared.service';
import { RecaptchaComponent } from 'ng-recaptcha';
import { Title } from '@angular/platform-browser';
import { GoogleCaptcha } from 'src/environments/environment';

@Component({
  selector: 'app-course-enquiry',
  templateUrl: './course-enquiry.component.html',
  styles: ['::ng-deep{p {text-overflow: ellipsis;overflow: hidden;}}'],
  styleUrls: ['./course-enquiry.component.css',
    '../../../assets/css/wp/animate.css',
    '../../../assets/vendors/general/swiper/css/swiper.min.css',
    '../../../assets/vendors/general/bootstrap/dist/css/bootstrap.min.css',
    '../../../assets/css/wp/layout.min.css'
  ]
})
export class CourseEnquiryComponent implements OnInit {

  ejLogoSrc: string = "assets/media/logos/ej_logo.png";
  tenantLogoSrc: string = "";
  courseId: any;
  courseEnquiryModel: any = {};
  hasDailyPrice: boolean = false;
  hasWeeklyPrice: boolean = false;
  enquiryModel: any = {};
  saveEnquiryBtnDisabled: boolean = false;
  errorRecaptchaRender: boolean = false;
  gRecaptchaResponse: string = "";
  phoneNumberError = false;
  phoneCountryCodeError = false;
  phoneNumberLengthError = false;
  loading = false;
  @ViewChild('recaptchaRef', { static: false }) recaptchaRef: RecaptchaComponent;
  captchaSiteKey = GoogleCaptcha.site_key;
  
  constructor(
    private readonly tenantHomeService: TenantHomeService,
    private cdRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title
    //private readonly toasterService: ToasterService
  ) { }

  async ngOnInit() {
    this.titleService.setTitle('eduJournal | Course Enquiry');
    window.scrollTo(0, 0);
    
    this.courseId = this.route.snapshot.paramMap.get('courseId');
    await this.getCourseEnquiryModel();

    this.validatePhoneNumber();
  }

  async getCourseEnquiryModel() {
    await this.tenantHomeService.getCourseEnquiryModel(this.courseId).then(data => {
      this.courseEnquiryModel = data;
      this.setTenantImgSrc();
      this.checkPriceType();
      this.detectChanges();
    });
  }

  setTenantImgSrc() {
    if (this.courseEnquiryModel && this.courseEnquiryModel.tenantLogo
      && this.courseEnquiryModel.tenantLogo.logoRelativePath != null && this.courseEnquiryModel.tenantLogo.logoRelativePath != ""
      && this.courseEnquiryModel.tenantLogo.logoName != null && this.courseEnquiryModel.tenantLogo.logoName != "") {
      this.tenantLogoSrc = this.courseEnquiryModel.tenantLogo.logoRelativePath + '\\60x60\\' + this.courseEnquiryModel.tenantLogo.logoName;
    }
    else {
      this.tenantLogoSrc = this.ejLogoSrc;
    }
    this.detectChanges();
  }

  checkPriceType() {
    for (let i = 0; i < this.courseEnquiryModel.trainingProgramViewModel.dailySchedules.length; i++) {
      if (this.courseEnquiryModel.trainingProgramViewModel.dailySchedules[i] != undefined && this.courseEnquiryModel.trainingProgramViewModel.dailySchedules[i].dailyscheduleprice.price > 0) {
        this.hasDailyPrice = true;
        break;
      }
    }
    for (let i = 0; i < this.courseEnquiryModel.trainingProgramViewModel.weeklySchedules.length; i++) {
      if (this.courseEnquiryModel.trainingProgramViewModel.weeklySchedules[i] != undefined && this.courseEnquiryModel.trainingProgramViewModel.weeklySchedules[i].weeklyscheduleprice.price > 0) {
        this.hasWeeklyPrice = true;
        break;
      }
    }
    this.detectChanges();
  }
  tenantHomeNav() {
    this.router.navigate(['/']);
  }

  async saveEnquiryEvent(form: NgForm) {
    if (this.validateGRrecaptcha() && !this.phoneNumberLengthError) {
      this.saveEnquiryBtnDisabled = true;
      await this.saveEnquiry(form);
    }
  }

  async saveEnquiry(form: NgForm) {
    this.loading = true;
    this.detectChanges();
    this.enquiryModel.gRecaptchaResponse = this.gRecaptchaResponse;
    this.prependCourse();
    await this.tenantHomeService.saveEnquiry(this.enquiryModel).then(response => {
      this.loading = false;
      if (response.status) {
        //this.toasterService.success(response.information);
        Swal.fire(response.information);
        form.resetForm();
        this.saveEnquiryBtnDisabled = false;
        this.recaptchaRef.reset();
        this.enquiryModel.mobile = "";
      }
      else {
        //this.toasterService.error(response.information);
        Swal.fire(response.information);
        this.saveEnquiryBtnDisabled = false;
      }
    });
  };

  validateGRrecaptcha() {
    //recaptcha failed validation
    if (this.gRecaptchaResponse.length == 0) {
      this.errorRecaptchaRender = true;
      return false;
    }
    //recaptcha passed validation
    else {
      this.errorRecaptchaRender = false;
      return true;
    }
  }

  resolved(captchaResponse: string) {
    this.gRecaptchaResponse = `${captchaResponse}`;
  }

  prependCourse() {
    var courseName = document.getElementById('course-name').innerText;
    if (this.enquiryModel.message.indexOf(courseName) == -1) {
      this.enquiryModel.message = courseName + " - " + this.enquiryModel.message;
    }
  }

  receivePhoneNumber(number: string) {
    this.enquiryModel.mobile = number;
    this.validatePhoneNumber();
  }
  receivePhoneNumberError(hasError: boolean) {
    this.phoneNumberLengthError = hasError;
  }

  validatePhoneNumber() {
    if (this.enquiryModel.mobile == undefined || this.enquiryModel.mobile == null || this.enquiryModel.mobile == '') {
      this.phoneNumberError = true;
    }
    else if (this.enquiryModel.mobile.indexOf('-') == -1 || this.enquiryModel.mobile.split('-')[0] == undefined || this.enquiryModel.mobile.split('-')[0] == null || this.enquiryModel.mobile.split('-')[0] == "") {
      this.phoneCountryCodeError = true;
      this.phoneNumberError = false;
    }
    else if (this.enquiryModel.mobile.split('-')[1] == undefined || this.enquiryModel.mobile.split('-')[1] == null || this.enquiryModel.mobile.split('-')[1] == "") {
      this.phoneNumberError = true;
    }
    else {
      this.phoneNumberError = false;
      this.phoneCountryCodeError = false;
    }
  }

  processTimeInput(timeInput) {
    if (isNaN(Date.parse(timeInput))) {
      return this.getDateTime(new Date(), timeInput);
    }
    else {
      return timeInput;
    }
  }

  getDateTime(date, time) {
    var index = time.indexOf(":");
    var index2 = time.indexOf(" ");

    var hours = time.substring(0, index);
    var minutes = time.substring(index + 1, index2);
    var mer = time.substring(index2 + 1, time.length);

    if (hours == "12")
      hours = (mer == "AM") ? parseInt(hours) - 12 : hours;
    else
      hours = (mer == "PM") ? parseInt(hours) + 12 : hours;

    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds("00");

    return date;
  }

  detectChanges() {
    if (this.cdRef && !(this.cdRef as ViewRef).destroyed) {
      this.cdRef.detectChanges();
    }
  }

}
