import { environment } from '../environments/environment'
declare var angular: any;
export const constants = {
    url_constants: {
        api: environment.api_url
    },
    admin_url: environment.admin_url,
    host_url: environment.host,
    dateformat: "dd MMM yyyy",
    maxDate: "31 Dec 9999",
    ipStackAPIKey: "50b13d1940836f95cc9e1cef48d3505a"
};

export const roleConstants = {
    salesManagerRole : "Sales Manager",
    teleCallerSalesRole : "TeleCaller (Sales)"
};

angular.module('core').constant('url_constants', {
    api: environment.api_url
});
angular.module('core').constant('admin_url', {
    admin_url: environment.admin_url
});