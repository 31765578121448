<!DOCTYPE html>

<html lang="en">

<head>
    <meta charset="utf-8" />
    <title>eduJournal </title>
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta content="width=device-width, initial-scale=1" name="viewport" />
    <meta content="" name="description" />
    <meta content="" name="author" />

    <!-- GLOBAL MANDATORY STYLES -->
    <link href="https://fonts.googleapis.com/css?family=Hind:300,400,500,600,700" rel="stylesheet" type="text/css">

    <!-- Favicon -->
    <link rel="shortcut icon" href="favicon.ico" />
</head>
<!-- END HEAD -->

<body
    class="kt--skin- kt-header--fixed kt-header--fixed-mobile kt-aside-left--enabled kt-aside-left--skin-dark kt-aside-left--fixed kt-aside-left--offcanvas kt-footer--push kt-aside--offcanvas-default">

    <!-- begin:: Page -->
    <div class="kt-grid kt-grid--hor kt-grid--root kt-page">


        <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-login kt-login--signin kt-login--2 kt-login-2--skin-2"
            id="m_login" style="background-image: url(../../../assets/app/media/img//bg/bg-3.jpg);">
            <div class="kt-grid__item kt-grid__item--fluid	m-login__wrapper">
                <div class="kt-login__container">
                    <div class="kt-login__logo">
                        <a href="#">
                            <img src="assets/media/logos/ej_logo.png">
                        </a>
                    </div>
                    
                    <!-- Success -->
                    <div class="kt-login__signin" *ngIf="isSuccess">
                        <div class="kt-login__head">
                            <h3 class="kt-login__title">Thank you for signing up</h3>
                        </div>
                        <div class="kt-login__form-action text-center kt--margin-top-50">
                        </div>
                        <div class="kt-login__account">
                            <span class="kt-login__account-msg">
                                Please check your email for further instructions on how to complete your account setup.
                            </span>
                            <!--<a href="javascript:;" id="m_login_signup" class="kt-link kt-link--light kt-login__account-link">Sign Up</a>-->
                        </div>
                    </div>
                    <!-- Failure -->
                    <div class="kt-login__signin" *ngIf="!isSuccess">
                        <div class="kt-login__head">
                            <h3 class="kt-login__title">Thank you for your interest</h3>
                        </div>
                        <div class="kt-login__form-action text-center kt--margin-top-50">
                        </div>
                        <div class="kt-login__account">
                            <span class="kt-login__account-msg">
                                Something went wrong. Please try again later..
                            </span>
                        </div>
                    </div>

                </div>
            </div>
        </div>


    </div>
    <!-- end:: Page -->
    <!-- JAVASCRIPTS(Load javascripts at bottom, this will reduce page load time) -->
    <!-- CORE PLUGINS -->
    <script src="../../../../assets/vendors/general/jquery/dist/jquery.js" type="text/javascript"></script>
    <script src="../../../../assets/vendors/wp-js/jquery-migrate.min.js" type="text/javascript"></script>
    <script src="../../../../assets/vendors/general/bootstrap/dist/js/bootstrap.min.js" type="text/javascript"></script>

    <!-- PAGE LEVEL PLUGINS -->
    <script src="../../../../assets/vendors/wp-js/jquery.easing.js" type="text/javascript"></script>
    <script src="../../../../assets/vendors/wp-js/jquery.back-to-top.js" type="text/javascript"></script>
    <script src="../../../../assets/vendors/wp-js/jquery.smooth-scroll.js" type="text/javascript"></script>
    <script src="../../../../assets/vendors/wp-js/jquery.wow.min.js" type="text/javascript"></script>
    <script src="../../../../assets/vendors/wp-js/jquery.parallax.min.js" type="text/javascript"></script>
    <script src="../../../../assets/vendors/wp-js/jquery.appear.js" type="text/javascript"></script>
    <script src="../../../../assets/vendors/general/swiper/js/swiper.jquery.min.js" type="text/javascript"></script>

    <!-- PAGE LEVEL SCRIPTS -->
    <script src="../../../../assets/vendors/wp-js/layout.min.js" type="text/javascript"></script>
    <script src="../../../../assets/vendors/wp-js/components/progress-bar.min.js" type="text/javascript"></script>
    <script src="../../../../assets/vendors/general/swiper/js/swiper.min.js" type="text/javascript"></script>
    <script src="../../../../assets/vendors/wp-js/components/wow.min.js" type="text/javascript"></script>
    <!-- end::Body -->
</body>

</html>