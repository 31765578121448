<!-- begin:: Page -->
<block-ui></block-ui>
<ng-container>
    <ej-splash-screen *ngIf="showSplashScreen"></ej-splash-screen>
    <ej-header-mobile *ngIf="(selfLayout !== 'home' || mobHeaderDisplay) && (!isTenantExpired)"></ej-header-mobile>
    <div class="kt-grid kt-grid--hor kt-grid--root">
        <!-- begin::Body -->
        <div
            [ngClass]="{ 'kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v1' : selfLayout =='home' || (isTenantExpired), 'kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page' : selfLayout !=='home' && (!isTenantExpired) }">
            <!-- begin:: Aside Left -->
            <ng-container *ngIf="(asideDisplay && selfLayout !== 'home') && (!isTenantExpired) && !isMember">
                <button class="kt-aside-close " id="kt_aside_close_btn"><i class="la la-close "></i></button>
                <ej-aside-left></ej-aside-left>
            </ng-container>
            <!-- end:: Aside Left -->

            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper " id="kt_wrapper">
                <!-- begin:: Header -->
                <ej-header *ngIf="selfLayout !== 'home'"></ej-header>
                <!-- end:: Header -->

                <!-- begin:: Content -->
                <div [ngClass]="{ 'kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-padding-b-60' : selfLayout !=='home' && (!isTenantExpired) }"
                    id="kt_content">
                    <!-- begin:: Content Head -->
                    <ej-subheader *ngIf="selfLayout !== 'home' && (!isTenantExpired) && !isMember"></ej-subheader>
                    <!-- end:: Content Head -->

                    <!-- begin:: Content Body -->
                    <div [ngClass]="{ 'kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid' : selfLayout !=='home' && (!isTenantExpired) }"
                        id="divMainContent">
                        <div ng-view class="view-frame ">
                        </div>
                        <router-outlet></router-outlet>

                    </div>
                    <!-- end:: Content Body -->
                </div>
                <!-- end:: Content -->

                <ej-footer *ngIf="selfLayout !== 'home'"></ej-footer>
            </div>
        </div>
        <!-- end:: Body -->
    </div>
</ng-container>

<!-- <ng-template #homeLayout>
    <div class="kt-grid kt-grid--hor kt-grid--root ">
        <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper ">
            <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid ">
                <div class="view-container ">
                    <div ng-view class="view-frame home-layout ">
                    </div>
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>
</ng-template> -->
<!-- end:: Page -->