import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { MatButtonModule, MatProgressBarModule, MatTabsModule, MatTooltipModule } from '@angular/material';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { HeaderComponent } from './header/header.component';
import { AsideLeftComponent } from './aside/aside-left.component';
import { FooterComponent } from './footer/footer.component';
import { SubheaderComponent } from './subheader/subheader.component';
import { BrandComponent } from './header/brand/brand.component';
import { BaseComponent } from './base/base.component';
import { HtmlClassService } from './html-class.service';
import { HeaderMobileComponent } from './header/header-mobile/header-mobile.component';
import { HeaderMenuComponent } from './header-menu/header-menu.component';
import { TopbarQuickPanelComponent } from './topbar/topbar-quick-panel.component';
import { TopbarUserComponent } from './topbar/topbar-user.component';
import { LayoutConfigService } from './services/layout-config.service';
import { MenuConfigService } from './services/menu-config.service';
import { MenuAsideService } from './services/menu-aside.service';
import { ToggleDirective } from './directives/toggle.directive'
import { MenuDirective } from './directives/menu.directive'
import { OffcanvasDirective } from './directives/offcanvas.directive'
import { InlineSVGModule } from 'ng-inline-svg';
import { HttpClientModule } from '@angular/common/http';
import { TopbarSearchComponent } from './topbar/topbar-search.component'
import { QuickActionComponent } from './topbar/quick-action/quick-action.component'
import { SplashScreenComponent } from './splash-screen/splash-screen.component'
import { SplashScreenService } from './services/splash-screen.service'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressSpinnerModule } from '@angular/material';
import { BlockUIModule } from 'ng-block-ui';
import { BlockuiTemplateComponent } from './block-ui-template/block-ui-template.component';
import { HeaderNoSidebarComponent } from './header/header-no-sidebar/header-no-sidebar.component';
import { JoyrideModule } from 'ngx-joyride';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxPermissionsModule } from 'ngx-permissions';
import { SessionTimeoutComponent } from './session-timeout/session-timeout.component';
import { TenantVerificationPopupComponent } from './../tenant-portal/tenant-verification/tenant-verification-popup/tenant-verification-popup.component'


@NgModule({
	declarations: [
		BaseComponent,
		FooterComponent,

		HeaderMenuComponent,
		TopbarQuickPanelComponent,
		TopbarUserComponent,

		// headers
		HeaderComponent,
		BrandComponent,
		HeaderMobileComponent,
		HeaderNoSidebarComponent,

		// subheader
		SubheaderComponent,
		TopbarSearchComponent,
		QuickActionComponent,
		// aside left menu components
		AsideLeftComponent,

		ToggleDirective,
		OffcanvasDirective,
		MenuDirective,
		SplashScreenComponent,
		BlockuiTemplateComponent,
		SessionTimeoutComponent,
		TenantVerificationPopupComponent
	],
	exports: [
		BaseComponent,
		FooterComponent,

		// headers
		HeaderComponent,
		BrandComponent,
		HeaderMobileComponent,
		HeaderNoSidebarComponent,

		// subheader
		SubheaderComponent,

		// aside left menu components
		AsideLeftComponent,
		SplashScreenComponent,
		HeaderMenuComponent,
		TopbarQuickPanelComponent,

		SessionTimeoutComponent,
	],
	providers: [
		HtmlClassService,
		LayoutConfigService,
		MenuConfigService,
		MenuAsideService,
		SplashScreenService,
		DeviceDetectorService
	],
	imports: [
		CommonModule,
		RouterModule,
		PerfectScrollbarModule,
		NgbModule,
		FormsModule,
		MatProgressBarModule,
		MatTabsModule,
		MatButtonModule,
		MatTooltipModule,
		InlineSVGModule,
		HttpClientModule,
		BrowserAnimationsModule,
		MatProgressSpinnerModule,
		BlockUIModule.forRoot({
			template: BlockuiTemplateComponent
		}),
		JoyrideModule.forChild(),
        NgxPermissionsModule.forChild()
	],
	entryComponents: [BlockuiTemplateComponent]
})
export class LayoutModule {
}