import { Injectable } from '@angular/core';
import { Subject,BehaviorSubject } from 'rxjs';
import * as objectPath from 'object-path';

@Injectable()
export class MenuConfigService {

	menuList$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
	onConfigUpdated$: Subject<any>;
	private menuConfig: any;

	constructor() {
		// register on config changed event and set default config
		this.onConfigUpdated$ = new Subject();
	}

	/**
	 * Returns the menuConfig
	 */
	getMenus() {
		return this.menuConfig;
	}

	/**
	 * Load config
	 *
	 * @param config: any
	 */
	loadConfigs(config: any) {
		this.menuConfig = config;
		this.onConfigUpdated$.next(this.menuConfig);
	}

	 async loadMenuByProfileRole(roleName:string):Promise<any>{
		if(roleName=='Super Admin'){
			return await objectPath.get(this.getMenus(), 'menu_items.items');
		}
		return [];
	}
}
