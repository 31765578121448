import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CalendarIcsModel } from '../models/calendar-ics.model';

declare var angular: any;
declare var require: any;
const ics = require('ics');
var FileSaver = require('file-saver');

@Injectable({
    providedIn: 'root'
})

export class CalendarService {
    downloadIcs(title: string, description: string, start: any, end: any, location: string) {
        ics.createEvent(this.getCalendarIcsModel(title, description, start, end, location)
            , (error, value) => {
                if (error) { }
                var blob = new Blob([value], { type: "text/plain;charset=utf-8" });
                FileSaver.saveAs(blob, "event.ics");
            })
    }

    getCalendarIcsModel(title, description, start, end, location) {
        var model: CalendarIcsModel = {
            title: title,
            description: description,
            start: this.getCalendarIcsDate(start),
            end: this.getCalendarIcsDate(end),
            location: location
        };
        return model;
    }

    getCalendarIcsDate(dateString: string) {
        var d = new Date(dateString);
        return [d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes()];
    }
}

angular.module('core')
    .factory('calendarService', downgradeInjectable(CalendarService));