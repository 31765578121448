import * as tslib_1 from "tslib";
import { OnInit, ChangeDetectorRef, ElementRef, AfterViewInit } from '@angular/core';
import { Address, SchoolProfile } from './school-profile.model';
import { CropperComponent } from 'angular-cropperjs';
import { FileTransferDTO } from './school-profile.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import { interval } from 'rxjs';
import Swal from 'sweetalert2';
import { Document } from '../create-course/course.model';
export class GettingStartedComponent {
    constructor(router, changeDetectorRef, gettingStartedService, storageService, modalService, sharedService, tenantInitService, globalService, newCourseService, notificationService, authService) {
        this.router = router;
        this.changeDetectorRef = changeDetectorRef;
        this.gettingStartedService = gettingStartedService;
        this.storageService = storageService;
        this.modalService = modalService;
        this.sharedService = sharedService;
        this.tenantInitService = tenantInitService;
        this.globalService = globalService;
        this.newCourseService = newCourseService;
        this.notificationService = notificationService;
        this.authService = authService;
        this.config = [];
        this.imageUploaded = false;
        this.fileTransferDTO = new FileTransferDTO();
        this.viewHeight = window.innerHeight + 'px';
        this.currentstep = 1;
        this.schoolProfile = new SchoolProfile();
        this.error = {
            schoolname: false,
            email: false,
            url: false,
            phone: false,
            otpVerification: false,
            otpVerificationMsg: "",
            'facebookLink': false,
            'twitterLink': false,
            'linkedInLink': false,
            'otherSocialMediaLink': false,
        };
        this.loggedInCountry = '';
        this.loggedInCountryCode = '';
        this.addressSaved = false;
        this.currentYear = new Date().getFullYear();
        this.loading = true;
        this.OtpTokenModel = {};
        this.OtpResendCountdown = 30;
        this.phoneNumberTenantLengthError = false;
        this.phoneNumberLengthError = false;
        this.phoneNumberOtpLengthError = false;
        this.incorporationDocumentError = false;
        this.incorporationDocumentTypeError = false;
        this.displayIncorporationUploadGrid = false;
        this.allowIncorporationUpload = true;
        this.documentError = false;
        this.documentTypeError = false;
        // ngx cropper - START
        this.imageChangedEvent = '';
        this.croppedImage = '';
        this.canvasRotation = 0;
        this.rotation = 0;
        this.scale = 1;
        this.showCropper = true;
        this.containWithinAspectRatio = false;
        this.transform = {};
        // ngx cropper - END
        this.croppedSchoolLogo = '';
        this.logoImageError = false;
        this.logoFileTypeError = false;
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (environment.isProduction)
                this.checkTenantPrimarySetupStatus();
            this.countries = this.storageService.getCountryCodes();
            //this.countries.push({ "code": "Others", "countryCurrency": null, "currencyCode": "Others", "dial_code": "", "flag_font_code": "", "name": "Others", "selected": false });
            this.getAllTimeZones();
            this.getCurrencies();
            this.schoolProfile.requiredSampleData = false;
            // await this.gettingStartedService.getTenantLoggedInCountry().then(res => {
            //   let country = res.country_name;
            //   this.loggedInCountry = country;
            //   this.schoolProfile.address.country = country;
            //   if (country != undefined && country != null && country != "") {
            //     let countrycodes = this.storageService.getCountryCodes();
            //     let selectedCountry = countrycodes.filter(x => x.name.toLowerCase() == this.schoolProfile.address.country.toLowerCase());
            //     if (selectedCountry != undefined && selectedCountry.length > 0) {
            //       this.loggedInCountryCode = selectedCountry[0].dial_code + '-';
            //       this.schoolProfile.phone = selectedCountry[0].dial_code + '-';
            //       this.schoolProfile.tenantPrimaryContactPhone = selectedCountry[0].dial_code + '-';
            //       this.schoolProfile.currencyCode = selectedCountry[0].currencyCode;
            //     }
            //     this.getTimeZonesByCountryCode(selectedCountry[0].code);
            //   }
            // })
            yield this.getSchoolProfileViewModel();
            let selectedCountry = this.countries.filter(x => x.selected);
            if (selectedCountry != undefined && selectedCountry.length > 0) {
                this.loggedInCountry = selectedCountry[0].name;
                this.schoolProfile.address.country = selectedCountry[0].name;
                this.loggedInCountryCode = selectedCountry[0].dial_code + '-';
                this.schoolProfile.phone = selectedCountry[0].dial_code + '-';
                this.schoolProfile.currencyCode = selectedCountry[0].currencyCode;
                this.schoolProfile.tenantPrimaryContactPhone = selectedCountry[0].dial_code + '-';
                this.getTimeZonesByCountryCode(selectedCountry[0].code);
            }
            this.username = this.storageService.getLoggedinUserName();
        });
    }
    getSchoolProfileViewModel() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.gettingStartedService.getSchoolProfileViewModel().then(res => {
                this.schoolProfile = res.schoolProfile;
                this.schoolProfile.address = res.address ? res.address : new Address();
                this.checkAllowIncorporationUpload();
                this.checkAllowLogoUpload();
                this.fillUploadedLogo();
            });
        });
    }
    getAllTimeZones() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.gettingStartedService.getAllTimeZones().then(res => {
                this.countryTimeZones = res;
                this.changeDetectorRef.detectChanges();
            });
        });
    }
    getTimeZonesByCountryCode(countryCode) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.gettingStartedService.getTimeZonesByCountryCode(countryCode).then(res => {
                if (res != undefined && res.length > 0) {
                    this.schoolProfile.countryTimeZoneId = res[0].timeZoneId;
                    this.ngAfterViewInitTimeZoneDdl();
                }
                this.changeDetectorRef.detectChanges();
            });
        });
    }
    getCurrencies() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.globalService.getCurrencies().then(res => {
                this.currencies = res;
            });
        });
    }
    ngAfterViewInit() {
        var self = this;
        $(document).ready(function () {
            function formatState(state) {
                if (!state.id) {
                    return state.text;
                }
                if (state.element.text == "") {
                    return;
                }
                var flagcssclass = 'flag-icon-' + state.element.getAttribute("data-country");
                var $state = $('<span><span class="flag-icon flag-icon-squared ' + flagcssclass + '"></span>  &nbsp;' + state.text + '</span>');
                return $state;
            }
            ;
            function formatResult(state) {
                if (!state.id) {
                    return state.text;
                }
                var flagcssclass = 'flag-icon-' + state.element.getAttribute("data-country");
                var name = state.text;
                var $state = $('<span> <span class="flag-icon flag-icon-squared ' + flagcssclass + '"></span>' + '&nbsp;' + name + '</span>');
                return $state;
            }
            ;
            $("#ddlCountry").select2({
                placeholder: "Ex: Singapore",
                templateResult: formatState,
                templateSelection: formatResult,
                containerCssClass: "ej-select2-mt-5"
            });
            $('#ddlCountry').on("select2:select", function (e) {
                self.schoolProfile.address.country = $(this).val().split(': ')[1];
                self.changeDetectorRef.detectChanges();
                self.countryChangeEvent();
            });
            $("#ddlTimeZone").select2({
                closeOnSelect: true,
                placeholder: "Search for the time zone...",
                containerCssClass: "ej-select2"
            });
            $('#ddlTimeZone').on("select2:select", function (e) {
                self.schoolProfile.countryTimeZoneId = $(this).val().split(': ')[1];
                self.changeDetectorRef.detectChanges();
                //self.timezoneChangeEvent();
            });
            $("#ddlCurrency").select2({
                closeOnSelect: true,
                placeholder: "Search for the currency..",
                containerCssClass: "ej-select2"
            });
            $('#ddlCurrency').on("select2:select", function (e) {
                self.schoolProfile.currencyCode = $(this).val().split(': ')[1];
                self.changeDetectorRef.detectChanges();
                //self.currencyChangeEvent();
            });
            $("#ddlInternationalCurrency").select2({
                closeOnSelect: true,
                placeholder: "Select international currency..",
                containerCssClass: "ej-select2"
            });
            $('#ddlInternationalCurrency').on("select2:select", function (e) {
                self.schoolProfile.internationalCurrencyCode = $(this).val().split(': ')[1];
                self.changeDetectorRef.detectChanges();
                //self.currencyChangeEvent();
            });
        });
    }
    checkTenantPrimarySetupStatus() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.tenantInitService.checkPrimaryInitialSetupStatus().then((response) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (response.status) {
                    this.router.navigate(['/dashboard']);
                }
            }));
        });
    }
    saveandcontinue() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.currentstep == 1) {
                let result = this.validateStep1();
                if (result) {
                    this.currentstep = 2;
                    // await this.gettingStartedService.saveSchoolProfileDetails(this.schoolProfile).then(response => {
                    //   this.currentstep = 2;
                    //   this.schoolProfile.address.id = response.address.id;
                    //   this.changeDetectorRef.detectChanges();
                    //   return;
                    // });
                }
            }
            else if (this.currentstep == 2) {
                this.currentstep = 3;
                let countrycodes = this.storageService.getCountryCodes();
                if (this.schoolProfile.address != undefined && this.schoolProfile.address.country != null && this.schoolProfile.address.country != undefined) {
                    let selectedCountry = countrycodes.filter(x => x.name.toLowerCase() == this.schoolProfile.address.country.toLowerCase());
                    if (selectedCountry != undefined && selectedCountry.length > 0) {
                        this.schoolProfile.tenantPrimaryContactPhone = selectedCountry[0].dial_code + '-';
                    }
                }
                // this.storageService.setTenantLogo(this.schoolProfile.schoolLogoRelativePath);
                // this.sharedService.sendLogoChangeEvent();
                this.ngAfterViewInit();
                // await this.gettingStartedService.saveSchoolProfileDetails(this.schoolProfile).then(response => {
                //   // this.schoolProfile.address.country = this.loggedInCountry;
                //   this.currentstep = 3;
                //   this.ngAfterViewInit();
                //   this.changeDetectorRef.detectChanges();
                //   this.storageService.setTenantLogo(this.schoolProfile.schoolLogoRelativePath);
                //   this.sharedService.sendLogoChangeEvent();
                //   return;
                // });
            }
            else if (this.currentstep == 3) {
                if (this.schoolProfile.address.id == undefined || this.schoolProfile.address.id == null || this.schoolProfile.address.id == '') {
                    this.schoolProfile.address.id = '00000000-0000-0000-0000-000000000000';
                }
                let countrycodes = this.storageService.getCountryCodes();
                if (this.schoolProfile.address != undefined && this.schoolProfile.address.country != null && this.schoolProfile.address.country != undefined) {
                    let selectedCountry = countrycodes.filter(x => x.name.toLowerCase() == this.schoolProfile.address.country.toLowerCase());
                    if (selectedCountry != undefined && selectedCountry.length > 0) {
                        this.schoolProfile.phone = selectedCountry[0].dial_code + '-';
                    }
                }
                let result = this.validateStep3();
                if (result) {
                    this.currentstep = 4;
                }
                // await this.gettingStartedService.saveSchoolProfileDetails(this.schoolProfile).then(response => {
                //   this.addressSaved = true;
                //   let countrycodes = this.storageService.getCountryCodes();
                //   if (this.schoolProfile.address != undefined && this.schoolProfile.address.country != null && this.schoolProfile.address.country != undefined) {
                //     let selectedCountry = countrycodes.filter(x => x.name.toLowerCase() == this.schoolProfile.address.country.toLowerCase());
                //     if (selectedCountry != undefined && selectedCountry.length > 0) {
                //       this.schoolProfile.phone = selectedCountry[0].dial_code + '-';
                //     }
                //   }
                //   this.currentstep = 4;
                //   return;
                // });
            }
            else if (this.currentstep == 4) {
                if (this.schoolProfile.address.id == undefined || this.schoolProfile.address.id == null || this.schoolProfile.address.id == '') {
                    this.schoolProfile.address.id = '00000000-0000-0000-0000-000000000000';
                }
                this.schoolProfile.requiredSampleData = true;
                let result = this.validateStep4();
                if (result) {
                    this.currentstep = 5;
                }
                // await this.gettingStartedService.saveSchoolProfileDetails(this.schoolProfile).then(response => {
                //   this.schoolProfile.requiredSampleData = true;
                //   this.currentstep = 5;
                //   return;
                // });
            }
            else if (this.currentstep == 5) {
                yield this.finalStepSaveClick();
            }
        });
    }
    finalStepSaveClick() {
        var isPhoneEmpty = (this.schoolProfile.phone == null
            || this.schoolProfile.phone == undefined
            || this.schoolProfile.phone.split('-')[1] == undefined
            || this.schoolProfile.phone.split('-')[1] == null
            || this.schoolProfile.phone.split('-')[1] == "");
        if (isPhoneEmpty) {
            this.finalStepSave();
        }
        else {
            this.OtpTokenModel.PhoneNumber = this.schoolProfile.phone;
            this.RequestOtp();
        }
    }
    finalStepSave() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.gettingStartedService.saveSchoolProfileDetails(this.schoolProfile).then((response) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                this.globalService.showSchoolTour = true;
                yield this.globalService.setCountries();
                if (this.schoolProfile.currencyCode) {
                    let cList = this.currencies.filter(y => y.code != null && y.code != undefined).filter(x => x.code.toLowerCase() == this.schoolProfile.currencyCode.toLowerCase());
                    this.globalService.setCurrency((cList != undefined && cList.length > 0) ? cList[0] : null);
                }
                if (this.schoolProfile.internationalCurrencyCode) {
                    let icList = this.currencies.filter(y => y.code != null && y.code != undefined).filter(x => x.code.toLowerCase() == this.schoolProfile.internationalCurrencyCode.toLowerCase());
                    this.globalService.setInternationalCurrency((icList != undefined && icList.length > 0) ? icList[0] : null);
                }
                this.router.navigate(['/dashboard']);
                return;
            }));
        });
    }
    previousclickevent() {
        this.currentstep = this.currentstep - 1;
        // if (this.currentstep == 3 && !this.addressSaved) {
        //   this.schoolProfile.address.country = this.loggedInCountry;
        // }
    }
    validateStep1() {
        this.error.schoolname = false;
        //// School name is captured in Sign Up now
        // if (this.schoolProfile.schoolname == undefined || this.schoolProfile.schoolname == null || this.schoolProfile.schoolname == "" || this.schoolProfile.schoolname.trim() == '') {
        //   this.error.schoolname = true;
        // }
        return !this.error.schoolname;
    }
    validateStep3() {
        return !this.phoneNumberTenantLengthError;
    }
    validateStep4() {
        this.error.email = false;
        this.error.url = false;
        this.error.phone = false;
        if (this.schoolProfile.email != undefined && this.schoolProfile.email != null && this.schoolProfile.email != "" && this.schoolProfile.email.trim() != '') {
            this.error.email = !this.validateEmail(this.schoolProfile.email);
        }
        if (this.schoolProfile.URL != undefined && this.schoolProfile.URL != null && this.schoolProfile.URL != "" && this.schoolProfile.URL.trim() != '') {
            this.error.url = !this.validateurl(this.schoolProfile.URL);
        }
        return (!this.error.email && !this.error.url && !this.phoneNumberLengthError && !this.error.facebookLink && !this.error.twitterLink && !this.error.linkedInLink && !this.error.otherSocialMediaLink);
    }
    validateEmail(email) {
        var regexEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
        if (regexEmail.test(email)) {
            return true;
        }
        else {
            return false;
        }
    }
    validateurl(str) {
        let regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
        if (regexp.test(str)) {
            return true;
        }
        else {
            return false;
        }
    }
    uploadFile(event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            var maxSize = 1024000; // 1MB
            event = event.target == undefined ? event : event.target.files;
            this.imageUrl = yield this.getBase64(event[0]);
            this.fileTransferDTO.fileName = event[0].name;
            this.fileTransferDTO.contentType = event[0].type;
            let isValid = yield this.validateUploadedLogoFile(event);
            if (isValid) {
                //ngx cropper
                this.fileChangeEvent(event);
                //opne cropper modal
                this.openCropperModal(this.imagecroppermodal);
            }
            this.changeDetectorRef.detectChanges();
        });
    }
    validateUploadedLogoFile(event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            var isValid = false;
            var maxSize = 1024000; // 1MB
            var acceptedTypes = ["png", "jpg", "jpeg"];
            if (acceptedTypes.includes(event[0].name.toLowerCase().substring(event[0].name.lastIndexOf('.') + 1))) {
                isValid = true;
            }
            else {
                this.logoFileTypeError = true;
                isValid = false;
            }
            if (isValid) {
                if (event[0].size > maxSize) {
                    this.logoImageError = true;
                    isValid = false;
                }
                else {
                    isValid = true;
                }
            }
            return isValid;
        });
    }
    ;
    getBase64(file) {
        return new Promise(function (resolve) {
            var reader = new FileReader();
            reader.onloadend = function () {
                resolve(reader.result);
            };
            reader.readAsDataURL(file);
        });
    }
    cropImage() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.currentstep == 2) {
                //this.fileTransferDTO.fileDataBase64 = this.angularCropper.cropper.getCroppedCanvas().toDataURL('image/jpeg');
                this.fileTransferDTO.fileDataBase64 = this.croppedSchoolLogo;
                yield this.gettingStartedService.uploadSchoolLogo(this.fileTransferDTO).then(response => {
                    if (response.status != false) {
                        this.schoolProfile.schoolLogoRelativePath = response.tempFilePath;
                        this.schoolProfile.schoolLogoName = response.savedImageName;
                        this.checkAllowLogoUpload();
                        $('#uploadedImage').attr('src', this.fileTransferDTO.fileDataBase64);
                        this.modalService.dismissAll();
                    }
                });
            }
            else {
            }
        });
    }
    openCropperModal(content) {
        $('#uploadedImage').attr('src', this.imageUrl);
        this.modalService.open(content).result.then((result) => {
        }, (reason) => {
        });
    }
    removeImage() {
        this.schoolProfile.schoolLogoRelativePath = "";
        this.schoolProfile.schoolLogoName = "";
        //this.imageUploaded = false;
        this.checkAllowLogoUpload();
    }
    checkAllowLogoUpload() {
        this.imageUploaded = this.schoolProfile.schoolLogoName ? true : false;
        this.changeDetectorRef.detectChanges();
    }
    fillUploadedLogo() {
        if (this.imageUploaded) {
            var imageUrl = this.schoolProfile.schoolLogoRelativePath + '/OriginalImage/' + this.schoolProfile.schoolLogoName;
            $('#uploadedImage').attr('src', imageUrl);
            //(document.getElementById('uploadedImage') as HTMLImageElement).src = imageUrl;
        }
        this.changeDetectorRef.detectChanges();
    }
    receivePhoneNumber(number) {
        this.schoolProfile.phone = number;
    }
    receivePhoneNumberError(hasError) {
        this.phoneNumberLengthError = hasError;
    }
    receiveTenantPhoneNumber(number) {
        this.schoolProfile.tenantPrimaryContactPhone = number;
    }
    receiveTenantPhoneNumberError(hasError) {
        this.phoneNumberTenantLengthError = hasError;
    }
    receiveOtpPhoneNumberError(hasError) {
        this.phoneNumberOtpLengthError = hasError;
    }
    skip() {
        if (this.currentstep == 1) {
            this.schoolProfile.schoolname = "";
        }
        else if (this.currentstep == 2) {
            this.schoolProfile.schoolTitle = "";
        }
        else if (this.currentstep == 3) {
            this.schoolProfile.address.addressline1 = "";
            this.schoolProfile.address.addressline2 = "";
            this.schoolProfile.address.city = "";
            this.schoolProfile.address.country = this.loggedInCountry;
            this.schoolProfile.address.id = "00000000-0000-0000-0000-000000000000";
            this.schoolProfile.address.postalcode = undefined;
            this.schoolProfile.address.state = "";
            this.schoolProfile.tenantPrimaryContactPhone = this.loggedInCountryCode;
        }
        else if (this.currentstep == 4) {
            this.schoolProfile.URL = "";
            this.schoolProfile.email = "";
            this.schoolProfile.phone = this.loggedInCountryCode;
        }
        else if (this.currentstep == 5) {
        }
        this.currentstep = +this.currentstep + 1;
        if (this.currentstep == 5) {
            this.schoolProfile.requiredSampleData = true;
        }
        this.ngAfterViewInit();
    }
    onResize(event) {
        this.viewHeight = event.target.innerHeight + 'px';
    }
    countryChangeEvent() {
        let selectedCountry = this.countries.filter(x => x.name.toLowerCase() == this.schoolProfile.address.country.toLowerCase());
        selectedCountry = selectedCountry[0].code.toLowerCase() == 'others' ? this.countries.filter(x => x.code.toLowerCase() == 'sg') : selectedCountry;
        // set timezone
        this.getTimeZonesByCountryCode(selectedCountry[0].code);
        // set currency
        if (selectedCountry != undefined && selectedCountry.length > 0) {
            this.schoolProfile.currencyCode = selectedCountry[0].currencyCode;
            this.changeDetectorRef.detectChanges();
            this.ngAfterViewInitCurrencyDdl();
        }
    }
    timezoneChangeEvent() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.newCourseService.getCountryCodeByTimeZoneId(this.schoolProfile.countryTimeZoneId).then(res => {
                if (res != undefined && res != "") {
                    let selectedTimeZoneCountry = this.countries.filter(y => y.code != null && y.code != undefined).filter(x => x.code.toLowerCase() == res.toLowerCase());
                    if (selectedTimeZoneCountry != undefined && selectedTimeZoneCountry.length > 0) {
                        // set country
                        this.schoolProfile.address.country = selectedTimeZoneCountry[0].name;
                        this.changeDetectorRef.detectChanges();
                        this.ngAfterViewInitCountryDdl();
                        // set currency
                        this.schoolProfile.currencyCode = selectedTimeZoneCountry[0].currencyCode;
                        this.changeDetectorRef.detectChanges();
                        this.ngAfterViewInitCurrencyDdl();
                    }
                }
            });
        });
    }
    currencyChangeEvent() {
        let selectedCurrencyCountry = this.countries.filter(y => y.currencyCode != null).filter(x => x.currencyCode.toLowerCase() == this.schoolProfile.currencyCode.toLowerCase());
        // set timezone
        this.getTimeZonesByCountryCode(selectedCurrencyCountry[0].code);
        // set country
        this.schoolProfile.address.country = selectedCurrencyCountry[0].name;
        this.changeDetectorRef.detectChanges();
        this.ngAfterViewInitCountryDdl();
    }
    ngAfterViewInitCountryDdl() {
        var self = this;
        $(document).ready(function () {
            $("#ddlCountry").select2({
                placeholder: "Ex: Singapore",
                templateResult: formatState,
                templateSelection: formatResult,
                containerCssClass: "ej-select2-mt-5"
            });
            function formatState(state) {
                if (!state.id) {
                    return state.text;
                }
                if (state.element.text == "") {
                    return;
                }
                var flagcssclass = 'flag-icon-' + state.element.getAttribute("data-country");
                var $state = $('<span><span class="flag-icon flag-icon-squared ' + flagcssclass + '"></span>  &nbsp;' + state.text + '</span>');
                return $state;
            }
            ;
            function formatResult(state) {
                if (!state.id) {
                    return state.text;
                }
                var flagcssclass = 'flag-icon-' + state.element.getAttribute("data-country");
                var name = state.text;
                var $state = $('<span> <span class="flag-icon flag-icon-squared ' + flagcssclass + '"></span>' + '&nbsp;' + name + '</span>');
                return $state;
            }
            ;
        });
    }
    ngAfterViewInitTimeZoneDdl() {
        var self = this;
        $(document).ready(function () {
            $("#ddlTimeZone").select2({
                closeOnSelect: true,
                placeholder: "Search for the time zone...",
                containerCssClass: "ej-select2"
            });
        });
    }
    ngAfterViewInitCurrencyDdl() {
        var self = this;
        $(document).ready(function () {
            $("#ddlCurrency").select2({
                closeOnSelect: true,
                placeholder: "Search for the currency..",
                containerCssClass: "ej-select2"
            });
        });
    }
    RequestOtp() {
        this.loading = true;
        this.error.otpVerification = false;
        this.error.otpVerificationMsg = "";
        this.OtpResendCountdown = 61;
        this.OtpTokenModel.editPhone = false;
        this.OtpTokenModel.TokenCode = [];
        for (var i = 0; i < 6; i++) {
            var otpinput = {};
            otpinput.id = "txtOtp" + i;
            otpinput.name = "txtOtp" + i;
            otpinput.value = "";
            this.OtpTokenModel.TokenCode.push(otpinput);
        }
        this.notificationService.Generate2FACode(this.OtpTokenModel).then(response => {
            this.loading = false;
            if (response.featureStatus == false) {
                $('#kt_modal_otp').modal('hide');
                this.finalStepSave();
            }
            else {
                if (response.status) {
                    this.OtpTokenModel.SessionId = response.id;
                    this.ResedOTPCountdown();
                }
                else {
                    this.error.otpVerification = true;
                    this.error.otpVerificationMsg = response.information;
                }
                $('#kt_modal_otp').modal('show');
            }
            this.changeDetectorRef.detectChanges();
        });
    }
    ChangeOTPPhone() {
        this.OtpTokenModel.editPhone = true;
    }
    SendOtpNewPhoneback() {
        this.OtpTokenModel.editPhone = false;
    }
    SendOtpNewPhone() {
        if (this.OtpTokenModel.PhoneNumber.length < 11) {
            this.error.otpVerification = true;
            this.error.otpVerificationMsg = "Invalid phone number.";
            return;
        }
        if (this.phoneNumberOtpLengthError) {
            return;
        }
        this.OtpTokenModel.SessionId = '';
        this.RequestOtp();
    }
    onKeyUpOTP(Key) {
        if (isNaN(Key.target.value)) {
            Key.target.value = "";
        }
        if (Key.target.value != "" && Key.target.nextSibling != null) {
            Key.target.nextSibling.focus();
        }
    }
    skipOtp() {
        $('#kt_modal_otp').modal('hide');
        this.finalStepSave();
    }
    ResedOTPCountdown() {
        this.subscription = interval(1000)
            .subscribe(x => {
            if (this.OtpResendCountdown > 0) {
                this.OtpResendCountdown--;
            }
        });
    }
    VerifyOtp() {
        this.error.otpVerification = false;
        this.error.otpVerificationMsg = "";
        this.loading = true;
        this.OtpTokenModel.Token = "";
        this.OtpTokenModel.TokenCode.forEach(element => {
            this.OtpTokenModel.Token = this.OtpTokenModel.Token + element.value;
        });
        if (this.OtpTokenModel.Token.length != 6) {
            this.error.otpVerification = true;
            this.error.otpVerificationMsg = "Please enter code.";
            return;
        }
        this.OtpTokenModel.Token = "EJ-" + this.OtpTokenModel.Token;
        this.notificationService.Verify2FACode(this.OtpTokenModel).then(response => {
            this.loading = false;
            if (response.status) {
                $('#kt_modal_otp').modal('hide');
                this.finalStepSave();
            }
            else {
                this.error.otpVerification = true;
                this.error.otpVerificationMsg = response.information;
            }
            this.changeDetectorRef.detectChanges();
        });
    }
    signout() {
        this.authService.signout();
    }
    uploadIncorporationDocuments(event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.schoolProfile.incorporationCertificateDocuments == null || this.schoolProfile.incorporationCertificateDocuments == undefined) {
                this.schoolProfile.incorporationCertificateDocuments = new Array();
            }
            let file = new FileTransferDTO();
            event = event.target == undefined ? event : event.target.files;
            file.fileName = event[0].name;
            file.contentType = event[0].type;
            let isValid = yield this.validateUploadIncorporationDocuments(event);
            if (isValid) {
                this.documentError = false;
                file.fileDataBase64 = yield this.getBase64(event[0]);
                this.gettingStartedService.uploadSchoolIncorporationCertificateDocuments(file).then(res => {
                    if (res.status) {
                        let document = new Document();
                        document.name = res.savedImageName;
                        document.path = res.filePath;
                        document.type = file.contentType;
                        document.originalName = file.fileName;
                        document.createdTime = new Date();
                        document.modifiedTime = new Date();
                        document.relativePath = res.tempFilePath;
                        this.schoolProfile.incorporationCertificateDocuments.push(document);
                        this.changeDetectorRef.detectChanges();
                        this.checkAllowIncorporationUpload();
                    }
                });
            }
        });
    }
    validateUploadIncorporationDocuments(event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            var isValid = false;
            var maxSize = 2097152; // 2MB
            var acceptedTypes = ["png", "jpg", "jpeg", "pdf"];
            if (acceptedTypes.includes(event[0].name.toLowerCase().substring(event[0].name.lastIndexOf('.') + 1))) {
                isValid = true;
                this.incorporationDocumentTypeError = false;
            }
            else {
                this.incorporationDocumentTypeError = true;
                isValid = false;
            }
            if (isValid) {
                if (event[0].size > maxSize) {
                    this.incorporationDocumentError = true;
                    isValid = false;
                }
                else {
                    this.incorporationDocumentError = false;
                    isValid = true;
                }
            }
            return isValid;
        });
    }
    ;
    deleteIncorporationDocument(index) {
        Swal.fire({
            title: 'Deleting document',
            text: 'Please confirm to proceed',
            icon: 'warning',
            confirmButtonText: 'Delete',
            showCancelButton: true,
            allowOutsideClick: true,
            allowEscapeKey: true,
            customClass: {
                title: 'swal-title-display-block'
            }
        }).then((result) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (result.value) {
                this.schoolProfile.incorporationCertificateDocuments[index].isDeleted = true;
                this.checkAllowIncorporationUpload();
                this.changeDetectorRef.detectChanges();
            }
        }));
    }
    checkAllowIncorporationUpload() {
        var uploads = this.schoolProfile.incorporationCertificateDocuments ? this.schoolProfile.incorporationCertificateDocuments.filter(x => !x.isDeleted) : [];
        this.allowIncorporationUpload = uploads ? uploads.length == 0 : true;
        //this.allowIncorporationUpload = uploads ? uploads.length < 5 : true;
        this.displayIncorporationUploadGrid = uploads ? uploads.length > 0 : false;
        this.changeDetectorRef.detectChanges();
    }
    validateFacebookUrl(event) {
        this.error.facebookLink = false;
        var url = event.target.value;
        if (url) {
            this.changeDetectorRef.detectChanges();
            this.error.facebookLink = false;
            if (/^(https?:\/\/)?((w{3}\.)?)facebook.com\/.*/i.test(url)) {
                this.error.facebookLink = false;
            }
            else {
                this.error.facebookLink = true;
            }
            this.changeDetectorRef.detectChanges();
        }
    }
    validateTwitterUrl(event) {
        this.error.twitterLink = false;
        var url = event.target.value;
        if (url) {
            this.changeDetectorRef.detectChanges();
            this.error.twitterLink = false;
            if (/^(https?:\/\/)?((w{3}\.)?)twitter.com\/.*/i.test(url)) {
                this.error.twitterLink = false;
            }
            else {
                this.error.twitterLink = true;
            }
            this.changeDetectorRef.detectChanges();
        }
    }
    validateLinkedinUrl(event) {
        this.error.linkedInLink = false;
        var url = event.target.value;
        if (url) {
            this.changeDetectorRef.detectChanges();
            this.error.linkedInLink = false;
            if (/^(https?:)?\/\/(?:[\w]+\.)?linkedin\.com\/.*/i.test(url)) {
                this.error.linkedInLink = false;
            }
            else {
                this.error.linkedInLink = true;
            }
            this.changeDetectorRef.detectChanges();
        }
    }
    validateOtherSocialMediaUrl(event) {
        this.error.otherSocialMediaLink = false;
        var url = event.target.value;
        if (url) {
            this.changeDetectorRef.detectChanges();
            this.error.otherSocialMediaLink = false;
            if (this.validateurl(url)) {
                this.error.otherSocialMediaLink = false;
            }
            else {
                this.error.otherSocialMediaLink = true;
            }
            this.changeDetectorRef.detectChanges();
        }
    }
    //ngx cropper functions - START
    fileChangeEvent(event) {
        this.imageChangedEvent = event;
    }
    imageCropped(event) {
        this.croppedImage = event.base64;
        this.setCroppedImage(event.base64);
    }
    imageLoaded() {
        this.showCropper = true;
    }
    cropperReady(e) {
        //console.log('Cropper ready', sourceImageDimensions);
    }
    loadImageFailed() {
        //console.log('Load failed');
    }
    rotateLeft() {
        this.canvasRotation--;
        this.flipAfterRotate();
    }
    rotateRight() {
        this.canvasRotation++;
        this.flipAfterRotate();
    }
    flipAfterRotate() {
        const flippedH = this.transform.flipH;
        const flippedV = this.transform.flipV;
        this.transform = Object.assign({}, this.transform, { flipH: flippedV, flipV: flippedH });
    }
    flipHorizontal() {
        this.transform = Object.assign({}, this.transform, { flipH: !this.transform.flipH });
    }
    flipVertical() {
        this.transform = Object.assign({}, this.transform, { flipV: !this.transform.flipV });
    }
    resetImage() {
        this.scale = 1;
        this.rotation = 0;
        this.canvasRotation = 0;
        this.transform = {};
    }
    zoomOut() {
        this.scale -= .1;
        this.transform = Object.assign({}, this.transform, { scale: this.scale });
    }
    zoomIn() {
        this.scale += .1;
        this.transform = Object.assign({}, this.transform, { scale: this.scale });
    }
    toggleContainWithinAspectRatio() {
        this.containWithinAspectRatio = !this.containWithinAspectRatio;
    }
    updateRotation() {
        this.transform = Object.assign({}, this.transform, { rotate: this.rotation });
    }
    //ngx cropper functions - END
    setCroppedImage(imageUrlBase64) {
        if (this.currentstep == 2) {
            this.croppedSchoolLogo = imageUrlBase64;
        }
    }
}
