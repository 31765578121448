import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { Trainer } from './trainer.model'
import { TrainerGettingStartedService } from './trainer-getting-started.service'
import { Router } from '@angular/router';
import { StorageService } from '../../../ej-shared/services/storage.service'
import { CropperComponent } from 'angular-cropperjs';
import { FileTransferDTO } from './trainer.model';
import { SharedService } from '../../../ej-shared/services/shared.service'
import { TenantInitService } from '../../../ej-shared/services/tenant-init.service'
import { GlobalService } from '../../../ej-shared/services/global.service'
import { environment } from '../../../environments/environment'

declare var $: any;

@Component({
  selector: 'app-getting-started',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './trainer-getting-started.component.html'
})
export class TrainerGettingStartedComponent implements OnInit, AfterViewInit {
  @ViewChild('angularCropper', { static: false }) public angularCropper: CropperComponent;
  @ViewChild('imagecroppermodal', { static: false }) imagecroppermodal: ElementRef;
  config = [];
  resultResult: any;
  fileTransferDTO: FileTransferDTO = new FileTransferDTO();

  public currentstep: number = 1;
  public trainer: Trainer = new Trainer();
  public error = {
    firstName: false,
    lastName: false,
    email: false,
    phone: false
  }
  countries: Promise<any>;
  loggedInCountry: string = '';
  loggedInCountryCode: string = '';
  addressSaved: boolean = false;
  constructor(
    private readonly router: Router,
    private readonly trainerGettingStartedService: TrainerGettingStartedService,
    private readonly storageService: StorageService,
    private readonly tenantInitService: TenantInitService,
    private readonly globalService: GlobalService
  ) { }

  async ngOnInit() {
    if (environment.isProduction)
      this.checkTenantPrimarySetupStatus();
    this.countries = this.storageService.getCountryCodes();

    await this.trainerGettingStartedService.getTenantLoggedInCountry().then(res => {
      let country = res.country_name;
      this.loggedInCountry = country;
      this.trainer.address.country = country;
      if (country != undefined && country != null && country != "") {
        let countrycodes = this.storageService.getCountryCodes();
        let selectedCountry = countrycodes.filter(x => x.name.toLowerCase() == this.trainer.address.country.toLowerCase());
        if (selectedCountry != undefined && selectedCountry.length > 0) {
          this.loggedInCountryCode = selectedCountry[0].dial_code + '-';
          this.trainer.phoneNumber = selectedCountry[0].dial_code + '-';
        }
      }
    })
  }

  ngAfterViewInit() {
    $(document).ready(function () {
      function formatState(state) {
        if (!state.id) {
          return state.text;
        }
        if (state.element.text == "") {
          return;
        }
        var flagcssclass = 'flag-icon-' + state.element.getAttribute("data-country");
        var $state = $(
          '<span><span class="flag-icon flag-icon-squared ' + flagcssclass + '"></span>  &nbsp;' + state.text + '</span>'
        );
        return $state;
      };
      function formatResult(state) {
        if (!state.id) {
          return state.text;
        }
        var flagcssclass = 'flag-icon-' + state.element.getAttribute("data-country");
        var name = state.text;
        var $state = $(
          '<span> <span class="flag-icon flag-icon-squared ' + flagcssclass + '"></span>' + '&nbsp;' + name + '</span>'
        );
        return $state;
      };
      $("#ddlCountryCode").select2({
        templateResult: formatState,
        templateSelection: formatResult,
      });
    });
  }

  async checkTenantPrimarySetupStatus() {
    await this.tenantInitService.checkTrainerPrimaryInitialSetupStatus().then(async (response: any) => {
      if (response.status) {
        this.router.navigate(['/dashboard']);
      }
    })
  }

  async saveandcontinue() {
    if (this.currentstep == 1) {
      let resultName = this.validateFirstName();
      let resultEmail = this.validateStep2();
      if (resultName && resultEmail) 
      {
        this.trainer.requiredSampleData = true;
        this.currentstep = 2;
      }
      let countrycodes = this.storageService.getCountryCodes();
      if (this.trainer.address != undefined && this.trainer.address.country != null && this.trainer.address.country != undefined) {
        let selectedCountry = countrycodes.filter(x => x.name.toLowerCase() == this.trainer.address.country.toLowerCase());
        if (selectedCountry != undefined && selectedCountry.length > 0) {
          this.trainer.phoneNumber = selectedCountry[0].dial_code + '-';
        }
      }
    }
    else if (this.currentstep == 2) {
      await this.trainerGettingStartedService.saveTrainerProfileDetails(this.trainer).then(async () => {
        this.globalService.showSchoolTour = true;
        await this.globalService.setCountries();
        await this.globalService.setCurrency(null);
        await this.globalService.setInternationalCurrency(null);
        this.router.navigate(['/dashboard']);
        return;
      });
    }
  }

  previousclickevent() {
    this.currentstep = this.currentstep - 1;
  }

  validateFirstName(): boolean {
    this.error.firstName = false;
    if (this.trainer.firstName == undefined || this.trainer.firstName == null || this.trainer.firstName == "" || this.trainer.firstName.trim() == '') {
      this.error.firstName = true;
    }
      return !this.error.firstName;
  }


  validateStep2(): boolean {
    this.error.email = false;
    if (this.trainer.email != undefined && this.trainer.email != null && this.trainer.email != "" && this.trainer.email.trim() != '') {
      this.error.email = !this.validateEmail(this.trainer.email);;
    }
    else
    {
      this.error.email = true;
    }
    return !this.error.email;
  }

  validateEmail(email): boolean {
    var regexEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
    if (regexEmail.test(email)) {
      return true;
    } else {
      return false;
    }
  }

  receivePhoneNumber(number: string) {
    this.trainer.phoneNumber = number;
  }

  skip() {
    if (this.currentstep == 1) {
      this.trainer.firstName = "";
      this.trainer.lastName = "";
    }
    else if (this.currentstep == 2) {
      this.trainer.email = "";
      this.trainer.phoneNumber = this.loggedInCountryCode;
    }
    else if (this.currentstep == 5) {
    }

    this.currentstep = +this.currentstep + 1;
    if (this.currentstep == 5) {
      this.trainer.requiredSampleData = true;
    }
    this.ngAfterViewInit();
  }

}