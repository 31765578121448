import { TrainerOtherDetails, Trainer } from '../trainer-getting-started/trainer.model';

export class CourseViewModel {
    constructor() {
        this.address = new Address();
        this.sessionDiscount = new SessionDiscount();
        this.weeklySchedules = new Array<Weeklyschedule>();
        this.otherDetails = new TrainingProgramOtherDetails();
        this.isDraft = true;
        this.id = '00000000-0000-0000-0000-000000000000';
        this.documents = new Array<Document>();
        this.wPPostId = 0;
        this.isOnlineCourse = false;
        this.programSettings = new TrainingProgramSettings();
        this.weeklyProgramPrice = new WeeklyProgramPrice();
        this.dailyProgramPrice = new DailyProgramPrice();
        this.tenantVerification = new TenantContactVerification();
        this.trainerOtherDetails = new TrainerOtherDetails();
        this.singleTrainerDetails = new Trainer();
        this.currentCourseApproval = {};
        this.currentTenantCourseApproval = {};
        this.tenantId = '00000000-0000-0000-0000-000000000000';
        this.isCreatedByAdmin = false;
        this.isInternational = false;
        this.currentAdminCoursePrice = {};
        this.isBatchCourse = false;
        this.isTopicCourse = false;
        this.batches = new Array<BatchViewModel>()
    }
    public name: string;
    public address: Address;
    public courseImageRelativePath: string;
    public courseImageName: string;
    public sessionDiscount: SessionDiscount;
    public fromdate: string;
    public todate: string;
    public weeklySchedules: Array<Weeklyschedule>;
    public otherDetails: TrainingProgramOtherDetails;
    public isDraft: boolean;
    public id: string;
    public description: string;
    public documents: Array<Document>;
    public wPPostId: number;
    public wPPostUrl: string;
    public wPStatus: string;
    public countryTimeZoneId: string;
    public currencyCode: string;
    public currencySymbol: string;
    public isOnlineCourse: boolean;
    public dailySchedules: Array<Dailyschedule>;
    public dailyProgramPrice: DailyProgramPrice;
    public weeklyProgramPrice: WeeklyProgramPrice;
    public trainerType: any;
    public scheduletype: any;
    public manageCourseWeeklySessionViewModel: any;
    public manageCourseDailyScheduleViewModel: any;
    public programSettings: TrainingProgramSettings;
    public tenantVerification: TenantContactVerification;
    public ageGroupId: string;
    public ageGroup: any;
    public trainerOtherDetails: TrainerOtherDetails;
    public courseType: string;
    public command: string;
    public singleTrainerDetails: Trainer;
    public currentCourseApproval: any;
    public currentTenantCourseApproval: any;
    public tenantId: string;
    public isCreatedByAdmin: boolean;
    public activityCode: string;
    public pricingType: any;
    public internationalCurrencyCode: string;
    public internationalCurrencySymbol: string;
    public isInternational: boolean;
    public currentAdminCoursePrice: any;
    public promoClassType: any;
    public isBatchCourse: boolean;
    public isTopicCourse: boolean;
    public batches: Array<BatchViewModel>;
    public onlineCourseType: any;
    public topics:Array<Topic>;
}
export class Topic {
    
    public id:string;
    public name:string;
    public isDeleted:boolean;
    public createdTime:Date;
}
export class Address {
    venuename: string;
    city: string;
    country: string;
    postalcode: string;
}

export class SessionDiscount {
    constructor() {

    }
    discountpercent: string;
    numberofsessions: string;
}

export class TrainingProgramOtherDetails {
    constructor() {
        this.categoryTags = [];
        this.isGroupClass = false;
        this.isIndivisualClass = true;
        this.isOfflineCourse = true;
        this.allowFreeClass = false;
        this.applyDiscount = false;
        this.languages = [];
        this.isPreviousTrainingProgramPrerequisite = false;
        this.previousTrainingProgramId = '00000000-0000-0000-0000-000000000000';
        this.isPaymentSummaryPageRequired = false;
    }
    public category: string;
    public categoryTags: Array<string>;
    public isGroupClass: boolean;
    public groupSize: number;
    public isIndivisualClass: boolean;
    public isOfflineCourse: boolean;
    public aboutTrainer: string;
    public trainerCV: string;
    public facebookURL: string;
    public twitterURL: string;
    public linkedInURL: string;
    public otherSocialMediaURL: string;
    public perSessionFee: number;
    public allowFreeClass: boolean;
    public applyDiscount: boolean;
    public trainerPhone: string;
    public trainerProfilePic: string;
    public trainerProfileRelativePath: string;
    public languages: any;
    public meetingUserId: string;
    public meetingUserName: string;
    public isPreviousTrainingProgramPrerequisite: boolean;
    public previousTrainingProgramId: string;
    public isPaymentSummaryPageRequired: boolean;
}

export class Weeklyschedule {
    id: string;
    tempSessions: any = [];
    displayHeader: boolean = false;
    headerrowspan: number;
    constructor() {
        this.weeklySessionViewModels = new Array<WeeklySession>();
        this.tempSessions = new Array<WeeklySession>();
        this.isDeleted = false;
        this.weeklyscheduleprice = new WeeklySchedulePrice();
        this.trainers = new Array<Trainer>();
    }
    public code: string;
    public weekday: string;
    public fromtime: string;
    public totime: string;
    public description: string;
    public weeklySessionViewModels: Array<WeeklySession>;
    public isDeleted: boolean;
    public weeklyscheduleprice: WeeklySchedulePrice;
    public trainers: Array<Trainer>;
}
export class WeeklySchedulePrice {
    public pricepermonth: number;
    public price: number;
    public internationalPrice: number;
    public maxnoofseats: number;
    public noOfEnrolments: number;
}
export class WeeklySession {
    isRescheduled: any;
    constructor() {
        this.address = new Address();
    }
    id: string;
    public weekday: string;
    public sessiondate: Date;
    public fromtime: string;
    public totime: string;
    public active: boolean;
    public address: Address;
    public parentWeeklySession: WeeklySession;
    public rescheduleRemarks: string;
    public cancelRemarks: string;
    public isSessionLocationChanged: boolean;
    public isOnlineSession: boolean;
    public meetingUserId: string;
    public meetingUserName: string;
}
export class AdditionalWeeklySession {
    isRescheduled: any;
    id: string;
    parentWeeklySessionId: string;
    parentAdditionalWeeklySessionId: string;
    public weekday: string;
    public sessiondate: Date;
    public fromtime: string;
    public totime: string;
    public active: boolean;
    public rescheduleRemarks: string;
    public cancelRemarks: string;
}

export class Dailyschedule {
    id: string;
    constructor() {
        this.address = new Address();
        this.trainers = new Array<Trainer>();
        this.dailyscheduleprice = new DailySchedulePrice();
    }
    public address: Address;
    public code: string;
    public title: string;
    public fromtime: string;
    public totime: string;
    public sessiondate: Date;
    public strSessionDate: string;
    public description: string;
    public isCancelled: boolean;
    public isRescheduled: any;
    public parentDailyschedule: Dailyschedule;
    public rescheduleRemarks: string;
    public cancelRemarks: string;
    public isSessionLocationChanged: boolean;
    public isOnlineSession: boolean;
    public meetingUserId: string;
    public meetingUserName: string;
    public isDeleted: boolean;
    public trainers: Array<Trainer>;
    public dailyscheduleprice: DailySchedulePrice;
}
export class DailySchedulePrice {
    public price: number;
    public internationalPrice: number;
    public maxnoofseats: number;
    public noOfEnrolments: number;
}
export class AdditionalDailySchedule {
    isRescheduled: any;
    id: string;
    parentDailyScheduleId: string;
    parentAdditionalDailyScheduleId: string;
    public sessiondate: Date;
    public fromtime: string;
    public totime: string;
    public isCancelled: boolean;
    public rescheduleRemarks: string;
    public cancelRemarks: string;
}

export class GrainSignature {
    public isDeleted: boolean;
    public createdBy: string;
    public createdTime: Date;
    public modifiedBy: string;
    public modifiedTime: Date;
}

export class Document extends GrainSignature {
    public name: string;
    public path: string;
    public type: string;
    public originalName: string;
    public relativePath: string;
}

export class WebsiteCourseModel {
    public post_id: number;
    public post_status: string;
}

export class TrainingProgramSettings {
    public minnoofsessions: number;
    public maxnoofsessions: number;
}

export class WeeklyProgramPrice {
    public pricepermonth: any;
    public price: any;
    public retailPrice: number;
    public internationalPrice: number;
    public internationalRetailPrice: number;
    public earlybirdprice: any;
    public payby: any;
    public maxnoofseats: any;
    public minnoofseats: any;
    public noOfEnrolments: any;
}

export class DailyProgramPrice {
    public price: number;
    public retailPrice: number;
    public internationalPrice: number;
    public internationalRetailPrice: number;
    public earlybirdprice: number;
    public payby: any;
    public maxnoofseats: any;
    public minnoofseats: any;
    public noOfEnrolments: any;
}

export class TenantContactVerification {
    constructor() {
    }
    public isPhoneVerified: boolean;
    public isEmailVerified: boolean;
    public tenantId: string;
    public phoneNumber: Array<string>;
    public photoIdStatus: VerificationStatus;
    public addressIdStatus: VerificationStatus;
    public qualificationStatus: VerificationStatus;
    public accreditationStatus: VerificationStatus;
    public profilePhotoStatus: VerificationStatus;
    public incorporationCertificateStatus: VerificationStatus;
}

export enum VerificationStatus {
    NA = 0,
    Pending = 1,
    Verified = 2,
    Rejected = 3
}

export class BatchViewModel {
    constructor() {
        this.weeklySchedules = new Array<Weeklyschedule>();
        this.id = '00000000-0000-0000-0000-000000000000';
        this.isDeleted = false;
    }
    public id: string;
    public name: string;
    public fromdate: string;
    public weeklySchedules: Array<Weeklyschedule>;
    public command: string;
    public numberOfSessions: any;
    public batchSize: any;
    public isDeleted: boolean;
    public noOfEnrolments: any;
}