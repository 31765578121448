import * as tslib_1 from "tslib";
import { OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import * as objectPath from 'object-path';
// import { PageConfigService } from '../../../../core/_base/layout';
import { LayoutConfig } from '../configurations/layout.config';
import { MenuConfig } from '../configurations/menu.config';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { constants } from '../../../helpers/constants';
import { environment } from 'src/environments/environment';
export class BaseComponent {
    constructor(layoutConfigService, menuConfigService, htmlClassService, authService, router, sharedService, cdRef, loaderService, globalService) {
        this.layoutConfigService = layoutConfigService;
        this.menuConfigService = menuConfigService;
        this.htmlClassService = htmlClassService;
        this.authService = authService;
        this.router = router;
        this.sharedService = sharedService;
        this.cdRef = cdRef;
        this.loaderService = loaderService;
        this.globalService = globalService;
        this.selfLayout = "home";
        this.showSplashScreen = true;
        this.noSidebar = false;
        this.isTenantExpired = false;
        this.mobHeaderDisplay = false;
        this.unsubscribe = [];
        this.isMember = false;
        this.sharedService.getShowSetupEvent().subscribe(() => {
            this.showSplashScreen = false;
            this.loaderService.blockLoader = false;
            this.cdRef.detectChanges();
        });
        this.sharedService.getMobileTopbarCloseEvent().subscribe(() => {
            this.htmlClassService.closeMobileHeader();
        });
        this.layoutConfigService.loadConfigs(new LayoutConfig().configs);
        this.menuConfigService.loadConfigs(new MenuConfig().configs);
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event) => {
            this.customConfig = this.layoutConfigService.getConfig();
            if (event.urlAfterRedirects.includes('auth-callback') || event.url.includes('signin')) {
                this.showSplashScreen = true;
                this.loaderService.blockLoader = true;
            }
            else if (event.urlAfterRedirects == "/myhome"
                || event.urlAfterRedirects == "/selectplan"
                || event.urlAfterRedirects.includes("/register")
                || event.urlAfterRedirects.includes("/courseenquiry")
                || event.urlAfterRedirects.includes("/registration")
                || window.location.pathname.includes('unauthorized')
                || window.location.pathname.includes('tenantnotfound')
                || window.location.pathname.includes('gettingstarted')
                || window.location.pathname.includes('servererror')
                || window.location.pathname.includes('createcourse')
                || window.location.pathname.includes('courseprofile')
                || window.location.pathname.includes('welcome')
                || window.location.pathname.includes('/meet')
                || window.location.pathname.includes('/myregistration')
                || window.location.pathname.includes('/feedback')) {
                this.showSplashScreen = false;
                this.loaderService.blockLoader = false;
                this.customConfig.self.layout = 'home';
                this.customConfig.header.self.fixed.desktop = "";
                this.customConfig.aside.self.fixed = false;
                this.htmlClassService.setConfig(this.customConfig);
                this.selfLayout = this.customConfig.self.layout;
            }
            else {
                this.selfLayout = objectPath.get(this.customConfig, 'fluid');
                this.customConfig.self.layout = 'fluid';
                this.customConfig.header.self.fixed.desktop = "kt-header--fixed";
                this.customConfig.aside.self.fixed = true;
                this.htmlClassService.setConfig(this.customConfig);
                this.selfLayout = this.customConfig.self.layout;
            }
            // let the layout type change
            const layoutConfigSubscription = this.layoutConfigService.onConfigUpdated$.subscribe(cfg => {
                setTimeout(() => {
                    //this.selfLayout = this.selfLayout;
                });
            });
            this.unsubscribe.push(layoutConfigSubscription);
            this.tenantExpiryEvent();
        });
        this.isTenant = globalService.isTenant;
        this.globalService.isTenantChange.subscribe((value) => {
            this.isTenant = value;
            this.tenantExpiryLayoutEvent();
            this.cdRef.detectChanges();
        });
        this.subscriptionExpiryInfo = globalService.subscriptionExpiryInfo;
        this.globalService.subscriptionExpiryInfoChange.subscribe((value) => {
            this.subscriptionExpiryInfo = value;
            this.tenantExpiryLayoutEvent();
            this.cdRef.detectChanges();
        });
        this.setDisplayHeaderCustom();
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.isMember = this.sharedService.isMember();
            if (window.location.pathname.includes('tenantnotfound')) {
                this.showSplashScreen = false;
                return;
            }
            this.isLoggedIn = yield this.authService.isLoggedin();
            if (window.location.pathname.includes('login')) {
                this.loaderService.blockLoader = true;
                this.authService.login();
            }
            if (window.location.pathname.includes('auth-callback') || window.location.pathname.includes('signin')) {
                this.loaderService.blockLoader = true;
            }
            if (window.location.pathname.includes('/myhome')
                && (window.location.origin.replace('/client', '').toLowerCase() != constants.admin_url.replace('/client', '').toLowerCase())
                && (window.location.origin.replace('/client', '').toLowerCase() != environment.commonLoginUrl.replace('/client', '').toLowerCase())) {
                this.router.navigate(['/']);
            }
            else if (window.location.pathname.includes('/myhome')
                && ((window.location.origin.replace('/client', '').toLowerCase() == constants.admin_url.replace('/client', '').toLowerCase())
                    || (window.location.origin.replace('/client', '').toLowerCase() == environment.commonLoginUrl.replace('/client', '').toLowerCase()))) {
                this.authService.login();
            }
            if (!this.isLoggedIn
                && ((window.location.origin.replace('/client', '').toLowerCase() == constants.admin_url.replace('/client', '').toLowerCase())
                    || (window.location.origin.replace('/client', '').toLowerCase() == environment.commonLoginUrl.replace('/client', '').toLowerCase())
                    || this.sharedService.isMember()
                    || this.sharedService.isRedirectUrl())
                && !window.location.pathname.includes('/auth-callback')
                && !window.location.pathname.includes('signin')
                && !window.location.pathname.includes('/meet')
                && !window.location.pathname.includes('/myregistration')
                && !window.location.pathname.includes('/feedback')) {
                this.authService.login();
            }
            if (window.location.pathname == '/'
                || window.location.pathname.includes('/myhome')
                || window.location.pathname.includes('/selectplan')
                || window.location.pathname.includes('/register')
                || window.location.pathname.includes('/courseenquiry')
                || window.location.pathname.includes('/registration')
                || window.location.pathname.includes('unauthorized')
                || window.location.pathname.includes('servererror')
                || window.location.pathname.includes('/meet')
                || window.location.pathname.includes('/myregistration')
                || window.location.pathname.includes('/feedback')) {
                this.showSplashScreen = false;
                this.loaderService.blockLoader = false;
            }
            else if (!this.isLoggedIn && !window.location.pathname.includes('/auth-callback') && !window.location.pathname.includes('signin')) {
                this.authService.login();
            }
            else {
                this.loaderService.blockLoader = false;
                this.showSplashScreen = false;
            }
            this.sharedService.getClickEvent().subscribe(() => {
                this.isLoggedIn = true;
                this.cdRef.detectChanges();
            });
            if (window.location.pathname.includes('gettingstarted')) {
                this.showSplashScreen = false;
                this.loaderService.blockLoader = false;
            }
            const config = this.layoutConfigService.getConfig();
            this.asideDisplay = objectPath.get(config, 'aside.self.display');
            this.subheaderDisplay = objectPath.get(config, 'subheader.display');
            const layoutConfigSubscription = this.layoutConfigService.onConfigUpdated$.subscribe(cfg => {
                setTimeout(() => {
                });
            });
            this.unsubscribe.push(layoutConfigSubscription);
        });
    }
    tenantExpiryEvent() {
        if (this.isTenant && this.subscriptionExpiryInfo.IsAccountExpired) {
            this.isTenantExpired = true;
            this.customConfig.self.layout = 'home';
            this.customConfig.header.self.fixed.desktop = "";
            this.customConfig.aside.self.fixed = false;
            this.customConfig.self.body["background-image"] = "./assets/media/images/bg_medicare.jpg";
            this.htmlClassService.setConfig(this.customConfig);
        }
    }
    tenantExpiryLayoutEvent() {
        if (this.isTenant && this.subscriptionExpiryInfo.IsAccountExpired) {
            this.isTenantExpired = true;
            this.customConfig.self.layout = 'home';
            this.customConfig.header.self.fixed.desktop = "";
            this.customConfig.aside.self.fixed = false;
            this.htmlClassService.setConfig(this.customConfig);
        }
        else {
            if (window.location.pathname == "/myhome"
                || window.location.pathname == "/selectplan"
                || window.location.pathname.includes("/register")
                || window.location.pathname.includes("/courseenquiry")
                || window.location.pathname.includes("/registration")
                || window.location.pathname.includes('unauthorized')
                || window.location.pathname.includes('tenantnotfound')
                || window.location.pathname.includes('gettingstarted')
                || window.location.pathname.includes('servererror')
                || window.location.pathname.includes('auth-callback')
                || window.location.pathname.includes('createcourse')
                || window.location.pathname.includes('courseprofile')
                || window.location.pathname.includes('signin')
                || window.location.pathname.includes('welcome')
                || window.location.pathname.includes('/meet')
                || window.location.pathname.includes("/myregistration")
                || window.location.pathname.includes('/feedback')) {
            }
            else {
                this.isTenantExpired = false;
                this.selfLayout = objectPath.get(this.customConfig, 'fluid');
                this.customConfig.self.layout = 'fluid';
                this.customConfig.header.self.fixed.desktop = "kt-header--fixed";
                this.customConfig.aside.self.fixed = true;
                this.htmlClassService.setConfig(this.customConfig);
                this.selfLayout = this.customConfig.self.layout;
            }
        }
    }
    ngOnDestroy() {
        this.unsubscribe.forEach(sb => sb.unsubscribe());
    }
    setDisplayHeaderCustom() {
        if (window.location.pathname.includes('createcourse') || window.location.pathname.includes('courseprofile')) {
            this.mobHeaderDisplay = true;
        }
    }
}
