export const environment = {
  production: true,
  api_url: window.location.origin,
  identityServerUrl: window.location.origin,
  host: window.location.origin,
  admin_url: 'https://admin.edujournal.com',
  isProduction: true,
  websiteUrl: 'https://www.edujournal.com/',
  commonLoginUrl: 'https://accounts.edujournal.com',
  member_url: 'https://member.edujournal.com',
  shortRedirect_url: 'https://url.edujournal.com'
};

export const ClientSettings = {
  authority: environment.identityServerUrl,
  client_id: "edujournal.spa.tenantportal",
  redirect_uri: environment.host + "/auth-callback",
  post_logout_redirect_uri: environment.host,
  response_type: "id_token token",
  scope: "openid profile IdentityServerApi",
  filterProtocolClaims: false,
  loadUserInfo: true,
  automaticSilentRenew: true,
  silent_redirect_uri: environment.host + '/silent-refresh',
  accessTokenExpiringNotificationTime: 60,
  silentRequestTimeout: 5000,
  revokeAccessTokenOnSignout: true
};

export const ZoomSettings = {
  OAUTH: {
    authorization_url: "https://zoom.us/oauth/authorize",
    client_id: "lPdFeJfT2OiXMzgt5Dk5Q",
    redirect_uri: "/myapp/zoom/zoomcallback"
  }
}

export const GoogleCaptcha = {
  site_key: "6LdP6jIcAAAAAB9R6-By5OAoa6PcUnMok3RQTT_Q",
  secret_key: "6LdP6jIcAAAAAKiKYjITel1lRsjm5Q60og54X7hL"
}

export const IntercomConfigKeys = {
  app_id: "qkwq8vwn",
  identity_verification_secret: "BMLExTnK-T7xa782A9My4GdV6mCIYE7MfwSxhjbj"
}

export const Constants = {
  defaultInternationalCurrencyCode: "USD",
  defaultInternationalCurrencySymbol: "$"
}