<div class="row">
    <div class="col-lg-12">
        <label *ngIf="displayLabel">Phone</label>
        <div class="input-group">
            <div class="input-group-prepend" id="divPhoneNumber">
                <select name="countryCode" class="form-control countrycode" disabled="{{ disabled }}" 
                    [(ngModel)]="countryCode" id="ddlCountryCode">
                    <option *ngFor="let option of countryCodes" [attr.data-country]="option.flag_font_code"
                        [attr.dial-code]="option.dial_code"
                        [attr.data-countryname]="option.name" selected="countrycode == option.dial_code"
                        [ngValue]="option.dial_code">
                        {{option.dial_code}}
                    </option>
                </select>
            </div>
            <input type="text" digitOnly maxlength="12" disabled="{{ disabled }}" class="form-control kt-input ej-select-no-backgroundimg"
            [ngClass]="phoneError ? (inputClass + ' is-invalid') : inputClass + ''"
            [(ngModel)]="phoneNumber" (change)="phoneNumberChangedEvent()">
            <div *ngIf="phoneError" class="invalid-feedback">
                {{phoneNumberErrorMessage}}
            </div>
        </div>
    </div>
</div>