import * as tslib_1 from "tslib";
import * as i0 from "@angular/core";
import * as i1 from "./storage.service";
import * as i2 from "@angular/common/http";
import * as i3 from "ngx-device-detector";
import { downgradeInjectable } from '@angular/upgrade/static';
import { Subject } from 'rxjs';
import { constants } from '../../helpers/constants';
export class GlobalService {
    constructor(storageService, http, deviceService) {
        this.storageService = storageService;
        this.http = http;
        this.deviceService = deviceService;
        this.userPermissionDataChange = new Subject();
        this.tenantAppliedThemeModel = {};
        this.subscriptionExpiryInfo = {};
        this.subscriptionExpiryInfoChange = new Subject();
        this.loggedUser = {};
        this.currentuser = {};
        this.primaryInitialSetupCompletedChange = new Subject();
        this.secondaryInitialSetupCompletedChange = new Subject();
        this.trainerPrimaryInitialSetupCompletedChange = new Subject();
        this.menuDataChange = new Subject();
        this.isTenantChange = new Subject();
        this.showSchoolTour = false;
        this.isLoggedInChange = new Subject();
        this.pendingCourseApprovalsCountChange = new Subject();
    }
    setTime() { this.time = new Date().getHours() + ":" + new Date().getMinutes() + ":" + new Date().getSeconds(); }
    getTime() { return this.time; }
    setBrowserPageTitle(data) { this.browserPageTitle = data; }
    getBrowserPageTitle() { return this.browserPageTitle; }
    setUserPermissionData(data) {
        this.userPermissionData = data;
        this.userPermissionDataChange.next(this.userPermissionData);
    }
    getUserPermissionData() { return this.userPermissionData; }
    setTenantDeactivated(data) { this.tenantDeactivated = data; }
    getTenantDeactivated() { return this.tenantDeactivated; }
    setTenantSuspended(data) { this.tenantSuspended = data; }
    getTenantSuspended() { return this.tenantSuspended; }
    setTenantAppliedThemeModel(data) { this.tenantAppliedThemeModel = data; }
    getTenantAppliedThemeModel() { return this.tenantAppliedThemeModel; }
    setSubscriptionExpiryInfo(data) {
        this.subscriptionExpiryInfo = data;
        this.subscriptionExpiryInfoChange.next(this.subscriptionExpiryInfo);
    }
    getSubscriptionExpiryInfo() { return this.subscriptionExpiryInfo; }
    setLoggedUser(data) { this.loggedUser = data; }
    getLoggedUser() { return this.loggedUser; }
    setCurrentuser(data) { this.currentuser = data; }
    getCurrentuser() { return this.currentuser; }
    setPrimaryInitialSetupCompleted(data) {
        this.primaryInitialSetupCompleted = data;
        this.primaryInitialSetupCompletedChange.next(this.primaryInitialSetupCompleted);
    }
    getPrimaryInitialSetupCompleted() { return this.primaryInitialSetupCompleted; }
    setSecondaryInitialSetupCompleted(data) {
        this.secondaryInitialSetupCompleted = data;
        this.secondaryInitialSetupCompletedChange.next(this.secondaryInitialSetupCompleted);
    }
    getSecondaryInitialSetupCompleted() { return this.secondaryInitialSetupCompleted; }
    setDisplayLeftSidebar(data) { this.displayLeftSidebar = data; }
    getDisplayLeftSidebar() { return this.displayLeftSidebar; }
    setPreviousState(data) { this.previousState = data; }
    getPreviousState() { return this.previousState; }
    setPreviousStateParameters(data) { this.previousStateParameters = data; }
    getPreviousStateParameters() { return this.previousStateParameters; }
    setSidebarMainMenu(data) { this.sidebarMainMenu = data; }
    getSidebarMainMenu() { return this.sidebarMainMenu; }
    setSidebarSubMenu(data) { this.sidebarSubMenu = data; }
    getSidebarSubMenu() { return this.sidebarSubMenu; }
    setMenuData(data) {
        this.menuData = data;
        this.menuDataChange.next(this.menuData);
    }
    getMenuData() { return this.menuData; }
    setTenantID(data) { this.tenantID = data; }
    getTenantID() { return this.tenantID; }
    setFbLoginStatus(data) { this.fbLoginStatus = data; }
    getFbLoginStatus() { return this.fbLoginStatus; }
    setStudentUploadfilename(data) { this.studentUploadfilename = data; }
    getStudentUploadfilename() { return this.studentUploadfilename; }
    setWorkshopcourseUploadfilename(data) { this.workshopcourseUploadfilename = data; }
    getWorkshopcourseUploadfilename() { return this.workshopcourseUploadfilename; }
    setWeeklycourseUploadfilename(data) { this.weeklycourseUploadfilename = data; }
    getWeeklycourseUploadfilename() { return this.weeklycourseUploadfilename; }
    setTrainersUploadfilename(data) { this.trainersUploadfilename = data; }
    getTrainersUploadfilename() { return this.trainersUploadfilename; }
    setProspectsUploadfilename(data) { this.prospectsUploadfilename = data; }
    getProspectsUploadfilename() { return this.prospectsUploadfilename; }
    setLeadsUploadfilename(data) { this.leadsUploadfilename = data; }
    getLeadsUploadfilename() { return this.leadsUploadfilename; }
    setIsTenant(data) { this.isTenant = data; this.isTenantChange.next(this.isTenant); }
    getIsTenant() { return this.isTenant; }
    setTrainerPrimaryInitialSetupCompleted(data) {
        this.trainerPrimaryInitialSetupCompleted = data;
        this.trainerPrimaryInitialSetupCompletedChange.next(this.trainerPrimaryInitialSetupCompleted);
    }
    getCountry() {
        let country = this.getSelectedCountry();
        return country;
    }
    getSelectedCountry() {
        let countries = this.storageService.getCountryCodes();
        let country;
        if (countries != null && countries != undefined) {
            country = countries.filter(c => c.selected)[0];
            return country;
        }
    }
    setCountries() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.getCountryCodes().then(res => {
                this.storageService.setCountryCodes(res);
            });
        });
    }
    setSeaCountries() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.getGivenSeaCountries().then(res => {
                this.storageService.setCountryCodes(res);
            });
        });
    }
    setPhoneCountries() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.getGivenSeaCountries().then(res => {
                this.storageService.setPhoneCountryCodes(res);
            });
        });
    }
    getCountryCodes() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(constants.url_constants.api + '/api/LayoutApi/GetCountryCodes').toPromise();
        });
    }
    getGivenSeaCountries(tenantId = "00000000-0000-0000-0000-000000000000") {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(constants.url_constants.api + '/api/LayoutApi/GetGivenSeaCountries/' + tenantId).toPromise();
        });
    }
    getCurrencies() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(constants.url_constants.api + '/api/LayoutApi/GetCurrencies/').toPromise();
        });
    }
    setCurrency(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (data) {
                this.storageService.setCurrency(data);
            }
            else {
                yield this.getSelectedCurrency().then(res => {
                    if (res)
                        this.storageService.setCurrency(res);
                });
            }
        });
    }
    getSelectedCurrency() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(constants.url_constants.api + '/api/TrainingProgramApi/GetSelectedCurrency').toPromise();
        });
    }
    getCurrencyCode() {
        let currency = this.storageService.getCurrency();
        if (currency && currency.symbol != null)
            return currency.symbol;
        else
            return '$';
    }
    setInternationalCurrency(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (data) {
                this.storageService.setInternationalCurrency(data);
            }
            else {
                yield this.getSelectedInternationalCurrency().then(res => {
                    if (res)
                        this.storageService.setInternationalCurrency(res);
                });
            }
        });
    }
    getSelectedInternationalCurrency() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(constants.url_constants.api + '/api/TrainingProgramApi/GetSelectedInternationalCurrency').toPromise();
        });
    }
    getInternationalCurrencySymbol() {
        let currency = this.storageService.getInternationalCurrency();
        if (currency && currency.symbol != null)
            return currency.symbol;
        else
            return '$';
    }
    postTenantActivity(type) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // let tenantsActivityModel = new TenantsActivityModel();
            // switch (type) {
            //     case 'ADDUSER':
            //         tenantsActivityModel.name = 'Create User';
            //         break;
            //     case 'EDITUSER':
            //         tenantsActivityModel.name = 'Edit User';
            //         break;
            //     case 'ADDTRAINER':
            //         tenantsActivityModel.name = 'Create Trainer';
            //         break;
            //     case 'EDITTRAINER':
            //         tenantsActivityModel.name = 'Edit Trainer';
            //         break;
            //     case 'ADDDAILYCOURSE':
            //         tenantsActivityModel.name = 'Create Daily Course';
            //         break;
            //     case 'EDITDAILYCOURSE':
            //         tenantsActivityModel.name = 'Edit Daily Course';
            //         break;
            //     case 'ADDWEEKLYCOURSE':
            //         tenantsActivityModel.name = 'Create Weekly Course';
            //         break;
            //     case 'EDITWEEKLYCOURSE':
            //         tenantsActivityModel.name = 'Edit Weekly Course';
            //         break;
            //     case 'ADDENQUIRY':
            //         tenantsActivityModel.name = 'Create Enquiry';
            //         break;
            //     case 'EDITENQUIRY':
            //         tenantsActivityModel.name = 'Edit Enquiry';
            //         break;
            // }
            this.saveTenantsActivity(type);
        });
    }
    saveTenantsActivity(code) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(constants.url_constants.api + '/api/TenantsApi/SaveTenantActivity?code=' + code).toPromise();
        });
    }
    isMobile() {
        return this.deviceService.isMobile();
    }
    setIsLoggedIn(data) {
        this.isLoggedIn = data;
        this.isLoggedInChange.next(this.isLoggedIn);
    }
    getIsLoggedIn() { return this.isLoggedIn; }
    setPendingCourseApprovalsCount(data) {
        this.pendingCourseApprovalsCount = data;
        this.pendingCourseApprovalsCountChange.next(this.pendingCourseApprovalsCount);
    }
    getPendingCourseApprovalsCount() { return this.pendingCourseApprovalsCount; }
}
GlobalService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GlobalService_Factory() { return new GlobalService(i0.ɵɵinject(i1.StorageService), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.DeviceDetectorService)); }, token: GlobalService, providedIn: "root" });
angular.module('core')
    .factory('globalService', downgradeInjectable(GlobalService));
