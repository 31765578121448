import { Injectable } from '@angular/core';
declare var angular: any;
import { downgradeInjectable } from '@angular/upgrade/static';
import { Subject } from 'rxjs';
import { StorageService } from './storage.service'
import { HttpClient } from '@angular/common/http';
import { constants } from '../../helpers/constants'
import { TenantsActivityModel } from '../models/tenant-activity.model'
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
    providedIn: 'root'
})
export class GlobalService {
    constructor(
        private readonly storageService: StorageService,
        private readonly http: HttpClient,
        private readonly deviceService: DeviceDetectorService
    ) { }

    public time: any;
    public browserPageTitle: any;
    public userPermissionData: any;
    userPermissionDataChange: Subject<any> = new Subject<any>();
    public tenantDeactivated: any;
    public tenantSuspended: any;
    public tenantAppliedThemeModel = {};
    public subscriptionExpiryInfo = {};
    subscriptionExpiryInfoChange: Subject<any> = new Subject<any>();
    public loggedUser = {};
    public currentuser = {};
    public primaryInitialSetupCompleted: any;
    primaryInitialSetupCompletedChange: Subject<any> = new Subject<any>();
    public secondaryInitialSetupCompleted: any;
    secondaryInitialSetupCompletedChange: Subject<any> = new Subject<any>();
    public trainerPrimaryInitialSetupCompleted: any;
    trainerPrimaryInitialSetupCompletedChange: Subject<any> = new Subject<any>();
    public displayLeftSidebar: any;
    public previousState: any;
    public previousStateParameters: any;
    public sidebarMainMenu: any;
    public sidebarSubMenu: any;
    public menuData: any;
    menuDataChange: Subject<any> = new Subject<any>();
    public tenantID: any;
    public fbLoginStatus: any;
    public studentUploadfilename: any;
    public workshopcourseUploadfilename: any;
    public weeklycourseUploadfilename: any;
    public trainersUploadfilename: any;
    public prospectsUploadfilename: any;
    public leadsUploadfilename: any;
    public isTenant: any;
    isTenantChange: Subject<any> = new Subject<any>();
    showSchoolTour: boolean = false;
    public isLoggedIn: any;
    isLoggedInChange: Subject<any> = new Subject<any>();
    public pendingCourseApprovalsCount: any;
    pendingCourseApprovalsCountChange: Subject<any> = new Subject<any>();

    setTime() { this.time = new Date().getHours() + ":" + new Date().getMinutes() + ":" + new Date().getSeconds(); }
    getTime() { return this.time; }

    setBrowserPageTitle(data) { this.browserPageTitle = data; }
    getBrowserPageTitle() { return this.browserPageTitle; }

    setUserPermissionData(data) {
        this.userPermissionData = data;
        this.userPermissionDataChange.next(this.userPermissionData);
    }
    getUserPermissionData() { return this.userPermissionData; }

    setTenantDeactivated(data) { this.tenantDeactivated = data; }
    getTenantDeactivated() { return this.tenantDeactivated; }

    setTenantSuspended(data) { this.tenantSuspended = data; }
    getTenantSuspended() { return this.tenantSuspended; }

    setTenantAppliedThemeModel(data) { this.tenantAppliedThemeModel = data; }
    getTenantAppliedThemeModel() { return this.tenantAppliedThemeModel; }

    setSubscriptionExpiryInfo(data) {
        this.subscriptionExpiryInfo = data;
        this.subscriptionExpiryInfoChange.next(this.subscriptionExpiryInfo);
    }
    getSubscriptionExpiryInfo() { return this.subscriptionExpiryInfo; }

    setLoggedUser(data) { this.loggedUser = data; }
    getLoggedUser() { return this.loggedUser; }

    setCurrentuser(data) { this.currentuser = data; }
    getCurrentuser() { return this.currentuser; }

    setPrimaryInitialSetupCompleted(data) {
        this.primaryInitialSetupCompleted = data;
        this.primaryInitialSetupCompletedChange.next(this.primaryInitialSetupCompleted);
    }
    getPrimaryInitialSetupCompleted() { return this.primaryInitialSetupCompleted; }

    setSecondaryInitialSetupCompleted(data) {
        this.secondaryInitialSetupCompleted = data;
        this.secondaryInitialSetupCompletedChange.next(this.secondaryInitialSetupCompleted);
    }
    getSecondaryInitialSetupCompleted() { return this.secondaryInitialSetupCompleted; }

    setDisplayLeftSidebar(data) { this.displayLeftSidebar = data; }
    getDisplayLeftSidebar() { return this.displayLeftSidebar; }

    setPreviousState(data) { this.previousState = data; }
    getPreviousState() { return this.previousState; }

    setPreviousStateParameters(data) { this.previousStateParameters = data; }
    getPreviousStateParameters() { return this.previousStateParameters; }

    setSidebarMainMenu(data) { this.sidebarMainMenu = data; }
    getSidebarMainMenu() { return this.sidebarMainMenu; }

    setSidebarSubMenu(data) { this.sidebarSubMenu = data; }
    getSidebarSubMenu() { return this.sidebarSubMenu; }

    setMenuData(data) {
        this.menuData = data;
        this.menuDataChange.next(this.menuData);
    }
    getMenuData() { return this.menuData; }

    setTenantID(data) { this.tenantID = data; }
    getTenantID() { return this.tenantID; }

    setFbLoginStatus(data) { this.fbLoginStatus = data; }
    getFbLoginStatus() { return this.fbLoginStatus; }

    setStudentUploadfilename(data) { this.studentUploadfilename = data; }
    getStudentUploadfilename() { return this.studentUploadfilename; }

    setWorkshopcourseUploadfilename(data) { this.workshopcourseUploadfilename = data; }
    getWorkshopcourseUploadfilename() { return this.workshopcourseUploadfilename; }

    setWeeklycourseUploadfilename(data) { this.weeklycourseUploadfilename = data; }
    getWeeklycourseUploadfilename() { return this.weeklycourseUploadfilename; }

    setTrainersUploadfilename(data) { this.trainersUploadfilename = data; }
    getTrainersUploadfilename() { return this.trainersUploadfilename; }

    setProspectsUploadfilename(data) { this.prospectsUploadfilename = data; }
    getProspectsUploadfilename() { return this.prospectsUploadfilename; }

    setLeadsUploadfilename(data) { this.leadsUploadfilename = data; }
    getLeadsUploadfilename() { return this.leadsUploadfilename; }

    setIsTenant(data) { this.isTenant = data; this.isTenantChange.next(this.isTenant); }
    getIsTenant() { return this.isTenant; }

    setTrainerPrimaryInitialSetupCompleted(data) {
        this.trainerPrimaryInitialSetupCompleted = data;
        this.trainerPrimaryInitialSetupCompletedChange.next(this.trainerPrimaryInitialSetupCompleted);
    }

    getCountry() {
        let country = this.getSelectedCountry();
        return country;
    }
    getSelectedCountry() {
        let countries = this.storageService.getCountryCodes();
        let country: any;
        if (countries != null && countries != undefined) {
            country = countries.filter(c => c.selected)[0];
            return country;
        }
    }
    async setCountries() {
        await this.getCountryCodes().then(res => {
            this.storageService.setCountryCodes(res);
        });
    }
    async setSeaCountries() {
        await this.getGivenSeaCountries().then(res => {
            this.storageService.setCountryCodes(res);
        });
    }
    async setPhoneCountries() {
        await this.getGivenSeaCountries().then(res => {
            this.storageService.setPhoneCountryCodes(res);
        });
    }
    async getCountryCodes(): Promise<any> {
        return await this.http.get(constants.url_constants.api + '/api/LayoutApi/GetCountryCodes').toPromise();
    }
    async getGivenSeaCountries(tenantId = "00000000-0000-0000-0000-000000000000"): Promise<any> {
        return await this.http.get(constants.url_constants.api + '/api/LayoutApi/GetGivenSeaCountries/' + tenantId).toPromise();
    }

    async getCurrencies(): Promise<any> {
        return await this.http.get(constants.url_constants.api + '/api/LayoutApi/GetCurrencies/').toPromise();
    }
    async setCurrency(data) {
        if (data) {
            this.storageService.setCurrency(data);
        }
        else {
            await this.getSelectedCurrency().then(res => {
                if (res)
                    this.storageService.setCurrency(res);
            });
        }
    }
    async getSelectedCurrency(): Promise<any> {
        return await this.http.get(constants.url_constants.api + '/api/TrainingProgramApi/GetSelectedCurrency').toPromise();
    }
    getCurrencyCode() {
        let currency = this.storageService.getCurrency();
        if (currency && currency.symbol != null)
            return currency.symbol;
        else
            return '$';
    }

    async setInternationalCurrency(data) {
        if (data) {
            this.storageService.setInternationalCurrency(data);
        }
        else {
            await this.getSelectedInternationalCurrency().then(res => {
                if (res)
                    this.storageService.setInternationalCurrency(res);
            });
        }
    }
    async getSelectedInternationalCurrency(): Promise<any> {
        return await this.http.get(constants.url_constants.api + '/api/TrainingProgramApi/GetSelectedInternationalCurrency').toPromise();
    }
    getInternationalCurrencySymbol() {
        let currency = this.storageService.getInternationalCurrency();
        if (currency && currency.symbol != null)
            return currency.symbol;
        else
            return '$';
    }

    async postTenantActivity(type: string) {
        // let tenantsActivityModel = new TenantsActivityModel();
        // switch (type) {
        //     case 'ADDUSER':
        //         tenantsActivityModel.name = 'Create User';
        //         break;
        //     case 'EDITUSER':
        //         tenantsActivityModel.name = 'Edit User';
        //         break;
        //     case 'ADDTRAINER':
        //         tenantsActivityModel.name = 'Create Trainer';
        //         break;
        //     case 'EDITTRAINER':
        //         tenantsActivityModel.name = 'Edit Trainer';
        //         break;
        //     case 'ADDDAILYCOURSE':
        //         tenantsActivityModel.name = 'Create Daily Course';
        //         break;
        //     case 'EDITDAILYCOURSE':
        //         tenantsActivityModel.name = 'Edit Daily Course';
        //         break;
        //     case 'ADDWEEKLYCOURSE':
        //         tenantsActivityModel.name = 'Create Weekly Course';
        //         break;
        //     case 'EDITWEEKLYCOURSE':
        //         tenantsActivityModel.name = 'Edit Weekly Course';
        //         break;
        //     case 'ADDENQUIRY':
        //         tenantsActivityModel.name = 'Create Enquiry';
        //         break;
        //     case 'EDITENQUIRY':
        //         tenantsActivityModel.name = 'Edit Enquiry';
        //         break;
        // }
        this.saveTenantsActivity(type);
    }

    async saveTenantsActivity(code: string) {
        return await this.http.get(constants.url_constants.api + '/api/TenantsApi/SaveTenantActivity?code=' + code).toPromise();
    }

    isMobile(): boolean {
        return this.deviceService.isMobile();
    }

    setIsLoggedIn(data) {
        this.isLoggedIn = data;
        this.isLoggedInChange.next(this.isLoggedIn);
    }
    getIsLoggedIn() { return this.isLoggedIn; }

    setPendingCourseApprovalsCount(data) {
        this.pendingCourseApprovalsCount = data;
        this.pendingCourseApprovalsCountChange.next(this.pendingCourseApprovalsCount);
    }
    getPendingCourseApprovalsCount() { return this.pendingCourseApprovalsCount; }
}

angular.module('core')
    .factory('globalService', downgradeInjectable(GlobalService));