import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TenantVerificationService } from '../tenant-verification.service';
import { TenantContactVerification, VerificationStatus } from '../../create-course/course.model';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { NavigatorService } from 'src/ej-shared/services/navigator.service';
import { StorageService } from 'src/ej-shared/services/storage.service';

declare var $: any;
@Component({
  selector: 'ej-tenant-verification-popup',
  templateUrl: './tenant-verification-popup.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }],
  styles: [`
  .custom-modal .modal-content {
    max-width: 650px;
    margin: auto;
    padding:0 0.5rem;
  }
`]
})
export class TenantVerificationPopupComponent implements OnInit {

  location: Location;
  loading: boolean = true;
  tenantVerification: TenantContactVerification = new TenantContactVerification();
  tenantVerificationdata: any = {};
  closeResult = '';
  trainerType: any;

  constructor(
    private readonly tenantVerificationService: TenantVerificationService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private modalService: NgbModal,
    location: Location,
    private readonly navigatorService: NavigatorService,
    private readonly storageService: StorageService
  ) {
    this.location = location;
  }

  ngOnInit() {
    this.getTenantVerification();
    this.trainerType = this.storageService.getTrainerType();
  }

  getTenantVerification() {
    this.loading = true;
    this.tenantVerificationService.getTenantVerification().then(res => {
      this.tenantVerification = res;
      this.loadTenantVerification();
      this.changeDetectorRef.detectChanges();
      this.loading = false;
    });
  }

  loadTenantVerification() {

    this.tenantVerificationdata.progressItems = 0;
    this.tenantVerificationdata.items = [];

    // email
    var item = {
      header: 'Email ID verification',
      content: 'Your email Id will make it easier for eduJournal to reach you.',
      icon: 'la la-envelope',
      color: '',
      verificationStatus: this.tenantVerification.isEmailVerified ? VerificationStatus.Verified : VerificationStatus.NA,
      verificationStatusText: this.tenantVerification.isEmailVerified ? VerificationStatus[VerificationStatus.Verified] : VerificationStatus[VerificationStatus.NA]
    };
    item.color = this.getcolorValue(item.verificationStatus);
    this.tenantVerificationdata.items.push(item);

    // phone
    var item = {
      header: 'Phone number verification',
      content: 'In emergencies, your students will know how to reach you.',
      icon: 'la la-phone',
      color: '',
      verificationStatus: this.tenantVerification.isPhoneVerified ? VerificationStatus.Verified : VerificationStatus.NA,
      verificationStatusText: this.tenantVerification.isPhoneVerified ? VerificationStatus[VerificationStatus.Verified] : VerificationStatus[VerificationStatus.NA]
    };
    item.color = this.getcolorValue(item.verificationStatus);
    this.tenantVerificationdata.items.push(item);

    if (this.trainerType == 1 || this.trainerType == 3) {
      // Profile photo
      var item = {
        header: 'Profile Photo verification',
        content: 'Let\'s ensure that there is a real person behind the process.',
        icon: 'la la-image',
        color: this.getcolorValue(this.tenantVerification.profilePhotoStatus),
        verificationStatus: this.tenantVerification.profilePhotoStatus,
        verificationStatusText: VerificationStatus[this.tenantVerification.profilePhotoStatus]
      };
      this.tenantVerificationdata.items.push(item);

      // PhotoID
      var item = {
        header: 'Photo ID verification',
        content: 'Let\' ensure that there is a real person behind the process.',
        icon: 'la la-user',
        color: this.getcolorValue(this.tenantVerification.photoIdStatus),
        verificationStatus: this.tenantVerification.photoIdStatus,
        verificationStatusText: VerificationStatus[this.tenantVerification.photoIdStatus]
      };
      this.tenantVerificationdata.items.push(item);

      // address
      var item = {
        header: 'Address verification',
        content: 'It helps us to provide suggested altenatives if an error is discovered.',
        icon: 'la la-map-marker',
        color: this.getcolorValue(this.tenantVerification.addressIdStatus),
        verificationStatus: this.tenantVerification.addressIdStatus,
        verificationStatusText: VerificationStatus[this.tenantVerification.addressIdStatus]
      };
      this.tenantVerificationdata.items.push(item);

      // qualification certificate
      var item = {
        header: 'Qualification certificate verification',
        content: 'Let\'s validate your qualification.',
        icon: 'la la-certificate',
        color: this.getcolorValue(this.tenantVerification.qualificationStatus),
        verificationStatus: this.tenantVerification.qualificationStatus,
        verificationStatusText: VerificationStatus[this.tenantVerification.qualificationStatus]
      };
      this.tenantVerificationdata.items.push(item);

      // relevent certificate
      var item = {
        header: 'Other relevent certificates verification',
        content: 'Let\'s validate your academic skills.',
        icon: 'la la-certificate',
        color: this.getcolorValue(this.tenantVerification.accreditationStatus),
        verificationStatus: this.tenantVerification.accreditationStatus,
        verificationStatusText: VerificationStatus[this.tenantVerification.accreditationStatus]
      };
      this.tenantVerificationdata.items.push(item);
    }
    else {
      // incorporation certificate
      var item = {
        header: 'Incorporation certificate verification',
        content: 'Let\'s validate your incorporation certificate.',
        icon: 'la la-certificate',
        color: this.getcolorValue(this.tenantVerification.incorporationCertificateStatus),
        verificationStatus: this.tenantVerification.incorporationCertificateStatus,
        verificationStatusText: VerificationStatus[this.tenantVerification.incorporationCertificateStatus]
      };
      this.tenantVerificationdata.items.push(item);
    }

    this.tenantVerificationdata.items.forEach(item => {
      if (item.verificationStatus == VerificationStatus.Verified) {
        this.tenantVerificationdata.progressItems++;
      }
    });
    this.tenantVerificationdata.progress = this.tenantVerificationdata.progressItems * 100 / this.tenantVerificationdata.items.length;
    this.tenantVerificationdata.progressWidth = this.tenantVerificationdata.progress + '%';
  }

  showTenantVerificationPopup(content) {
    this.getTenantVerification();
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg', windowClass: 'custom-modal' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  private getcolorValue(p) {
    var color = '';
    switch (+p) {
      case VerificationStatus.NA: color = 'secondary'; break;
      case VerificationStatus.Pending: color = 'warning'; break;
      case VerificationStatus.Verified: color = 'primary'; break;
      case VerificationStatus.Rejected: color = 'danger'; break;
    }
    return color;
  }

  gotoTenantVerification() {
    this.modalService.dismissAll();
    if (this.trainerType == 1 || this.trainerType == 3) {
      this.navigatorService.navigate('/myapp/trainerprofile', false);
    }
    else {
      this.navigatorService.navigate('/schoolprofile', false);
    }
  }
}
