import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageService } from 'src/ej-shared/services/storage.service';
import { constants } from '../helpers/constants';

@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {

    constructor(private storageService: StorageService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let accessToken = this.storageService.getAccessToken();
        if (request.url.includes(constants.url_constants.api)) {
            request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + accessToken) });
            request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        }
        return next.handle(request);
    }
}
