import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-getting-started',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './demo-trainer-getting-started.component.html'
})
export class DemoTrainerGettingStartedComponent implements OnInit {
  // @ViewChild('fromTime', { static: true }) fromTime: ElementRef;
  // @ViewChild('toTime', { static: true }) toTime: ElementRef;
  currentstep: number = 1;
  weekday: string;
  weekdays: any;
  fromDate: any;
  toDate: any;
  description: string;
  fromTime: any;
  toTime: any;
  schedule: any = [];
  trainingProgram: any = {};
  

  public error = {
    weekday: false,
    fromDate: false,
    toDate: false,
    fromTime: false,
    toTime: false
  }

  async ngOnInit() {
      this.weekdays  = [
        { value: 'Sunday', name: 'Sunday' },
        { value: 'Monday', name: 'Monday' },
        { value: 'Tuesday', name: 'Tuesday' },
        { value: 'Wednesday', name: 'Wednesday' },
        { value: 'Thursday', name: 'Thursday' },
        { value: 'Friday', name: 'Friday' },
        { value: 'Saturday', name: 'Saturday' }
    ];
    this.trainingProgram.weeklySchedules = [];
    $("#fromTime").timepicker();
    $("#toTime").timepicker();  
      }
      
  async saveandcontinue() {
    if (this.currentstep == 1) {
        this.currentstep = 2;
      }
      
    else if (this.currentstep == 2) {
      this.currentstep = 3;
    }
    else if (this.currentstep == 3) {
      this.currentstep = 4;
    }
    else if (this.currentstep == 4) {
      this.currentstep = 5;
    }
    else if (this.currentstep == 5) {
      this.currentstep = 6;
    }
    else if (this.currentstep == 6) {
      this.currentstep = 7;
    }
    else if (this.currentstep == 7) {
      this.currentstep = 8;
    }
  }

  previousclickevent() {
    this.currentstep = this.currentstep - 1;
  }
  async addSession() {
    this.weekday = this.weekday;
    if (this.validateScheduleForm()) {
        var sessions = this.getWeeklySessions();
                        if (sessions.length > 0) {
                            this.schedule.id = this.schedule.id === undefined ? '00000000-0000-0000-0000-000000000000' : this.schedule.id;
                            // this.schedule.fromTime = this.getDateObject(this.fromTime);
                            // this.schedule.toTime = this.getDateObject(this.toTime);
                            this.schedule.weekday = this.weekday;
                            this.schedule.weeklySessionViewModels = this.getWeeklySessions();
                            var schedules: any = {};
                            schedules.weekday = this.weekday;
                            schedules.fromTime = this.fromTime;
                            schedules.toTime = this.toTime;
                            schedules.description = this.description;
                            this.schedule.push(schedules);
                            this.schedule.lstSchedule = this.groupBy(this.schedule);
                            this.trainingProgram.weeklySchedules = this.schedule.lstSchedule;
                            this.reset();
                        } 
                    } 

  }
  getWeeklySessions() {
    var fromDate = new Date(this.getDateObject(this.fromDate));
    var toDate = new Date(this.getDateObject(this.toDate));
    var weeklySessions = [];

    var weeklySession: any = {};

    while (fromDate <= toDate) {
        if (this.getDayOfWeek(fromDate) == this.weekday) {
            var sessionDate = new Date(fromDate);
            weeklySession.sessiondate = sessionDate;
            weeklySession.fromTime = this.getDateObject(this.fromTime);
            weeklySession.toTime = this.getDateObject(this.toTime);
            weeklySession.active = true;
            weeklySession.weekday = this.weekday;
            weeklySession.description = this.description;
            weeklySessions.push(weeklySession);
            weeklySession = {};
        }
        fromDate = new Date(fromDate.setDate(
            fromDate.getDate() + 1
        ))
    }
    return weeklySessions;
}

getDateObject(dateStr) {
  if (dateStr) {
      if (dateStr.toString().includes("T00:00:00") && !dateStr.toString().includes("T00:00:00Z")) {
          dateStr = dateStr.toString() + "Z";
      } else if (dateStr.toString().includes("-")) {
          dateStr = dateStr.toString().replace(/-/g, " ");
      }
  }
  return new Date(dateStr);
}

getDayOfWeek(date) {
  var dayOfWeek = new Date(date).getDay();
  return isNaN(dayOfWeek) ? null : ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][dayOfWeek];
}

async validateScheduleForm() {
  var isValid = true;
  if (this.fromDate == undefined || this.fromDate == "" || this.fromDate == null) {
      isValid = false;
     this.error.fromDate = true;
  } else {
      this.error.fromDate = false;
  }
  if (this.toDate == undefined || this.toDate == "" || this.toDate == null) {
      isValid = false;
      this.error.toDate = true;
  } else {
    this.error.toDate = false;
  }
  if (this.weekday == "" || this.weekday == null) {
      isValid = false;
      this.error.weekday = true;
  } else {
      this.error.weekday = false;
  }
  // if (this.fromTime == "" || this.fromTime == null) {
  //     isValid = false;
  //     this.error.fromTime = true;
  // } else {
  //     this.error.fromTime = false;
  // }
  // if (this.toTime == "" || this.toTime == null) {
  //     isValid = false;
  //     this.error.toTime = true;
  // } else {
  //     this.error.toTime = false;
  // }
  // if (this.fromTime != "" && this.fromTime != null && this.toTime != "" && this.toTime != null) {
  //     if (new Date(this.toTime) < new Date(this.fromTime)) {
  //         isValid = false;
  //         // this.error.schedule.sessiontime = true;
  //     } else {
  //         // this.error.schedule.sessiontime = false;
  //     }
  // }
  return isValid;
}
groupBy(array) {
  var groups = new Set(array.map(item => item.weekday)), 
    results = [];
    groups.forEach(g => 
    results.push({
      weekday: g,
    schedules: array.filter(i => i.weekday === g)
  }
))
return results;
}
reset() {
  this.description = '';
  this.fromDate = '';
  this.toDate = '';
  this.weekday = '';
  this.fromTime = '';
  this.toTime = '';
}
}