<!--begin: Quick actions -->
<div joyrideStep="StartTour" [stepContent]="takeTourContent" stepPosition="bottom">
    <div ngbDropdown placement="bottom-right" autoClose="outside" class="kt-header__topbar-item" id="divEJQuickActions"
        joyrideStep="QuickActions" [stepContent]="quickActionsContent" stepPosition="bottom" ngbTooltip="Quick Actions">
        <div ngbDropdownToggle class="kt-header__topbar-wrapper">
            <span class="kt-header__topbar-icon"
                [ngClass]="{ 'kt-header__topbar-icon--warning': iconType === 'warning' }">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                    height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect x="0" y="0" width="24" height="24"></rect>
                        <rect fill="#000000" x="4" y="4" width="7" height="7" rx="1.5"></rect>
                        <path
                            d="M5.5,13 L9.5,13 C10.3284271,13 11,13.6715729 11,14.5 L11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L5.5,20 C4.67157288,20 4,19.3284271 4,18.5 L4,14.5 C4,13.6715729 4.67157288,13 5.5,13 Z M14.5,4 L18.5,4 C19.3284271,4 20,4.67157288 20,5.5 L20,9.5 C20,10.3284271 19.3284271,11 18.5,11 L14.5,11 C13.6715729,11 13,10.3284271 13,9.5 L13,5.5 C13,4.67157288 13.6715729,4 14.5,4 Z M14.5,13 L18.5,13 C19.3284271,13 20,13.6715729 20,14.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 L14.5,20 C13.6715729,20 13,19.3284271 13,18.5 L13,14.5 C13,13.6715729 13.6715729,13 14.5,13 Z"
                            fill="#000000" opacity="0.3"></path>
                    </g>
                </svg>
            </span>
        </div>
        <div ngbDropdownMenu
            class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl"
            id="divQuickActions">
            <form>
                <!--begin: Head -->
                <div class="kt-head kt-head--skin-{{skin}}" style="background-image: url(./assets/media/bg/bg-6.jpg)">
                    <h3 class="kt-head__title">
                        Quick Actions
                        <span class="kt-space-15"></span>
                        <button class="btn btn-primary btn-sm btn-bold btn-font-md" href="javascript:void(0)"
                            (click)="starttour()">Start
                            tour</button>
                    </h3>
                </div>
                <!--end: Head -->
                <!--begin: Grid Nav -->
                <div class="kt-grid-nav kt-grid-nav--skin-{{ gridNavSkin }}">
                    <div class="kt-grid-nav__row">
                        <a class="kt-grid-nav__item" href="javascript:void(0)"
                            (click)="removeQuickActions('/schoolprofile')"
                            *ngxPermissionsOnly="['Permissions.Settings.SchoolProfile']">
                            <span class="kt-grid-nav__icon">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="24px" height="24px" viewBox="0 0 24 24" version="1.1"
                                    class="kt-svg-icon kt-svg-icon--success kt-svg-icon--lg">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <rect id="bound" x="0" y="0" width="24" height="24" />
                                        <path
                                            d="M11,20 L11,17 C11,16.4477153 11.4477153,16 12,16 C12.5522847,16 13,16.4477153 13,17 L13,20 L15.5,20 C15.7761424,20 16,20.2238576 16,20.5 C16,20.7761424 15.7761424,21 15.5,21 L8.5,21 C8.22385763,21 8,20.7761424 8,20.5 C8,20.2238576 8.22385763,20 8.5,20 L11,20 Z"
                                            id="Combined-Shape" fill="#000000" opacity="0.3" />
                                        <path
                                            d="M3,5 L21,5 C21.5522847,5 22,5.44771525 22,6 L22,16 C22,16.5522847 21.5522847,17 21,17 L3,17 C2.44771525,17 2,16.5522847 2,16 L2,6 C2,5.44771525 2.44771525,5 3,5 Z M4.5,8 C4.22385763,8 4,8.22385763 4,8.5 C4,8.77614237 4.22385763,9 4.5,9 L13.5,9 C13.7761424,9 14,8.77614237 14,8.5 C14,8.22385763 13.7761424,8 13.5,8 L4.5,8 Z M4.5,10 C4.22385763,10 4,10.2238576 4,10.5 C4,10.7761424 4.22385763,11 4.5,11 L7.5,11 C7.77614237,11 8,10.7761424 8,10.5 C8,10.2238576 7.77614237,10 7.5,10 L4.5,10 Z"
                                            id="Combined-Shape" fill="#000000" />
                                    </g>
                                </svg>
                            </span>
                            <span class="kt-grid-nav__title">Training Institute</span>
                            <span class="kt-grid-nav__desc">View and edit</span>
                        </a>
                        <a class="kt-grid-nav__item" href="javascript:void(0)"
                            (click)="removeQuickActions('/myapp/trainerprofile')"
                            *ngxPermissionsOnly="['Permissions.Settings.TrainerProfile']">
                            <!-- (click)="navigateToTrainerProfile()" -->
                            <span class="kt-grid-nav__icon">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="24px" height="24px" viewBox="0 0 24 24" version="1.1"
                                    class="kt-svg-icon kt-svg-icon--success kt-svg-icon--lg">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <rect id="bound" x="0" y="0" width="24" height="24" />
                                        <path
                                            d="M11,20 L11,17 C11,16.4477153 11.4477153,16 12,16 C12.5522847,16 13,16.4477153 13,17 L13,20 L15.5,20 C15.7761424,20 16,20.2238576 16,20.5 C16,20.7761424 15.7761424,21 15.5,21 L8.5,21 C8.22385763,21 8,20.7761424 8,20.5 C8,20.2238576 8.22385763,20 8.5,20 L11,20 Z"
                                            id="Combined-Shape" fill="#000000" opacity="0.3" />
                                        <path
                                            d="M3,5 L21,5 C21.5522847,5 22,5.44771525 22,6 L22,16 C22,16.5522847 21.5522847,17 21,17 L3,17 C2.44771525,17 2,16.5522847 2,16 L2,6 C2,5.44771525 2.44771525,5 3,5 Z M4.5,8 C4.22385763,8 4,8.22385763 4,8.5 C4,8.77614237 4.22385763,9 4.5,9 L13.5,9 C13.7761424,9 14,8.77614237 14,8.5 C14,8.22385763 13.7761424,8 13.5,8 L4.5,8 Z M4.5,10 C4.22385763,10 4,10.2238576 4,10.5 C4,10.7761424 4.22385763,11 4.5,11 L7.5,11 C7.77614237,11 8,10.7761424 8,10.5 C8,10.2238576 7.77614237,10 7.5,10 L4.5,10 Z"
                                            id="Combined-Shape" fill="#000000" />
                                    </g>
                                </svg>
                            </span>
                            <span class="kt-grid-nav__title">Trainer Profile</span>
                            <span class="kt-grid-nav__desc">View and edit</span>
                        </a>
                        <a href="javascript:void(0)" class="kt-grid-nav__item"
                            (click)="removeQuickActions('/accountsettings')"
                            *ngIf="trainerType != 3">
                            <span class="kt-grid-nav__icon">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="24px" height="24px" viewBox="0 0 24 24" version="1.1"
                                    class="kt-svg-icon kt-svg-icon--success kt-svg-icon--lg">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <rect id="bound" x="0" y="0" width="24" height="24" />
                                        <circle id="Combined-Shape" fill="#000000" opacity="0.3" cx="12" cy="12"
                                            r="10" />
                                        <path
                                            d="M12,11 C10.8954305,11 10,10.1045695 10,9 C10,7.8954305 10.8954305,7 12,7 C13.1045695,7 14,7.8954305 14,9 C14,10.1045695 13.1045695,11 12,11 Z M7.00036205,16.4995035 C7.21569918,13.5165724 9.36772908,12 11.9907452,12 C14.6506758,12 16.8360465,13.4332455 16.9988413,16.5 C17.0053266,16.6221713 16.9988413,17 16.5815,17 L7.4041679,17 C7.26484009,17 6.98863236,16.6619875 7.00036205,16.4995035 Z"
                                            id="Combined-Shape" fill="#000000" opacity="0.3" />
                                    </g>
                                </svg>
                            </span>
                            <span class="kt-grid-nav__title">Account</span>
                            <span class="kt-grid-nav__desc">Manage Subscriptions</span>
                        </a>
                    </div>
                    <div class="kt-grid-nav__row">
                        <a href="javascript:void(0)" class="kt-grid-nav__item"
                            (click)="removeQuickActions('/importdata')"
                            *ngxPermissionsOnly="['Permissions.Settings.ImportData']">
                            <span class="kt-grid-nav__icon">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="24px" height="24px" viewBox="0 0 24 24" version="1.1"
                                    class="kt-svg-icon kt-svg-icon--success kt-svg-icon--lg">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <rect id="bound" x="0" y="0" width="24" height="24" />
                                        <path
                                            d="M2,13 C2,12.5 2.5,12 3,12 C3.5,12 4,12.5 4,13 C4,13.3333333 4,15 4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 C2,15 2,13.3333333 2,13 Z"
                                            id="Path-57" fill="#000000" fill-rule="nonzero" opacity="0.3" />
                                        <rect id="Rectangle" fill="#000000" opacity="0.3" x="11" y="2" width="2"
                                            height="14" rx="1" />
                                        <path
                                            d="M12.0362375,3.37797611 L7.70710678,7.70710678 C7.31658249,8.09763107 6.68341751,8.09763107 6.29289322,7.70710678 C5.90236893,7.31658249 5.90236893,6.68341751 6.29289322,6.29289322 L11.2928932,1.29289322 C11.6689749,0.916811528 12.2736364,0.900910387 12.6689647,1.25670585 L17.6689647,5.75670585 C18.0794748,6.12616487 18.1127532,6.75845471 17.7432941,7.16896473 C17.3738351,7.57947475 16.7415453,7.61275317 16.3310353,7.24329415 L12.0362375,3.37797611 Z"
                                            id="Path-102" fill="#000000" fill-rule="nonzero" />
                                    </g>
                                </svg>
                            </span>
                            <span class="kt-grid-nav__title">Data Imports</span>
                            <span class="kt-grid-nav__desc">File Upload</span>
                        </a>
                        <!-- <a href="javascript:void(0)" class="kt-grid-nav__item"
                            (click)="removeQuickActions('/emailtemplates')"
                            *ngIf="trainerType != 3">
                            <span class="kt-grid-nav__icon">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="24px" height="24px" viewBox="0 0 24 24" version="1.1"
                                    class="kt-svg-icon kt-svg-icon--success kt-svg-icon--lg">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <rect id="bound" x="0" y="0" width="24" height="24" />
                                        <path
                                            d="M5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,17 C21,18.1045695 20.1045695,19 19,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,8 C3,6.8954305 3.8954305,6 5,6 Z M18.1444251,7.83964668 L12,11.1481833 L5.85557487,7.83964668 C5.4908718,7.6432681 5.03602525,7.77972206 4.83964668,8.14442513 C4.6432681,8.5091282 4.77972206,8.96397475 5.14442513,9.16035332 L11.6444251,12.6603533 C11.8664074,12.7798822 12.1335926,12.7798822 12.3555749,12.6603533 L18.8555749,9.16035332 C19.2202779,8.96397475 19.3567319,8.5091282 19.1603533,8.14442513 C18.9639747,7.77972206 18.5091282,7.6432681 18.1444251,7.83964668 Z"
                                            id="Combined-Shape" fill="#000000" />
                                    </g>
                                </svg>
                            </span>
                            <span class="kt-grid-nav__title">Email Templates</span>
                            <span class="kt-grid-nav__desc">Customize</span>
                        </a> -->
                        <a href="javascript:void(0)" class="kt-grid-nav__item"
                            (click)="removeQuickActions('/myapp/courses')">
                            <span class="kt-grid-nav__icon">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <rect id="bound" x="0" y="0" width="24" height="24"/>
                                        <path d="M2.56066017,10.6819805 L4.68198052,8.56066017 C5.26776695,7.97487373 6.21751442,7.97487373 6.80330086,8.56066017 L8.9246212,10.6819805 C9.51040764,11.267767 9.51040764,12.2175144 8.9246212,12.8033009 L6.80330086,14.9246212 C6.21751442,15.5104076 5.26776695,15.5104076 4.68198052,14.9246212 L2.56066017,12.8033009 C1.97487373,12.2175144 1.97487373,11.267767 2.56066017,10.6819805 Z M14.5606602,10.6819805 L16.6819805,8.56066017 C17.267767,7.97487373 18.2175144,7.97487373 18.8033009,8.56066017 L20.9246212,10.6819805 C21.5104076,11.267767 21.5104076,12.2175144 20.9246212,12.8033009 L18.8033009,14.9246212 C18.2175144,15.5104076 17.267767,15.5104076 16.6819805,14.9246212 L14.5606602,12.8033009 C13.9748737,12.2175144 13.9748737,11.267767 14.5606602,10.6819805 Z" id="Combined-Shape" fill="#000000" opacity="0.3"/>
                                        <path d="M8.56066017,16.6819805 L10.6819805,14.5606602 C11.267767,13.9748737 12.2175144,13.9748737 12.8033009,14.5606602 L14.9246212,16.6819805 C15.5104076,17.267767 15.5104076,18.2175144 14.9246212,18.8033009 L12.8033009,20.9246212 C12.2175144,21.5104076 11.267767,21.5104076 10.6819805,20.9246212 L8.56066017,18.8033009 C7.97487373,18.2175144 7.97487373,17.267767 8.56066017,16.6819805 Z M8.56066017,4.68198052 L10.6819805,2.56066017 C11.267767,1.97487373 12.2175144,1.97487373 12.8033009,2.56066017 L14.9246212,4.68198052 C15.5104076,5.26776695 15.5104076,6.21751442 14.9246212,6.80330086 L12.8033009,8.9246212 C12.2175144,9.51040764 11.267767,9.51040764 10.6819805,8.9246212 L8.56066017,6.80330086 C7.97487373,6.21751442 7.97487373,5.26776695 8.56066017,4.68198052 Z" id="Combined-Shape" fill="#000000"/>
                                    </g>
                                </svg>
                            </span>
                            <span class="kt-grid-nav__title">Courses</span>
                            <span class="kt-grid-nav__desc">Manage Courses</span>
                        </a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<!--end: Quick actions -->
<style>
    #divEJQuickActions>.dropdown-toggle::after {
        display: none;
    }
</style>
<ng-template #quickActionsContent>
    <p class="text-dark-75 font-weight-bolder font-size-h5 m-0"> Easily access your Training Institute profile, account details with
        help of <b>eduJournal</b> quick actions.
    </p>
</ng-template>

<ng-template #takeTourContent>
    <p class="text-dark-75 font-weight-bolder font-size-h5 m-0"> You are ready to start using the application, you can
        start tour anytime from here.
    </p>
</ng-template>