import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MenuConfigService } from '../services/menu-config.service';
import { HttpClient } from '@angular/common/http';
import { constants } from 'src/helpers/constants';
import { map } from 'rxjs/operators';

@Injectable()
export class MenuAsideService {
	classes: string;
	menuList$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
	apiUrl: string = constants.url_constants.api;

	constructor(
		private menuConfigService: MenuConfigService,
		private http: HttpClient
	) {
		//this.loadMenu();
	}

	// async getMenu() {
	// 	//const menuItems: any[];
	// 	await this.getMenuItems().then(data => {
	// 		// this.menuList$.next(data);
	// 		return data;
	// 	});

	// 	// return menuItems;
	// }

	async getMenu(): Promise<any> {
		return await this.http.get(this.apiUrl + '/api/LayoutApi/GetMenu')
			.pipe(map((res: any) => { return res })).toPromise();
	}


	async loadMenu() {
		const menuItems: any[] = await this.menuConfigService.loadMenuByProfileRole("Super Admin").then(data => {
			return data;
		});
		this.menuList$.next(menuItems);
		return menuItems;
	}



	// async getPermissions(username: string, insurerCode: string): Promise<Observable<Permission[]>> {
	// 	const permissions = await this.apiPermissionService.permissionsapi(username, insurerCode).then(data => {
	// 		return data;
	// 	});
	// 	this.permissionsService.loadPermissions(await permissions)
	// 	console.log(permissions)
	// 	return of(permissions);

	// }

	async getStudentMenu(): Promise<any> {
		return await this.http.get(this.apiUrl + '/api/LayoutApi/GetStudentMenu')
			.pipe(map((res: any) => { return res })).toPromise();
	}
}
