import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ej-blockui-template',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./block-ui.scss'],
  template:
    `<div class="blockUI blockMsg blockElement block-ui-template">
          <div class="blockui mx-auto"><span>Please wait...</span><span>
            <div class="kt-spinner kt-spinner--v2 kt-spinner--success"></div>
          </span></div>
        </div>`
})
export class BlockuiTemplateComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
}
