// Angular
import { AfterViewInit, Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import * as $ from 'jquery';
import { DeviceDetectorService } from 'ngx-device-detector';
import { JoyrideService } from 'ngx-joyride';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedService } from '../../../../ej-shared/services/shared.service'
import { PermissionService } from 'src/ej-shared/services/permission.service';
import { GlobalService } from 'src/ej-shared/services/global.service';
import { NavigatorService } from 'src/ej-shared/services/navigator.service';
import { StorageService } from 'src/ej-shared/services/storage.service';
@Component({
	selector: 'ej-quick-action',
	templateUrl: './quick-action.component.html',
})
export class QuickActionComponent implements OnInit, AfterViewInit {
	trainerType: any;
	
	// Public properties

	// Set icon class name
	@Input() icon: string = 'flaticon2-gear';

	@Input() iconType: '' | 'warning';

	// Set true to icon as SVG or false as icon class
	@Input() useSVG: boolean;

	// Set bg image path
	@Input() bgImage: string;

	// Set skin color, default to light
	@Input() skin: 'light' | 'dark' = 'light';

	@Input() gridNavSkin: 'light' | 'dark' = 'light';

	/**
	 * Component constructor
	 */
	constructor(private readonly joyrideService: JoyrideService,
		private readonly deviceService: DeviceDetectorService,
		private router: Router,
		private readonly sharedService: SharedService,
		private readonly permissionService: PermissionService,
		private globalService: GlobalService,
		private readonly navigatorService: NavigatorService,
		private readonly storageService: StorageService,
		private cdRef: ChangeDetectorRef) {
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * After view init
	 */
	ngAfterViewInit(): void {
	}

	/**
	 * On init
	 */
	ngOnInit(): void {
		this.trainerType = this.storageService.getTrainerType();
		this.cdRef.detectChanges();
	}

	onSVGInserted(svg) {
		svg.classList.add('kt-svg-icon', 'kt-svg-icon--success', 'kt-svg-icon--lg');
	}

	removeQuickActions(url) {
		$('#divQuickActions').removeClass('show');
		this.navigatorService.navigate(url, false);

	}

	starttour() {
		if (this.deviceService.isMobile()) {
			this.joyrideService.startTour(
				{
					steps: ['CoursesMobile', 'StudentsMobile', 'FeesPaymentsMobile', 'UsersMobile'],
					themeColor: '#63b76a'
				}
			);
		} else {
			this.joyrideService.startTour(
				{
					steps: ['Courses', 'Students', 'FeesPayments', 'Profile', 'Settings', 'QuickActions', 'Trainers', 'Users', 'Enquiries', 'Dashboard', 'StartTour'],
					themeColor: '#63b76a',
					waitingTime: 50
				}
			);
		}
	}

	navigateToTrainerProfile() {
		this.router.navigate(['myapp/trainerprofile']);
	}
}
