<!-- <div *ngIf="isTenant && !subscriptionExpiryInfo?.IsAccountExpired && !secondaryInitialSetupCompleted" id="kt_header_menu" class="kt-header-menu kt-header-menu-mobile  kt-header-menu--layout-default ">
    <ul class="kt-menu__nav ">
        <li class="kt-menu__item  kt-menu__item--open kt-menu__item--here kt-menu__item--submenu kt-menu__item--rel kt-menu__item--open kt-menu__item--here kt-menu__item--active"
            data-ktmenu-submenu-toggle="click" aria-haspopup="true">
            <a href="gettingstarted" class="kt-menu__link kt-menu__toggle">
                <span class="kt-menu__link-text">Getting Started</span>
                <i class="kt-menu__ver-arrow la la-angle-right"></i></a>
        </li>
    </ul>
</div> -->
<div class="kt-header-menu-wrapper" id="kt_header_menu_wrapper" style="opacity: 1;">
    <div class="kt-header-logo">
        <a href="javascript:;" *ngIf="(isTenant && subscriptionExpiryInfo?.IsAccountExpired) || isMember">
            <img alt="Logo" src="../assets/media/logos/ej_logo.png">
        </a>
    </div>
</div>