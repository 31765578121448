<head>
    <link href="../../../assets//css/error/error-3.css" rel="stylesheet" type="text/css" />
</head>
<div class="kt-grid kt-grid--hor kt-grid--root">
    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
        <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
            <div class="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-padding-0" id="kt_content">
                <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid kt-padding-l-70 kt-padding-r-70">
                    <div class="row row-no-padding row-col-separator-md justify-content-center">
                        <div class="kt-grid__item kt-grid__item--fluid kt-grid  kt-error-v3">
                            <div class="kt-error_container">
                                <span class="kt-error_number">
					<h1>404</h1>
				</span>
                                <p class="kt-error_title kt--font-metal">
                                    How did you get here?
                                </p>
                                <p class="kt-error_subtitle">
                                    Sorry we can't seem to find the page you're looking for.
                                </p>
                                <p class="kt-error_description">
                                    There may be a misspelling in the URL entered, or the page you are looking for may no longer exist.
                                </p>
                                <p class="kt-error_description">
                                    <a href="javascript:void(0);" (click)="goBack()" class="btn btn-secondary kt-btn kt-btn--icon">
                                        <span>
							<i class="la la-arrow-left"></i>
							<span>Go Back</span>
                                        </span>
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>