import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { constants } from 'src/helpers/constants';
import { SchoolProfile } from './school-profile.model'
import { HttpHeaders } from '@angular/common/http'

@Injectable({
    providedIn: 'root'
})

export class GettingStartedService {
    constructor(private http: HttpClient) { }
    async getSchoolProfileViewModel(): Promise<any> {
        return await this.http.get(constants.url_constants.api + '/api/SchoolProfileApi/GetSchoolProfileViewModel').toPromise();
    }
    async saveSchoolProfileDetails(schoolProfile: SchoolProfile): Promise<any> {
        return await this.http.post(constants.url_constants.api + '/api/SchoolProfileApi/SaveSchoolProfileDetails', schoolProfile).toPromise();
    }
    async uploadSchoolLogo(file: any): Promise<any> {
        return await this.http.post(constants.url_constants.api + '/api/SchoolProfileApi/UploadSchoolProfile', file).toPromise();
    }
    async getIP(): Promise<any> {
        return await this.http.get('https://api.ipify.org?format=json').toPromise();
    }

    async getTenantLoggedInCountry(): Promise<any> {
        var ip = "";
        await this.getIP().then(res =>
            ip = res.ip);
        return await this.http.get(constants.url_constants.api + '/api/TenantsApi/GetTenantLoggedinCountryDetails?ipAddress=' + ip).toPromise();
    }
    async getTimeZonesByCountryCode(countryCode): Promise<any> {
        return await this.http.get(constants.url_constants.api + '/api/TrainingProgramApi/GetTimeZonesByCountryCode/' + countryCode).toPromise();
    }
    async getAllTimeZones(): Promise<any> {
        return await this.http.get(constants.url_constants.api + '/api/TrainingProgramApi/GetAllTimeZones').toPromise();
    }

    async uploadSchoolIncorporationCertificateDocuments(file: any, tenantId = "00000000-0000-0000-0000-000000000000"): Promise<any> {
        return await this.http.post(constants.url_constants.api + '/api/SchoolProfileApi/UploadSchoolIncorporationCertificateDocuments/' + tenantId, file).toPromise();
    }
}