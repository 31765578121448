import * as tslib_1 from "tslib";
import { constants } from 'src/helpers/constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class StudentRegistrationformService {
    constructor(http) {
        this.http = http;
    }
    getRegistrationForms() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(constants.url_constants.api + '/api/CustomForm/GetRegistrationForms').toPromise();
        });
    }
    saveRegistrationForm(studentRegistrationform) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.post(constants.url_constants.api + '/api/CustomForm/SaveRegistrationForm', studentRegistrationform).toPromise();
        });
    }
}
StudentRegistrationformService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StudentRegistrationformService_Factory() { return new StudentRegistrationformService(i0.ɵɵinject(i1.HttpClient)); }, token: StudentRegistrationformService, providedIn: "root" });
