export class MenuConfig {
	public defaults: any = {
	menu_items: {
			self: {},
			items: [
				{
					title: 'Dashboard',
					root: true,
					icon: 'fa fa-clinic-medical',
					page: '/dashboard',
					translate: 'MENU.DASHBOARD',
					bullet: 'dot',
				},
				{
					title: 'Tenants',
					root: true,
					icon: 'fa fa-clinic-medical',
					page: '/tenant',
					translate: 'MENU.DASHBOARD',
					bullet: 'dot',
				},
				{section: 'Settings'},
				{
					title: 'Roles',
					root: true,
					bullet:'dot',
					icon:'fa fa-building',
					submenu: [
						{
							title: 'Roles',
							page: ''
						},
						{
							title: 'Map Roles',
							page: '',
						}
					]
				},
			]
		}
	};

	public get configs(): any {
		return this.defaults;
	}
}