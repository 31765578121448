import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { constants } from 'src/helpers/constants';

@Injectable({
    providedIn: 'root'
})

export class CourseApprovalService {

    constructor(private http: HttpClient) { }

    async getCourseApprovalStatusCount(tenantId = "00000000-0000-0000-0000-000000000000"): Promise<any> {
        return await this.http.get(constants.url_constants.api + '/api/TrainingProgramApi/GetCourseApprovalStatusCount/' + tenantId).toPromise();
    }

    async saveCourseApproval(courseApprovalPostModel): Promise<any> {
        return await this.http.post(constants.url_constants.api + '/api/TrainingProgramApi/SaveCourseApproval', courseApprovalPostModel).toPromise();
    }

    async searchCourseApprovals(courseApprovalFilter: any, tenantId = "00000000-0000-0000-0000-000000000000"): Promise<any> {
        return await this.http.post(constants.url_constants.api + '/api/TrainingProgramApi/SearchCourseApprovals/' + tenantId, courseApprovalFilter).toPromise();
    }

    async getTraningProgramCategories(): Promise<any> {
        return await this.http.get(constants.url_constants.api + '/api/TrainingProgramApi/GetTraningProgramCategories').toPromise();
    }

    async getCourseApprovals(courseId, tenantId, isTenant = false): Promise<any> {
        return await this.http.get(constants.url_constants.api + '/api/TrainingProgramApi/GetCourseApprovals/' + courseId + '/' + tenantId + '/' + isTenant).toPromise();
    }

    async SaveCourseContentToTrainingProgram(CourseContentMappingFilter:any): Promise<any> {
        return await this.http.post(constants.url_constants.api + '/api/CourseContentMapping/SaveCourseContentToTrainingProgram/',CourseContentMappingFilter).toPromise();
    }

    async GetCourseContentIdList(CourseId:any): Promise<any> {
        return await this.http.get(constants.url_constants.api + '/api/CourseContentMapping/GetCourseContentIdList/'+CourseId).toPromise();
    }

}