/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./layout/base/base.component.ngfactory";
import * as i4 from "./layout/base/base.component";
import * as i5 from "./layout/services/layout-config.service";
import * as i6 from "./layout/services/menu-config.service";
import * as i7 from "./layout/html-class.service";
import * as i8 from "../idsvr/auth.service";
import * as i9 from "../ej-shared/services/shared.service";
import * as i10 from "../ej-shared/services/loader.service";
import * as i11 from "../ej-shared/services/global.service";
import * as i12 from "./layout/session-timeout/session-timeout.component.ngfactory";
import * as i13 from "./layout/session-timeout/session-timeout.component";
import * as i14 from "@ng-idle/core";
import * as i15 from "@ng-idle/keepalive";
import * as i16 from "./utility/components/intercom/intercom.component.ngfactory";
import * as i17 from "./utility/components/intercom/intercom.component";
import * as i18 from "../ej-shared/services/storage.service";
import * as i19 from "./app.component";
import * as i20 from "@angular/upgrade/static";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "ej-base", [], null, null, null, i3.View_BaseComponent_0, i3.RenderType_BaseComponent)), i1.ɵdid(3, 245760, null, 0, i4.BaseComponent, [i5.LayoutConfigService, i6.MenuConfigService, i7.HtmlClassService, i8.AuthService, i2.Router, i9.SharedService, i1.ChangeDetectorRef, i10.LoaderService, i11.GlobalService], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 0, "a", [["id", "btnNavigate"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "ej-session-timeout", [], null, null, null, i12.View_SessionTimeoutComponent_0, i12.RenderType_SessionTimeoutComponent)), i1.ɵdid(6, 114688, null, 0, i13.SessionTimeoutComponent, [i14.Idle, i15.Keepalive, i8.AuthService, i1.ElementRef, i11.GlobalService, i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "ej-intercom", [], null, null, null, i16.View_IntercomComponent_0, i16.RenderType_IntercomComponent)), i1.ɵdid(8, 114688, null, 0, i17.IntercomComponent, [i18.StorageService, i11.GlobalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 3, 0); _ck(_v, 6, 0); _ck(_v, 8, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i19.AppComponent, [i20.UpgradeModule, i2.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i19.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
