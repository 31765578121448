import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { constants } from 'src/helpers/constants';

@Injectable({
    providedIn: 'root'
})

export class TenantHomeService {
    constructor(private http: HttpClient) { }
    async getHomeViewModel(): Promise<any> {
        return await this.http.get(constants.url_constants.api + '/api/TenantsApi/GetHomeViewModel').toPromise();
    }
    async getCourseEnquiryModel(id): Promise<any> {
        return await this.http.get(constants.url_constants.api + '/api/TenantsApi/GetCourseEnquiryModel?courseId=' + id).toPromise();
    }
    async saveEnquiry(enquiryModel): Promise<any> {
        return await this.http.post(constants.url_constants.api + '/api/EnquiryApi/SaveEnquiry', enquiryModel).toPromise();
    }
}