/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./block-ui.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./block-ui-template.component";
var styles_BlockuiTemplateComponent = [i0.styles];
var RenderType_BlockuiTemplateComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_BlockuiTemplateComponent, data: {} });
export { RenderType_BlockuiTemplateComponent as RenderType_BlockuiTemplateComponent };
export function View_BlockuiTemplateComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "blockUI blockMsg blockElement block-ui-template"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "blockui mx-auto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please wait..."])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "kt-spinner kt-spinner--v2 kt-spinner--success"]], null, null, null, null, null))], null, null); }
export function View_BlockuiTemplateComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ej-blockui-template", [], null, null, null, View_BlockuiTemplateComponent_0, RenderType_BlockuiTemplateComponent)), i1.ɵdid(1, 114688, null, 0, i2.BlockuiTemplateComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BlockuiTemplateComponentNgFactory = i1.ɵccf("ej-blockui-template", i2.BlockuiTemplateComponent, View_BlockuiTemplateComponent_Host_0, {}, {}, []);
export { BlockuiTemplateComponentNgFactory as BlockuiTemplateComponentNgFactory };
