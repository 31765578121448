import * as tslib_1 from "tslib";
import { HttpClient } from "@angular/common/http";
import { UserManager } from "oidc-client";
import { downgradeInjectable } from '@angular/upgrade/static';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../ej-shared/services/storage.service";
import * as i3 from "../ej-shared/services/permission.service";
import * as i4 from "../ej-shared/services/global.service";
import * as i5 from "@angular/common/http";
import * as i6 from "../ej-shared/services/tenant-init.service";
import * as i7 from "../ej-shared/services/shared.service";
import * as i8 from "../ej-shared/services/navigator.service";
import * as i9 from "../ej-shared/services/calendar.service";
import * as i10 from "../ej-ts/admin-portal/tenant-registration/tenant-registration.service";
import * as i11 from "ngx-permissions";
import { constants } from '../helpers/constants';
export class AuthService {
    constructor(router, storageService, permissionService, globalService, http, tenantInitService, sharedService, navigatorService, calendarService, tenantRegistrationService, permissionsService) {
        this.router = router;
        this.storageService = storageService;
        this.permissionService = permissionService;
        this.globalService = globalService;
        this.http = http;
        this.tenantInitService = tenantInitService;
        this.sharedService = sharedService;
        this.navigatorService = navigatorService;
        this.calendarService = calendarService;
        this.tenantRegistrationService = tenantRegistrationService;
        this.permissionsService = permissionsService;
        this.isLogged = false;
        this.currentUserInfo = new currentUserInfo();
        this.tenantId = this.storageService.getTenantId();
        this.ngOnInit();
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (window.location.pathname.includes('login') || window.location.pathname.includes('auth-callback') || window.location.pathname.includes('signin')) {
                this.sharedService.sendShowSetupEvent(true);
            }
            if (this.manager == undefined) {
                if (environment.commonLoginUrl == constants.host_url.replace('/client', '').toLowerCase()) {
                    this.manager = new UserManager(getClientSettings('00000000-0000-0000-0000-000000000000'));
                }
                else if (!this.sharedService.isMember() && constants.admin_url.replace('/client', '').toLowerCase() != constants.host_url.replace('/client', '').toLowerCase() && !this.sharedService.isRedirectUrl()) {
                    if (this.tenantId != null && this.tenantId != undefined)
                        this.manager = new UserManager(getClientSettings(this.tenantId));
                    yield this.GetAndSetTenantDetails();
                    let result = yield this.isLoggedin();
                    this.globalService.setIsLoggedIn(result);
                    let otac = '';
                    if (constants.admin_url.toLowerCase() != constants.host_url.toLowerCase()) {
                        if (window.location.pathname.includes('login')) {
                            otac = window.location.pathname;
                            otac = otac.replace("/login", '');
                            otac = otac.replace("/", '');
                            this.storageService.setOTAC(otac);
                        }
                        else {
                            this.storageService.setOTAC("");
                        }
                    }
                }
                else {
                    this.manager = new UserManager(getClientSettings('00000000-0000-0000-0000-000000000000'));
                }
            }
        });
    }
    GetAndSetTenantDetails() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.getTenantDetail().then((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                this.tenantDetail = res;
                if (res.tenant.id == "00000000-0000-0000-0000-000000000000") {
                    this.router.navigate(['/tenantnotfound']);
                    //this.navigatorService.navigate('/tenantnotfound', false);
                }
                else {
                    this.storageService.setTenantId(res.tenant.id);
                    //this.storageService.setTenantLogo(res.tenantLogoPath);
                    this.storageService.setTrainerType(res.trainerType);
                    this.storageService.setTenantName(res.tenant.firstName);
                    this.tenantId = res.tenant.id;
                    this.manager = new UserManager(getClientSettings(this.tenantId));
                    yield this.tenantInitService.checkPrimaryInitialSetupStatus();
                    if (res.trainerType == 1 || res.trainerType == 3) {
                        this.tenantInitService.checkTrainerPrimaryInitialSetupStatus();
                    }
                }
            }));
        });
    }
    login() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            var isSignInTokenUser = this.storageService.getIsSignInTokenUser();
            if (isSignInTokenUser != null && isSignInTokenUser.toString() == 'true') {
                this.completeAuthenticationSignIn(null);
            }
            else {
                if (this.manager == undefined) {
                    if (environment.commonLoginUrl == constants.host_url.replace('/client', '').toLowerCase()) {
                        this.manager = new UserManager(getClientSettings('00000000-0000-0000-0000-000000000000'));
                    }
                    else if (!this.sharedService.isMember() && constants.admin_url.replace('/client', '').toLowerCase() != constants.host_url.replace('/client', '').toLowerCase() && !this.sharedService.isRedirectUrl()) {
                        if (this.tenantId != null && this.tenantId != undefined)
                            this.manager = new UserManager(getClientSettings(this.tenantId));
                        yield this.GetAndSetTenantDetails();
                        this.globalService.setIsLoggedIn(true);
                        yield this.getCountryCodes().then(res => {
                            this.storageService.setCountryCodes(res);
                        });
                        yield this.globalService.setPhoneCountries();
                        yield this.globalService.setCurrency(null);
                        yield this.globalService.setInternationalCurrency(null);
                        return this.manager.signinRedirect();
                    }
                    else {
                        this.manager = new UserManager(getClientSettings('00000000-0000-0000-0000-000000000000'));
                        return this.manager.signinRedirect();
                    }
                }
                else {
                    return this.manager.signinRedirect();
                }
            }
        });
    }
    completeAuthentication() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.manager.signinRedirectCallback().then((user) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                this.storageService.setCurrentUser(user);
                this.isLogged = true;
                this.globalService.setIsLoggedIn(true);
                this.storageService.setIsSignInTokenUser(false);
                yield this.getCountryCodes().then(res => {
                    this.storageService.setCountryCodes(res);
                });
                yield this.globalService.setPhoneCountries();
                yield this.globalService.setCurrency(null);
                yield this.globalService.setInternationalCurrency(null);
                this.goToApplication();
            }));
        });
    }
    signinSilentCallback() {
        this.manager.signinSilentCallback()
            .catch((err) => {
            console.log(err);
        });
    }
    isAuthenticated() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let result = yield this.isLoggedin();
            if (!result) {
                this.storageService.clearCurrentUser();
                this.login();
            }
            return result;
        });
    }
    isLoggedin() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            var isUserLoggedin = false;
            let user = this.storageService.getCurrentUser();
            if (JSON.parse(user)) {
                let currentUser = JSON.parse(user);
                var sub = currentUser.profile.sub;
                var expires_at = currentUser.expires_at;
                var expiry_date = new Date(expires_at * 1000);
                isUserLoggedin = (sub && expiry_date > new Date());
            }
            if (isUserLoggedin && !this.isLogged) {
                yield this.getPermissions();
                this.isLogged = true;
                if (this.tenantId != null && this.tenantId != undefined) {
                    this.globalService.setIsTenant(true);
                    yield this.tenantInitService.setTenantInit();
                }
            }
            return isUserLoggedin;
        });
    }
    isUserLoggedin() {
        var isUserLoggedin = false;
        let user = this.storageService.getCurrentUser();
        if (JSON.parse(user)) {
            let currentUser = JSON.parse(user);
            var sub = currentUser.profile.sub;
            var expires_at = currentUser.expires_at;
            var expiry_date = new Date(expires_at * 1000);
            isUserLoggedin = (sub && expiry_date > new Date());
        }
        return isUserLoggedin;
    }
    getPermissions() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            //Get User Permissions
            if (!this.sharedService.isMember()) {
                yield this.permissionService.GetUserPermissionById().then(response => {
                    this.globalService.setUserPermissionData(response.item1);
                    this.globalService.setLoggedUser(response.item2);
                    this.permissionsService.loadPermissions(response.item1);
                });
            }
            else {
                //load member permissions
            }
        });
    }
    signout() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let user = JSON.parse(this.storageService.getCurrentUser());
            this.storageService.clearCurrentUser();
            this.globalService.setIsLoggedIn(false);
            if (user) {
                yield this.manager.signoutRedirect({ "id_token_hint": user.id_token, "post_logout_redirect_uri": encodeURI(ClientSettings.post_logout_redirect_uri) });
            }
            else {
                yield this.manager.signoutRedirect({ "post_logout_redirect_uri": encodeURI(ClientSettings.post_logout_redirect_uri) });
            }
        });
    }
    goToApplication() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.sharedService.isMember() && this.tenantId != '00000000-0000-0000-0000-000000000000' && this.tenantId != null && this.tenantId != undefined) {
                this.globalService.setIsTenant(true);
                yield this.goToTenantApp();
            }
            else {
                this.globalService.setIsTenant(false);
                if (this.sharedService.isMember()) {
                    this.router.navigate(['/member/events']);
                }
                if (this.sharedService.isAdmin()) {
                    // Check if admindashboard permission available
                    // Else goto first url in the menu
                    this.globalService.userPermissionDataChange.subscribe((value) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                        if (value.includes("Permissions.Dashboard"))
                            this.router.navigate(['/admindashboard']);
                        else
                            yield this.goToFirstMenuScreen();
                    }));
                }
                this.sharedService.sendShowSetupEvent(false);
            }
        });
    }
    goToFirstMenuScreen() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            var firstMenuUrl = '';
            var menu = this.globalService.menuData;
            if (menu == undefined || menu == null || menu.length == 0) {
                yield this.getMenu().then(res => {
                    firstMenuUrl = this.getFirstMenuUrl(res);
                    this.router.navigate([firstMenuUrl]);
                });
            }
            else {
                firstMenuUrl = this.getFirstMenuUrl(menu);
                this.router.navigate([firstMenuUrl]);
            }
        });
    }
    getFirstMenuUrl(menu) {
        var firstMenuUrl = '';
        if (menu != undefined && menu != null && menu.length > 0) {
            if (menu[0].items != null && menu[0].items.length > 0) {
                firstMenuUrl = menu[0].items[0].url;
            }
            else {
                firstMenuUrl = menu[0].url;
            }
        }
        return firstMenuUrl;
    }
    goToTenantApp() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.tenantInitService.getTenantSubscriptionExpiryInfo().then((response) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (response.IsAccountExpired) {
                    this.gotoTenantExpired();
                    this.sharedService.sendShowSetupEvent(false);
                }
                else {
                    if (this.globalService.getPrimaryInitialSetupCompleted()) {
                        if (this.storageService.getTrainerType() == 3) {
                            this.navigatorService.navigate('myapp/courses', this.storageService.getIsSignInTokenUser());
                        }
                        else {
                            // Check if dashboard permission available
                            // Else goto first url in the menu
                            var permissionList = this.globalService.getUserPermissionData();
                            if (permissionList != null && permissionList != undefined && permissionList.length > 0) {
                                if (permissionList.indexOf("Permissions.Dashboard") != -1) {
                                    //this.navigatorService.navigate('/dashboard', this.storageService.getIsSignInTokenUser());
                                    this.router.navigate(['/dashboard']);
                                }
                                else {
                                    yield this.goToFirstMenuScreen();
                                }
                            }
                            else {
                                this.globalService.userPermissionDataChange.subscribe((value) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                                    if (value.includes("Permissions.Dashboard"))
                                        //this.navigatorService.navigate('/dashboard', this.storageService.getIsSignInTokenUser());
                                        this.router.navigate(['/dashboard']);
                                    else
                                        yield this.goToFirstMenuScreen();
                                }));
                            }
                        }
                        this.sharedService.sendShowSetupEvent(false);
                    }
                    else {
                        yield this.getTenantCourseSetupAssistance(this.tenantId).then((resp) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                            if (resp.id == '00000000-0000-0000-0000-000000000000') {
                                this.navigatorService.navigate('myapp/welcome', false);
                                this.sharedService.sendShowSetupEvent(false);
                            }
                            else {
                                yield this.gotoTenantGettingStarted();
                            }
                        }));
                    }
                    this.globalService.setIsTenant(true);
                }
            }));
        });
    }
    gotoTenantGettingStarted() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.getTenantDetail().then((res) => {
                this.tenantDetail = res;
                this.storageService.setTenantId(res.tenant.id);
                this.storageService.setTrainerType(res.trainerType);
                this.storageService.setTenantName(res.tenant.firstName);
                if (res.trainerType == 1 || res.trainerType == 3) {
                    this.navigatorService.navigate('myapp/createcourse', false);
                    this.sharedService.sendShowSetupEvent(false);
                }
                else {
                    this.router.navigate(['/gettingstarted']);
                    //this.navigatorService.navigate('/gettingstarted', false);
                    this.sharedService.sendShowSetupEvent(false);
                }
            });
        });
    }
    gotoWebsite() {
        this.router.navigate(['/']);
    }
    getTenantDetail() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.tenantDetail != null && this.tenantDetail != undefined) {
                return this.tenantDetail;
            }
            else {
                return yield this.http.get(constants.url_constants.api + '/api/TenantResolverApi/GetTenantDetail').toPromise();
            }
        });
    }
    getTenantDetails() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(constants.url_constants.api + '/api/TenantResolverApi/GetTenantDetail').toPromise();
        });
    }
    getCountryCodes() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(constants.url_constants.api + '/api/LayoutApi/GetCountryCodes').toPromise();
        });
    }
    getTenantCourseSetupAssistance(tenantId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(constants.url_constants.api + '/api/TenantsApi/GetTenantCourseSetupAssistance/' + tenantId).toPromise();
        });
    }
    getMenu() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(constants.url_constants.api + '/api/LayoutApi/GetMenu').toPromise();
        });
    }
    gotoServerError() {
        this.router.navigate(['/servererror']);
    }
    gotoUnauthorized() {
        this.router.navigate(['/unauthorized']);
    }
    gotoTenantExpired() {
        this.router.navigate(['/subscriptionexpired']);
    }
    completeAuthenticationSignIn(loginres) {
        if (loginres) {
            this.currentUserInfo.access_token = loginres.tokenResponse.accessToken;
            this.currentUserInfo.token_type = loginres.tokenResponse.tokenType;
            this.currentUserInfo.expires_at = Math.round((new Date).getTime() / 1000) + parseInt(loginres.tokenResponse.expiresIn.toString());
            this.currentUserInfo.refresh_token = loginres.tokenResponse.refreshToken;
            this.storageService.setCurrentUser(this.currentUserInfo);
        }
        else {
            let cUser = this.storageService.getCurrentUser();
            if (JSON.parse(cUser)) {
                this.currentUserInfo = JSON.parse(cUser);
            }
        }
        this.signinUserinfo().then((user) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.currentUserInfo.profile = user;
            if (user) {
                this.storageService.setCurrentUser(this.currentUserInfo);
                yield this.GetAndSetTenantDetails();
                this.isLogged = false;
                yield this.isLoggedin();
                this.globalService.setIsLoggedIn(true);
                this.storageService.setIsSignInTokenUser(true);
                yield this.getCountryCodes().then(res => {
                    this.storageService.setCountryCodes(res);
                });
                yield this.globalService.setPhoneCountries();
                yield this.globalService.setCurrency(null);
                yield this.globalService.setInternationalCurrency(null);
                // Saving Last Login details for Sign-in flow
                // For Normal login flow, last login is being saved from auth-callback component
                yield this.getIP().then(res => {
                    if (res.ip) {
                        let apiReq = { 'Query': res.ip };
                        this.saveTenantLoginDetails(apiReq).then(res2 => { });
                    }
                });
                this.goToApplication();
            }
            else { }
        })).catch((error) => {
            console.info(error);
            return this.manager.signinRedirect();
        });
    }
    signinUserinfo() {
        return this.http.get(constants.url_constants.api + '/connect/userinfo').toPromise();
    }
    getIP() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get('https://api.ipify.org?format=json').toPromise();
        });
    }
    saveTenantLoginDetails(apiReq) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.post(constants.url_constants.api + '/api/TenantsApi/SaveTenantLastLogin', apiReq).toPromise();
        });
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.StorageService), i0.ɵɵinject(i3.PermissionService), i0.ɵɵinject(i4.GlobalService), i0.ɵɵinject(i5.HttpClient), i0.ɵɵinject(i6.TenantInitService), i0.ɵɵinject(i7.SharedService), i0.ɵɵinject(i8.NavigatorService), i0.ɵɵinject(i9.CalendarService), i0.ɵɵinject(i10.TenantRegistrationService), i0.ɵɵinject(i11.NgxPermissionsService)); }, token: AuthService, providedIn: "root" });
import { ClientSettings, environment } from '../environments/environment';
import * as Oidc from 'oidc-client';
import { currentUserInfo } from './model/login';
export function getClientSettings(tenantId) {
    let otac = '';
    if (constants.admin_url.replace('/client', '').toLowerCase() != constants.host_url.replace('/client', '').toLowerCase()) {
        if (window.location.pathname.includes('login')) {
            otac = window.location.pathname.replace("/client", '');
            otac = otac.replace("/login", '');
            otac = otac.replace("/", '');
        }
    }
    return {
        authority: ClientSettings.authority,
        client_id: ClientSettings.client_id,
        redirect_uri: ClientSettings.redirect_uri,
        post_logout_redirect_uri: ClientSettings.post_logout_redirect_uri,
        response_type: ClientSettings.response_type,
        scope: ClientSettings.scope,
        silent_redirect_uri: ClientSettings.silent_redirect_uri,
        automaticSilentRenew: ClientSettings.automaticSilentRenew,
        accessTokenExpiringNotificationTime: ClientSettings.accessTokenExpiringNotificationTime,
        silentRequestTimeout: ClientSettings.silentRequestTimeout,
        revokeAccessTokenOnSignout: ClientSettings.revokeAccessTokenOnSignout,
        filterProtocolClaims: ClientSettings.filterProtocolClaims,
        acr_values: "tenant:" + tenantId + " otac:" + otac,
        userStore: new Oidc.WebStorageStateStore({ store: window.localStorage })
    };
}
export function getSignInUserClientSettings() {
    let otac = '';
    if (window.location.pathname.includes('login')) {
        otac = window.location.pathname.replace("/client", '');
        otac = otac.replace("/login", '');
        otac = otac.replace("/", '');
    }
    return {
        clientId: ClientSettings.client_id,
        scope: ClientSettings.scope,
        otac: otac
    };
}
angular.module('core')
    .factory('authService', downgradeInjectable(AuthService));
