import { Component, OnInit, ChangeDetectorRef, ViewRef } from '@angular/core';
import { LayoutConfigService } from '../services/layout-config.service';
import { GlobalService } from 'src/ej-shared/services/global.service';

@Component({
	selector: 'ej-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
	today: number = Date.now();
	subscriptionExpiryInfo: any;
	constructor(
		private layoutConfigService: LayoutConfigService,
		private cdRef: ChangeDetectorRef,
		private globalService: GlobalService
	) {
		// Update this.subscriptionExpiryInfo on globalService.subscriptionExpiryInfo change
		this.subscriptionExpiryInfo = globalService.subscriptionExpiryInfo;
		this.globalService.subscriptionExpiryInfoChange.subscribe((value) => {
			this.subscriptionExpiryInfo = value;
			this.detectChanges();
		});
	}

	ngOnInit() {
		// const config = this.layoutConfigService.getConfig();
	}

	closeSubscriptionInfoDiv = function () {
		this.subscriptionExpiryInfo.ShowExpiryInfoDiv = false;
		this.detectChanges();
	}

	detectChanges() {
		if (this.cdRef && !(this.cdRef as ViewRef).destroyed) {
			this.cdRef.detectChanges();
		}
	}
}