import * as tslib_1 from "tslib";
import { constants } from '../../../helpers/constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class TenantRegistrationService {
    constructor(http) {
        this.http = http;
    }
    getAvailableEditions() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(constants.url_constants.api + '/api/EditionApi/GetAvailableEditions').toPromise();
        });
    }
    getEditionByID(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(constants.url_constants.api + '/api/EditionApi/GetEditionByID?id=' + id).toPromise();
        });
    }
    registerTenant(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.post(constants.url_constants.api + '/api/TenantRegistration/RegisterTenant', data).toPromise();
        });
    }
    tenantEmailOccured(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(constants.url_constants.api + '/api/TenantRegistration/TenantEmailOccured/' + data).toPromise();
        });
    }
    tenancyNameOccured(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(constants.url_constants.api + '/api/TenantRegistration/TenancyNameOccured/' + data).toPromise();
        });
    }
}
TenantRegistrationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TenantRegistrationService_Factory() { return new TenantRegistrationService(i0.ɵɵinject(i1.HttpClient)); }, token: TenantRegistrationService, providedIn: "root" });
