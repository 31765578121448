import * as tslib_1 from "tslib";
import { constants } from 'src/helpers/constants';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class TrainerGettingStartedService {
    constructor(http) {
        this.http = http;
    }
    getIP() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get('https://api.ipify.org?format=json').toPromise();
        });
    }
    getTenantLoggedInCountry() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            var ip = "";
            yield this.getIP().then(res => ip = res.ip);
            return yield this.http.get(constants.url_constants.api + '/api/TenantsApi/GetTenantLoggedinCountryDetails?ipAddress=' + ip).toPromise();
        });
    }
    saveTrainerProfileDetails(trainer) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.post(constants.url_constants.api + '/api/TrainerProfileApi/SaveTrainerProfile', trainer).toPromise();
        });
    }
}
TrainerGettingStartedService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TrainerGettingStartedService_Factory() { return new TrainerGettingStartedService(i0.ɵɵinject(i1.HttpClient)); }, token: TrainerGettingStartedService, providedIn: "root" });
angular.module('core')
    .factory('trainerGettingStartedService', downgradeInjectable(TrainerGettingStartedService));
