
export class SchoolProfile {
    constructor() {
        this.address = new Address();
    }
    id: string;
    schoolname: string;
    schoolTitle: string;
    schoolLogoRelativePath: string;
    schoolLogoName: string;
    address: Address;
    URL: string;
    phone: string;
    email: string;
    requiredSampleData: boolean;
    activityCode: string;
    countryTimeZoneId: string;
    currencyCode: string;
    facebookURL: string;
    twitterURL: string;
    linkedInURL: string;
    otherSocialMediaURL: string;
    tenantPrimaryContactName: string;
    tenantPrimaryContactPhone: string;
    registrationnumber: string;
    incorporationCertificateDocuments: any;
    internationalCurrencyCode: string;
}

export class Address {
    constructor() {
        this.id = "00000000-0000-0000-0000-000000000000";
    }
    id: string;
    addressline1: string;
    addressline2: string;
    city: string;
    state: string;
    country: string;
    postalcode: number;
}

export class FileTransferDTO {
    fileName: string;
    fileDataBase64: any;
    contentType: string;
}