import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { constants } from '../../../helpers/constants';

@Injectable({
  providedIn: 'root'
})
export class TenantRegistrationService {

  constructor(private http: HttpClient) { }

  async getAvailableEditions(): Promise<any> {
    return await this.http.get(constants.url_constants.api + '/api/EditionApi/GetAvailableEditions').toPromise();
  }
  async getEditionByID(id): Promise<any> {
    return await this.http.get(constants.url_constants.api + '/api/EditionApi/GetEditionByID?id=' + id).toPromise();
  }
  async registerTenant(data): Promise<any> {
    return await this.http.post(constants.url_constants.api + '/api/TenantRegistration/RegisterTenant', data).toPromise();
  }
  async tenantEmailOccured(data): Promise<any> {
    return await this.http.get(constants.url_constants.api + '/api/TenantRegistration/TenantEmailOccured/' + data).toPromise();
  }
  async tenancyNameOccured(data): Promise<any> {
    return await this.http.get(constants.url_constants.api + '/api/TenantRegistration/TenancyNameOccured/' + data).toPromise();
  }
}
