import { Pipe, PipeTransform } from '@angular/core';
import { GlobalService } from '../../../ej-shared/services/global.service'

@Pipe({
  name: 'ejCurrencyFormat'
})
export class CurrencyFormatPipe implements PipeTransform {

  constructor(private readonly globalService: GlobalService) {

  }

  transform(value: any, formatPrice: boolean = false): any {
    if (formatPrice) {
      if (value == 0)
        return "Free";
    }
    var currencycode = this.globalService.getCurrencyCode();
    if (parseFloat(value).toString() != "NaN")
      return currencycode + ' ' + parseFloat(value).toFixed(2);
    else
      return currencycode;
  }
}
