<!DOCTYPE html>
<!--
Template Name: Metronic - Responsive Admin Dashboard Template build with Twitter Bootstrap 4
Author: KeenThemes
Website: http://www.keenthemes.com/
Contact: support@keenthemes.com
Follow: www.twitter.com/keenthemes
Dribbble: www.dribbble.com/keenthemes
Like: www.facebook.com/keenthemes
Purchase: http://themeforest.net/item/metronic-responsive-admin-dashboard-template/4021469?ref=keenthemes
Renew Support: http://themeforest.net/item/metronic-responsive-admin-dashboard-template/4021469?ref=keenthemes
License: You must have a valid license purchased only from themeforest(the above link) in order to legally use the theme for your project.
-->
<html lang="en">
<!-- begin::Head -->

<head>
    <meta charset="utf-8" />
    <title>
        Site Unavailable | eduJournal
    </title>
    <meta name="description" content="Base form control examples">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    <!--begin::Web font -->
    <script src="https://ajax.googleapis.com/ajax/libs/webfont/1.6.16/webfont.js"></script>
    <script>
        WebFont.load({
            google: {
                "families": ["Poppins:300,400,500,600,700", "Roboto:300,400,500,600,700"]
            },
            active: function() {
                sessionStorage.fonts = true;
            }
        });
    </script>
    <!--end::Web font -->
    <!--begin::Base Styles -->
    <!--end::Base Styles -->
</head>
<!-- end::Head -->
<!-- end::Body -->

<body class="kt-page--fluid  kt-skin- kt-page--loading-enabled kt-page--loading kt-content--skin-light2 kt-header--fixed kt-header--fixed-mobile kt-aside-left--enabled kt-aside-left--skin-light kt-aside-left--fixed kt-aside-left--offcanvas kt-aside-right--enabled kt-footer--fixed kt-footer--push kt-aside--offcanvas-default">
    <!-- begin::Page loader -->
    <div class="kt-grid kt-grid--hor kt-grid--root kt-page">
        <div class="kt-grid__item kt-grid__item--fluid kt-grid  kt-error-3">
            <div class="kt-error_container">
                <div class="row justify-content-center kt-margin-t-40">
                    <div class="col-lg-10">
                        <div class="kt-portlet kt-portlet--rounded">
                            <div class="kt-portlet__body kt-padding-t-5">
                                <div class="kt-invoice-2">
                                    <div class="kt-invoice__wrapper">
                                        <div class="kt-invoice__head">
                                            <div class="kt-invoice__container">
                                                <div class="row justify-content-center ">
                                                    <div class="col-lg-12 kt-align-center  kt-margin-t-20">
                                                        <a>
                                                            <img src="../../../assets//media//img//default/logo_default_light.png">
                                                        </a>
                                                    </div>
                                                </div>
                                                <div class="kt-separator"></div>
                                                <div class="row justify-content-center">
                                                    <div class="col-lg-12  kt-align-center">
                                                        <i class="la la-binoculars display-1"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="kt-invoice__body kt-invoice__body--centered">
                                            <div class="row  kt-align-center">
                                                <div class="col-lg-12">
                                                    <h3><span>{{ tenantUrl }}</span> does not exist!</h3>
                                                    <p class="lead">
                                                        Register now to claim it
                                                    </p>
                                                    <span>In order to start using eduJournal, click the below button</span>
                                                </div>
                                            </div>
                                            <div class="row  kt-align-center">
                                                <div class="col-lg-12">
                                                    <br>
                                                    <a href="{{ registerTenantUrl }}" class="btn btn-primary">Register</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</body>
<!-- end::Body -->

</html>