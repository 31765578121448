import { HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../ej-shared/services/loader.service";
/**
 * This interceptor automatically adds the token header needed by our backend API if such token is present
 * in the current state of the application.
 */
export class HttpInterceptorService {
    constructor(loaderService) {
        this.loaderService = loaderService;
    }
    intercept(req, next) {
        var displayLoader = true;
        // URLs without Loader to be added here
        if (location.href.indexOf("/meet/") != -1) {
            displayLoader = false;
        }
        if (displayLoader) {
            this.loaderService.showLoader();
        }
        const currentReq = req.clone();
        return next.handle(currentReq).pipe(map(resp => {
            if (resp instanceof HttpResponse) {
                if (displayLoader) {
                    this.loaderService.hideLoader();
                }
                return resp;
            }
        }));
    }
}
HttpInterceptorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HttpInterceptorService_Factory() { return new HttpInterceptorService(i0.ɵɵinject(i1.LoaderService)); }, token: HttpInterceptorService, providedIn: "root" });
