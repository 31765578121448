import { NgbDateStructAdapter } from "@ng-bootstrap/ng-bootstrap/datepicker/adapters/ngb-date-adapter";
import {NgbDateStruct, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';

export function toInteger(value: any): number {
    return parseInt(`${value}`, 10);
  }
  
  export function toString(value: any): string {
    return (value !== undefined && value !== null) ? `${value}` : '';
  }
  
  export function getValueInRange(value: number, max: number, min = 0): number {
    return Math.max(Math.min(value, max), min);
  }
  
  export function isString(value: any): value is string {
    return typeof value === 'string';
  }
  
  export function isNumber(value: any): value is number {
    return !isNaN(toInteger(value));
  }
  
  export function isInteger(value: any): value is number {
    return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
  }
  
  export function isDefined(value: any): boolean {
    return value !== undefined && value !== null;
  }
  
  export function padNumber(value: number) {
    if (isNumber(value)) {
      return `0${value}`.slice(-2);
    } else {
      return '';
    }
  }
  
  export function regExpEscape(text) {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }
  
  export function hasClassName(element: any, className: string): boolean {
    return element && element.className && element.className.split &&
        element.className.split(/\s+/).indexOf(className) >= 0;
  }

  export function getMonthArray(){
    return ['Jan', 'Feb', 'Mar', 'Apr', 'May','Jun', 'Jul', 'Aug', 'Sep','Oct', 'Nov', 'Dec'];
  }



    export function monthNumToName(monthnum) {
      let months = getMonthArray();
      //console.log("monthnum"+ monthnum);
      //console.log("month Name"+ months[monthnum - 1]);
      return months[monthnum - 1] || '';
    }

    export function NumToMonth(month) {
        let months = getMonthArray();
        let month_index = months.indexOf(month);
        return padNumber(month_index+1);
      }

    export function defaultDateFormat(date){
      return date.day+'-'+monthNumToName(date.month)+'-'+date.year
    }

    export function defaultNgbDateFormat(stringDate){
        if(!stringDate){
            return null;
        }
      let months = getMonthArray();
      const dateParts = stringDate.trim().split('-');
      let month_index = months.indexOf(dateParts[1]);
      let dateModel = { day: toInteger(padNumber(dateParts[0])), month: toInteger(padNumber(month_index+1)), year: toInteger(dateParts[2])};
      //dateModel = { year: 1789, month: 7, day: 14 };
      console.log(dateModel);
      return dateModel;
    }

    export function changeDateApi(date){
      if(date != null && date != undefined)
      {
        let year = date.slice(0,4);
        let month =  date.slice(5,7);
        let day = date.slice(8,10);
        let FormattedDate = day+'-'+monthNumToName(month)+'-'+year;
        if(FormattedDate == "31-Dec-9999")
          return null;
        else
          return FormattedDate;
      }
      else
      {
        return null;
      }
    }
    export function changeDateIEApi(date){
      let year = date.slice(0,4);
      let month =  date.slice(5,7);
      let day = date.slice(8,10);
     return day+'/'+month+'/'+year
    }
    export function changeTodayDate(date){
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = ("0" + date.getDate()).slice(-2);
      return day+'-'+monthNumToName(month)+'-'+year
    }
    export function changeTodayDateIE(date){
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = ("0" + date.getDate()).slice(-2);
      return day+'/'+month+'/'+year
    }

    export function ToInt(x){x=parseInt(x);return isNaN(x)?0:x;}