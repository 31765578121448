/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./splash-screen.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i4 from "@angular/material/progress-spinner";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./splash-screen.component";
import * as i8 from "../services/layout-config.service";
import * as i9 from "../services/splash-screen.service";
import * as i10 from "../../../ej-shared/services/global.service";
import * as i11 from "../../../ej-shared/services/loader.service";
var styles_SplashScreenComponent = [i0.styles];
var RenderType_SplashScreenComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SplashScreenComponent, data: {} });
export { RenderType_SplashScreenComponent as RenderType_SplashScreenComponent };
function View_SplashScreenComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loaderMessage; _ck(_v, 1, 0, currVal_0); }); }
function View_SplashScreenComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", "Logo"]], [[1, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loaderLogo; _ck(_v, 0, 0, currVal_0); }); }
export function View_SplashScreenComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { splashScreen: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["splashScreen", 1]], null, 6, "div", [["class", "kt-splash-screen"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SplashScreenComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SplashScreenComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["diameter", "40"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i3.View_MatSpinner_0, i3.RenderType_MatSpinner)), i1.ɵdid(7, 114688, null, 0, i4.MatSpinner, [i1.ElementRef, i5.Platform, [2, i2.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i4.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { diameter: [0, "diameter"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.loaderType === "spinner-message"); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.loaderType === "spinner-logo"); _ck(_v, 5, 0, currVal_1); var currVal_5 = "40"; _ck(_v, 7, 0, currVal_5); }, function (_ck, _v) { var currVal_2 = i1.ɵnov(_v, 7)._noopAnimations; var currVal_3 = i1.ɵnov(_v, 7).diameter; var currVal_4 = i1.ɵnov(_v, 7).diameter; _ck(_v, 6, 0, currVal_2, currVal_3, currVal_4); }); }
export function View_SplashScreenComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ej-splash-screen", [], null, null, null, View_SplashScreenComponent_0, RenderType_SplashScreenComponent)), i1.ɵdid(1, 114688, null, 0, i7.SplashScreenComponent, [i1.ElementRef, i8.LayoutConfigService, i9.SplashScreenService, i1.ChangeDetectorRef, i10.GlobalService, i11.LoaderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SplashScreenComponentNgFactory = i1.ɵccf("ej-splash-screen", i7.SplashScreenComponent, View_SplashScreenComponent_Host_0, {}, {}, []);
export { SplashScreenComponentNgFactory as SplashScreenComponentNgFactory };
