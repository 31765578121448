import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { TenantRegistrationService } from '../tenant-registration/tenant-registration.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-tenant-package',
  templateUrl: './tenant-package.component.html',
  styleUrls: ['./tenant-package.component.css']
})
export class TenantPackageComponent implements OnInit {

  editionViewModel: any = {};

  constructor(private readonly tenantRegistrationService: TenantRegistrationService,
    private chRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router) { }

  async ngOnInit() {
    await this.getEdition();
  }

  async getEdition() {
    await this.tenantRegistrationService.getAvailableEditions().then(async data => {

      // getting the first edition as per old logic
      // need to change
      await this.tenantRegistrationService.getEditionByID(data[0].id).then(data => {
        this.editionViewModel = data;
        this.chRef.detectChanges();
      });
    });
  }

  registrationrequest(editionId, packageId, trialPeriod) {
    this.router.navigate(['/register', editionId, packageId, trialPeriod]);
    //this.router.navigate(['/register']);
  }
}
