import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { constants } from 'src/helpers/constants';
import { HttpHeaders } from '@angular/common/http'
import { StudentRegistrationForm } from './student-registration-form.model';

@Injectable({
    providedIn: 'root'
})

export class StudentRegistrationformService {
    constructor(private http: HttpClient) { }
    async getRegistrationForms(): Promise<any> {
        return await this.http.get(constants.url_constants.api + '/api/CustomForm/GetRegistrationForms').toPromise();
    }
    async saveRegistrationForm(studentRegistrationform:StudentRegistrationForm): Promise<any> {
        return await this.http.post(constants.url_constants.api + '/api/CustomForm/SaveRegistrationForm', studentRegistrationform).toPromise();
    }
}