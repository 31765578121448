import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ej-server-error',
  templateUrl: './server-error.component.html',
  styleUrls: ['./server-error.component.css']
})
export class ServerErrorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  goBack() {
    window.history.go(-1);
  }
}
