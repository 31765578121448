import * as tslib_1 from "tslib";
import { ElementRef, OnInit, Renderer2, ChangeDetectorRef, ViewRef } from '@angular/core';
import { filter } from 'rxjs/operators';
import { NavigationEnd } from '@angular/router';
import * as objectPath from 'object-path';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
export class AsideLeftComponent {
    constructor(htmlClassService, menuAsideService, layoutConfigService, router, render, sharedService, cdRef, globalService, authService, navigatorService, joyrideService, deviceService, modalService, storageService, courseApprovalService) {
        this.htmlClassService = htmlClassService;
        this.menuAsideService = menuAsideService;
        this.layoutConfigService = layoutConfigService;
        this.router = router;
        this.render = render;
        this.sharedService = sharedService;
        this.cdRef = cdRef;
        this.globalService = globalService;
        this.authService = authService;
        this.navigatorService = navigatorService;
        this.joyrideService = joyrideService;
        this.deviceService = deviceService;
        this.modalService = modalService;
        this.storageService = storageService;
        this.courseApprovalService = courseApprovalService;
        this.menuList = [];
        this.currentRouteUrl = '';
        this.subscriptionExpiryInfo = {};
        this.menuCanvasOptions = {
            baseClass: 'kt-aside',
            overlay: true,
            closeBy: 'kt_aside_close_btn',
            toggleBy: {
                target: 'kt_aside_mobile_toggler',
                state: 'kt-header-mobile__toolbar-toggler--active'
            }
        };
        this.menuOptions = {
            scroll: null,
            submenu: {
                desktop: {
                    default: 'dropdown',
                },
                tablet: 'accordion',
                mobile: 'accordion'
            },
            accordion: {
                expandAll: false // allow having multiple expanded accordions in the menu
            }
        };
        this.sharedService.getMenuEvent().subscribe(() => {
            this.getMenuList();
        });
        this.sharedService.getSideMenuEvent().subscribe(() => {
            this.selectedURL = this.sharedService.activeItem;
            this.cdRef.detectChanges();
        });
        // Update this.primaryInitialSetupCompleted on globalService.primaryInitialSetupCompleted change
        this.primaryInitialSetupCompleted = globalService.primaryInitialSetupCompleted;
        this.globalService.primaryInitialSetupCompletedChange.subscribe((value) => {
            this.primaryInitialSetupCompleted = value;
            this.detectChanges();
        });
        // Update this.isTenant on globalService.isTenant change
        this.isTenant = globalService.isTenant;
        this.globalService.isTenantChange.subscribe((value) => {
            this.isTenant = value;
            this.detectChanges();
        });
        // Update this.subscriptionExpiryInfo on globalService.subscriptionExpiryInfo change
        this.subscriptionExpiryInfo = globalService.subscriptionExpiryInfo;
        this.globalService.subscriptionExpiryInfoChange.subscribe((value) => {
            this.subscriptionExpiryInfo = value;
            this.detectChanges();
        });
        // Update this.pendingCourseApprovalsCount on globalService.pendingCourseApprovalsCount change
        this.pendingCourseApprovalsCount = globalService.pendingCourseApprovalsCount;
        this.globalService.pendingCourseApprovalsCountChange.subscribe((value) => {
            this.pendingCourseApprovalsCount = value;
            this.detectChanges();
            this.getMenuBadges();
        });
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.currentRouteUrl = this.router.url.split(/[?#]/)[0];
            //this.selectedURL = this.currentRouteUrl.replace('/', '');
            this.selectedURL = this.sharedService.activeItem;
            if (this.selectedURL == 'auth-callback') {
                this.selectedURL = 'dashboard';
            }
            this.router.events
                .pipe(filter(event => event instanceof NavigationEnd))
                .subscribe(event => this.currentRouteUrl = this.router.url.split(/[?#]/)[0]);
            const config = this.layoutConfigService.getConfig();
            if (objectPath.get(config, 'aside.menu.dropdown') !== true && objectPath.get(config, 'aside.self.fixed')) {
                this.render.setAttribute(this.asideMenu.nativeElement, 'data-ktmenu-scroll', '1');
            }
            if (objectPath.get(config, 'aside.menu.dropdown')) {
                this.render.setAttribute(this.asideMenu.nativeElement, 'data-ktmenu-dropdown', '1');
                this.render.setAttribute(this.asideMenu.nativeElement, 'data-ktmenu-dropdown-timeout', objectPath.get(config, 'aside.menu.submenu.dropdown.hover-timeout'));
            }
            //this.getMenuList();
            this.sidebarMainMenu = this.globalService.getSidebarMainMenu();
            this.sidebarSubMenu = this.globalService.getSidebarSubMenu();
            if (this.authService.isLoggedin()) {
                yield this.getMenuList();
            }
        });
    }
    getMenuList() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.sharedService.isMember()) {
                this.menuAsideService.getStudentMenu().then(data => {
                    this.globalService.setMenuData(data);
                    this.menuList = data;
                    this.detectChanges();
                });
            }
            else {
                this.menuAsideService.getMenu().then(data => {
                    this.menuList = data;
                    this.globalService.setMenuData(data);
                    if (!this.isTenant) {
                        this.getCourseApprovalStatusCount();
                    }
                    this.detectChanges();
                    if (!this.sharedService.isAdmin()) {
                        if (this.globalService.showSchoolTour) {
                            this.initTourPageModal();
                        }
                    }
                });
            }
        });
    }
    getMenuBadges() {
        var self = this;
        if (self.menuList.length > 0) {
            let badgeArray = [];
            badgeArray.push({ 'url': 'adminapp/approvecourses', 'type': 'kt-badge--danger', 'value': self.pendingCourseApprovalsCount, 'isProcessed': false });
            for (let x = 0; x < badgeArray.length; x++) {
                if (!badgeArray[x].isProcessed) {
                    // loop first level of menu
                    for (let i = 0; i < self.menuList.length; i++) {
                        if (!badgeArray[x].isProcessed) {
                            // check if badge url exists
                            if (self.menuList[i].url == badgeArray[x].url) {
                                self.menuList[i].badge = badgeArray[x];
                                badgeArray[x].isProcessed = true;
                                break;
                            }
                            else {
                                // loop second level of menu
                                for (let j = 0; j < self.menuList[i].items.length; j++) {
                                    // check if badge url exists
                                    if (self.menuList[i].items[j].url == badgeArray[x].url) {
                                        self.menuList[i].items[j].badge = badgeArray[x];
                                        badgeArray[x].isProcessed = true;
                                        break;
                                    }
                                    else {
                                        // loop third level of menu
                                    }
                                }
                            }
                        }
                    }
                }
            }
            this.detectChanges();
        }
    }
    getCourseApprovalStatusCount() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.courseApprovalService.getCourseApprovalStatusCount().then(response => {
                this.pendingCourseApprovalsCount = response.pendingCount;
                this.globalService.setPendingCourseApprovalsCount(this.pendingCourseApprovalsCount);
                this.detectChanges();
            });
        });
    }
    initTourPageModal() {
        this.openTourStartModal(this.tourStartModal);
    }
    ;
    startTourFromModal() {
        this.modalService.dismissAll();
        if (this.deviceService.isMobile()) {
            this.joyrideService.startTour({
                steps: ['CoursesMobile', 'StudentsMobile', 'FeesPaymentsMobile', 'UsersMobile'],
                themeColor: '#63b76a'
            });
        }
        else {
            this.joyrideService.startTour({
                steps: ['Courses', 'Students', 'FeesPayments', 'Profile', 'Settings', 'QuickActions', 'Trainers', 'Users', 'Enquiries', 'Dashboard', 'StartTour'],
                themeColor: '#63b76a',
                waitingTime: 50
            });
        }
    }
    skipTour() {
        this.modalService.dismissAll();
    }
    openTourStartModal(content) {
        var options = { centered: true };
        this.modalService.open(content, options).result.then((result) => {
        }, (reason) => {
        });
    }
    detectChanges() {
        if (this.cdRef && !this.cdRef.destroyed) {
            this.cdRef.detectChanges();
        }
    }
    isMenuItemIsActive(item) {
        if (item.items) {
            return this.isMenuRootItemIsActive(item);
        }
        if (!item.url) {
            return false;
        }
        //return this.currentRouteUrl.indexOf(item.url) !== -1;
        return this.currentRouteUrl == "/" + item.url;
    }
    isMenuRootItemIsActive(item) {
        let result = false;
        for (const subItem of item.items) {
            result = this.isMenuItemIsActive(subItem);
            if (result) {
                return true;
            }
        }
        return false;
    }
    /**
     * Use for fixed left aside menu, to show menu on mouseenter event.
     * @param e Event
     */
    mouseEnter(e) {
        // check if the left aside menu is fixed
        if (document.body.classList.contains('kt-aside--fixed')) {
            if (this.outsideTm) {
                clearTimeout(this.outsideTm);
                this.outsideTm = null;
            }
            this.insideTm = setTimeout(() => {
                // if the left aside menu is minimized
                if (document.body.classList.contains('kt-aside--minimize') && KTUtil.isInResponsiveRange('desktop')) {
                    // show the left aside menu
                    this.render.removeClass(document.body, 'kt-aside--minimize');
                    this.render.addClass(document.body, 'kt-aside--minimize-hover');
                }
            }, 50);
        }
    }
    /**
     * Use for fixed left aside menu, to show menu on mouseenter event.
     * @param e Event
     */
    mouseLeave(e) {
        if (document.body.classList.contains('kt-aside--fixed')) {
            if (this.insideTm) {
                clearTimeout(this.insideTm);
                this.insideTm = null;
            }
            this.outsideTm = setTimeout(() => {
                // if the left aside menu is expand
                if (document.body.classList.contains('kt-aside--minimize-hover') && KTUtil.isInResponsiveRange('desktop')) {
                    // hide back the left aside menu
                    this.render.removeClass(document.body, 'kt-aside--minimize-hover');
                    this.render.addClass(document.body, 'kt-aside--minimize');
                }
            }, 100);
        }
    }
    getItemCssClasses(item) {
        let classes = 'kt-menu__item';
        if (objectPath.get(item, 'submenu')) {
            classes += ' kt-menu__item--submenu';
        }
        if (!item.items && this.isMenuItemIsActive(item)) {
            classes += ' kt-menu__item--active kt-menu__item--here';
        }
        if (item.items && this.isMenuItemIsActive(item)) {
            classes += ' kt-menu__item--open kt-menu__item--here';
        }
        // custom class for menu item
        if (objectPath.has(item, 'custom-class')) {
            classes += ' ' + item['custom-class'];
        }
        if (objectPath.get(item, 'icon-only')) {
            classes += ' kt-menu__item--icon-only';
        }
        return classes;
    }
    getItemAttrSubmenuToggle(item) {
        let toggle = 'hover';
        if (objectPath.get(item, 'toggle') === 'click') {
            toggle = 'click';
        }
        else if (objectPath.get(item, 'submenu.type') === 'tabs') {
            toggle = 'tabs';
        }
        else {
            // submenu toggle default to 'hover'
        }
        return toggle;
    }
    itemClickEvent(url) {
        this.selectedURL = url;
        this.navigatorService.navigate('/' + url, false);
    }
    ngAfterViewInit() {
    }
    next() {
        // scroll 0 pixels horizontal and 1 pixel up
        window.scrollBy(0, -0.0001);
        if ((document.getElementsByClassName('joyride-step__prev-container'))[0]) {
            if ((document.getElementsByClassName('joyride-step__next-container'))[0]) {
                (document.getElementsByClassName('joyride-step__next-container'))[0].textContent = "Next";
            }
        }
        else {
            if ((document.getElementsByClassName('joyride-step__next-container'))[0]) {
                (document.getElementsByClassName('joyride-step__next-container'))[0].textContent = "Start";
            }
        }
        this.detectChanges();
    }
    triggerNext() {
        if ((document.getElementsByClassName('joyride-step__next-container'))[0]) {
            if ((document.getElementsByClassName('joyride-step__next-container'))[0].getElementsByTagName("button")[0]) {
                (document.getElementsByClassName('joyride-step__next-container'))[0].getElementsByTagName("button")[0].click();
                this.next();
            }
        }
    }
}
