import { downgradeInjectable } from '@angular/upgrade/static';
import * as i0 from "@angular/core";
import * as i1 from "ngx-toastr";
export class ToasterService {
    constructor(toastr) {
        this.toastr = toastr;
    }
    error(message) {
        this.toastr.error(message, null, {
            timeOut: 3000,
            enableHtml: true,
        });
    }
    success(message) {
        this.toastr.success(message, null, {
            timeOut: 3000,
            enableHtml: true
        });
    }
    info(message) {
        this.toastr.info(message, null, {
            timeOut: 3000,
            enableHtml: true,
            closeButton: true,
        });
    }
    warning(message) {
        this.toastr.warning(message, null, {
            timeOut: 3000,
            enableHtml: true
        });
    }
    clear() {
        this.toastr.clear();
    }
}
ToasterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ToasterService_Factory() { return new ToasterService(i0.ɵɵinject(i1.ToastrService)); }, token: ToasterService, providedIn: "root" });
angular.module('core')
    .factory('toasterService', downgradeInjectable(ToasterService));
