<div class="row">
    <div class="col-lg-12 kt-padding-b-20 kt-align-right">
        <!-- <button class="btn btn-primary" type="button"  >
            <i class="fa fa-plus"></i> 
        </button> -->
        <a routerLink="/addstudentregistrationform" href="" class="btn btn-primary">
            <i class="fa fa-plus"></i> Add New Form
        </a>
    </div>
</div>
<div class="row justify-content-md-center">
    <div class="col-lg-10">
        <div class="kt-portlet">
            <div class="kt-portlet__body">
                <div class="kt-widget11">
                    <div class="table-responsive">
                        <!--begin::Table-->
                        <table class="table">
                            <tbody>
                                <!-- <tr ng-repeat="registrationform in registrationforms"> -->
                                <tr *ngFor="let registrationform of registrationforms">
                                    <td>
                                        <div>
                                            <span class="kt-widget11__title">{{registrationform?.formName}}</span>
                                            <span class="kt-widget11__sub">{{registrationform?.description}}</span>
                                        </div>
                                    </td>
                                    <td></td>
                                    <td class="kt-align-right kt-font-brand">
                                        <a data-placement="left" href="customForms/{{registrationform.id}}"
                                        ngbTooltip="Configure" class="btn btn-secondary-only">
                                            <i class="la la-cogs"></i>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                            <!--end::Tbody-->
                        </table>
                        <!--end::Table-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>