import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, UrlHandlingStrategy } from '@angular/router';
import { UpgradeModule } from '@angular/upgrade/static';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module'
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BasicAuthInterceptor } from 'src/helpers/basic-auth.interceptor';
import { AuthCallbackComponent } from '../idsvr/auth-callback/auth-callback.component'
import { AppRoutingModule } from './app-routing.module';
import { TenantHomeComponent } from './tenant-home/tenant-home.component'
import { NgbDateParserFormatter, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from '../ej-shared/services/toastr.service'
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TenantRegistrationComponent } from './admin-portal/tenant-registration/tenant-registration.component';
import { TenantPackageComponent } from './admin-portal/tenant-package/tenant-package.component';
import { FormsModule } from '@angular/forms';
import { CourseEnquiryComponent } from './tenant-home/course-enquiry/course-enquiry.component';
import { CustomDateFormatPipe } from './utility/pipes/custom-date-format.pipe';
import { HttpInterceptorService } from '../helpers/http-interceptors'
import { UnauthorizedComponent } from '../ej-shared/components/unauthorized/unauthorized.component'
import { TenantRegResultComponent } from './admin-portal/tenant-registration/tenant-reg-result/tenant-reg-result.component';
import { TenantNotFoundComponent } from '../ej-shared/components/tenant-not-found/tenant-not-found.component';
import { GettingStartedComponent } from './tenant-portal/getting-started/getting-started.component'
import { RecaptchaModule } from 'ng-recaptcha';
import { AngularCropperjsModule } from 'angular-cropperjs';
import { DragDropDirective } from './utility/directives/drag-drop.directive';
// import { PhoneNumberComponent } from './utility/components/phone-number/phone-number.component'
//import { CurrencyFormatPipe } from './utility/pipes/currency-format.pipe'
import { ServerErrorComponent } from '../ej-shared/components/server-error/server-error.component'
import { NgbDateCustomParserFormatter } from './utility/datepicker/formatter/NgbDateCustomParserFormatter';
import { NgbCustomDateNativeAdapter } from './utility/datepicker/adapter/NgbCustomDateNativeAdapter';
import { JoyrideModule } from 'ngx-joyride';
import { StudentRegistrationFormsComponent } from './tenant-portal/student-registration-forms/student-registration-forms.component';
import { AddStudentRegistrationFormComponent } from './tenant-portal/student-registration-forms/add-student-registration-form/add-student-registration-form.component';
import { PhoneNumberPipe } from './utility/pipes/phone-number.pipe';
import { NgxPermissionsModule } from 'ngx-permissions';
import { SharedModule } from './utility/shared.module';
import { TrainerGettingStartedComponent } from './tenant-portal/trainer-getting-started/trainer-getting-started.component';
import { DemoTrainerGettingStartedComponent } from './tenant-portal/demo-trainer-getting-started/demo-trainer-getting-started.component';
import { DlDateTimePickerModule } from 'angular-bootstrap-datetimepicker';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { SigninComponent } from 'src/idsvr/signin/signin.component';
import { SafePipe } from './utility/pipes/safe.pipe';

export class CustomHandlingStrategy implements UrlHandlingStrategy {
  shouldProcessUrl(url) {
    return url.toString().startsWith("ng2-route")
      || url.toString().includes("/auth-callback")
      || url.toString() == "/"
      || url.toString().includes("/selectplan")
      || url.toString().includes("/register")
      || url.toString().includes("/courseenquiry")
      || url.toString().includes("/registration")
      || url.toString().includes("/unauthorized")
      || url.toString().includes("/tenantnotfound")
      || url.toString().includes("/gettingstarted")
      || url.toString().includes("/studentregistrationforms")
      || url.toString().includes("/addstudentregistrationform")
      || url.toString().includes("servererror")
      || url.toString().includes("/adminapp")
      || url.toString().includes("/myapp")
      || url.toString().includes("/member")
      || url.toString().includes("/trainerprofile")
      || url.toString().includes("/trainergettingstarted")
      || url.toString().includes("/demotrainergettingstarted")
      || url.toString().includes("/signin")
      || url.toString().includes("/meet")
      || url.toString().includes("/myregistration")
      || url.toString().includes("/feedback")
  
  }
  extract(url) { return url; }
  merge(url, whole) { return url; }
}

@NgModule({
  declarations: [
    AppComponent,
    AuthCallbackComponent,
    TenantHomeComponent,
    TenantRegistrationComponent,
    TenantPackageComponent,
    CourseEnquiryComponent,
    CustomDateFormatPipe,
    TenantRegResultComponent,
    UnauthorizedComponent,
    TenantNotFoundComponent,
    GettingStartedComponent,
    DragDropDirective,
    // PhoneNumberComponent,
    //CurrencyFormatPipe,
    ServerErrorComponent,
    StudentRegistrationFormsComponent,
    AddStudentRegistrationFormComponent,
    ServerErrorComponent,
    PhoneNumberPipe,
    TrainerGettingStartedComponent,
    DemoTrainerGettingStartedComponent,
    SigninComponent],
  imports: [
    BrowserModule,
    UpgradeModule,
    LayoutModule,
    AppRoutingModule,
    RouterModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    FormsModule,
    RecaptchaModule,
    AngularCropperjsModule,
    JoyrideModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    SharedModule,
    DlDateTimePickerModule,
    ImageCropperModule,
    NgIdleKeepaliveModule.forRoot(),
  ],
  exports: [LayoutModule, DragDropDirective,
    //CurrencyFormatPipe, 
    PhoneNumberPipe, SharedModule, NgxPermissionsModule],
  entryComponents: [
  ],
  providers: [
    { provide: UrlHandlingStrategy, useClass: CustomHandlingStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
    ToasterService,
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
    { provide: NgbDateAdapter, useClass: NgbCustomDateNativeAdapter }
  ],
  bootstrap: [AppComponent]
})

export class AppModule {

  constructor(private upgrade: UpgradeModule) { }
  ngDoBootstrap() {
    this.upgrade.bootstrap(document.body, ['eduJournalApp'], { strictDi: true });
  }
}