import * as tslib_1 from "tslib";
import { constants } from 'src/helpers/constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class GettingStartedService {
    constructor(http) {
        this.http = http;
    }
    getSchoolProfileViewModel() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(constants.url_constants.api + '/api/SchoolProfileApi/GetSchoolProfileViewModel').toPromise();
        });
    }
    saveSchoolProfileDetails(schoolProfile) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.post(constants.url_constants.api + '/api/SchoolProfileApi/SaveSchoolProfileDetails', schoolProfile).toPromise();
        });
    }
    uploadSchoolLogo(file) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.post(constants.url_constants.api + '/api/SchoolProfileApi/UploadSchoolProfile', file).toPromise();
        });
    }
    getIP() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get('https://api.ipify.org?format=json').toPromise();
        });
    }
    getTenantLoggedInCountry() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            var ip = "";
            yield this.getIP().then(res => ip = res.ip);
            return yield this.http.get(constants.url_constants.api + '/api/TenantsApi/GetTenantLoggedinCountryDetails?ipAddress=' + ip).toPromise();
        });
    }
    getTimeZonesByCountryCode(countryCode) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(constants.url_constants.api + '/api/TrainingProgramApi/GetTimeZonesByCountryCode/' + countryCode).toPromise();
        });
    }
    getAllTimeZones() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(constants.url_constants.api + '/api/TrainingProgramApi/GetAllTimeZones').toPromise();
        });
    }
    uploadSchoolIncorporationCertificateDocuments(file, tenantId = "00000000-0000-0000-0000-000000000000") {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.post(constants.url_constants.api + '/api/SchoolProfileApi/UploadSchoolIncorporationCertificateDocuments/' + tenantId, file).toPromise();
        });
    }
}
GettingStartedService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GettingStartedService_Factory() { return new GettingStartedService(i0.ɵɵinject(i1.HttpClient)); }, token: GettingStartedService, providedIn: "root" });
