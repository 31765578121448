/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "./subheader.component";
import * as i4 from "@angular/platform-browser";
import * as i5 from "../../../ej-shared/services/shared.service";
var styles_SubheaderComponent = [];
var RenderType_SubheaderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SubheaderComponent, data: {} });
export { RenderType_SubheaderComponent as RenderType_SubheaderComponent };
function View_SubheaderComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [["class", "kt-subheader__separator kt-subheader__separator--v"]], null, null, null, null, null))], null, null); }
function View_SubheaderComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [["class", "kt-subheader__breadcrumbs-separator"]], null, null, null, null, null))], null, null); }
function View_SubheaderComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "a", [["class", "kt-subheader__breadcrumbs-link"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(2, 1), (_l()(), i0.ɵted(3, null, ["", " "]))], function (_ck, _v) { var currVal_2 = _ck(_v, 2, 0, _v.parent.context.$implicit.link); _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).target; var currVal_1 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _v.parent.context.$implicit.label; _ck(_v, 3, 0, currVal_3); }); }
function View_SubheaderComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [["class", "kt-subheader__breadcrumbs-link"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.label; _ck(_v, 1, 0, currVal_0); }); }
function View_SubheaderComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [["class", "kt-subheader__breadcrumbs-link kt-font-brand"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.label; _ck(_v, 1, 0, currVal_0); }); }
function View_SubheaderComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "kt-subheader__breadcrumbs"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SubheaderComponent_3)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SubheaderComponent_4)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SubheaderComponent_5)), i0.ɵdid(6, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SubheaderComponent_6)), i0.ɵdid(8, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = (_v.context.index != 0); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.link; _ck(_v, 4, 0, currVal_1); var currVal_2 = ((_v.context.$implicit.link == "") && !_v.context.last); _ck(_v, 6, 0, currVal_2); var currVal_3 = ((_v.context.$implicit.link == "") && _v.context.last); _ck(_v, 8, 0, currVal_3); }, null); }
export function View_SubheaderComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "kt-subheader  kt-grid__item"], ["id", "kt_subheader"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "div", [["class", "kt-container  kt-container--fluid "]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 6, "div", [["class", "kt-subheader__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "h3", [["class", "kt-subheader__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, [" ", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SubheaderComponent_1)), i0.ɵdid(6, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SubheaderComponent_2)), i0.ɵdid(8, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (((_co.breadcrumbs == null) ? null : _co.breadcrumbs.length) > 0); _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.breadcrumbs; _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 4, 0, currVal_0); }); }
export function View_SubheaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ej-subheader", [], null, null, null, View_SubheaderComponent_0, RenderType_SubheaderComponent)), i0.ɵdid(1, 114688, null, 0, i3.SubheaderComponent, [i0.ChangeDetectorRef, i1.ActivatedRoute, i1.Router, i4.Title, i5.SharedService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SubheaderComponentNgFactory = i0.ɵccf("ej-subheader", i3.SubheaderComponent, View_SubheaderComponent_Host_0, {}, {}, []);
export { SubheaderComponentNgFactory as SubheaderComponentNgFactory };
