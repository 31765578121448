import * as tslib_1 from "tslib";
import { constants } from 'src/helpers/constants';
import { WeeklySession, Dailyschedule, AdditionalWeeklySession, AdditionalDailySchedule } from './course.model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class NewCourseService {
    constructor(http) {
        this.http = http;
    }
    getCourseDropDownDetails(tenantId = "00000000-0000-0000-0000-000000000000") {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(constants.url_constants.api + '/api/TrainingProgramApi/GetCourseDropDownDetails/' + tenantId).toPromise();
        });
    }
    uploadBannerImage(file, id, tenantId = "00000000-0000-0000-0000-000000000000") {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.post(constants.url_constants.api + '/api/TrainingProgramApi/UploadBannerImage/' + tenantId + '?id=' + id, file).toPromise();
        });
    }
    uploadTrainerPicForCourse(file, id, tenantId = "00000000-0000-0000-0000-000000000000") {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.post(constants.url_constants.api + '/api/TrainingProgramApi/UploadTrainerPicForCourse/' + tenantId + '?id=' + id, file).toPromise();
        });
    }
    saveTrainingProgram(course) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.post(constants.url_constants.api + '/api/TrainingProgramApi/Save', course).toPromise();
        });
    }
    saveCourseNew(course) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.post(constants.url_constants.api + '/api/TrainingProgramApi/SaveTrainingProgramNew', course).toPromise();
        });
    }
    saveAndPublishCourseNew(course) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.post(constants.url_constants.api + '/api/TrainingProgramApi/SaveAndPublishTrainingProgram', course).toPromise();
        });
    }
    getTrainingProgrambyTenantID(nextPageNumber, courseFilter) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.post(constants.url_constants.api + '/api/TrainingProgramApi/GetTrainingProgrambyTenantID/' + nextPageNumber, courseFilter).toPromise();
        });
    }
    getTrainingProgrambyTenantIDForEnrolment(nextPageNumber, courseFilter) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.post(constants.url_constants.api + '/api/TrainingProgramApi/getTrainingProgrambyTenantIDForEnrolment/' + nextPageNumber, courseFilter).toPromise();
        });
    }
    getTrainingProgramStatusCount() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(constants.url_constants.api + '/api/TrainingProgramApi/GetTrainingProgramStatusCount').toPromise();
        });
    }
    getTrainingProgrambyID(id, tenantId = "00000000-0000-0000-0000-000000000000") {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(constants.url_constants.api + '/api/TrainingProgramApi/GetTrainingProgrambyId/' + id + '/' + tenantId).toPromise();
        });
    }
    getTrainingProgrambyIdForSocialShare(id, tenantId = "00000000-0000-0000-0000-000000000000") {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(constants.url_constants.api + '/api/TrainingProgramApi/GetTrainingProgrambyIdForSocialShare/' + id + '/' + tenantId).toPromise();
        });
    }
    deleteTrainingProgrambyId(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(constants.url_constants.api + '/api/TrainingProgramApi/DeleteTrainingProgrambyId/' + id).toPromise();
        });
    }
    UpdateCourseOnWebsite(courseWebsitePostModel) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.post(constants.url_constants.api + '/api/TrainingProgramApi/UpdateCourseOnWebsite', courseWebsitePostModel).toPromise();
        });
    }
    uploadCourseDocuments(file, tenantId = "00000000-0000-0000-0000-000000000000") {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.post(constants.url_constants.api + '/api/TrainingProgramApi/UploadCourseDocuments/' + tenantId, file).toPromise();
        });
    }
    deleteWeeklySession(additionalWeeklySession, courseId, tenantId = "00000000-0000-0000-0000-000000000000") {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.post(constants.url_constants.api + '/api/TrainingProgramApi/DeleteWeeklySession/' + tenantId + '?courseId=' + courseId, additionalWeeklySession).toPromise();
        });
    }
    updateWeeklySession(additionalWeeklySession, courseId, tenantId = "00000000-0000-0000-0000-000000000000") {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.post(constants.url_constants.api + '/api/TrainingProgramApi/UpdateWeeklySession/' + tenantId + '?courseId=' + courseId, additionalWeeklySession).toPromise();
        });
    }
    getTimeZonesByCountryCode(countryCode, tenantId = "00000000-0000-0000-0000-000000000000") {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(constants.url_constants.api + '/api/TrainingProgramApi/GetTimeZonesByCountryCode/' + countryCode + '/' + tenantId).toPromise();
        });
    }
    getAllTimeZones(tenantId = "00000000-0000-0000-0000-000000000000") {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(constants.url_constants.api + '/api/TrainingProgramApi/GetAllTimeZones/' + tenantId).toPromise();
        });
    }
    rescheduleDailySchedule(dailyschedule, courseId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.post(constants.url_constants.api + '/api/TrainingProgramApi/RescheduleDailySchedule?courseId=' + courseId, dailyschedule).toPromise();
        });
    }
    cancelDailySchedule(additionalDailySchedule, courseId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.post(constants.url_constants.api + '/api/TrainingProgramApi/CancelDailySchedule?courseId=' + courseId, additionalDailySchedule).toPromise();
        });
    }
    getCountryCodeByTimeZoneId(timeZoneId, tenantId = "00000000-0000-0000-0000-000000000000") {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(constants.url_constants.api + '/api/TrainingProgramApi/GetCountryCodeByTimeZoneId/' + tenantId + '?timeZoneId=' + timeZoneId).toPromise();
        });
    }
    getManageCourseSessionViewModel(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(constants.url_constants.api + '/api/TrainingProgramApi/GetManageCourseSessionViewModel/' + id).toPromise();
        });
    }
    updateWeeklySessionLocation(weeklySession, courseId, tenantId = "00000000-0000-0000-0000-000000000000") {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.post(constants.url_constants.api + '/api/TrainingProgramApi/UpdateWeeklySessionLocation/' + tenantId + '?courseId=' + courseId, weeklySession).toPromise();
        });
    }
    updateDailySessionLocation(dailyschedule, courseId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.post(constants.url_constants.api + '/api/TrainingProgramApi/UpdateDailySessionLocation?courseId=' + courseId, dailyschedule).toPromise();
        });
    }
    ListTenants() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.post(constants.admin_url + '/api/TenantsApi/GetTenants', '{}').toPromise();
        });
    }
    getCountryCodes(tenantId = "00000000-0000-0000-0000-000000000000") {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(constants.url_constants.api + '/api/LayoutApi/GetCountryCodes/' + tenantId).toPromise();
        });
    }
    getGivenSeaCountries(tenantId = "00000000-0000-0000-0000-000000000000") {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(constants.url_constants.api + '/api/LayoutApi/GetGivenSeaCountries/' + tenantId).toPromise();
        });
    }
    getCurrencies(tenantId = "00000000-0000-0000-0000-000000000000") {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(constants.url_constants.api + '/api/LayoutApi/GetCurrencies/' + tenantId).toPromise();
        });
    }
    getTrainingProgrambyTenantIDFromAdmin(nextPageNumber, courseFilter, tenantId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.post(constants.url_constants.api + '/api/TrainingProgramApi/GetTrainingProgrambyTenantIDFromAdmin/' + nextPageNumber + '/' + tenantId, courseFilter).toPromise();
        });
    }
    getTrainingProgramStatusCountFromAdmin(tenantId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(constants.url_constants.api + '/api/TrainingProgramApi/GetTrainingProgramStatusCountFromAdmin/' + tenantId).toPromise();
        });
    }
    checkPrimaryInitialSetupStatusByTenantId(tenantId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(constants.url_constants.api + '/api/InitialSetup/CheckPrimaryInitialSetupStatusByTenantId/' + tenantId).toPromise();
        });
    }
    getTrainingProgramContentSocialMedia(courseId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(constants.url_constants.api + '/api/TrainingProgramApi/GetTrainingProgramContentSocialMedia/' + courseId).toPromise();
        });
    }
    getTrainers() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(constants.url_constants.api + '/api/TrainingProgramApi/GetTrainers/').toPromise();
        });
    }
    saveNewTrainer(trainer) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.post(constants.url_constants.api + '/api/TrainerApi/SaveNewTrainer/', trainer).toPromise();
        });
    }
    getTrainingProgrambyIdWithPrice(id, tenantId = "00000000-0000-0000-0000-000000000000") {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(constants.url_constants.api + '/api/TrainingProgramApi/GetTrainingProgrambyIdWithPrice/' + id + '/' + tenantId).toPromise();
        });
    }
    getActiveCourses(tenantId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(constants.url_constants.api + '/api/TrainingProgramApi/GetActiveCourses/' + tenantId).toPromise();
        });
    }
    getActiveCoursesByTenants(tenantIdList) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.post(constants.url_constants.api + '/api/TrainingProgramApi/GetActiveCoursesByTenants/', tenantIdList).toPromise();
        });
    }
    getTrainingProgramsByCategory(filter) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.post(constants.url_constants.api + '/api/TrainingProgramApi/GetTrainingProgramsByCategory/', filter).toPromise();
        });
    }
    getTrainerScheduleForBatchCreation(trainerId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(constants.url_constants.api + '/api/TrainingProgramApi/GetTrainerScheduleForBatchCreation/' + trainerId).toPromise();
        });
    }
    GetTraningPrograms() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(constants.url_constants.api + '/api/TrainingProgramApi/GetTraningPrograms').toPromise();
        });
    }
    GetTraningProgramsByTenantId(tenantId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(constants.url_constants.api + '/api/TrainingProgramApi/GetTraningPrograms/' + tenantId).toPromise();
        });
    }
    getTrainingProgramSessionsForDemoBooking(id, selectedSessionId = "00000000-0000-0000-0000-000000000000") {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(constants.url_constants.api + '/api/TrainingProgramApi/GetTrainingProgramSessionsForDemoBooking/' + id + '/' + selectedSessionId).toPromise();
        });
    }
}
NewCourseService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NewCourseService_Factory() { return new NewCourseService(i0.ɵɵinject(i1.HttpClient)); }, token: NewCourseService, providedIn: "root" });
