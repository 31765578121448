import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { constants } from 'src/helpers/constants';
declare var angular: any;
import { StorageService } from './storage.service'

@Injectable({
    providedIn: "root"
})

export class PermissionService {
    constructor(
        private http: HttpClient,
        private readonly storageService: StorageService) { }
    public permissionList = [];
    apiUrl: string = constants.url_constants.api;

    setPermissions(permissions) {
        this.permissionList = permissions;
        //$rootScope.$broadcast('permissionsChanged');
    }

    hasPermission(permission) {
        permission = permission.trim();
        return this.permissionList.some(item => {
            if (typeof item !== 'string') { // item.Name is only used because when I called setPermission, I had a Name property
                return false;
            }
            return item.trim() === permission;
        });
    }

    //Get User Permissions
    async GetUserPermissionById(): Promise<any> {
        let userId = this.storageService.getUserId();
        return await this.http.get(this.apiUrl + '/api/UserApi/GetUserPermissionById/' + userId).toPromise();
    }
}

angular.module('core')
    .factory('permissions', downgradeInjectable(PermissionService));