import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { constants } from 'src/helpers/constants';

@Pipe({
  name: 'mcDateFormat'
})
export class CustomDateFormatPipe implements PipeTransform {
  transform(value: any, hideMaxDate: boolean): any {
      let formattedDate = new DatePipe('en-US').transform(value, constants.dateformat);
      if ((hideMaxDate && formattedDate == constants.maxDate) || formattedDate == null) {
          return "-";
      }
      return formattedDate;
  }
}
