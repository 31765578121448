import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { downgradeInjectable } from '@angular/upgrade/static';
declare var angular: any;
import { SubHeader } from '../models/bredcum.model'
import { environment } from '../../environments/environment'

@Injectable({
    providedIn: 'root'
})
export class SharedService {
    public subHeader: SubHeader = new SubHeader();

    private subject = new Subject<any>();
    private bredcumEvent = new Subject<any>();
    private menuEvent = new Subject<any>();
    private logoChangeEvent = new Subject<any>();
    private sideMenuChangeEvent = new Subject<any>();
    private showSetupScreenEvent = new Subject<any>();
    private closeMobileTopbar = new Subject<any>();
    activeItem: string;
    public showSplashScreen: boolean = false;

    sendClickEvent() {
        this.subject.next();
    }
    getClickEvent(): Observable<any> {
        return this.subject.asObservable();
    }

    sendBredcumEvent(subHeader) {
        this.subHeader = subHeader;
        this.bredcumEvent.next();
        this.sendMobileTopbarCloseEvent();
        window.scroll(0, 0);
    }
    getBredcumEvent(): Observable<any> {
        return this.bredcumEvent.asObservable();
    }

    sendMenuEvent() {
        this.menuEvent.next();
    }
    getMenuEvent(): Observable<any> {
        return this.menuEvent.asObservable();
    }

    sendLogoChangeEvent() {
        this.logoChangeEvent.next();
    }
    getLogoChangeEvent(): Observable<any> {
        return this.logoChangeEvent.asObservable();
    }

    sendSideMenuEvent(activeitem: string) {
        this.activeItem = activeitem;
        this.sideMenuChangeEvent.next();
    }
    getSideMenuEvent(): Observable<any> {
        return this.sideMenuChangeEvent.asObservable();
    }

    sendShowSetupEvent(showSplashScreen: boolean) {
        this.showSplashScreen = showSplashScreen;
        this.showSetupScreenEvent.next();
    }
    getShowSetupEvent(): Observable<any> {
        return this.showSetupScreenEvent.asObservable();
    }

    isEmail(search: string): boolean {
        let isValidEmail: boolean;
        let regexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        isValidEmail = regexp.test(search);
        return isValidEmail;
    }

    sendMobileTopbarCloseEvent() {
        this.closeMobileTopbar.next();
    }
    getMobileTopbarCloseEvent(): Observable<any> {
        return this.closeMobileTopbar.asObservable();
    }
    isMember(): boolean {
        if (window.location.origin.toLowerCase() == environment.member_url.toLowerCase()) {
            return true;
        } else {
            return false;
        }
    }
    isAdmin(): boolean {
        if (window.location.origin.toLowerCase() == environment.admin_url.toLowerCase()) {
            return true;
        } else {
            return false;
        }
    }
    isRedirectUrl(): boolean {
        if (window.location.origin.toLowerCase() == environment.shortRedirect_url.toLowerCase()) {
            return true;
        } else {
            return false;
        }
    }
}

angular.module('core')
    .factory('sharedService', downgradeInjectable(SharedService));