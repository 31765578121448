import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoaderService } from '../ej-shared/services/loader.service'
import { downgradeInjectable } from '@angular/upgrade/static';
declare var angular: any;
/**
 * This interceptor automatically adds the token header needed by our backend API if such token is present
 * in the current state of the application.
 */
@Injectable({
    providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

    constructor(private loaderService: LoaderService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        var displayLoader = true;
        // URLs without Loader to be added here
        if (location.href.indexOf("/meet/") != -1) { displayLoader = false; }

        if (displayLoader) { this.loaderService.showLoader(); }
        const currentReq = req.clone();
        return next.handle(currentReq).pipe(
            map(resp => {
                if (resp instanceof HttpResponse) {
                    if (displayLoader) { this.loaderService.hideLoader(); }
                    return resp;
                }
            })
        );
    }
}