<ng-container>
    <div class="kt-header__topbar-item" *ngxPermissionsOnly="['Permissions.TenantVerification.Create']">
        <div class="kt-header__topbar-wrapper" data-offset="30px,0px" aria-expanded="true"
            *ngIf="tenantVerificationdata.items != null">
            <span class="kt-header__topbar-icon" (click)="showTenantVerificationPopup(content)" placement="bottom"
                tooltipClass="kt-font-info" ngbTooltip="Tenant Verification">
                <svg *ngIf="tenantVerificationdata.progressItems == tenantVerificationdata.items.length"
                    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                    height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect id="bound" x="0" y="0" width="24" height="24" />
                        <circle id="Oval-5" fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                        <path
                            d="M16.7689447,7.81768175 C17.1457787,7.41393107 17.7785676,7.39211077 18.1823183,7.76894473 C18.5860689,8.1457787 18.6078892,8.77856757 18.2310553,9.18231825 L11.2310553,16.6823183 C10.8654446,17.0740439 10.2560456,17.107974 9.84920863,16.7592566 L6.34920863,13.7592566 C5.92988278,13.3998345 5.88132125,12.7685345 6.2407434,12.3492086 C6.60016555,11.9298828 7.23146553,11.8813212 7.65079137,12.2407434 L10.4229928,14.616916 L16.7689447,7.81768175 Z"
                            id="Path-92" fill="#000000" fill-rule="nonzero" />
                    </g>
                </svg>
                <i class="fas fa-exclamation-circle text-warning"
                    *ngIf="tenantVerificationdata.progressItems != tenantVerificationdata.items.length"></i>
            </span>
        </div>
    </div>
</ng-container>

<ng-template #content let-modal>
    <div class="modal-header">
        <div class="row">
            <div class="col-md-12">
                <h4 class="kt-font-dark" id="LabelTenentVerification" *ngIf="(trainerType == 1 || trainerType == 3)">Trainer Verification</h4>
                <h4 class="kt-font-dark" id="LabelTenentVerification" *ngIf="(trainerType == 2)">Training Institute Verification</h4>
                <span class="">Complete the below steps to connect with audience in eduJournal platform</span>
            </div>
        </div>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"
            style="zoom: 1.25;">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">

        <div class="row">
            <div class="col-md-12">
                <span class="kt-font-dark kt-font-lg font-weight-normal">Complete your steps
                    ({{tenantVerificationdata.progressItems}}/{{tenantVerificationdata.items.length}})</span>
                <div class="progress kt-margin-t-10 kt-margin-b-10" style="border-radius : 3px; height:6px">
                    <div class="progress-bar bg-primary" style="border-radius : 3px" role="progressbar"
                        [ngStyle]="{ 'width': tenantVerificationdata.progressWidth}"
                        [attr.aria-valuenow]="tenantVerificationdata.progress" aria-valuemin="0" aria-valuemax="100">
                    </div>
                </div>
            </div>
        </div>
        <div class="kt-widget4">
            <div class="kt-widget4__item" style="padding-top: 1.5rem;padding-bottom: 1.5rem;"
                *ngFor="let item of tenantVerificationdata.items; let odd = odd;  let even = even; let index = index">
                <div class="kt-widget4__pic kt-widget4__pic--logo kt-font-{{item.color}}">
                    <i class="{{item.icon}} fa-2x"></i>
                </div>
                <div class="kt-widget4__info kt-margin-l-10">
                    <a href="#" (click)="gotoTenantVerification()" class="kt-widget4__title kt-font-{{item.color}}"
                        *ngIf="item.verificationStatusText != 'Verified'">
                        {{item.header}}
                    </a>
                    <span href="#" class="kt-font-{{item.color}} kt-font-bold" style="font-size: 1.1rem;"
                        *ngIf="item.verificationStatusText == 'Verified'">
                        {{item.header}}
                    </span>
                    <p class="kt-widget4__text">
                        {{item.content}}
                    </p>
                </div>
                <span class="kt-widget4__number text-{{item.color}}" *ngIf="item.verificationStatusText == 'Verified'">
                    <i class="fa fa-check-circle" style="font-size: 1.75rem;"></i>
                </span>
                <span class="kt-widget4__number text-{{item.color}}" *ngIf="item.verificationStatusText != 'Verified'">
                    <i class="fas fa-exclamation-circle" style="font-size: 1.75rem;"></i>
                </span>
            </div>
        </div>
    </div>
</ng-template>