import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthCallbackComponent } from '../idsvr/auth-callback/auth-callback.component';
import { TenantHomeComponent } from './tenant-home/tenant-home.component'
import { TenantRegistrationComponent } from './admin-portal/tenant-registration/tenant-registration.component';
import { TenantPackageComponent } from './admin-portal/tenant-package/tenant-package.component';
import { CourseEnquiryComponent } from './tenant-home/course-enquiry/course-enquiry.component';
import { UnauthorizedComponent } from '../ej-shared/components/unauthorized/unauthorized.component'
import { TenantRegResultComponent } from './admin-portal/tenant-registration/tenant-reg-result/tenant-reg-result.component';
import { TenantNotFoundComponent } from '../ej-shared/components/tenant-not-found/tenant-not-found.component'
import { GettingStartedComponent } from './tenant-portal/getting-started/getting-started.component'
import { ServerErrorComponent } from '../ej-shared/components/server-error/server-error.component'
import { TrainerGettingStartedComponent } from './tenant-portal/trainer-getting-started/trainer-getting-started.component'
import { TrainerProfileComponent } from './tenant-portal/trainer-profile/trainer-profile.component'
import { DemoTrainerGettingStartedComponent } from './tenant-portal/demo-trainer-getting-started/demo-trainer-getting-started.component'
import { StudentRegistrationFormsComponent } from './tenant-portal/student-registration-forms/student-registration-forms.component';
import { AddStudentRegistrationFormComponent } from './tenant-portal/student-registration-forms/add-student-registration-form/add-student-registration-form.component';
import { SigninComponent } from 'src/idsvr/signin/signin.component';
import { JitsiMeetComponent } from './shared/jitsi-meet/jitsi-meet.component';
import { LeadOpenRegistrationComponent } from './shared/lead-open-registration/lead-open-registration.component';
const routes: Routes = [
    {
        path: 'auth-callback',
        component: AuthCallbackComponent
    },
    {
        path: 'signin/:access_token/:expires_at',
        component: SigninComponent
    },
    {
        path: 'tenantnotfound',
        component: TenantNotFoundComponent
    },
    {
        path: 'myhome',
        pathMatch: 'full',
        component: TenantHomeComponent
    },
    {
        path: 'selectplan',
        pathMatch: 'full',
        component: TenantPackageComponent
    },
    {
        path: 'register/:editionId/:packageId/:trialPeriod',
        pathMatch: 'full',
        component: TenantRegistrationComponent
    },
    {
        path: 'courseenquiry/:courseId',
        pathMatch: 'full',
        component: CourseEnquiryComponent
    },
    {
        path: 'registration/:isSuccess',
        pathMatch: 'full',
        component: TenantRegResultComponent
    },
    {
        path: 'unauthorized',
        pathMatch: 'full',
        component: UnauthorizedComponent
    },
    {
        path: 'gettingstarted',
        pathMatch: 'full',
        component: GettingStartedComponent
    },
    {
        path: 'servererror',
        pathMatch: 'full',
        component: ServerErrorComponent
    },
    {
        path: 'myregistration',
        loadChildren: 'src/ej-ts/shared/lead-open-registration/lead-open-registration.module#LeadOpenRegistrationModule'
    },
    {
        path: 'feedback',
        loadChildren: 'src/ej-ts/shared/feed-back/feed-back.module#FeedBackModule'
    },
    
    { path: 'adminapp', loadChildren: 'src/ej-ts/admin-portal/admin.module#AdminModule' },
    { path: 'member', loadChildren: 'src/ej-ts/member-portal/member.module#MemberModule' },
    { path: 'myapp', loadChildren: 'src/ej-ts/tenant-portal/tenant.module#TenantModule' },
    {
        path: 'trainergettingstarted',
        pathMatch: 'full',
        component: TrainerGettingStartedComponent
    },
    {
        path: 'demotrainergettingstarted',
        pathMatch: 'full',
        component: DemoTrainerGettingStartedComponent
    },
    {
        path: 'studentregistrationforms',
        pathMatch: 'full',
        component: StudentRegistrationFormsComponent
    },
    {
        path: 'addstudentregistrationform',
        pathMatch: 'full',
        component: AddStudentRegistrationFormComponent
    },
    {
        path: 'meet',
        loadChildren: 'src/ej-ts/shared/jitsi-meet/jitsi-meet.module#JitsiMeetModule'
    },
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'myhome',
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes)
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
