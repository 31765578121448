import { Component, ElementRef, OnInit, ViewChild, ChangeDetectorRef, ViewRef } from '@angular/core';
import * as objectPath from 'object-path';
import { LayoutConfigService } from '../services/layout-config.service'
import { SplashScreenService } from '../services/splash-screen.service';
import { GlobalService } from 'src/ej-shared/services/global.service';
import { LoaderService } from 'src/ej-shared/services/loader.service';

@Component({
	selector: 'ej-splash-screen',
	templateUrl: './splash-screen.component.html',
	styleUrls: ['./splash-screen.component.scss']
})
export class SplashScreenComponent implements OnInit {
	loaderLogo: string;
	loaderType: string;
	loaderMessage: string;

	@ViewChild('splashScreen', { static: false }) splashScreen: ElementRef;

	constructor(
		private el: ElementRef,
		private layoutConfigService: LayoutConfigService,
		private splashScreenService: SplashScreenService,
		private cdRef: ChangeDetectorRef,
		private globalService: GlobalService,
		private loaderService: LoaderService) {
		// // Update this.loaderMessage on globalService.primaryInitialSetupCompleted change
		// this.globalService.primaryInitialSetupCompletedChange.subscribe((value) => {
		// 	this.loaderMessage = value ? "Please wait..." : "Please wait while we setup your Training Institute...";
		// 	this.detectChanges();
		// });
	}

	ngOnInit() {
		this.loaderService.blockLoader = true;
		this.loaderService.hideLoader();

		// init splash screen, see loader option in layout.config.ts
		const loaderConfig = this.layoutConfigService.getConfig('loader');
		this.loaderLogo = objectPath.get(loaderConfig, 'logo');
		this.loaderType = objectPath.get(loaderConfig, 'type');
		this.loaderMessage = objectPath.get(loaderConfig, 'message');
		this.splashScreenService.init(this.splashScreen);
	}

	detectChanges() {
		if (this.cdRef && !(this.cdRef as ViewRef).destroyed) {
			this.cdRef.detectChanges();
		}
	}
}