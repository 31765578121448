import { Component, OnInit, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Idle, EventTargetInterruptSource, DEFAULT_INTERRUPTSOURCES, AutoResume } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { AuthService } from 'src/idsvr/auth.service';
import { GlobalService } from 'src/ej-shared/services/global.service';
declare var $: any;

@Component({
  selector: 'ej-session-timeout',
  templateUrl: './session-timeout.component.html',
  styleUrls: ['./session-timeout.component.css']
})
export class SessionTimeoutComponent implements OnInit {

  lastPing?: Date = null;
  countdown = null;
  timeoutMax = 60;
  timeoutNow = 60;
  timeoutPercentage = 100;
  isLoggedIn: boolean = false;

  constructor(private idle: Idle,
    private keepalive: Keepalive,
    private readonly authService: AuthService,
    private element: ElementRef,
    private globalService: GlobalService,
    private readonly changeDetectorRef: ChangeDetectorRef) {

    // URLs without timeout to be added here
    if (location.href.indexOf("/meet/") == -1) {

      // sets an idle timeout of 13 minutes
      idle.setIdle(780);

      // sets a timeout period of 60 seconds
      idle.setTimeout(this.timeoutMax);

      // sets the interrupts like Keydown, scroll, mouse wheel, mouse down, and etc
      idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

      // enable reset only on click of "Continue/Stay Connected"
      idle.setAutoResume(AutoResume.notIdle);

      // to-do on timeout event
      idle.onTimeout.subscribe(() => {
        idle.stop();
        authService.signout();
      });

      idle.onIdleEnd.subscribe(() => {
        this.reset();
      });

      // to-do on timeout warning
      idle.onTimeoutWarning.subscribe((countdown) => {
        this.countdown = this.timeoutNow = countdown;
        this.timeoutPercentage = (countdown / this.timeoutMax) * 100;
        this.initTimeoutWarningModal();
        this.forceLogout(60);   // force logout after 60 seconds
      });

      // sets the ping interval to 15 seconds
      keepalive.interval(15);

      globalService.isLoggedInChange.subscribe((value) => {
        this.isLoggedIn = value;
        if (this.isLoggedIn) {
          this.reset();
        }
        else {
          idle.stop();
        }
      });
    }
  }

  ngOnInit() {
  }

  reset() {
    if (this.isLoggedIn) {
      this.idle.watch();
      this.closeTimeoutWarningModal();
    }
  }

  initTimeoutWarningModal() {
    $('#session-timeout-dialog').modal('show');
  };

  forceLogout(sec) {
    var self = this;
    setTimeout(() => {
      if ($('#session-timeout-dialog').is(':visible')) {
        self.idle.stop();
        this.authService.signout();
      }
    }, sec * 1000);
  }

  closeTimeoutWarningModal() {
    $('#session-timeout-dialog').modal('hide');
  };

  logout() {
    this.authService.signout();
  }

}