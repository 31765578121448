import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UserManager } from "oidc-client";
import { downgradeInjectable } from '@angular/upgrade/static';
import { StorageService } from '../ej-shared/services/storage.service'
declare var angular: any;
import { PermissionService } from '../ej-shared/services/permission.service'
import { GlobalService } from '../ej-shared/services/global.service'
import { constants } from '../helpers/constants'
import { SharedService } from '../ej-shared/services/shared.service'
import { NavigatorService } from '../ej-shared/services/navigator.service'
import { CalendarService } from '../ej-shared/services/calendar.service'
import { NgxPermissionsService } from 'ngx-permissions';

@Injectable({
    providedIn: "root"
})

export class AuthService {

    private isLogged: boolean = false;
    private manager;
    private tenantId: string;
    private tenantDetail: any;
    currentUserInfo: any = new currentUserInfo();
    constructor(
        private router: Router,
        private storageService: StorageService,
        private readonly permissionService: PermissionService,
        private globalService: GlobalService,
        private readonly http: HttpClient,
        private tenantInitService: TenantInitService,
        private sharedService: SharedService,
        private readonly navigatorService: NavigatorService,
        private calendarService: CalendarService,
        private tenantRegistrationService: TenantRegistrationService,
        private readonly permissionsService: NgxPermissionsService) {
        this.tenantId = this.storageService.getTenantId();
        this.ngOnInit();
    }
    async ngOnInit() {
        if (window.location.pathname.includes('login') || window.location.pathname.includes('auth-callback') || window.location.pathname.includes('signin')) {
            this.sharedService.sendShowSetupEvent(true);
        }
        if (this.manager == undefined) {
            if (environment.commonLoginUrl == constants.host_url.replace('/client', '').toLowerCase()) {
                this.manager = new UserManager(getClientSettings('00000000-0000-0000-0000-000000000000'));
            }
            else if (!this.sharedService.isMember() && constants.admin_url.replace('/client', '').toLowerCase() != constants.host_url.replace('/client', '').toLowerCase() && !this.sharedService.isRedirectUrl()) {

                if (this.tenantId != null && this.tenantId != undefined)
                    this.manager = new UserManager(getClientSettings(this.tenantId));

                await this.GetAndSetTenantDetails();
                let result = await this.isLoggedin();
                this.globalService.setIsLoggedIn(result);

                let otac = '';
                if (constants.admin_url.toLowerCase() != constants.host_url.toLowerCase()) {
                    if (window.location.pathname.includes('login')) {
                        otac = window.location.pathname;
                        otac = otac.replace("/login", '');
                        otac = otac.replace("/", '');
                        this.storageService.setOTAC(otac);
                    } else {
                        this.storageService.setOTAC("");
                    }
                }
            } else {
                this.manager = new UserManager(getClientSettings('00000000-0000-0000-0000-000000000000'));
            }
        }
    }

    async GetAndSetTenantDetails() {
        await this.getTenantDetail().then(async (res: any) => {
            this.tenantDetail = res;
            if (res.tenant.id == "00000000-0000-0000-0000-000000000000") {
                this.router.navigate(['/tenantnotfound']);
                //this.navigatorService.navigate('/tenantnotfound', false);
            } else {
                this.storageService.setTenantId(res.tenant.id);
                //this.storageService.setTenantLogo(res.tenantLogoPath);
                this.storageService.setTrainerType(res.trainerType);
                this.storageService.setTenantName(res.tenant.firstName);
                this.tenantId = res.tenant.id;
                this.manager = new UserManager(getClientSettings(this.tenantId));
                await this.tenantInitService.checkPrimaryInitialSetupStatus();
                if (res.trainerType == 1 || res.trainerType == 3) {
                    this.tenantInitService.checkTrainerPrimaryInitialSetupStatus();
                }
            }
        });
    }

    async login() {
        var isSignInTokenUser = this.storageService.getIsSignInTokenUser();
        if (isSignInTokenUser != null && isSignInTokenUser.toString() == 'true') {
            this.completeAuthenticationSignIn(null);
        }
        else {
            if (this.manager == undefined) {
                if (environment.commonLoginUrl == constants.host_url.replace('/client', '').toLowerCase()) {
                    this.manager = new UserManager(getClientSettings('00000000-0000-0000-0000-000000000000'));
                }
                else if (!this.sharedService.isMember() && constants.admin_url.replace('/client', '').toLowerCase() != constants.host_url.replace('/client', '').toLowerCase() && !this.sharedService.isRedirectUrl()) {
                    if (this.tenantId != null && this.tenantId != undefined)
                        this.manager = new UserManager(getClientSettings(this.tenantId));

                    await this.GetAndSetTenantDetails();
                    this.globalService.setIsLoggedIn(true);

                    await this.getCountryCodes().then(res => {
                        this.storageService.setCountryCodes(res);
                    });
                    await this.globalService.setPhoneCountries();
                    await this.globalService.setCurrency(null);
                    await this.globalService.setInternationalCurrency(null);

                    return this.manager.signinRedirect();
                } else {
                    this.manager = new UserManager(getClientSettings('00000000-0000-0000-0000-000000000000'));
                    return this.manager.signinRedirect();
                }
            } else {
                return this.manager.signinRedirect();
            }
        }
    }

    async completeAuthentication() {
        await this.manager.signinRedirectCallback().then(async user => {
            this.storageService.setCurrentUser(user);
            this.isLogged = true;
            this.globalService.setIsLoggedIn(true);
            this.storageService.setIsSignInTokenUser(false);
            await this.getCountryCodes().then(res => {
                this.storageService.setCountryCodes(res);
            });
            await this.globalService.setPhoneCountries();
            await this.globalService.setCurrency(null);
            await this.globalService.setInternationalCurrency(null);
            this.goToApplication();
        });
    }

    signinSilentCallback() {
        this.manager.signinSilentCallback()
            .catch((err) => {
                console.log(err);
            });
    }

    async isAuthenticated(): Promise<boolean> {
        let result = await this.isLoggedin();
        if (!result) {
            this.storageService.clearCurrentUser();
            this.login();
        }
        return result;
    }

    public async isLoggedin(): Promise<boolean> {
        var isUserLoggedin = false;
        let user = this.storageService.getCurrentUser();
        if (JSON.parse(user)) {
            let currentUser = JSON.parse(user);
            var sub = currentUser.profile.sub;
            var expires_at = currentUser.expires_at;
            var expiry_date = new Date(expires_at * 1000);
            isUserLoggedin = (sub && expiry_date > new Date());
        }
        if (isUserLoggedin && !this.isLogged) {
            await this.getPermissions();
            this.isLogged = true;
            if (this.tenantId != null && this.tenantId != undefined) {
                this.globalService.setIsTenant(true);
                await this.tenantInitService.setTenantInit();
            }
        }
        return isUserLoggedin;
    }

    public isUserLoggedin() {
        var isUserLoggedin = false;
        let user = this.storageService.getCurrentUser();
        if (JSON.parse(user)) {
            let currentUser = JSON.parse(user);
            var sub = currentUser.profile.sub;
            var expires_at = currentUser.expires_at;
            var expiry_date = new Date(expires_at * 1000);
            isUserLoggedin = (sub && expiry_date > new Date());
        }
        return isUserLoggedin;
    }

    async getPermissions(): Promise<any> {
        //Get User Permissions
        if (!this.sharedService.isMember()) {
            await this.permissionService.GetUserPermissionById().then(response => {
                this.globalService.setUserPermissionData(response.item1);
                this.globalService.setLoggedUser(response.item2);
                this.permissionsService.loadPermissions(response.item1);
            });
        } else {
            //load member permissions
        }
    }

    async signout() {
        let user = JSON.parse(this.storageService.getCurrentUser());
        this.storageService.clearCurrentUser();
        this.globalService.setIsLoggedIn(false);
        if (user) {
            await this.manager.signoutRedirect({ "id_token_hint": user.id_token, "post_logout_redirect_uri": encodeURI(ClientSettings.post_logout_redirect_uri) });
        }
        else {
            await this.manager.signoutRedirect({ "post_logout_redirect_uri": encodeURI(ClientSettings.post_logout_redirect_uri) });
        }
    }

    async goToApplication() {
        if (!this.sharedService.isMember() && this.tenantId != '00000000-0000-0000-0000-000000000000' && this.tenantId != null && this.tenantId != undefined) {
            this.globalService.setIsTenant(true);
            await this.goToTenantApp();
        }
        else {
            this.globalService.setIsTenant(false);
            if (this.sharedService.isMember()) {
                this.router.navigate(['/member/events']);
            }
            if (this.sharedService.isAdmin()) {
                // Check if admindashboard permission available
                // Else goto first url in the menu
                this.globalService.userPermissionDataChange.subscribe(async (value) => {
                    if (value.includes("Permissions.Dashboard"))
                        this.router.navigate(['/admindashboard']);
                    else
                        await this.goToFirstMenuScreen();
                });
            }
            this.sharedService.sendShowSetupEvent(false);
        }
    }

    async goToFirstMenuScreen() {
        var firstMenuUrl = '';
        var menu = this.globalService.menuData;
        if (menu == undefined || menu == null || menu.length == 0) {
            await this.getMenu().then(res => {
                firstMenuUrl = this.getFirstMenuUrl(res);
                this.router.navigate([firstMenuUrl]);
            });
        }
        else {
            firstMenuUrl = this.getFirstMenuUrl(menu);
            this.router.navigate([firstMenuUrl]);
        }
    }

    getFirstMenuUrl(menu) {
        var firstMenuUrl = '';
        if (menu != undefined && menu != null && menu.length > 0) {
            if (menu[0].items != null && menu[0].items.length > 0) {
                firstMenuUrl = menu[0].items[0].url;
            }
            else {
                firstMenuUrl = menu[0].url;
            }
        }
        return firstMenuUrl;
    }

    async goToTenantApp() {
        await this.tenantInitService.getTenantSubscriptionExpiryInfo().then(async (response: any) => {
            if (response.IsAccountExpired) {
                this.gotoTenantExpired();
                this.sharedService.sendShowSetupEvent(false);
            }
            else {
                if (this.globalService.getPrimaryInitialSetupCompleted()) {
                    if (this.storageService.getTrainerType() == 3) {
                        this.navigatorService.navigate('myapp/courses', this.storageService.getIsSignInTokenUser());
                    }
                    else {
                        // Check if dashboard permission available
                        // Else goto first url in the menu
                        var permissionList = this.globalService.getUserPermissionData();
                        if (permissionList != null && permissionList != undefined && permissionList.length > 0) {
                            if (permissionList.indexOf("Permissions.Dashboard") != -1) {
                                //this.navigatorService.navigate('/dashboard', this.storageService.getIsSignInTokenUser());
                                this.router.navigate(['/dashboard']);
                            }
                            else {
                                await this.goToFirstMenuScreen();
                            }
                        }
                        else {
                            this.globalService.userPermissionDataChange.subscribe(async (value) => {
                                if (value.includes("Permissions.Dashboard"))
                                    //this.navigatorService.navigate('/dashboard', this.storageService.getIsSignInTokenUser());
                                    this.router.navigate(['/dashboard']);
                                else
                                    await this.goToFirstMenuScreen();
                            });
                        }
                    }
                    this.sharedService.sendShowSetupEvent(false);
                }
                else {
                    await this.getTenantCourseSetupAssistance(this.tenantId).then(async (resp: any) => {
                        if (resp.id == '00000000-0000-0000-0000-000000000000') {
                            this.navigatorService.navigate('myapp/welcome', false);
                            this.sharedService.sendShowSetupEvent(false);
                        }
                        else {
                            await this.gotoTenantGettingStarted();
                        }
                    })
                }
                this.globalService.setIsTenant(true);
            }
        })
    }

    async gotoTenantGettingStarted() {
        await this.getTenantDetail().then((res: any) => {
            this.tenantDetail = res;
            this.storageService.setTenantId(res.tenant.id);
            this.storageService.setTrainerType(res.trainerType);
            this.storageService.setTenantName(res.tenant.firstName);
            if (res.trainerType == 1 || res.trainerType == 3) {
                this.navigatorService.navigate('myapp/createcourse', false);
                this.sharedService.sendShowSetupEvent(false);
            }
            else {
                this.router.navigate(['/gettingstarted']);
                //this.navigatorService.navigate('/gettingstarted', false);
                this.sharedService.sendShowSetupEvent(false);
            }
        });
    }

    gotoWebsite() {
        this.router.navigate(['/'])
    }
    async getTenantDetail(): Promise<any> {
        if (this.tenantDetail != null && this.tenantDetail != undefined) {
            return this.tenantDetail;
        }
        else {
            return await this.http.get(constants.url_constants.api + '/api/TenantResolverApi/GetTenantDetail').toPromise();
        }
    }

    async getTenantDetails(): Promise<any> {
        return await this.http.get(constants.url_constants.api + '/api/TenantResolverApi/GetTenantDetail').toPromise();
    }

    async getCountryCodes(): Promise<any> {
        return await this.http.get(constants.url_constants.api + '/api/LayoutApi/GetCountryCodes').toPromise();
    }

    async getTenantCourseSetupAssistance(tenantId): Promise<any> {
        return await this.http.get(constants.url_constants.api + '/api/TenantsApi/GetTenantCourseSetupAssistance/' + tenantId).toPromise();
    }

    async getMenu(): Promise<any> {
        return await this.http.get(constants.url_constants.api + '/api/LayoutApi/GetMenu').toPromise();
    }

    gotoServerError() {
        this.router.navigate(['/servererror']);
    }

    gotoUnauthorized() {
        this.router.navigate(['/unauthorized']);
    }

    gotoTenantExpired() {
        this.router.navigate(['/subscriptionexpired']);
    }

    completeAuthenticationSignIn(loginres: TokenfromURL) {
        if (loginres) {
            this.currentUserInfo.access_token = loginres.tokenResponse.accessToken;
            this.currentUserInfo.token_type = loginres.tokenResponse.tokenType;
            this.currentUserInfo.expires_at = Math.round((new Date).getTime() / 1000) + parseInt(loginres.tokenResponse.expiresIn.toString());
            this.currentUserInfo.refresh_token = loginres.tokenResponse.refreshToken;
            this.storageService.setCurrentUser(this.currentUserInfo);
        }
        else {
            let cUser = this.storageService.getCurrentUser();
            if (JSON.parse(cUser)) {
                this.currentUserInfo = JSON.parse(cUser);
            }
        }

        this.signinUserinfo().then(async user => {
            this.currentUserInfo.profile = user;
            if (user) {
                this.storageService.setCurrentUser(this.currentUserInfo);

                await this.GetAndSetTenantDetails();
                this.isLogged = false;
                await this.isLoggedin();
                this.globalService.setIsLoggedIn(true);

                this.storageService.setIsSignInTokenUser(true);

                await this.getCountryCodes().then(res => {
                    this.storageService.setCountryCodes(res);
                });
                await this.globalService.setPhoneCountries();
                await this.globalService.setCurrency(null);
                await this.globalService.setInternationalCurrency(null);

                // Saving Last Login details for Sign-in flow
                // For Normal login flow, last login is being saved from auth-callback component
                await this.getIP().then(res => {
                    if (res.ip) {
                        let apiReq = { 'Query': res.ip };
                        this.saveTenantLoginDetails(apiReq).then(res2 => { });
                    }
                });

                this.goToApplication();
            } else { }
        }).catch((error) => {
            console.info(error);
            return this.manager.signinRedirect();
        });
    }

    signinUserinfo(): Promise<any> {
        return this.http.get(constants.url_constants.api + '/connect/userinfo').toPromise();
    }

    async getIP(): Promise<any> {
        return await this.http.get('https://api.ipify.org?format=json').toPromise();
    }

    async saveTenantLoginDetails(apiReq: any): Promise<any> {
        return await this.http.post(constants.url_constants.api + '/api/TenantsApi/SaveTenantLastLogin', apiReq).toPromise();
    }
}

import { ClientSettings, environment } from '../environments/environment'
import * as Oidc from 'oidc-client';
import { Router } from '@angular/router';
import { TenantInitService } from '../ej-shared/services/tenant-init.service';
import { TenantRegistrationService } from "src/ej-ts/admin-portal/tenant-registration/tenant-registration.service";
import { currentUserInfo, TokenfromURL } from './model/login';

export function getClientSettings(tenantId: string) {
    let otac = '';
    if (constants.admin_url.replace('/client', '').toLowerCase() != constants.host_url.replace('/client', '').toLowerCase()) {
        if (window.location.pathname.includes('login')) {
            otac = window.location.pathname.replace("/client", '');
            otac = otac.replace("/login", '');
            otac = otac.replace("/", '');
        }
    }
    return {
        authority: ClientSettings.authority,
        client_id: ClientSettings.client_id,
        redirect_uri: ClientSettings.redirect_uri,
        post_logout_redirect_uri: ClientSettings.post_logout_redirect_uri,
        response_type: ClientSettings.response_type,
        scope: ClientSettings.scope,
        silent_redirect_uri: ClientSettings.silent_redirect_uri,
        automaticSilentRenew: ClientSettings.automaticSilentRenew,
        accessTokenExpiringNotificationTime: ClientSettings.accessTokenExpiringNotificationTime,
        silentRequestTimeout: ClientSettings.silentRequestTimeout,
        revokeAccessTokenOnSignout: ClientSettings.revokeAccessTokenOnSignout,
        filterProtocolClaims: ClientSettings.filterProtocolClaims,
        acr_values: "tenant:" + tenantId + " otac:" + otac,
        userStore: new Oidc.WebStorageStateStore({ store: window.localStorage })
    };
}

export function getSignInUserClientSettings() {
    let otac = '';
    if (window.location.pathname.includes('login')) {
        otac = window.location.pathname.replace("/client", '');
        otac = otac.replace("/login", '');
        otac = otac.replace("/", '');
    }
    return {
        clientId: ClientSettings.client_id,
        scope: ClientSettings.scope,
        otac: otac
    };
}

angular.module('core')
    .factory('authService', downgradeInjectable(AuthService));