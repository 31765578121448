import { Component, OnInit } from '@angular/core';
import { AuthService } from "../auth.service";
import { SharedService } from '../../ej-shared/services/shared.service'
import { PermissionService } from 'src/ej-shared/services/permission.service';
import { GlobalService } from 'src/ej-shared/services/global.service';
import { HttpClient } from '@angular/common/http';
import { constants } from '../../helpers/constants'
import { LoaderService } from 'src/ej-shared/services/loader.service';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html'
})
export class AuthCallbackComponent implements OnInit {

  error: boolean;

  constructor(
    private readonly authService: AuthService,
    private readonly sharedService: SharedService,
    private readonly permissionService: PermissionService,
    private globalService: GlobalService,
    private readonly http: HttpClient,
    private loaderService: LoaderService,
    private readonly permissionsService: NgxPermissionsService
  ) { }

  async ngOnInit() {
    //this.sharedService.sendShowSetupEvent(true);
    this.loaderService.blockLoader = true;
    this.loaderService.hideLoader();
    await this.authService.completeAuthentication();
    this.sharedService.sendClickEvent();

    // menu load event
    this.sharedService.sendMenuEvent();

    //Get User Permissions
    if (!this.sharedService.isMember()) {
      this.permissionService.GetUserPermissionById().then(response => {
        this.globalService.setUserPermissionData(response.item1);
        this.globalService.setLoggedUser(response.item2);
        this.permissionsService.loadPermissions(response.item1);
      });
    } else {
      //load student permissions
    }
    if (!this.sharedService.isMember() && !this.sharedService.isAdmin() && !this.sharedService.isRedirectUrl()) {
      await this.getIP().then(res => {
        if (res.ip) {
          let apiReq = { 'Query': res.ip };
          this.saveTenantLoginDetails(apiReq).then(res2 => { });
        }
      });
    }
  }

  async getIP(): Promise<any> {
    return await this.http.get('https://api.ipify.org?format=json').toPromise();
  }

  async saveTenantLoginDetails(apiReq: any): Promise<any> {
    return await this.http.post(constants.url_constants.api + '/api/TenantsApi/SaveTenantLastLogin', apiReq).toPromise();
  }
}