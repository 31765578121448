<div id="kt_header" class="kt-header kt-grid__item  kt-header--fixed">
    <button class="kt-header-menu-wrapper-close" id="kt_header_menu_mobile_close_btn"><i
            class="la la-close"></i></button>
    <ej-header-menu class="kt-header-menu-wrapper" id="kt_header_menu_wrapper"></ej-header-menu>
    <div class="kt-header__topbar">
        <ej-tenant-verification-popup  *ngIf="isTenant"></ej-tenant-verification-popup>
        <ej-topbar-quick-panel class="kt-header__topbar-item kt-header__topbar-item--quick-panel"
            data-toggle="kt-tooltip" data-placement="bottom" ngbTooltip="Settings">
        </ej-topbar-quick-panel>
        <!-- <ej-topbar-search class="kt-header__topbar-item kt-header__topbar-item--search dropdown" id="kt_quick_search_toggle">
        </ej-topbar-search> -->
        <ej-quick-action *ngIf="!isAdmin && !isTenantExpired && !isMember && trainerType!=3"
            class="kt-header__topbar-item kt-header__topbar-item--quick-panel"></ej-quick-action>
        <ej-topbar-user class="kt-header__topbar-item kt-header__topbar-item--user"></ej-topbar-user>
    </div>
</div>
