/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./auth-callback.component";
import * as i2 from "../auth.service";
import * as i3 from "../../ej-shared/services/shared.service";
import * as i4 from "../../ej-shared/services/permission.service";
import * as i5 from "../../ej-shared/services/global.service";
import * as i6 from "@angular/common/http";
import * as i7 from "../../ej-shared/services/loader.service";
import * as i8 from "ngx-permissions";
var styles_AuthCallbackComponent = [];
var RenderType_AuthCallbackComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AuthCallbackComponent, data: {} });
export { RenderType_AuthCallbackComponent as RenderType_AuthCallbackComponent };
export function View_AuthCallbackComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_AuthCallbackComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-auth-callback", [], null, null, null, View_AuthCallbackComponent_0, RenderType_AuthCallbackComponent)), i0.ɵdid(1, 114688, null, 0, i1.AuthCallbackComponent, [i2.AuthService, i3.SharedService, i4.PermissionService, i5.GlobalService, i6.HttpClient, i7.LoaderService, i8.NgxPermissionsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AuthCallbackComponentNgFactory = i0.ɵccf("app-auth-callback", i1.AuthCallbackComponent, View_AuthCallbackComponent_Host_0, {}, {}, []);
export { AuthCallbackComponentNgFactory as AuthCallbackComponentNgFactory };
