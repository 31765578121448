import { downgradeInjectable } from '@angular/upgrade/static';
import * as i0 from "@angular/core";
import * as i1 from "./shared.service";
export class StorageService {
    constructor(sharedService) {
        this.sharedService = sharedService;
    }
    // Used to get the display name in header
    getLoggedinUserName() {
        let objUserDetails = JSON.parse(window.localStorage.getItem('currentUser'));
        if (this.getTrainerType() == 1 || this.getTrainerType() == 3) {
            return this.getTenantName();
        }
        else {
            if (objUserDetails != null && objUserDetails != undefined && objUserDetails.profile != null && objUserDetails.profile != undefined) {
                return objUserDetails.profile.given_name == undefined ? objUserDetails.profile.preferred_username : objUserDetails.profile.given_name;
                //return objUserDetails.profile.preferred_username == undefined ? objUserDetails.profile.name : objUserDetails.profile.preferred_username;
            }
            else {
                // Something went wrong with current user
                return '';
            }
        }
    }
    setCurrentUser(user) {
        window.localStorage.setItem('currentUser', JSON.stringify(user));
    }
    clearCurrentUser() {
        window.localStorage.removeItem('currentUser');
        window.localStorage.removeItem('tenantid');
        window.localStorage.removeItem('tenantlogo');
        window.localStorage.removeItem('issignintokenuser');
        window.localStorage.clear();
    }
    getCurrentUser() {
        return window.localStorage.getItem('currentUser');
    }
    getAccessToken() {
        let currentUser = JSON.parse(this.getCurrentUser());
        if (currentUser && currentUser.access_token) {
            return currentUser.access_token;
        }
        else {
            return '';
        }
    }
    getUserId() {
        let currentUser = JSON.parse(this.getCurrentUser());
        if (currentUser && currentUser.access_token) {
            return currentUser.profile.sub;
        }
        else {
            return '';
        }
    }
    setTenantId(tenantId) {
        window.localStorage.setItem('tenantid', tenantId);
    }
    getTenantId() {
        return window.localStorage.getItem('tenantid');
    }
    setTenantLogo(logo) {
        window.localStorage.setItem('tenantlogo', logo);
        //this.sharedService.sendLogoChangeEvent();
    }
    getTenantLogo() {
        return window.localStorage.getItem('tenantlogo');
    }
    setOTAC(tenantId) {
        window.localStorage.setItem('otac', tenantId);
    }
    getOTAC() {
        return window.localStorage.getItem('otac');
    }
    setCountryCodes(data) {
        window.localStorage.setItem('countrycodes', JSON.stringify(data));
    }
    getCountryCodes() {
        return JSON.parse(window.localStorage.getItem('countrycodes'));
    }
    setPhoneCountryCodes(data) {
        window.localStorage.setItem('phonecountrycodes', JSON.stringify(data));
    }
    getPhoneCountryCodes() {
        return JSON.parse(window.localStorage.getItem('phonecountrycodes'));
    }
    setTrainerType(data) {
        window.localStorage.setItem('trainertype', JSON.stringify(data));
    }
    getTrainerType() {
        return JSON.parse(window.localStorage.getItem('trainertype'));
    }
    setTenantName(data) {
        window.localStorage.setItem('tenantname', JSON.stringify(data));
    }
    getTenantName() {
        return JSON.parse(window.localStorage.getItem('tenantname'));
    }
    setCurrency(data) {
        window.localStorage.setItem('currency', JSON.stringify(data));
    }
    getCurrency() {
        return JSON.parse(window.localStorage.getItem('currency'));
    }
    setInternationalCurrency(data) {
        window.localStorage.setItem('internationalcurrency', JSON.stringify(data));
    }
    getInternationalCurrency() {
        return JSON.parse(window.localStorage.getItem('internationalcurrency'));
    }
    setIsSignInTokenUser(data) {
        window.localStorage.setItem('issignintokenuser', JSON.stringify(data));
    }
    getIsSignInTokenUser() {
        return JSON.parse(window.localStorage.getItem('issignintokenuser'));
    }
}
StorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StorageService_Factory() { return new StorageService(i0.ɵɵinject(i1.SharedService)); }, token: StorageService, providedIn: "root" });
angular.module('core')
    .factory('storageService', downgradeInjectable(StorageService));
