import { downgradeInjectable } from '@angular/upgrade/static';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./storage.service";
export class NavigatorService {
    constructor(router, storageService) {
        this.router = router;
        this.storageService = storageService;
    }
    navigate(dest, reload) {
        if (dest == 'courses' || dest == '/courses') {
            dest = '/myapp/courses';
        }
        if (window.location.pathname.includes('adminapp') || dest.includes('adminapp')
            || window.location.pathname.includes('member') || dest.includes('member')
            || window.location.pathname.includes('myapp') || dest.includes('myapp')) {
            this.router.navigate([dest]);
        }
        else if (reload) {
            window.location.href = dest;
        }
        else {
            let elem = document.getElementById('btnNavigate');
            elem.setAttribute('href', dest);
            elem.click();
        }
    }
}
NavigatorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NavigatorService_Factory() { return new NavigatorService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.StorageService)); }, token: NavigatorService, providedIn: "root" });
angular.module('core')
    .factory('navigatorService', downgradeInjectable(NavigatorService));
