import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { constants } from 'src/helpers/constants';
import { Trainer } from './trainer.model'
import { HttpHeaders } from '@angular/common/http'
import { downgradeInjectable } from '@angular/upgrade/static';
declare var angular: any;

@Injectable({
    providedIn: 'root'
})

export class TrainerGettingStartedService {
    constructor(private http: HttpClient) { }
    async getIP(): Promise<any> {
        return await this.http.get('https://api.ipify.org?format=json').toPromise();
    }

    async getTenantLoggedInCountry(): Promise<any> {
        var ip = "";
        await this.getIP().then(res =>
            ip = res.ip);
        return await this.http.get(constants.url_constants.api + '/api/TenantsApi/GetTenantLoggedinCountryDetails?ipAddress=' + ip).toPromise();
    }
    
    async saveTrainerProfileDetails(trainer: Trainer): Promise<any> {
        return await this.http.post(constants.url_constants.api + '/api/TrainerProfileApi/SaveTrainerProfile', trainer).toPromise();
    }
}

angular.module('core')
    .factory('trainerGettingStartedService', downgradeInjectable(TrainerGettingStartedService));