<!--begin: User Bar -->
<div class="kt-header__topbar-item kt-header__topbar-item--user">
    <div class="kt-header__topbar-wrapper" data-toggle="dropdown" data-offset="0px,0px" joyrideStep="Profile"
        [stepContent]="myProfileContent" stepPosition="bottom" ngbTooltip="Manage Profile">
        <div class="kt-header__topbar-user">
            <span class="kt-header__topbar-welcome kt-hidden-mobile">Hi,</span>
            <span class="kt-header__topbar-username kt-hidden-mobile">{{username }}</span>
            <span
                class="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-primary">{{username
                | slice:0:1 | uppercase }}</span>
        </div>
    </div>
    <div
        class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
        <!--begin: Head -->
        <div class="kt-user-card kt-user-card--skin-light kt-notification-item-padding-x">
            <div class="kt-user-card__avatar">
                <span
                    class="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-primary kt-badge--unified-primary">{{username
                    | slice:0:1 | uppercase }}</span>
            </div>
            <div class="kt-user-card__name kt-font-dark">
                {{username | uppercase}}
            </div>
            <div class="kt-user-card__badge">

            </div>
        </div>

        <!--end: Head -->
        <!--begin: Navigation -->
        <div class="kt-notification">
            <a class="kt-notification__item" (click)="redirectToMyProfile()" href="javascript:void(0)" *ngIf="!isMember">
                <div class="kt-notification__item-icon">
                    <i class="fa fa-user-cog kt-font-primary"></i>
                </div>
                <div class="kt-notification__item-details">
                    <div class="kt-notification__item-title kt-font-primary">
                        My Profile
                    </div>
                    <div class="kt-notification__item-time">
                        Manage your profile
                    </div>
                </div>
            </a>
            <a class="kt-notification__item" *ngIf="!isAdmin && !isMember && (trainerType == 2)" (click)="gotoWebsite()"
                href="javascript:void(0)">
                <div class="kt-notification__item-icon">
                    <i class="fa fa-home kt-font-primary"></i>
                </div>
                <div class="kt-notification__item-details">
                    <div class="kt-notification__item-title kt-font-primary">
                        Go to Website
                    </div>
                    <div class="kt-notification__item-time">
                        Manage your Website
                    </div>
                </div>
            </a>

            <a href="#" class="kt-notification__item" *ngIf="isMember">
                <div class="kt-notification__item-icon">
                    <i class="flaticon2-calendar-3 kt-font-primary"></i>
                </div>
                <div class="kt-notification__item-details">
                    <div class="kt-notification__item-title kt-font-bold">
                        My Profile
                    </div>
                    <div class="kt-notification__item-time">
                        Account settings and more
                    </div>
                </div>
            </a>
            <a href="#" class="kt-notification__item" *ngIf="isMember">
                <div class="kt-notification__item-icon">
                    <i class="flaticon2-mail kt-font-warning"></i>
                </div>
                <div class="kt-notification__item-details">
                    <div class="kt-notification__item-title kt-font-bold">
                        My Bookings
                    </div>
                    <div class="kt-notification__item-time">
                        Registered Courses
                    </div>
                </div>
            </a>
            <a href="#" class="kt-notification__item" *ngIf="isMember">
                <div class="kt-notification__item-icon">
                    <i class="flaticon-like kt-font-danger"></i>
                </div>
                <div class="kt-notification__item-details">
                    <div class="kt-notification__item-title kt-font-bold">
                        My Wishlist
                    </div>
                    <div class="kt-notification__item-time">
                        Liked Courses
                    </div>
                </div>
            </a>
            <div class="kt-notification__custom kt-space-between">
                <a href="javascript:;" (click)="logout()" class="btn btn-label btn-label-primary btn-sm btn-bold">Sign
                    Out</a>
                <a *ngIf="isMember" href="javascript:;" class="btn btn-label btn-label-primary btn-sm btn-bold">Upgrade
                    Plan
                </a>
            </div>
        </div>

        <!--end: Navigation -->
    </div>
</div>

<!--end: User Bar -->

<ng-template #myProfileContent>
    <p class="text-dark-75 font-weight-bolder font-size-h5 m-0">Manage your profile and change password.
    </p>
</ng-template>
<joyrideStep joyrideStep="Welcome" stepPosition="center" ngbTooltip="Welcome to eduJournal" [stepContent]="welcomeContent">
</joyrideStep>
<ng-template #welcomeContent>
    <p>Thank you for registering with us.</p>
    <p class="text-dark-75 font-weight-bolder font-size-h5 m-0"> <b>eduJournal</b> is the easiest way for
        education institutes to manage its operations and students.
    </p><br>
    <p>Let us help you to start using <b>eduJournal</b> with a simple tour.</p>
</ng-template>