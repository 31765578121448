import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { constants } from 'src/helpers/constants';
import { WeeklySession, Dailyschedule, CourseViewModel, AdditionalWeeklySession, AdditionalDailySchedule } from './course.model';

@Injectable({
    providedIn: 'root'
})

export class NewCourseService {

    constructor(private http: HttpClient) { }

    async getCourseDropDownDetails(tenantId = "00000000-0000-0000-0000-000000000000"): Promise<any> {
        return await this.http.get(constants.url_constants.api + '/api/TrainingProgramApi/GetCourseDropDownDetails/' + tenantId).toPromise();
    }

    async uploadBannerImage(file: any, id: string, tenantId = "00000000-0000-0000-0000-000000000000"): Promise<any> {
        return await this.http.post(constants.url_constants.api + '/api/TrainingProgramApi/UploadBannerImage/' + tenantId + '?id=' + id, file).toPromise();
    }

    async uploadTrainerPicForCourse(file: any, id: string, tenantId = "00000000-0000-0000-0000-000000000000"): Promise<any> {
        return await this.http.post(constants.url_constants.api + '/api/TrainingProgramApi/UploadTrainerPicForCourse/' + tenantId + '?id=' + id, file).toPromise();
    }

    async saveTrainingProgram(course: any): Promise<any> {
        return await this.http.post(constants.url_constants.api + '/api/TrainingProgramApi/Save', course).toPromise();
    }

    async saveCourseNew(course: any): Promise<any> {
        return await this.http.post(constants.url_constants.api + '/api/TrainingProgramApi/SaveTrainingProgramNew', course).toPromise();
    }

    async saveAndPublishCourseNew(course: any): Promise<any> {
        return await this.http.post(constants.url_constants.api + '/api/TrainingProgramApi/SaveAndPublishTrainingProgram', course).toPromise();
    }

    async getTrainingProgrambyTenantID(nextPageNumber, courseFilter): Promise<any> {
        return await this.http.post(constants.url_constants.api + '/api/TrainingProgramApi/GetTrainingProgrambyTenantID/' + nextPageNumber, courseFilter).toPromise();
    }
    async getTrainingProgrambyTenantIDForEnrolment(nextPageNumber, courseFilter): Promise<any> {
        return await this.http.post(constants.url_constants.api + '/api/TrainingProgramApi/getTrainingProgrambyTenantIDForEnrolment/' + nextPageNumber, courseFilter).toPromise();
    }

    async getTrainingProgramStatusCount(): Promise<any> {
        return await this.http.get(constants.url_constants.api + '/api/TrainingProgramApi/GetTrainingProgramStatusCount').toPromise();
    }

    async getTrainingProgrambyID(id, tenantId = "00000000-0000-0000-0000-000000000000"): Promise<any> {
        return await this.http.get(constants.url_constants.api + '/api/TrainingProgramApi/GetTrainingProgrambyId/' + id + '/' + tenantId).toPromise();
    }
    async getTrainingProgrambyIdForSocialShare(id, tenantId = "00000000-0000-0000-0000-000000000000"): Promise<any> {
        return await this.http.get(constants.url_constants.api + '/api/TrainingProgramApi/GetTrainingProgrambyIdForSocialShare/' + id + '/' + tenantId).toPromise();
    }


    async deleteTrainingProgrambyId(id): Promise<any> {
        return await this.http.get(constants.url_constants.api + '/api/TrainingProgramApi/DeleteTrainingProgrambyId/' + id).toPromise();
    }
    async UpdateCourseOnWebsite(courseWebsitePostModel: any): Promise<any> {
        return await this.http.post(constants.url_constants.api + '/api/TrainingProgramApi/UpdateCourseOnWebsite', courseWebsitePostModel).toPromise();
    }
    async uploadCourseDocuments(file: any, tenantId = "00000000-0000-0000-0000-000000000000"): Promise<any> {
        return await this.http.post(constants.url_constants.api + '/api/TrainingProgramApi/UploadCourseDocuments/' + tenantId, file).toPromise();
    }
    async deleteWeeklySession(additionalWeeklySession: AdditionalWeeklySession, courseId: string, tenantId = "00000000-0000-0000-0000-000000000000"): Promise<any> {
        return await this.http.post(constants.url_constants.api + '/api/TrainingProgramApi/DeleteWeeklySession/' + tenantId + '?courseId=' + courseId, additionalWeeklySession).toPromise();
    }
    async updateWeeklySession(additionalWeeklySession: AdditionalWeeklySession, courseId: string, tenantId = "00000000-0000-0000-0000-000000000000"): Promise<any> {
        return await this.http.post(constants.url_constants.api + '/api/TrainingProgramApi/UpdateWeeklySession/' + tenantId + '?courseId=' + courseId, additionalWeeklySession).toPromise();
    }
    async getTimeZonesByCountryCode(countryCode, tenantId = "00000000-0000-0000-0000-000000000000"): Promise<any> {
        return await this.http.get(constants.url_constants.api + '/api/TrainingProgramApi/GetTimeZonesByCountryCode/' + countryCode + '/' + tenantId).toPromise();
    }
    async getAllTimeZones(tenantId = "00000000-0000-0000-0000-000000000000"): Promise<any> {
        return await this.http.get(constants.url_constants.api + '/api/TrainingProgramApi/GetAllTimeZones/' + tenantId).toPromise();
    }
    async rescheduleDailySchedule(dailyschedule: AdditionalDailySchedule, courseId: string): Promise<any> {
        return await this.http.post(constants.url_constants.api + '/api/TrainingProgramApi/RescheduleDailySchedule?courseId=' + courseId, dailyschedule).toPromise();
    }
    async cancelDailySchedule(additionalDailySchedule: AdditionalDailySchedule, courseId: string): Promise<any> {
        return await this.http.post(constants.url_constants.api + '/api/TrainingProgramApi/CancelDailySchedule?courseId=' + courseId, additionalDailySchedule).toPromise();
    }
    async getCountryCodeByTimeZoneId(timeZoneId, tenantId = "00000000-0000-0000-0000-000000000000"): Promise<any> {
        return await this.http.get(constants.url_constants.api + '/api/TrainingProgramApi/GetCountryCodeByTimeZoneId/' + tenantId + '?timeZoneId=' + timeZoneId).toPromise();
    }
    async getManageCourseSessionViewModel(id): Promise<any> {
        return await this.http.get(constants.url_constants.api + '/api/TrainingProgramApi/GetManageCourseSessionViewModel/' + id).toPromise();
    }
    async updateWeeklySessionLocation(weeklySession: WeeklySession, courseId: string, tenantId = "00000000-0000-0000-0000-000000000000"): Promise<any> {
        return await this.http.post(constants.url_constants.api + '/api/TrainingProgramApi/UpdateWeeklySessionLocation/' + tenantId + '?courseId=' + courseId, weeklySession).toPromise();
    }
    async updateDailySessionLocation(dailyschedule: Dailyschedule, courseId: string): Promise<any> {
        return await this.http.post(constants.url_constants.api + '/api/TrainingProgramApi/UpdateDailySessionLocation?courseId=' + courseId, dailyschedule).toPromise();
    }
    async ListTenants(): Promise<any> {
        return await this.http.post(constants.admin_url + '/api/TenantsApi/GetTenants', '{}').toPromise();
    }
    async getCountryCodes(tenantId = "00000000-0000-0000-0000-000000000000"): Promise<any> {
        return await this.http.get(constants.url_constants.api + '/api/LayoutApi/GetCountryCodes/' + tenantId).toPromise();
    }
    async getGivenSeaCountries(tenantId = "00000000-0000-0000-0000-000000000000"): Promise<any> {
        return await this.http.get(constants.url_constants.api + '/api/LayoutApi/GetGivenSeaCountries/' + tenantId).toPromise();
    }
    async getCurrencies(tenantId = "00000000-0000-0000-0000-000000000000"): Promise<any> {
        return await this.http.get(constants.url_constants.api + '/api/LayoutApi/GetCurrencies/' + tenantId).toPromise();
    }

    async getTrainingProgrambyTenantIDFromAdmin(nextPageNumber, courseFilter, tenantId): Promise<any> {
        return await this.http.post(constants.url_constants.api + '/api/TrainingProgramApi/GetTrainingProgrambyTenantIDFromAdmin/' + nextPageNumber + '/' + tenantId, courseFilter).toPromise();
    }
    async getTrainingProgramStatusCountFromAdmin(tenantId): Promise<any> {
        return await this.http.get(constants.url_constants.api + '/api/TrainingProgramApi/GetTrainingProgramStatusCountFromAdmin/' + tenantId).toPromise();
    }
    async checkPrimaryInitialSetupStatusByTenantId(tenantId): Promise<any> {
        return await this.http.get(constants.url_constants.api + '/api/InitialSetup/CheckPrimaryInitialSetupStatusByTenantId/' + tenantId).toPromise();
    }
    async getTrainingProgramContentSocialMedia(courseId): Promise<any> {
        return await this.http.get(constants.url_constants.api + '/api/TrainingProgramApi/GetTrainingProgramContentSocialMedia/' + courseId).toPromise();
    }

    async getTrainers(): Promise<any> {
        return await this.http.get(constants.url_constants.api + '/api/TrainingProgramApi/GetTrainers/').toPromise();
    }
    async saveNewTrainer(trainer): Promise<any> {
        return await this.http.post(constants.url_constants.api + '/api/TrainerApi/SaveNewTrainer/', trainer).toPromise();
    }
    async getTrainingProgrambyIdWithPrice(id, tenantId = "00000000-0000-0000-0000-000000000000"): Promise<any> {
        return await this.http.get(constants.url_constants.api + '/api/TrainingProgramApi/GetTrainingProgrambyIdWithPrice/' + id + '/' + tenantId).toPromise();
    }
    async getActiveCourses(tenantId): Promise<any> {
        return await this.http.get(constants.url_constants.api + '/api/TrainingProgramApi/GetActiveCourses/' + tenantId).toPromise();
    }
    async getActiveCoursesByTenants(tenantIdList): Promise<any> {
        return await this.http.post(constants.url_constants.api + '/api/TrainingProgramApi/GetActiveCoursesByTenants/', tenantIdList).toPromise();
    }

    async getTrainingProgramsByCategory(filter): Promise<any> {
        return await this.http.post(constants.url_constants.api + '/api/TrainingProgramApi/GetTrainingProgramsByCategory/', filter).toPromise();
    }
    async getTrainerScheduleForBatchCreation(trainerId): Promise<any> {
        return await this.http.get(constants.url_constants.api + '/api/TrainingProgramApi/GetTrainerScheduleForBatchCreation/' + trainerId).toPromise();
    }

    async GetTraningPrograms(): Promise<any> {
        return await this.http.get(constants.url_constants.api + '/api/TrainingProgramApi/GetTraningPrograms').toPromise();
    }
    async GetTraningProgramsByTenantId(tenantId): Promise<any> {
        return await this.http.get(constants.url_constants.api + '/api/TrainingProgramApi/GetTraningPrograms/' + tenantId).toPromise();
    }

    async getTrainingProgramSessionsForDemoBooking(id, selectedSessionId = "00000000-0000-0000-0000-000000000000"): Promise<any> {
        return await this.http.get(constants.url_constants.api + '/api/TrainingProgramApi/GetTrainingProgramSessionsForDemoBooking/' + id + '/' + selectedSessionId).toPromise();
    }

}