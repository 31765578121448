import * as tslib_1 from "tslib";
import { OnInit, ChangeDetectorRef, ViewRef } from '@angular/core';
//import { ToasterService } from 'src/ej-shared/services/toastr.service';
import Swal from 'sweetalert2';
import { GoogleCaptcha } from 'src/environments/environment';
export class CourseEnquiryComponent {
    constructor(tenantHomeService, cdRef, route, router, titleService
    //private readonly toasterService: ToasterService
    ) {
        this.tenantHomeService = tenantHomeService;
        this.cdRef = cdRef;
        this.route = route;
        this.router = router;
        this.titleService = titleService;
        this.ejLogoSrc = "assets/media/logos/ej_logo.png";
        this.tenantLogoSrc = "";
        this.courseEnquiryModel = {};
        this.hasDailyPrice = false;
        this.hasWeeklyPrice = false;
        this.enquiryModel = {};
        this.saveEnquiryBtnDisabled = false;
        this.errorRecaptchaRender = false;
        this.gRecaptchaResponse = "";
        this.phoneNumberError = false;
        this.phoneCountryCodeError = false;
        this.phoneNumberLengthError = false;
        this.loading = false;
        this.captchaSiteKey = GoogleCaptcha.site_key;
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.titleService.setTitle('eduJournal | Course Enquiry');
            window.scrollTo(0, 0);
            this.courseId = this.route.snapshot.paramMap.get('courseId');
            yield this.getCourseEnquiryModel();
            this.validatePhoneNumber();
        });
    }
    getCourseEnquiryModel() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.tenantHomeService.getCourseEnquiryModel(this.courseId).then(data => {
                this.courseEnquiryModel = data;
                this.setTenantImgSrc();
                this.checkPriceType();
                this.detectChanges();
            });
        });
    }
    setTenantImgSrc() {
        if (this.courseEnquiryModel && this.courseEnquiryModel.tenantLogo
            && this.courseEnquiryModel.tenantLogo.logoRelativePath != null && this.courseEnquiryModel.tenantLogo.logoRelativePath != ""
            && this.courseEnquiryModel.tenantLogo.logoName != null && this.courseEnquiryModel.tenantLogo.logoName != "") {
            this.tenantLogoSrc = this.courseEnquiryModel.tenantLogo.logoRelativePath + '\\60x60\\' + this.courseEnquiryModel.tenantLogo.logoName;
        }
        else {
            this.tenantLogoSrc = this.ejLogoSrc;
        }
        this.detectChanges();
    }
    checkPriceType() {
        for (let i = 0; i < this.courseEnquiryModel.trainingProgramViewModel.dailySchedules.length; i++) {
            if (this.courseEnquiryModel.trainingProgramViewModel.dailySchedules[i] != undefined && this.courseEnquiryModel.trainingProgramViewModel.dailySchedules[i].dailyscheduleprice.price > 0) {
                this.hasDailyPrice = true;
                break;
            }
        }
        for (let i = 0; i < this.courseEnquiryModel.trainingProgramViewModel.weeklySchedules.length; i++) {
            if (this.courseEnquiryModel.trainingProgramViewModel.weeklySchedules[i] != undefined && this.courseEnquiryModel.trainingProgramViewModel.weeklySchedules[i].weeklyscheduleprice.price > 0) {
                this.hasWeeklyPrice = true;
                break;
            }
        }
        this.detectChanges();
    }
    tenantHomeNav() {
        this.router.navigate(['/']);
    }
    saveEnquiryEvent(form) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.validateGRrecaptcha() && !this.phoneNumberLengthError) {
                this.saveEnquiryBtnDisabled = true;
                yield this.saveEnquiry(form);
            }
        });
    }
    saveEnquiry(form) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.loading = true;
            this.detectChanges();
            this.enquiryModel.gRecaptchaResponse = this.gRecaptchaResponse;
            this.prependCourse();
            yield this.tenantHomeService.saveEnquiry(this.enquiryModel).then(response => {
                this.loading = false;
                if (response.status) {
                    //this.toasterService.success(response.information);
                    Swal.fire(response.information);
                    form.resetForm();
                    this.saveEnquiryBtnDisabled = false;
                    this.recaptchaRef.reset();
                    this.enquiryModel.mobile = "";
                }
                else {
                    //this.toasterService.error(response.information);
                    Swal.fire(response.information);
                    this.saveEnquiryBtnDisabled = false;
                }
            });
        });
    }
    ;
    validateGRrecaptcha() {
        //recaptcha failed validation
        if (this.gRecaptchaResponse.length == 0) {
            this.errorRecaptchaRender = true;
            return false;
        }
        //recaptcha passed validation
        else {
            this.errorRecaptchaRender = false;
            return true;
        }
    }
    resolved(captchaResponse) {
        this.gRecaptchaResponse = `${captchaResponse}`;
    }
    prependCourse() {
        var courseName = document.getElementById('course-name').innerText;
        if (this.enquiryModel.message.indexOf(courseName) == -1) {
            this.enquiryModel.message = courseName + " - " + this.enquiryModel.message;
        }
    }
    receivePhoneNumber(number) {
        this.enquiryModel.mobile = number;
        this.validatePhoneNumber();
    }
    receivePhoneNumberError(hasError) {
        this.phoneNumberLengthError = hasError;
    }
    validatePhoneNumber() {
        if (this.enquiryModel.mobile == undefined || this.enquiryModel.mobile == null || this.enquiryModel.mobile == '') {
            this.phoneNumberError = true;
        }
        else if (this.enquiryModel.mobile.indexOf('-') == -1 || this.enquiryModel.mobile.split('-')[0] == undefined || this.enquiryModel.mobile.split('-')[0] == null || this.enquiryModel.mobile.split('-')[0] == "") {
            this.phoneCountryCodeError = true;
            this.phoneNumberError = false;
        }
        else if (this.enquiryModel.mobile.split('-')[1] == undefined || this.enquiryModel.mobile.split('-')[1] == null || this.enquiryModel.mobile.split('-')[1] == "") {
            this.phoneNumberError = true;
        }
        else {
            this.phoneNumberError = false;
            this.phoneCountryCodeError = false;
        }
    }
    processTimeInput(timeInput) {
        if (isNaN(Date.parse(timeInput))) {
            return this.getDateTime(new Date(), timeInput);
        }
        else {
            return timeInput;
        }
    }
    getDateTime(date, time) {
        var index = time.indexOf(":");
        var index2 = time.indexOf(" ");
        var hours = time.substring(0, index);
        var minutes = time.substring(index + 1, index2);
        var mer = time.substring(index2 + 1, time.length);
        if (hours == "12")
            hours = (mer == "AM") ? parseInt(hours) - 12 : hours;
        else
            hours = (mer == "PM") ? parseInt(hours) + 12 : hours;
        date.setHours(hours);
        date.setMinutes(minutes);
        date.setSeconds("00");
        return date;
    }
    detectChanges() {
        if (this.cdRef && !this.cdRef.destroyed) {
            this.cdRef.detectChanges();
        }
    }
}
