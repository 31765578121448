import { Component } from '@angular/core';
import { UpgradeModule } from "@angular/upgrade/static";
import { ActivatedRoute } from '@angular/router';
import { LayoutConfigService } from './layout/services/layout-config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(private upgrade: UpgradeModule,
    private readonly route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.upgrade.bootstrap(document.body, ['eduJournalApp']);
  }
}